import {NxSelectOption} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import {SelectHelper} from '../../utils/select-helper';
import {LoanConfiguration} from '../model/loan-configuration.model';
import {PaymentInterval} from '../model/payment-interval.model';

export enum PaymentIntervalName {
  SINGLE_PAYMENT_NAME = 'Single payment',
  MONTHLY_NAME = 'Monthly'
}

type IsSinglePaymentCheck = (id?: number | null) => boolean;

export interface PaymentIntervalOptionsProps {
  paymentIntervalOptions?: NxSelectOption[];
  getIsSinglePaymentInterval: IsSinglePaymentCheck;
}

export default function usePaymentIntervalOptions(
  loanProduct: LoanConfiguration, paymentIntervals?: PaymentInterval[]
): PaymentIntervalOptionsProps {

  const singlePaymentEntryId = paymentIntervals?.find(({name}): boolean => name === PaymentIntervalName.SINGLE_PAYMENT_NAME)?.id;

  const getIsSinglePaymentInterval = (singlePaymentEntryId?: number | null): IsSinglePaymentCheck =>
    (id?: number | null): boolean => !isNil(singlePaymentEntryId) && Number(id) === Number(singlePaymentEntryId);

  const availableOptionsForConfiguration = paymentIntervals?.filter(paymentIntervals => loanProduct.paymentIntervalIds?.includes(paymentIntervals.id));

  return {
    paymentIntervalOptions: SelectHelper.mapPaymentIntervalsToStringOptions(availableOptionsForConfiguration),
    getIsSinglePaymentInterval: getIsSinglePaymentInterval(singlePaymentEntryId)
  };
}

