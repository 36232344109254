import {RoutePaths} from '../../../../routes/routes.paths';
import {StringHelper} from '../../../../utils/string-helper';
import {ApplicationBasicData} from '../../loan-application/loan-application.model';

// TODO [LOS-238]
// Current solution is rather weak as we probably should map based on currentPhaseType, because route paths are already
// connected with it (see predefined-steps), but phase type is not easily accessible from BE at applications list level

export const getPhaseRoutePath = (phaseName: string, phaseId: number): string => {
  switch (phaseName) {
    case 'Calculator':
      return RoutePaths.CALCULATOR;
    case 'Customer data':
      return RoutePaths.CUSTOMER_DATA;
    case 'Eligibility criteria':
      return RoutePaths.ELIGIBILITY_CRITERIA;
    case 'Loan application data':
      return RoutePaths.LOAN_APPLICATION_DATA;
    case 'Agreements':
      return RoutePaths.AGREEMENTS;
    case 'Pre release':
      return RoutePaths.PRE_RELEASE;
    case 'Summary':
      return RoutePaths.SUMMARY;
    default:
      return `${RoutePaths.CUSTOM}/${phaseId}`; // if no step matches assume it's custom phase
  }
};

// Client full name patter is "Lastname M. Firstname" M. -> first letter of middle name
export const getFullClientName = (application: ApplicationBasicData): string =>
  StringHelper.getClientName(
    application.applicantFirstName, application.applicantMiddleName, application.applicantLastName
  );
