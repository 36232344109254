import {NxFormikRadio, NxRadioVariant} from '@nextbank/ui-components';
import {useFormikContext} from 'formik';
import includes from 'lodash/includes';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {SecurityContext} from '../../../../../App';
import {isSysAdminOrManager} from '../../../../../utils/permissions-utils';
import {TransHelper} from '../../../../../utils/trans-helper';
import {FormikCheckbox} from '../../../../shared/nx-formik-checkbox/FormikCheckbox';
import AdvancedSearchMenu from '../../../../shared/search-bar/advanced-search-menu/AdvancedSearchMenu';
import {ApplicationStatus, ApprovalStatus} from '../../../loan-application/loan-application.model';
import {FilterValues, TableType} from '../../loan-application-dashboard.model';
import {filtersDefaultState} from '../../LoanApplicationDasboard';
import styles from './ApplicationsAdvancedSearch.module.scss';

const ADVANCED_SEARCH_ID = 'advanced-search';

export const TRANS_PREFIX = 'LOAN_APPLICATIONS.SEARCH_BAR';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

const ApplicationsAdvancedSearch = (): ReactElement => {

  const {t} = useTranslation();
  const {userData} = useContext(SecurityContext);
  const {values, setValues} = useFormikContext<FilterValues>();

  // Status/Approval filters are not applied for TO_APPROVE / TO_RELEASE / TO_UPDATE
  const areCheckboxesDisabled =
    includes([TableType.TO_APPROVE, TableType.TO_RELEASE, TableType.TO_UPDATE], values.tableType);

  useEffect(() => {
    if (areCheckboxesDisabled) {
      setValues({
        ...values,
        applicationStatuses: [],
        approvalStatuses: []
      });
    }
  }, [areCheckboxesDisabled]);

  const isFilterActive = (): boolean => {
    return values.applicationStatuses.length > 0 || values.approvalStatuses.length > 0;
  };

  const availableTableTypes = isSysAdminOrManager(userData)
    ? Object.values(TableType)
    : Object.values(TableType).filter(value => value != TableType.ALL_APPLICATIONS);

  const options = availableTableTypes
    .map(value => ({value, key: value, label: t(`${TRANS_PREFIX}.TABLE_TYPE.${value}`)}));

  const applicationStatusOptions = Object.values(ApplicationStatus)
    .filter(value => value !== ApplicationStatus.ERROR)
    .map(value => ({value, label: t(`LOAN_APPLICATIONS.APPLICATION_STATUS.${value}`)}));

  const approvalStatusesOptions = Object.values(ApprovalStatus)
    .filter(value => value !== ApprovalStatus.REQUIRED && value !== ApprovalStatus.APPROVED)
    .map(value => ({value, label: t(`LOAN_APPLICATIONS.APPROVAL_STATUS.${value}`)}));

  const MenuForm = (): ReactElement => {
    return (
      <>
        <div className={styles.description}>
          <PrefixTrans>APPLICATION_OVERVIEW</PrefixTrans>
        </div>
        <NxFormikRadio name={'tableType'}
                       options={options}
                       variant={NxRadioVariant.COLUMN} />
        <div className={styles.description}>
          <PrefixTrans>APPLICATION_STATUS</PrefixTrans>
        </div>
        <div className={styles.checkboxes}>
          {
            applicationStatusOptions.map(option =>
              <FormikCheckbox
                disabled={areCheckboxesDisabled}
                value={option.value}
                name='applicationStatuses'
                key={option.value}>
                {option.label}
              </FormikCheckbox>
            )
          }
        </div>
        <div className={styles.description}>
          <PrefixTrans>APPROVAL_STATUS</PrefixTrans>
        </div>
        <div className={styles.checkboxes}>
          {
            approvalStatusesOptions.map(option =>
              <FormikCheckbox
                disabled={areCheckboxesDisabled}
                value={option.value}
                name='approvalStatuses'
                key={option.value}>
                {option.label}
              </FormikCheckbox>
            )
          }
        </div>
      </>
    );
  };

  return (
    <AdvancedSearchMenu menuId={ADVANCED_SEARCH_ID}
                        isActive={isFilterActive()}
                        onReset={(): void => setValues(filtersDefaultState)}>
      <MenuForm />
    </AdvancedSearchMenu>
  );
};

ApplicationsAdvancedSearch.displayName = 'AdvancedSearchMenu';

export default ApplicationsAdvancedSearch;
