import {omit} from 'lodash';
import {CheckGroup} from '../../../../shared/model/check-group.model';
import {Check} from '../../../../shared/model/check.model';
import {generateKey} from '../../../../utils/generate-key';
import {DraggableItem} from '../../../shared/drag-drop/DragDropItems';

export type DraggableCheck = Check & DraggableItem

export type DraggableCheckGroup = CheckGroup<DraggableCheck> & DraggableItem

export const toDraggableCheckGroup = (checkGroup: CheckGroup): DraggableCheckGroup => ({
  ...checkGroup,
  ...generateKey(),
  checks: checkGroup.checks.map(check => toDraggableCheck(check))
});

export const toDraggableCheck = (check: Check): DraggableCheck => ({
  ...check,
  ...generateKey()
});

// TODO LOS-421 Move setting order to draggable checks when all models adjusted to DraggableItem with orderNO
export const toCheckGroups = (checkGroups: DraggableCheckGroup[]): CheckGroup[] => {
  return checkGroups.map((draggableGroup, orderNo) => toCheckGroup({...draggableGroup, orderNo}));
};

const toCheckGroup = (droppableCheckGroup: DraggableCheckGroup): CheckGroup => {
  const {checks, ...checkGroup} = omit(droppableCheckGroup, 'key');
  const checksWithoutKeys = checks.map(field => omit(field, 'key'));

  return {
    ...checkGroup,
    checks: checksWithoutKeys.map((checks, orderNo) => ({...checks, orderNo}))
  };
};
