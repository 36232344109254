import {NxSelectOption} from '@nextbank/ui-components';
import {TFunction} from 'i18next';
import {DocumentFieldType, FieldType} from '../shared/model/field.model';

const sortOptionsAlphabetically = (optionA, optionB): number => optionA.label < optionB.label ? -1 : 1;

export const getFieldTypeOptions = (t: TFunction, showDocumentType = true): NxSelectOption[] =>
  Object.values(FieldType)
    .filter(type => (showDocumentType || type !== FieldType.DOCUMENT))
    .map(value => ({
        label: t(`COMMON.FIELD_TYPES.${value}`),
        value
    }))
    .sort(sortOptionsAlphabetically);

export const getDocumentTypeOptions = (t: TFunction): NxSelectOption[] =>
  Object.values(DocumentFieldType)
    .map(value => ({
      label: t(`COMMON.DOCUMENT_TYPES.${value}`),
      value
    }));

export const isMultiDictionary = (type?: FieldType): boolean => type === FieldType.DICTIONARY_MULTI;
export const isDictionaryType = (type?: FieldType): boolean =>
  isMultiDictionary(type) || type === FieldType.DICTIONARY_SINGLE;
