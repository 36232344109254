import {RoutePaths} from '../../../../routes/routes.paths';
import {LoanStep} from '../../../../shared/model/loan-step.model';
import {Phase} from '../../../../shared/model/phase.model';
import {LoanSteps} from '../../../../utils/loan-steps-base-class';
import {buildConfigurationStepPath, getPredefinedConfigurationSteps} from '../../../../utils/loan-steps-utils';
import {PREDEFINED_FIRST_CONFIGURATION_STEPS, PREDEFINED_LAST_CONFIGURATION_STEPS} from './predefined-steps';

export class LoanConfigurationSteps extends LoanSteps {

  constructor(processId: number, customPhases: Phase[] = []) {

    const steps = [
      ...getPredefinedConfigurationSteps(processId, PREDEFINED_FIRST_CONFIGURATION_STEPS),
      ...customPhases.map(phase => ({
        label: phase.name,
        path: buildConfigurationStepPath(`${RoutePaths.CUSTOM}/${phase.id}`, processId),
        isCustomStep: true
      })) as LoanStep[],
      ...getPredefinedConfigurationSteps(processId, PREDEFINED_LAST_CONFIGURATION_STEPS)
    ];

    steps.forEach((value, index) => value.orderNo = `${index}`);

    super(steps);
  }

  showFinalApprovalConfiguration(path: string): boolean {
    const index = this.getStepIndex(path);
    return !this.steps[index].hiddenFinalApprovalConfiguration;
  }
}
