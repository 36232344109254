import React, {ReactElement, useContext} from 'react';
import {CATEGORIES_URL} from '../../../../../constants/api-urls';
import useDelete from '../../../../../shared/hooks/use-delete.hook';
import {TransHelper} from '../../../../../utils/trans-helper';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {ConfirmationPopup} from '../../../../shared/confirmation-popup/ConfirmationPopup';
import {CategoriesContext} from '../../Categories';

export const PrefixTrans = TransHelper.getPrefixedTrans('ADMIN.CATEGORIES.DELETE_CONFIRMATION_POPUP');

interface Props {
  categoryId?: number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const DeleteCategoryConfirmationPopup = ({categoryId, open, setOpen}: Props): ReactElement => {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {refreshCategories, setLoading} = useContext(CategoriesContext);
  const deleteCategory = useDelete<void>(`${CATEGORIES_URL}/${categoryId}`);

  const onConfirm = async (): Promise<void> => {
    setLoading(true);
    setOpen(false);

    deleteCategory()
      .then(() => {
        refreshCategories();
      })
      .catch(error => {
        setLoading(false);
        showErrorMessage(error.message)
      });
  }

  return (
    <ConfirmationPopup header={<PrefixTrans>HEADER</PrefixTrans>}
                       description={<PrefixTrans>DESCRIPTION</PrefixTrans>}
                       confirmButtonLabel={<PrefixTrans>CONFIRM</PrefixTrans>}
                       onDeny={(): void => setOpen(false)}
                       onConfirm={onConfirm}
                       open={open} />
  );
};

export default DeleteCategoryConfirmationPopup;
