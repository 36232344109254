import {omit} from 'lodash';
import {ApprovalRule, Approver} from '../../../../../../shared/model/phase.model';
import {generateKey} from '../../../../../../utils/generate-key';

export interface KeyedApprovalRule extends ApprovalRule<KeyedApprover[]> {
  key: string;
}

export interface KeyedApprover extends Approver {
  key: string;
}

export const toKeyedApprovalRule = (approvalRule: ApprovalRule): KeyedApprovalRule => ({
  ...approvalRule,
  ...generateKey(),
  approvers: approvalRule.approvers.map(approver => toKeyedApprover(approver))
});

export const toKeyedApprover = (approver: Approver): KeyedApprover => ({
  ...approver,
  ...generateKey()
});

export const toApprovalRule = (keyedApprovalRule: KeyedApprovalRule): ApprovalRule => {
  const {approvers, ...approvalRule} = omit(keyedApprovalRule, 'key');
  const approversWithoutKeys = approvers.map(approver => omit(approver, 'key'));

  return {
    ...approvalRule,
    approvers: approversWithoutKeys.map((approver, orderNo) => ({...approver, orderNo}))
  };
};
