import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import {useMemo} from 'react';
import {
  CustomerDataPhase
} from '../../components/loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {DictionaryHelper} from '../../utils/dictionary-helper';
import {areNotNil} from '../../utils/empty-checks-utils';
import {StaticFieldsHelper} from '../../utils/step-form-utils/static-fields-helper';
import {DictionaryEntry} from '../model/dictionary.model';
import useDictionariesQuery from './use-dictionaries-query.hook';

export interface IndividualDataDictionariesState {
  areCorporateDataDictionaryEntriesLoaded?: boolean;
  corporateDataDictionaryEntries: CorporateDataDictionaries;
}

export interface CorporateDataDictionaries {
  businessType?: DictionaryEntry[];
  riskLevel?: DictionaryEntry[];
  dosriType?: DictionaryEntry[];
  registrationCountry?: DictionaryEntry[];
  conglomerate?: DictionaryEntry[];
  relatedPartyTransaction?: DictionaryEntry[];
}

export interface CorporateDataDictionaryIds {
  businessType?: number;
  riskLevel?: number;
  dosriType?: number;
  registrationCountry?: number;
  relatedPartyTransaction?: number;
}

const getDictionaryIds = (config: CustomerDataPhase): CorporateDataDictionaryIds =>
  mapValues({
    businessType: 'BUSINESS_TYPE',
    riskLevel: 'RISK_LEVEL',
    dosriType: 'DOSRI_TYPE',
    registrationCountry: 'REGISTRATION_COUNTRY',
    conglomerate: 'CONGLOMERATE',
    relatedPartyTransaction: 'RELATED_PARTY_TRANSACTION'
  }, code => StaticFieldsHelper.getFieldDictionaryId(config, code));

export default function useCorporateDataDictionaries(config?: CustomerDataPhase): IndividualDataDictionariesState {

  const dictionaryIds: CorporateDataDictionaryIds = useMemo(
    () => config ? getDictionaryIds(config) : {},
    [config]
  );

  const {dictionaries, loaded} = useDictionariesQuery(dictionaryIds);
  const corporateDataEntries = mapValues(dictionaryIds, id => DictionaryHelper.getEntries(dictionaries, id));

  return {
    corporateDataDictionaryEntries: {
      ...corporateDataEntries,
      registrationCountry: DictionaryHelper.movePhilippinesDictionaryToTop(corporateDataEntries.registrationCountry)
    },
    areCorporateDataDictionaryEntriesLoaded: loaded && areNotNil(values(corporateDataEntries))
  };
}

