import {Table, TableBody, TableContainer, TableRow} from '@material-ui/core';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {LoanCreationRouteIds, RoutePaths} from '../../../../routes/routes.paths';
import {Process} from '../../../../shared/model/process.model';
import {CashHelper} from '../../../../utils/cash-helper';
import {buildApplicationStepPath} from '../../../../utils/loan-steps-utils';
import {StringHelper} from '../../../../utils/string-helper';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import Instruction from '../../../shared/instruction/Instruction';
import TableDataCell from '../../../shared/table/cells/TableDataCell';
import LoanTypeSelectionTableHead from './loan-type-selection-table-head/LoanTypeSelectionTableHead';
import styles from './LoanTypeSelectionTable.module.scss';
import {LoanCreationType} from '../../../../shared/model/creation-type.model';
import {CustomerType} from '../../../../shared/model/customer-type.model';
import {UrlParams} from '../../../../routes/routes.model';
import {useParams} from 'react-router';
import isNil from 'lodash/isNil';

interface Props {
  processes: Process[] | undefined;
  loanCreationType?: LoanCreationType;
  applicantSourceId?: number;
  customerType?: CustomerType;
}

const {addDays, addPercentage} = StringHelper;

const LoanTypeSelectionTable = ({
                                  processes,
                                  loanCreationType,
                                  applicantSourceId,
                                  customerType
                                }: Props): React.ReactElement => {

  const {t} = useTranslation();
  const history = useGuardedHistory();
  const {creationType} = useParams<UrlParams>();

  const navigateToNewLoanApplication = (processId: number): () => void => (): void => {
    let newLoanCalculatorPath;
    if (isNil(creationType) && loanCreationType === LoanCreationType.CONSOLIDATION && customerType) {
      newLoanCalculatorPath = buildApplicationStepPath(RoutePaths.CALCULATOR,
        processId,
        `${LoanCreationRouteIds.CONSOLIDATE}/${applicantSourceId}/${customerType.toLowerCase()}`);
    } else {
      newLoanCalculatorPath = buildApplicationStepPath(RoutePaths.CALCULATOR, processId, creationType);
    }
    history.push(newLoanCalculatorPath);
  };

  return (
    <>
      <Instruction>
        <Trans>LOAN_APPLICATIONS.SELECT_APPLICATION</Trans>
      </Instruction>
      <TableContainer>
        <Table>
          <LoanTypeSelectionTableHead />
          <TableBody>
            {
              processes?.map(({id, loanProduct}, index) =>
                <TableRow onClick={navigateToNewLoanApplication(id)}
                          className={styles.tableRow}
                          key={index}>
                  <TableDataCell data={index + 1} />
                  <TableDataCell data={loanProduct.code} />
                  <TableDataCell data={loanProduct.name} />
                  <TableDataCell data={addPercentage(loanProduct.minInterestRate)} />
                  <TableDataCell data={addPercentage(loanProduct.maxInterestRate)} />
                  <TableDataCell data={CashHelper.addCurrencyMask(loanProduct.minAmount)} />
                  <TableDataCell data={CashHelper.addCurrencyMask(loanProduct.maxAmount)} />
                  <TableDataCell data={addDays(loanProduct.minTerm, t)} />
                  <TableDataCell data={addDays(loanProduct.maxTerm, t)} />
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LoanTypeSelectionTable;
