import {DictionaryEntryRequirement, DictionaryRequirement} from '../shared/model/dictionary-requirement.model';
import {Dictionary, DictionaryEntry} from '../shared/model/dictionary.model';

export const DictionaryHelper = {
  getEntries: (dictionaries?: Dictionary[], dictionaryId?: number): DictionaryEntry[] | undefined => {
    if (!dictionaries || !dictionaryId) {
      return;
    }

    return dictionaries.find(dictionary => dictionary.id === dictionaryId)?.dictionaryEntries;
  },

  getEntryCodeById: (id: number | string, entries?: DictionaryEntry[]): string => {
    const foundEntry = entries?.find(entry => String(entry.id) === String(id));
    if (!foundEntry) {
      throw Error('Dictionary entry not found for provided entry ID');
    }
    return foundEntry.code;
  },

  getEntryIdByCode: (fieldCode: unknown, dictionary?: DictionaryEntry[]): string | undefined => {

    const entry = dictionary?.find(value => value.code === fieldCode);

    if (!entry) {
      console.debug('Dictionary entry for requested field not found: ', fieldCode);
      return;
    }

    return `${entry.id}`;
  },

  getEntryRequirementLabel: (entries?: DictionaryEntry[], requirement?: DictionaryEntryRequirement): string => {
    const entry = entries?.find(entry => entry.id === requirement?.dictionaryEntryId);
    return entry?.name ?? '';
  },

  getEnabledEntryRequirements: (dictionaryRequirement?: DictionaryRequirement): DictionaryEntryRequirement[] =>
    dictionaryRequirement ? dictionaryRequirement.entryRequirements.filter(entry => entry.enabled) : [],

  movePhilippinesDictionaryToTop: (entries: DictionaryEntry[] | undefined): DictionaryEntry[] | undefined => {
    return moveDictionaryEntryToTop('Philippines', entries);
  },
  moveDictionaryEntryToTop:
    (optionLabel: string, entries: DictionaryEntry[] | undefined): DictionaryEntry[] | undefined =>
      moveDictionaryEntryToTop(optionLabel, entries)

};

const moveDictionaryEntryToTop = (optionLabel: string,
                                  entries: DictionaryEntry[] | undefined): DictionaryEntry[] | undefined => {

  if (!entries) {
    return;
  }

  const searchedOption = entries?.find(value => value.name === optionLabel);
  if (searchedOption) {
    const filteredOptions = entries.filter(value => value.name !== optionLabel);

    return [
      searchedOption,
      ...filteredOptions
    ];
  }

  return entries;
};
