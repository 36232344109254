import React from 'react';
import styles from './FormGroup.module.scss';

interface Props {
  children?: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  visible?: boolean;
}

const FormGroup = ({children, header, className, visible = true}: Props): React.ReactElement => (
  <>
    {
      visible &&
        <div className={className}>
          {
            header && <div className={styles.header}>{header}</div>
          }
          {children}
        </div>
    }
  </>
);

export default FormGroup;
