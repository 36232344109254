import {Avatar} from '@material-ui/core';
import {NxLoader} from '@nextbank/ui-components';
import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useMemo} from 'react';
import {Trans} from 'react-i18next';
import {SecurityContext} from '../../../../App';
import {MANAGEMENT_BRANCHES_URL} from '../../../../constants/api-urls';
import {DEFAULT_DATE_FORMAT} from '../../../../constants/format-templates';
import {UserData} from '../../../../security-context.model';
import useSingleQuery from '../../../../shared/hooks/use-single-query.hook';
import {Branch} from '../../../../shared/model/management.model';
import {SystemContext} from '../../../shared/system-context-provider/SystemContextProvider';
import LogoutButton from './logout-button/LogoutButton';
import styles from './UserDetails.module.scss';

const getUserInitials = (userData: UserData): string => userData?.username.split(' ')
  .map(token => token[0])
  .join()
  .toUpperCase();

const UserDetails = (): ReactElement => {

  const {userData} = useContext(SecurityContext);
  const {webServerTime} = useContext(SystemContext);
  const branches = useSingleQuery<Branch[]>(MANAGEMENT_BRANCHES_URL);

  const branchName = useMemo(() => branches?.find(branch => branch.id === userData?.branchId)?.name, [branches]);

  return isNil(userData)
    ? <NxLoader />
    :
    <div className={styles.userData}>
      <span className={styles.userName}>{userData.username}</span>
      <Avatar>{getUserInitials(userData)}</Avatar>
      <div className={styles.details}>
        {branchName}
        <div className={styles.systemDate}>
          <span><Trans>TOP_BAR.USER_DATA.SYSTEM_DATE</Trans></span>
          <span>{dayjs(webServerTime).format(DEFAULT_DATE_FORMAT)}</span>
        </div>
      </div>
      <LogoutButton />
    </div>;
};

export default UserDetails;
