import {isNil} from 'lodash';
import isEqual from 'lodash/isEqual';
import {useEffect, useRef, useState} from 'react';
import useFileUpload from '../../../../../../shared/hooks/use-file-upload.hook';
import {NxFile} from '../../../../../../shared/model/nx-file.model';
import {
  CustomerDataPhase
} from '../../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {handleNxFiles} from '../../shared/custom-checks/check-values-utils';
import {CustomerDataFormHelper} from '../customer-data-form-helper';
import {Applicant} from '../customer-data-form.model';
import {CustomFieldValue, CustomFieldValueTO} from './custom-field.model';

const {getCustomFields} = CustomerDataFormHelper;

interface CustomFieldInitValues {
  customFieldInitValues: (CustomFieldValue | CustomFieldValue<NxFile>)[];
  areCustomFieldValuesLoaded: boolean;
}

export default function useCustomFieldInitValues(config?: CustomerDataPhase,
                                                 applicants?: Applicant[]): CustomFieldInitValues {

  const lastValue = useRef<CustomFieldValueTO[] | undefined>();
  const newCustomFieldValues = applicants?.flatMap(applicant => applicant.customFieldValues);

  const {downloadFile} = useFileUpload();
  const [customFieldValues, setCustomFieldValues] = useState<(CustomFieldValue | CustomFieldValue<NxFile>)[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isNil(config)) {
      return;
    }

    if (isEqual(lastValue.current, newCustomFieldValues)) {
      return;
    }
    lastValue.current = newCustomFieldValues;

    setLoaded(false);
    const promises = getCustomFields(config, newCustomFieldValues).map(value => handleNxFiles(value, downloadFile));

    Promise.all(promises)
      .then(resolvedData => {
        setCustomFieldValues(resolvedData as (CustomFieldValue | CustomFieldValue<NxFile>)[]);
        setLoaded(true);
      })
      .catch(e => console.error('Error when handling custom field files', e));
    // TODO check dependencies
  }, [config, newCustomFieldValues]);

  return {customFieldInitValues: customFieldValues, areCustomFieldValuesLoaded: loaded};
}
