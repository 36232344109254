import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import {useEffect, useRef, useState} from 'react';
import useFileUpload from '../../../../../../shared/hooks/use-file-upload.hook';
import {
  CustomerDataPhase
} from '../../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {CustomerDataFormHelper} from '../customer-data-form-helper';
import {Applicant} from '../customer-data-form.model';
import {IdDocument, IdDocumentTO} from './id-document.model';

interface IdDocumentFilesState {
  idDocuments: IdDocument[];
  areIdDocumentsLoaded: boolean;
}

const {getIdDocuments} = CustomerDataFormHelper;

export default function useIdDocumentFiles(applicants: Applicant[], config?: CustomerDataPhase): IdDocumentFilesState {

  const lastValue = useRef<IdDocumentTO[]>();
  const newIdDocumentsTO = applicants?.flatMap(applicant => applicant.idDocuments);

  const {downloadFile} = useFileUpload();
  const [idDocumentFiles, setIdDocumentFiles] = useState<IdDocument[]>([]);
  const [areIdDocumentsLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isNil(config)) {
      return;
    }

    if (isEqual(lastValue.current, newIdDocumentsTO)) {
      return;
    }
    lastValue.current = newIdDocumentsTO;

    if (isEmpty(newIdDocumentsTO)) {
      setLoaded(true);
      return;
    }

    setLoaded(false);
    const idDocumentPromises = newIdDocumentsTO.map(async existingDocument => {
      const scans = await Promise.all(existingDocument.scans.map(scan => downloadFile(scan)));
      return {...existingDocument, scans};
    });

    Promise.all(idDocumentPromises)
      .then(parsedDocuments => {
        setIdDocumentFiles(parsedDocuments);
        setLoaded(true);
      });
  }, [newIdDocumentsTO, config]);

  return {
    idDocuments: !isNil(config) ? getIdDocuments(config, idDocumentFiles) : [],
    areIdDocumentsLoaded: areIdDocumentsLoaded
  };
}
