import {NxFormikPercentageInput} from '@nextbank/ui-components';
import React, {useContext} from 'react';
import {ConfiguredField} from '../../../../../../shared/model/field.model';
import {StepContext} from '../loan-application-step/LoanApplicationStep';
import {ConfiguredFieldWrapper} from './ConfiguredFieldWrapper';
import {FieldDisablementReason} from './field-disablement-reason.model';

interface Props {
  field: ConfiguredField<number>;
  label: React.ReactNode;
  name: string;
  disablementReason?: FieldDisablementReason;
  hint?: React.ReactNode;
}

export const ConfiguredFieldPercentageInput = (
  {field, label, name, disablementReason, hint}: Props
): React.ReactElement => {

  const {isStepReadonly} = useContext(StepContext);

  return (
    <ConfiguredFieldWrapper enabled={field.enabled} disablementReason={disablementReason} hint={hint}>
      <NxFormikPercentageInput disabled={isStepReadonly || !!disablementReason}
                               required={field.required}
                               name={`${name}.value`}
                               label={label} />
    </ConfiguredFieldWrapper>
  );
};
