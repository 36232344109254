import {isNil} from 'lodash';
import {UploadFileFunctionType} from '../shared/hooks/use-file-upload.hook';
import {FileBasicData, NxFile} from '../shared/model/nx-file.model';

export const handleFileUpload = (fileData: NxFile, uploadFile: UploadFileFunctionType): Promise<FileBasicData> => {

  const {file, isChanged, id} = fileData;

  if (!file || !isChanged) {
    return Promise.resolve({id} as FileBasicData);
  }

  return uploadFile({file, publiclyAccessible: false})
    .then(id => {
      if (!id) {
        throw Error('Id from response is undefined');
      }

      return {id} as FileBasicData;
    });
};

export const blobToBase64String = (blobFile: Blob): Promise<string> => {

  const reader = new FileReader();

  return new Promise((resolve, reject): void => {

    if (isNil(blobFile)) {
      reject('Blob file is null or undefined!');
      return;
    }

    reader.readAsDataURL(blobFile);
    reader.onloadend = (): void => resolve(reader.result?.toString() ?? '');
  });
};

export const blobResponseToFile = async (blobFile: File, fileName: string): Promise<File> => {

  const buffer = await blobFile.arrayBuffer();

  return new File([buffer], fileName);
};
