import React, {createContext, useState} from 'react';
import {AlertSnackbarActionType, useAlertSnackbar} from '../alert-snackbar/alert-snackbar-hook';
import {AlertSnackbar, Severity, SnackBarMessage} from '../alert-snackbar/AlertSnackbar';

interface AppSnackbarContextType {
  showErrorMessage: (message: SnackBarMessage, stayOpen?: boolean) => void;
  showSuccessMessage: (message: SnackBarMessage) => void;
  closeMessage: () => void;
}

export const AppSnackbarContext = createContext<AppSnackbarContextType>({
  showErrorMessage: () => ({}),
  showSuccessMessage: () => ({}),
  closeMessage: () => ({})
});

interface Props {
  children: React.ReactNode;
}

export function AppSnackbarProvider({children}: Props): React.ReactElement {
  const [snackbarState, dispatchSnackbarAction] = useAlertSnackbar();
  const [stayOpen, setStayOpen] = useState(false);

  const showMessage = (message: SnackBarMessage, severity: Severity): void =>
    dispatchSnackbarAction({
      type: AlertSnackbarActionType.SHOW,
      severity,
      message
    });
  const handleClose = (): void => {
    if (!stayOpen) {
      dispatchSnackbarAction({type: AlertSnackbarActionType.HIDE});
    }
  };

  const closeMessage = (): void => {
    setStayOpen(false);
    handleClose();
  };

  const showErrorMessage = (message: SnackBarMessage, stayOpen = false): void => {
    setStayOpen(stayOpen);
    showMessage(message, Severity.ERROR);
  };

  const showSuccessMessage = (message: SnackBarMessage): void => showMessage(message, Severity.SUCCESS);

  return (
    <>
      <AlertSnackbar handleClose={(): void => handleClose()}
                     severity={snackbarState.severity}
                     message={snackbarState.message}
                     open={snackbarState.isOpen} />
      <AppSnackbarContext.Provider value={{showErrorMessage, showSuccessMessage, closeMessage}}>
        {children}
      </AppSnackbarContext.Provider>
    </>
  );
}

