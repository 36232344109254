export enum ReportStatus {
  // Report is waiting to be run
  SCHEDULED = 'SCHEDULED',
  // Report is currently generating
  STARTED = 'STARTED',
  // Report failed to generate
  FAILED = 'FAILED',
  // Report was generated
  COMPLETED = 'COMPLETED'
}

export interface ReportResponse {
  status: ReportStatus;
  url: string;
}
