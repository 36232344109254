import {useField, useFormikContext} from 'formik';
import React, {ReactElement, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../../../../assets/images/icon-close.svg';
import {TransHelper} from '../../../../../utils/trans-helper';
import {ApplicationStatus, ApprovalStatus} from '../../../loan-application/loan-application.model';
import {FilterValues, TableType} from '../../loan-application-dashboard.model';
import styles from './ActiveFitlers.module.scss';

export const TRANS_PREFIX = 'LOAN_APPLICATIONS';
export const SEARCH_BAR_TRANS_PREFIX = `${TRANS_PREFIX}.SEARCH_BAR`;
export const PrefixTrans = TransHelper.getPrefixedTrans(SEARCH_BAR_TRANS_PREFIX);

const ActiveFilters = (): ReactElement => {

  const {t} = useTranslation();
  const {values: filterState,submitForm} = useFormikContext<FilterValues>();
  const [applicationStatuses, , applicationStatusesHelper] = useField<string[]>('applicationStatuses');
  const [approvalStatuses, , approvalStatusesHelper] = useField<string[]>('approvalStatuses');

  const getTableType = (): ReactNode => {
    return Object.values(TableType)
      .filter(tableType => tableType === filterState.tableType)
      .map(tableType => t(`${SEARCH_BAR_TRANS_PREFIX}.TABLE_TYPE.${tableType}`));
  };

  const updateApplicationStatuses = (chipValue: string): void => {
    applicationStatusesHelper.setValue(applicationStatuses.value.filter(value => value !== chipValue));
  };

  const getApplicationStatues = (): ReactNode => {
    return Object.values(ApplicationStatus)
      .filter(applicationStatus => filterState.applicationStatuses.includes(applicationStatus))
      .map(applicationStatus =>
        <div key={applicationStatus} className={styles.chip}>
          {t(`${TRANS_PREFIX}.APPLICATION_STATUS.${applicationStatus}`)}
          <div className={styles.icon} onClick={(): void => updateApplicationStatuses(applicationStatus)}>
            <CloseIcon />
          </div>
        </div>
      );
  };

  const updateApprovalStatuses = (chipValue: string): void => {
    approvalStatusesHelper.setValue(approvalStatuses.value.filter(value => value !== chipValue));
  };

  const getApprovalStatuses = (): ReactNode => {
    return Object.values(ApprovalStatus)
      .filter(approvalStatus => filterState.approvalStatuses.includes(approvalStatus))
      .map(approvalStatus =>
        <div key={approvalStatus} className={styles.chip}>
          {t(`${TRANS_PREFIX}.APPROVAL_STATUS.${approvalStatus}`)}
          <div className={styles.icon} onClick={(): void => updateApprovalStatuses(approvalStatus)}>
            <CloseIcon />
          </div>
        </div>
      );
  };

  return (
    <div className={styles.activeFilters}>
      <PrefixTrans>FILTERS</PrefixTrans>
      <div className={styles.chip}>
        {getTableType()}
      </div>
      {getApplicationStatues()}
      {getApprovalStatuses()}
    </div>
  );
};

export default ActiveFilters;
