import {TFunction} from 'i18next';
import {AnySchema, object, string} from 'yup';
import {isAnyFilled} from '../../empty-checks-utils';
import {getNxFileValidationSchema} from './nx-file-validation-schema';
import {
  dependencyKeysPairs,
  getDefaultFieldLengthExceededError
} from './validation-schema-utils';

const MAX_LENGTH_32= 32
const MAX_LENGTH_512 = 512

export const getDocumentFieldValidationSchema = (required: boolean, requiredError: string, t: TFunction): AnySchema => {

  const fileSchema = getNxFileValidationSchema(required, requiredError);

  const nonFileFields = ['name', 'type', 'remarks', 'number'];

  const otherNonFileFields = (key: string): string[] => nonFileFields.filter(fieldKey => fieldKey !== key);

  const stringSchema = string().nullable();
  const getMaxLengthSchema = (length: number): AnySchema => stringSchema
    .max(length, getDefaultFieldLengthExceededError(t, length));

  const requiredStringSchema = stringSchema.required(requiredError);
  const max32LengthSchema = getMaxLengthSchema(MAX_LENGTH_32);
  const max32LengthRequiredSchema = getMaxLengthSchema(MAX_LENGTH_32).required(requiredError);
  const max512LengthSchema = getMaxLengthSchema(MAX_LENGTH_512);

  const requiredSchema = object({
    type: requiredStringSchema,
    name: max32LengthRequiredSchema,
    file: fileSchema,
    number: max32LengthSchema,
    remarks: max512LengthSchema
  });

  const addedFileCheckRule = {
    is: (value): boolean => !!value?.file,
    then: requiredStringSchema
  };

  const optionalSchema = object().shape({
    type: stringSchema
      .when(otherNonFileFields('type'), {
        is: (...values) => isAnyFilled([...values]),
        then: requiredStringSchema
      })
      .when('file', addedFileCheckRule),
    name: stringSchema
      .when(otherNonFileFields('name'), {
        is: (...values) => isAnyFilled([...values]),
        then: max32LengthRequiredSchema
      })
      .when('file', addedFileCheckRule),
    file: fileSchema
      .when(nonFileFields, {
        is: (...values) => isAnyFilled([...values]),
        then: getNxFileValidationSchema(true, requiredError)
      }),
    number: max32LengthSchema,
    remarks: stringSchema
  }, [...dependencyKeysPairs([...nonFileFields, 'file'])]);

  return required ? requiredSchema.nullable() : optionalSchema.nullable();
};
