import {NxButton, NxInput} from '@nextbank/ui-components';
import clsx from 'clsx';
import {cloneDeep, isEmpty} from 'lodash';
import React, {ChangeEvent, ReactElement, useState} from 'react';
import {Trans} from 'react-i18next';
import {generateKey} from '../../../utils/generate-key';
import {getInputValue} from '../../../utils/input-utils';
import {StringHelper} from '../../../utils/string-helper';
import BinButton from '../bin-button/BinButton';
import DragDropItem from '../drag-drop/DragDropItem';
import DragDropItems from '../drag-drop/DragDropItems';
import styles from './ListFieldConfig.module.scss';
import ListFieldInput from './ListFieldInput';

const fieldLabel = <Trans>COMMON.FIELDS.FIELD_NAME</Trans>;

interface Props {
  initialItems?: ListFieldItem[],
  handleSave: (items: ListFieldItem[]) => Promise<void>,
}

export type ListFieldItem = {
  id?: number,
  name: string;
  key: string;
};

/**
 * Component manages value list.
 * @param initialItems - initial values in list
 * @param handleSave - action performed on save button click. Parameter is a created/updated list items within
 *   component.
 */

export default function ListFieldConfig({initialItems, handleSave}: Props): ReactElement {
  const [listFieldItems, setListFieldItems] = useState<ListFieldItem[]>(initialItems ?? []);
  const [newFieldName, setNewFieldName] = useState<string>('');
  const handleNewFieldChange = (event: ChangeEvent): void => setNewFieldName(getInputValue(event));
  const [isSaving, setIsSaving] = useState(false);

  const handleListChange = (key: string): (e: ChangeEvent) => void => (event: ChangeEvent): void => {
    const items = cloneDeep(listFieldItems);
    const index = items.findIndex(item => item.key === key);
    items[index].name = getInputValue(event);
    setListFieldItems(items);
  };

  const removeListItem = (key: string): void => {
    const filteredList = listFieldItems.filter(item => item.key !== key);
    setListFieldItems(filteredList);
  };

  const addListField = (): void => {
    const items = cloneDeep(listFieldItems);
    items.push(
      {
        name: newFieldName,
        ...generateKey()
      }
    );

    setListFieldItems(items);
    setNewFieldName('');
  };

  const handleDictionarySave = async (): Promise<void> => {
    setIsSaving(true);
    await handleSave(listFieldItems);
  };

  const customFieldItemFunction = (provided, isDragging, item): ReactElement =>
    <DragDropItem className={styles.row} provided={provided} isDragging={isDragging} item={<ListItem item={item} />} />;

  const ListItem = ({item}: {item: ListFieldItem}): ReactElement => (
    <>
      <ListFieldInput onBlur={handleListChange(item.key)}
                      className={styles.input}
                      initValue={item.name}
                      label={fieldLabel} />
      <BinButton onClick={(): void => removeListItem(item.key)} />
    </>
  );

  return (
    <>
      {
        listFieldItems.length > 0 &&
          <div className={styles.fields}>
            <DragDropItems<ListFieldItem>
              droppableId={'listConfiguration'}
              items={listFieldItems}
              setItems={setListFieldItems}
              itemComponentFunction={customFieldItemFunction} />
          </div>
      }
      <div className={styles.row}>
        <NxInput className={styles.input} label={fieldLabel} onChange={handleNewFieldChange} value={newFieldName} />
        <NxButton className={styles.add} onClick={addListField} disabled={StringHelper.isBlank(newFieldName)}>
          <Trans>COMMON.ADD</Trans>
        </NxButton>
      </div>
      <div className={clsx(styles.row, styles.row_endAligned)}>
        <NxButton onClick={handleDictionarySave} loaded={!isSaving} disabled={isEmpty(listFieldItems)}>
          <Trans>COMMON.SAVE</Trans>
        </NxButton>
      </div>
    </>
  );
}
