import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export interface FieldError {
  fieldName: string;
  fieldPath: string;
  messages: string[];
}

export interface PhaseValidationResult {
  phaseName: string;
  errors: FieldError[];
}

export function areNotEmpty(results?: PhaseValidationResult[]): boolean {
  return !isNil(results) && !isEmpty(results) && results.map(result => isEmpty(result.errors)).some(isEmpty => isEmpty);
}
