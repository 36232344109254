import {LoanApplicationStepsRoutePaths} from '../../../../../../routes/routes.paths';
import {ApplicationRelationshipType} from '../customer-data-form.model';

export const getRedirectPath = (locationPath: string, relationshipType: ApplicationRelationshipType): string => {
  switch (relationshipType) {
    case ApplicationRelationshipType.BORROWER:
      return locationPath + LoanApplicationStepsRoutePaths.BORROWER;
    case ApplicationRelationshipType.CO_BORROWER:
      return locationPath + LoanApplicationStepsRoutePaths.CO_BORROWERS;
    case ApplicationRelationshipType.CO_MAKER:
      return locationPath + LoanApplicationStepsRoutePaths.CO_MAKERS;
    default:
      throw new Error('Unrecognized applicant relationship');
  }
};
