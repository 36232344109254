import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import clsx from 'clsx';
import React from 'react';
import {Trans} from 'react-i18next';
import NxPopup from '../nx-popup/NxPopup';
import styles from './ConfirmationPopup.module.scss';

interface Props {
  onConfirm: () => void;
  onDeny?: () => void;
  open: boolean;
  isLoading?: boolean;
  header: React.ReactNode;
  description?: React.ReactNode;
  confirmButtonLabel: React.ReactNode;
  confirmButtonClass?: string;
  denyButtonLabel?: React.ReactNode;
  footerClassName?: string;
}

export const ConfirmationPopup = (
  {
    header,
    description,
    confirmButtonLabel,
    denyButtonLabel = <Trans>COMMON.CANCEL</Trans>,
    onConfirm,
    confirmButtonClass,
    onDeny,
    open,
    footerClassName,
    isLoading = false
  }: Props): React.ReactElement =>
  <NxPopup open={open} header={header} description={description} footerClassName={footerClassName}>
    {
      onDeny &&
      <NxButton className={styles.button} onClick={onDeny} variant={NxButtonVariant.TEXT}>{denyButtonLabel}</NxButton>
    }
    <NxButton loaded={!isLoading}
              disabled={isLoading}
              className={clsx(styles.button, confirmButtonClass)}
              onClick={onConfirm}>{confirmButtonLabel}</NxButton>
  </NxPopup>;
