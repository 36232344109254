import {createContext} from 'react';

export const LoanApplicationNavigationContext =
  createContext<LoanApplicationNavigationContextType>({} as LoanApplicationNavigationContextType);

export interface LoanApplicationNavigationContextType {
  isSubmitting: boolean;

  applicationChanged: boolean;
  setApplicationChanged: (applicationChanged: boolean) => void;

  revertStep:(phaseId: number) => Promise<void>
  moveStep: (path: string) => Promise<void>;
  saveStep: (validate: boolean) => Promise<void>;
  setSaveStepFunc: (handleSave: (validate: boolean) => Promise<void>) => void;
}
