import React, {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {RoutePaths} from '../../../../routes/routes.paths';
import {LoanStep} from '../../../../shared/model/loan-step.model';
import {buildConfigurationStepPath} from '../../../../utils/loan-steps-utils';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import {LoanStepTabs} from '../../../shared/loan-step-tabs/LoanStepTabs';
import {LoanConfigurationContext} from '../LoanConfiguration';
import {useTranslation} from 'react-i18next';

function LoanConfigurationSidebar(): React.ReactElement {
  const {t} = useTranslation();
  const history = useGuardedHistory();
  const currentStepPath = useLocation().pathname;
  const {steps, process, arePhasesUpdating} = useContext(LoanConfigurationContext);

  const redirectToStep = (step: LoanStep): () => void => (): void =>
    history.push(step.redirectTo ?? step.path);

  const customPhasesStep = {
    path: buildConfigurationStepPath(RoutePaths.EDIT_CUSTOM_PHASES, process.id),
    label: t('LOAN_STEPS_LABELS.EDIT_CUSTOM_PHASES'),
    isConfigStep: true
  };

  const loanConfigurationSteps =
    [...steps.getSteps(t), customPhasesStep]
      .map(step => ({
        ...step,
        onStepClick: redirectToStep(step)
      }));

  return <LoanStepTabs currentStepPath={currentStepPath} loaded={!arePhasesUpdating} steps={loanConfigurationSteps} />;
}

export default LoanConfigurationSidebar;
