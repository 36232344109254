import {TableHead, TableRow} from '@material-ui/core';
import React, {ReactElement} from 'react';
import {LoanDetailsLabel} from '../../../../constants/loan-details-labels';
import {TransHelper} from '../../../../utils/trans-helper';
import TableHeaderCell from '../../../shared/table/cells/TableHeaderCell';
import styles from './LoanConfigurationsTableHead.module.scss';

const headers = [
  LoanDetailsLabel.NO,
  LoanDetailsLabel.CODE,
  LoanDetailsLabel.PRODUCT_NAME,
  LoanDetailsLabel.MODIFIED_BY,
  LoanDetailsLabel.LAST_EDIT,
  LoanDetailsLabel.CHANNEL,
  LoanDetailsLabel.STATUS
];

const LoanConfigurationsTableHead = (): ReactElement =>
  <TableHead>
    <TableRow>
      {
        headers.map(header => <TableHeaderCell key={header} header={TransHelper.getLoanDetailsLabel(header)} />)
      }
      <TableHeaderCell className={styles.buttonHeader} />
    </TableRow>
  </TableHead>;

export default LoanConfigurationsTableHead;
