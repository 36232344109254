import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import clsx from 'clsx';
import dayjs, {Dayjs} from 'dayjs';
import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {ReactComponent as BackIcon} from '../../../../assets/images/icon-arrow-back.svg';
import {ReactComponent as CheckedIcon} from '../../../../assets/images/icon-checked.svg';
import {ReactComponent as CloseIcon} from '../../../../assets/images/icon-close.svg';
import {DATE_TIME_FORMAT} from '../../../../constants/format-templates';
import {RoutePaths} from '../../../../routes/routes.paths';
import {TransHelper} from '../../../../utils/trans-helper';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import {LoanDetail} from '../../../shared/loan-detail/LoanDetail';
import {Batch, BatchStatus} from '../../batch.model';
import BatchStatusChip from '../../status-chip/BatchStatusChip';
import styles from './BatchTopDetails.module.scss';

interface Props {
  batch: Batch;
  setRejectPopupOpen: (isOpen: boolean) => void;
  setRevokePopupOpen: (isOpen: boolean) => void;
  setApprovePopupOpen: (isOpen: boolean) => void;
}

export const TRANS_PREFIX = 'BATCH';
export const PrefixTrans = TransHelper.getPrefixedTrans(`${TRANS_PREFIX}.DETAILS`);
export const LabelsPrefixTrans = TransHelper.getPrefixedTrans(`${TRANS_PREFIX}.LABELS`);

// TODO refactor details. It is common with LoanConfiguration & LoanApplication
export const BatchTopDetails = (
  {batch, setRejectPopupOpen, setRevokePopupOpen, setApprovePopupOpen}: Props
): ReactElement => {

  const history = useGuardedHistory();

  const getDateWithUsername = (date: Dayjs, fullname: string): string =>
    `${date?.format(DATE_TIME_FORMAT)} (${fullname})`;

  const getValidOrInvalid = (): ReactElement => {
    return <div className={styles.rowsNumber}>
      <span className={clsx(styles.icon, styles.validIcon)}><CheckedIcon /></span>
      <span className={styles.number}>{batch.validItemsCount ?? '-'}</span>
      <span className={styles.separator}>|</span>
      <span className={clsx(styles.icon)}><CloseIcon className={styles.invalidIcon} /></span>
      <span className={styles.number}>{batch.invalidItemsCount ?? '-'}</span>
    </div>;
  };

  const toReadableProcessingTime = (processingTimeInMillis?: number): string => {
    if (!processingTimeInMillis) {
      return '-';
    }

    let processingTime = `${dayjs.duration(processingTimeInMillis).seconds()} secs`;
    const minutes = dayjs.duration(processingTimeInMillis).minutes();
    const hours = dayjs.duration(processingTimeInMillis).hours();
    if (minutes > 0 || hours > 0) {
      processingTime = `${minutes} mins ${processingTime}`;
    }
    if (hours > 0) {
      processingTime = `${hours} hrs ${processingTime}`;
    }

    return processingTime;
  };

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <LoanDetail title={<LabelsPrefixTrans>ID</LabelsPrefixTrans>}
                    content={batch.id} />
        <LoanDetail title={<LabelsPrefixTrans>NAME</LabelsPrefixTrans>}
                    content={batch.name} />
        <LoanDetail title={<LabelsPrefixTrans>CREATED_ON</LabelsPrefixTrans>}
                    content={batch.createdOn?.format(DATE_TIME_FORMAT) ?? '-'} />
        <LoanDetail title={<LabelsPrefixTrans>UPLOADED_BY</LabelsPrefixTrans>}
                    content={batch.createdBy} />
        <LoanDetail title={<LabelsPrefixTrans>RECORDS_COUNT</LabelsPrefixTrans>}
                    content={batch.itemsCount ?? '-'} />
        <LoanDetail title={<LabelsPrefixTrans>VALID_OR_INVALID_NO</LabelsPrefixTrans>}
                    content={getValidOrInvalid()} />
        <LoanDetail title={<LabelsPrefixTrans>STATUS</LabelsPrefixTrans>}
                    content={<BatchStatusChip status={batch.status} />} />
        {
          batch.approvedOn && batch.approvedBy &&
          <LoanDetail title={<LabelsPrefixTrans>APPROVED_ON</LabelsPrefixTrans>}
                      content={getDateWithUsername(batch.approvedOn, batch.approvedBy)}
                      longDetailValue={true} />
        }
        {
          batch.canceledOn && batch.canceledBy &&
          <LoanDetail title={<LabelsPrefixTrans>CANCELED_ON</LabelsPrefixTrans>}
                      content={getDateWithUsername(batch.canceledOn, batch.canceledBy)}
                      longDetailValue={true} />
        }
        {
          batch.processingTime && <LoanDetail title={<LabelsPrefixTrans>PROCESSING_TIME</LabelsPrefixTrans>}
                                              content={toReadableProcessingTime(batch.processingTime)} />
        }
      </div>
      <div className={styles.buttons}>
        <NxButton className={styles.button}
                  variant={NxButtonVariant.OUTLINED}
                  startIcon={<BackIcon />}
                  onClick={(): void => history.push(RoutePaths.BATCH)}>
          <PrefixTrans>GO_BACK</PrefixTrans>
        </NxButton>
        {
          batch.status === BatchStatus.UPLOADED &&
          <>
            <NxButton className={styles.button}
                      variant={NxButtonVariant.REJECT}
                      onClick={(): void => setRejectPopupOpen(true)}>
              <Trans>COMMON.REJECT</Trans>
            </NxButton>
            <NxButton
              disabled={batch.validItemsCount == 0}
              startIcon={<CheckedIcon />}
              className={styles.button}
              onClick={(): void => setApprovePopupOpen(true)}>
              <Trans>COMMON.APPROVE</Trans>
            </NxButton>
          </>
        }
        {
          (batch.status === BatchStatus.PROCESSED || batch.status === BatchStatus.ERROR) &&
          <>
            <NxButton className={styles.button}
                      variant={NxButtonVariant.REJECT}
                      onClick={(): void => setRevokePopupOpen(true)}>
              <PrefixTrans>REVOKE</PrefixTrans>
            </NxButton>
          </>
        }
      </div>
    </div>
  );
};
