import {NxButton, NxButtonVariant, NxFormikTextarea, NxPopup} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {ApplicationStatus} from '../../loan-application.model';
import styles from './LoanStatusChangePopup.module.scss';
import {StatusChangePayload} from './status-change-payload.model';

interface Props {
  status: ApplicationStatus;
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: StatusChangePayload) => Promise<void>;
  header: ReactElement;
  description: ReactElement;
  confirmButtonMessage: ReactElement;
}

const TEXT_AREA_ROWS_NUMBER = 4;

export const LoanStatusChangePopup = (
  {status, open, onCancel, onConfirm, header, description, confirmButtonMessage}: Props
): ReactElement => {

  const popupHeader =
    <div className={styles.header}>
      {header}
    </div>;

  const submit = (values: StatusChangePayload): Promise<void> => {
    return onConfirm(values);
  };

  const StatusChangeForm = ({submitForm, isSubmitting}: FormikProps<StatusChangePayload>): ReactElement => (
    <Form>
      <NxFormikTextarea className={styles.input}
                        label={<Trans>TOP_BAR.REMARKS_FIELD</Trans>}
                        rows={TEXT_AREA_ROWS_NUMBER}
                        name='remarks' />
      <div className={styles.buttonRow}>
        <NxButton onClick={onCancel} variant={NxButtonVariant.TEXT}>
          <Trans>COMMON.CANCEL</Trans>
        </NxButton>
        <NxButton className={styles.button}
                  disabled={isSubmitting}
                  loaded={!isSubmitting}
                  onClick={submitForm}>
          {confirmButtonMessage}
        </NxButton>
      </div>
    </Form>
  );

  return (
    <NxPopup header={popupHeader} open={open}>
      <div className={styles.container}>
        <div className={styles.description}>
          {description}
        </div>
        <Formik<StatusChangePayload>
          onSubmit={submit}
          initialValues={{status}}>
          {StatusChangeForm}
        </Formik>
      </div>
    </NxPopup>
  );
};
