import clsx from 'clsx';
import React from 'react';
import {ReactComponent as BinIcon} from '../../../assets/images/icon-bin.svg';
import IconButtonSwitch from '../icon-button-switches/IconButtonSwitch';
import styles from './BinButton.module.scss';

interface Props {
  bordered?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  variant?: BinVariant;
}

export enum BinVariant {
  DEFAULT = 'DEFAULT',
  PRIMARY = 'PRIMARY'
}

const BinButton = ({className, onClick, disabled, bordered, variant = BinVariant.DEFAULT}: Props): React.ReactElement =>
  <IconButtonSwitch ariaLabel={'remove'}
                    bordered={bordered}
                    disabled={disabled}
                    icon={<BinIcon className={clsx({[styles.primary]: variant == BinVariant.PRIMARY && !disabled})} />}
                    onClick={onClick}
                    className={className}
  />;

export default BinButton;
