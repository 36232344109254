import {CancelTokenSource} from 'axios';
import HttpService from '../http-service/http.service';
import useCatchUnauthorizedError from './use-catch-unauthorized-error.hook';

/**
 * Dedicated hook for perform DELETE request.
 *
 * @param url - target url for API request.
 *
 * T - type of data returned by api call.
 *
 * @return function which allows execute configured DELETE request. It has following arguments:
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */
export default function useDelete<T>(url: string):
  (cancelTokenSource?: CancelTokenSource | null) => Promise<T | undefined> {
  
    const catchUnauthorizedError = useCatchUnauthorizedError();

    return (cancelTokenSource?: CancelTokenSource | null): Promise<T | undefined> =>
      HttpService.delete<T>(url, cancelTokenSource)
        .catch(catchUnauthorizedError);
  }
