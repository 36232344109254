import clsx from 'clsx';
import React, {ReactElement} from 'react';
import {ReactComponent as BinIcon} from '../../../../assets/images/icon-bin.svg';
import usePost from '../../../../shared/hooks/use-post.hook';
import {ApiHelper} from '../../../../utils/api-helper';
import {ApplicationStatus} from '../loan-application.model';
import styles from './CancelLoanButton.module.scss';
import LoanStatusChangeButton from './status-change-button/LoanStatusChangeButton';
import {StatusChangePayload} from './status-change-button/status-change-payload.model';

interface Props {
  className?: string;
  productName: string;
  applicationId: number;
}

export default function CancelLoanButton({className, productName, applicationId}: Props): ReactElement {

  const cancel = usePost<void, StatusChangePayload>(`${ApiHelper.getApplicationUrl(applicationId)}/change-status`);

  const cancelClassName = clsx(className, styles.cancel);

  return <LoanStatusChangeButton className={cancelClassName}
                                 status={ApplicationStatus.CANCELED}
                                 productName={productName}
                                 changeStatus={cancel}
                                 icon={<BinIcon />}
                                 translationPath={'TOP_BAR.CANCEL'} />;
}
