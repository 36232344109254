import React from 'react';
import {Trans} from 'react-i18next';
import {useParams} from 'react-router';
import {ReactComponent as CheckedIcon} from '../../../../assets/images/icon-checked.svg';
import {ReactComponent as CloseIcon} from '../../../../assets/images/icon-close.svg';
import {BatchUrlParams} from '../../../../routes/routes.model';
import usePost from '../../../../shared/hooks/use-post.hook';
import {TransHelper} from '../../../../utils/trans-helper';
import {ConfirmationPopup} from '../../../shared/confirmation-popup/ConfirmationPopup';
import {getBatchUrl} from '../BatchDetails';
import styles from './ApprovePopup.module.scss';

export const TRANS_PREFIX = 'BATCH.DETAILS.POPUP';
export const APPROVE_TRANS_PREFIX = `${TRANS_PREFIX}.APPROVE`;
export const CommonPrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);
export const PrefixTrans = TransHelper.getPrefixedTrans(APPROVE_TRANS_PREFIX);

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onClose: () => void;
  validRowsNo: number;
  invalidRowsNo: number;
  refreshBatch: () => Promise<void>;
}

export const ApprovePopup = ({open, setOpen, onClose, validRowsNo, invalidRowsNo, refreshBatch}: Props): React.ReactElement => {

  const {batchId} = useParams<BatchUrlParams>();
  const approveBatch = usePost<void, void>(`${getBatchUrl(batchId)}/approve`);

  const boldComponent = <span className={styles.bold} />;
  const messagePart = <span className={styles.messagePart} />;
  const messageBody = (
    <div className={styles.message}>
      <div className={styles.correctRowsInfo}>
        <Trans components={{component: messagePart}}>
          {`${APPROVE_TRANS_PREFIX}.VALID_INVALID_ROWS_1`}
        </Trans>
        <span className={styles.icon}><CheckedIcon className={styles.validIcon} /></span>
        <Trans values={{validRowsNo}} components={{bold: boldComponent, component: messagePart}}>
          {`${APPROVE_TRANS_PREFIX}.VALID_INVALID_ROWS_2`}
        </Trans>
        <span className={styles.icon}><CloseIcon className={styles.invalidIcon} /></span>
        <Trans values={{invalidRowsNo}} components={{bold: boldComponent, component: messagePart}}>
          {`${APPROVE_TRANS_PREFIX}.VALID_INVALID_ROWS_3`}
        </Trans>
      </div>
      <span><PrefixTrans>MESSAGE</PrefixTrans></span>
      <span><CommonPrefixTrans>CONFIRMATION_MSG</CommonPrefixTrans></span>
    </div>
  );

  const closePopup = (): void => setOpen(false);

  return <ConfirmationPopup
    header={<PrefixTrans>HEADER</PrefixTrans>}
    description={messageBody}
    onConfirm={(): Promise<void> => approveBatch().then(() => {
      closePopup();
      refreshBatch();
      onClose();
    })}
    confirmButtonLabel={<Trans>COMMON.APPROVE</Trans>}
    onDeny={closePopup}
    denyButtonLabel={<Trans>COMMON.CANCEL</Trans>}
    open={open}
  />;
};
