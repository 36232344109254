import {TableHead, TableRow} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import TableHeaderCell from '../../../../../../../../../../shared/table/cells/TableHeaderCell';
import {PrefixTrans} from '../../../DocumentSearchSidePanel';
import styles from './DocumentsTableHead.module.scss';

const DocumentsTableHead = (): React.ReactElement => (
  <TableHead>
    <TableRow>
      <TableHeaderCell small className={styles.header} header={<PrefixTrans>APPLICATION_NUMBER</PrefixTrans>} />
      <TableHeaderCell small className={styles.header} header={<PrefixTrans>CUSTOMER_NAME</PrefixTrans>} />
      <TableHeaderCell small className={styles.header} header={<PrefixTrans>TYPE</PrefixTrans>} />
      <TableHeaderCell small className={styles.header} header={<PrefixTrans>DOCUMENT_NUMBER</PrefixTrans>} />
      <TableHeaderCell small className={styles.header} header={<PrefixTrans>DOCUMENT_NAME</PrefixTrans>} />
      <TableHeaderCell small className={styles.header} header={<PrefixTrans>CREATED_ON</PrefixTrans>} />
      <TableHeaderCell small className={clsx(styles.header, styles.header_button)} />
    </TableRow>
  </TableHead>
);

export default DocumentsTableHead;
