import {NxSelectOption} from '@nextbank/ui-components';
import React from 'react';
import {PrefixTrans} from '../components/loan-configurations/shared/check/Check';

interface NxSelectOptionLabel {
  label: React.ReactNode;
}

export const movePhilippinesOptionToTop = <T extends NxSelectOptionLabel>(options: T[] | undefined): T[] | undefined => {
  return moveOptionToTop('Philippines', options);
};

export const moveOptionToTop =
  <T extends NxSelectOptionLabel>(optionLabel: string, options: T[] | undefined): T[] | undefined => {

    if (!options) {
      return;
    }

    const searchedOption = options?.find(value => value.label === optionLabel);
    if (searchedOption) {
      const filteredOptions = options.filter(value => value.label !== optionLabel);

      return [
        searchedOption,
        ...filteredOptions
      ];
    }

    return options;
  };

export const toSelectOption = (label: string, value: string, usePrefixTrans = true): NxSelectOption => ({
  label: usePrefixTrans ? <PrefixTrans>{label}</PrefixTrans> : label,
  value
});
