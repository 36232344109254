export enum BatchItemsTableHeaderLabel {
  ID = 'ID',
  REFERENCE_NUMBER = 'REFERENCE_NUMBER',
  DESCRIPTION = 'DESCRIPTION',
  VALID = 'VALID',
  STATUS = 'STATUS',
  PROCESSED_ON = 'PROCESSED_ON'
}

enum ItemLabels {
  CREATED_ON = 'CREATED_ON',
  ERRORS = 'ERRORS',
  DESCRIPTION = 'DESCRIPTION'
}

export const BatchItemLabels = {
  ...BatchItemsTableHeaderLabel,
  ...ItemLabels
}
