import {FileTypes} from '../components/shared/inputs/nx-file-upload/file-upload.model';

export const getFileExtension = (file: File): string => file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase();

export const getMimeTypeByExtension = (extension: string): string | undefined => {
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'csv':
      return 'text/csv';
    case 'mp3':
      return 'audio/mp3';
    case 'mp4':
      return 'video/mp4';
    case 'aac':
      return 'audio/aac';
    case 'm4a':
      return 'audio/m4a';
  }
};

export const getSupportedFileLabels = (extensionNames: FileTypes[]): string[] => {
  const uniqueFileExtensions = Array.from(new Set(extensionNames.map(getFileExtensionName)));

  return uniqueFileExtensions.map(fileExtension => {
    const extensionName = fileExtension.toUpperCase();
    if (extensionName === 'MP4') {
      return `${extensionName} (Video & Audio)`;
    }
    return extensionName;
  });
};

export const getFileExtensionName = (type: FileTypes): string => {
  switch (type) {
    case FileTypes.APPLICATION_PDF:
      return 'pdf';
    case FileTypes.IMAGE_JPG:
      return 'jpg';
    case FileTypes.IMAGE_JPEG:
      return 'jpeg';
    case FileTypes.IMAGE_PNG:
      return 'png';
    case FileTypes.CSV:
      return 'csv';
    case FileTypes.AUDIO_MP3:
      return 'mp3';
    case FileTypes.AUDIO_MP4:
    case FileTypes.VIDEO_MP4:
      return 'mp4';
    case FileTypes.AUDIO_AAC:
      return 'aac';
    case FileTypes.AUDIO_M4A:
      return 'm4a';
  }
};
  
