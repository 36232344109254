import {CustomerType} from '../../../../../../shared/model/customer-type.model';
import {PagedRecords, PagedRequest} from '../../../../../../shared/model/paged.model';

export interface CustomersByPage extends PagedRecords<ApplicantSearchResult> {
}

export interface ApplicantSearchResult {
  birthDate: string;
  fullName: string;
  sourceId: number;
  sourceNumber: string;
  registrationDate: string;
  customerType: CustomerType;
}

export type ApplicantSearchPayload = IndividualCustomerSearchFormFields | CorporateCustomerSearchFormFields;

export interface ApplicantSearchForm {
  customerType: CustomerType;

}

export interface IndividualCustomerSearchFormFields extends PagedRequest, ApplicantSearchForm {
  birthDate: string;
  firstName: string;
  lastName: string;
}

export interface CorporateCustomerSearchFormFields extends PagedRequest, ApplicantSearchForm {
  corporateName: string;
  dateOfRegistration: string;
}

export enum DataTestIds {
  CUSTOMERS_TABLE = 'CUSTOMERS_TABLE',
  NEW_CUSTOMER_SELECT = 'NEW_CUSTOMER_SELECT',
  TABLE_LOADER = 'CUSTOMERS_TABLE_LOADER'
}
