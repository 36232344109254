import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React from 'react';
import {Trans} from 'react-i18next';

interface Props {
  stepPaths: {
    previousStepPath?: string,
    nextStepPath?: string
  }
  navigateTo: (path: string) => void;
  disableButtons?: boolean;
  nextButtonLoaded?: boolean;
}

export const LoanStepNavigationButtons = ({stepPaths, navigateTo, disableButtons = false, nextButtonLoaded = true}: Props): React.ReactElement => {

  const {previousStepPath, nextStepPath} = stepPaths;

  return (
    <>
      {
        previousStepPath && (
          <NxButton onClick={(): void => navigateTo(previousStepPath)}
                    variant={NxButtonVariant.PREVIOUS}
                    disabled={disableButtons}>
            <Trans>COMMON.PREVIOUS</Trans>
          </NxButton>
        )
      }
      {
        nextStepPath && (
          <NxButton onClick={(): void => navigateTo(nextStepPath)}
                    variant={NxButtonVariant.NEXT}
                    disabled={disableButtons}
                    loaded={nextButtonLoaded}>
            <Trans>COMMON.NEXT</Trans>
          </NxButton>
        )
      }
    </>
  );
};
