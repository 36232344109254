export enum LoanDetailsLabel {
  NO = 'NO',
  CIF = 'CIF',
  CLIENT = 'CLIENT',
  CODE = 'CODE',
  PRODUCT_NAME = 'PRODUCT_NAME',
  REFERENCE_NO = 'REFERENCE_NO',
  MIN_AMOUNT = 'MIN_AMOUNT',
  MAX_AMOUNT = 'MAX_AMOUNT',
  MIN_INTEREST_RATE = 'MIN_INTEREST_RATE',
  MAX_INTEREST_RATE = 'MAX_INTEREST_RATE',
  MIN_TERM = 'MIN_TERM',
  MAX_TERM = 'MAX_TERM',
  MODIFIED_BY = 'MODIFIED_BY',
  LAST_EDIT = 'LAST_EDIT',
  CHANNEL = 'CHANNEL',
  STATUS = 'STATUS',
  APPLICATION_TYPE = 'APPLICATION_TYPE'
}
