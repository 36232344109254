import {NxSelect, NxSelectOption} from '@nextbank/ui-components';
import {isNumber} from 'lodash';
import isNil from 'lodash/isNil';
import React, {createContext, useContext, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {PROCESSES_URL} from '../../../../constants/api-urls';
import usePost from '../../../../shared/hooks/use-post.hook';
import {AppSnackbarContext} from '../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {ConfirmationPopup} from '../../../shared/confirmation-popup/ConfirmationPopup';
import styles from './CopyConfigurationPopupProvider.module.scss';

const TransPrefix = 'LOAN_CONFIGURATIONS.COPY_CONFIG';

interface Props {
  processes: NxSelectOption<number>[];
  children: React.ReactNode;
}

interface CopyConfigurationPopupType {
  setOpen: (processId: number) => void;
}

interface CopyConfigurationRequest {
  sourceProcessId: number;
}

export const CopyConfigurationPopupContext = createContext<CopyConfigurationPopupType>({
    setOpen: (processId: number) => {
      console.warn('CopyConfigurationPopupContext default setOpen function');
    }
  }
);

export const CopyConfigurationPopupProvider = ({children, processes}: Props): React.ReactElement => {

  const {showSuccessMessage, showErrorMessage} = useContext(AppSnackbarContext);
  const {t} = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [targetProcessId, setTargetProcessId] = useState<number | undefined>(undefined);
  const [targetProcessName, setTargetProcessName] = useState<React.ReactNode | undefined>(undefined);
  const [sourceProcessId, setSourceProcessId] = useState<number | undefined>(undefined);
  const copyConfiguration = usePost<void, CopyConfigurationRequest>(`${PROCESSES_URL}/${targetProcessId}/copy-configuration`);

  const contextProps = {
    setOpen: (processId: number): void => {
      setTargetProcessId(processId);
      setTargetProcessName(processes.find(value => value.value === processId)?.label);
      setOpen(true);
    }
  };

  const onConfirm = (): void => {
    if (isNil(targetProcessId) || isNil(targetProcessName) || !isNumber(sourceProcessId)) {
      showErrorMessage(t(`${TransPrefix}.TARGET_CONFIG_NOT_SET`));
      return;
    }

    const urlParams = new URLSearchParams(`sourceProcessId=${sourceProcessId}`);
    copyConfiguration(null, null, urlParams).then(() => {
      showSuccessMessage(t(`${TransPrefix}.SUCCESS_ALERT`));
    }).catch(e => {
      console.error(e);
      showErrorMessage(t(`${TransPrefix}.ERROR_ALERT`));
    }).finally(() => closePopup());
  };

  const closePopup = (): void => {
    setOpen(false);
    setTargetProcessId(undefined);
    setTargetProcessName(undefined);
  };

  const handleOnChange = (id: number | null): void => {
    if (isNil(id)) {
      console.warn('Selected ID is null');
      return;
    }

    setSourceProcessId(id);
  };

  const Header = (): React.ReactElement => (
    <div className={styles.header}>
      <span><Trans>{`${TransPrefix}.HEADER_PART1`}</Trans></span>
      <span>{targetProcessName}</span>
      <span><Trans>{`${TransPrefix}.HEADER_PART2`}</Trans></span>
    </div>
  );

  return (
    <>
      <ConfirmationPopup confirmButtonLabel={<Trans>{`${TransPrefix}.COPY`}</Trans>}
                         header={<Header />}
                         description={<NxSelect className={styles.select}
                                                label={<Trans>{`${TransPrefix}.SELECT_LABEL`}</Trans>}
                                                value={sourceProcessId}
                                                onChange={handleOnChange}
                                                options={processes} />}
                         onConfirm={onConfirm}
                         onDeny={closePopup}
                         open={open} />
      <CopyConfigurationPopupContext.Provider value={contextProps}>
        {children}
      </CopyConfigurationPopupContext.Provider>
    </>
  );
};
