import {TFunction} from 'i18next';
import {AnySchema, array, boolean, number, object, string} from 'yup';
import {
  getCustomFieldValueSchema
} from '../../../../../../utils/step-form-utils/validation-schema/custom-field-value-validation-schema';
import {
  getOptionalValidationSchema
} from '../../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';

export const getCheckValuesValidationSchema = (t: TFunction): AnySchema =>
  getOptionalValidationSchema(partialValidation => array().of(
    object({
      checkId: number(),
      id: number(),
      isAutomatic: boolean(),
      fieldType: string(),
      required: boolean(),
      value: getCustomFieldValueSchema(partialValidation, t)
    })
  ));
