import {ListItemIcon, MenuItem} from '@material-ui/core';
import {NxLoader, NxLoaderColorVariant, NxLoaderVariant} from '@nextbank/ui-components';
import React, {ReactElement, ReactNode} from 'react';
import styles from './TableMenuOption.module.scss';

interface Props {
  icon: ReactNode;
  label: ReactNode;
  loaded?: boolean;
  onClick: () => void;
}

const TableMenuOption =
  React.forwardRef(({icon, label, loaded = true, onClick}: Props, ref): ReactElement  => (
    <MenuItem innerRef={ref} className={styles.option} onClick={onClick}>
      {
        loaded
          ? <>
              <ListItemIcon className={styles.icon}>{icon}</ListItemIcon>{label}
            </>
          : <NxLoader variant={NxLoaderVariant.SMALL} colorVariant={NxLoaderColorVariant.TEXT} />
      }
    </MenuItem>
  ));

TableMenuOption.displayName = 'TableMenuOption';

export default TableMenuOption;