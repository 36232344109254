import React, {ReactElement} from 'react';
import {NxInput} from '@nextbank/ui-components';

interface Props {
  label: string,
  value?: string,
  isBoolean?: boolean,
  className?: string
}

const ReadOnlyInput = ({label, value, isBoolean = false, className}: Props): ReactElement => {
  return (
    <NxInput className={className}
             label={label}
             disabled
             value={isBoolean ? value === 'true' ? 'Yes' : 'No' : value} />
  );
};

export default ReadOnlyInput;
