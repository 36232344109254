import clsx from 'clsx';
import React, {ReactElement} from 'react';
import {ApprovalExecutionStatus} from '../../../../../loan-application.model';
import {PrefixTrans} from '../approval-utils';
import styles from './ApprovalStatus.module.scss';

interface Props {
  status?: ApprovalExecutionStatus;
}

export const ApprovalStatus = ({status}: Props): ReactElement => {

  const isStatusApproved = status === ApprovalExecutionStatus.APPROVED;
  const isStatusRejected = status === ApprovalExecutionStatus.REJECTED;

  const statusClassName = clsx(
    styles.status,
    {
      [styles.status_approved]: isStatusApproved,
      [styles.status_rejected]: isStatusRejected
    }
  );

  return (
    <div className={statusClassName}>
      {
        isStatusApproved ? <PrefixTrans>APPROVED</PrefixTrans>
          : isStatusRejected ? <PrefixTrans>REJECTED</PrefixTrans>
          : <PrefixTrans>WAITING_FOR_APPROVAL</PrefixTrans>
      }
    </div>
  );
};
