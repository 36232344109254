import React, {ReactElement, useState} from 'react';
import CustomerSelect from '../../../customer-data/customer-select/CustomerSelect';
import {ApplicationRelationshipType} from '../../../customer-data/customer-data-form.model';
import {LoanCreationType} from '../../../../../../../shared/model/creation-type.model';
import styles from './ConsolidatedLoansDialogContent.module.scss';
import CustomerConsolidatedLoansTable from './CustomerCbsLoansTable';
import Instruction from '../../../../../../shared/instruction/Instruction';
import {TransHelper} from '../../../../../../../utils/trans-helper';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS');

const ConsolidatedLoansDialogContent = (): ReactElement => {

  const [selectedApplicantSourceId, setSelectedApplicantSourceId] = useState<number | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);

  const onSelectCustomer = (applicantSourceId?: number): void => {
    setSelectedApplicantSourceId(applicantSourceId);
    setShowTable(true);
  };

  return (
    <div className={styles.container}>
      {!showTable ? (
        <>
          <Instruction>
            <PrefixTrans>SEARCH_CUSTOMER</PrefixTrans>
          </Instruction>
          <CustomerSelect isSelecting={false}
                          setSelecting={setLoading}
                          relationshipType={ApplicationRelationshipType.BORROWER}
                          loanCreationType={LoanCreationType.CONSOLIDATION}
                          onSelectCustomer={onSelectCustomer} />
        </>
      ) : (
        <>
          <Instruction>
            <PrefixTrans>CUSTOMER_LOANS</PrefixTrans>
          </Instruction>
          <CustomerConsolidatedLoansTable applicantSourceId={selectedApplicantSourceId}
                                          loading={isLoading}
                                          setLoading={setLoading} />
        </>
      )}
    </div>
  );
};

export default ConsolidatedLoansDialogContent;
