import axios, {AxiosRequestConfig as Config, AxiosResponse, CancelTokenSource} from 'axios';
import {isNil} from 'lodash';
import {isDevelopmentMode} from '../../utils/app-utils';
import {HttpError} from './http-error';
import {ABORT_ERROR_NAME, ErrorBody} from './http-error.model';

const DOMAIN_PREFIX_TO_TRIM = /^(private\.)/;
const getEnvUrl = (): string => {
  if (window.location.hostname.match(DOMAIN_PREFIX_TO_TRIM) != null) {
    const hostname = window.location.hostname.replace(DOMAIN_PREFIX_TO_TRIM, '');
    return 'https://private.' + hostname;
  }
  return `https://api.${window.location.hostname}`;
};

const API_HOST = !isDevelopmentMode ? getEnvUrl() : '';
const API_URL = `${API_HOST}/api`;

axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 600000;
axios.interceptors.response.use(
  response => response,
  error => {
    const errorBody = axios.isCancel(error) ?
      {errorCode: ABORT_ERROR_NAME} as ErrorBody
      : error.response.data;

    return Promise.reject(new HttpError(error.response, errorBody));
  }
);

const getResponseData = async <T>(axiosRequest: Promise<AxiosResponse<T>>): Promise<T> => {

  const response = await axiosRequest;

  return response.data;
};

class HttpService {
  get = <T>(url: string, cancelSource?: CancelTokenSource | null, config?: Config): Promise<T> =>
    getResponseData<T>(axios.get(url, {...config, cancelToken: cancelSource?.token}));

  put = <T>(url: string, data: unknown, cancelSource?: CancelTokenSource | null): Promise<T> =>
    getResponseData<T>(axios.put(url, data, {cancelToken: cancelSource?.token}));

  post = <T>(url: string, data?: unknown, cancelSource?: CancelTokenSource | null, config?: Config): Promise<T> =>
    getResponseData<T>(axios.post(url, data, {...config, cancelToken: cancelSource?.token}));

  delete = <T>(url: string, cancelSource?: CancelTokenSource | null, config?: Config): Promise<T> =>
    getResponseData<T>(axios.delete(url, {...config, cancelToken: cancelSource?.token}));

  postMultipart<T, Data>(url: string, data: Data, cancelSource?: CancelTokenSource | null): Promise<T> {

    const formData = new FormData();

    Object.keys(data).filter(key => !isNil(data[key])).forEach(key => formData.append(key, data[key]));

    return this.post(url, formData, cancelSource);
  }
}

export default new HttpService();
