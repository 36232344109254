import {
  Application,
  LoanApplicationSimulation
} from '../../components/loan-applications/loan-application/loan-application.model';
import {
  CalculatorPhase
} from '../../components/loan-configurations/loan-configuration/steps/calculator/calculator-phase.model';
import {Process} from '../model/process.model';
import {ApiHelper} from '../../utils/api-helper';
import {PhaseName} from '../../constants/api-urls';
import useSingleQuery from './use-single-query.hook';

export interface ProductChangedProps {
  isProductChanged: boolean,
  currentSimulation?: LoanApplicationSimulation
}

export default function useConsolidatedLoanProductChanged(
  application?: Application,
  newProcess?: Process
): ProductChangedProps {

  const isProductChanged = application !== undefined && application.processId !== newProcess?.id;
  const currentConfig = useSingleQuery<CalculatorPhase>(ApiHelper.getPhaseConfigurationUrl(application?.processId ?? '',
    PhaseName.CALCULATOR), null, isProductChanged);

  const currentSimulation = (): LoanApplicationSimulation | undefined => {
    return application?.simulations.find(simulation => simulation.phaseId === currentConfig?.id);
  };

  return {
    isProductChanged,
    currentSimulation: currentSimulation()
  };
}
