import {NxButtonLink, NxButtonVariant} from '@nextbank/ui-components';
import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {DATE_TIME_FORMAT} from '../../../../constants/format-templates';
import {createPathToApplication} from '../../../../utils/application-utils';
import {CashHelper} from '../../../../utils/cash-helper';
import {StringHelper} from '../../../../utils/string-helper';
import {TransHelper} from '../../../../utils/trans-helper';
import {ApplicationBasicData} from '../../../loan-applications/loan-application/loan-application.model';
import {LoanDetail} from '../../../shared/loan-detail/LoanDetail';
import {BatchItem, PreApprovedApplication} from '../../batch.model';
import {BatchItemLabels} from '../table/batch-items-table.model';
import IsValidChip from '../table/is-valid-chip/BatchItemIsValidChip';
import styles from './BatchItemDetails.module.scss';

export const TRANS_PREFIX = 'BATCH.BATCH_ITEMS';
export const DETAILS_TRANS_PREFIX = `${TRANS_PREFIX}.DETAILS`;
export const LabelsPrefixTrans = TransHelper.getPrefixedTrans(`${TRANS_PREFIX}.LABELS`);
export const DetailsPrefixTrans = TransHelper.getPrefixedTrans(DETAILS_TRANS_PREFIX);

interface Props {
  batchItem: BatchItem;
  application?: ApplicationBasicData;
}

export const BatchItemDetails = ({batchItem, application}: Props): React.ReactElement => {

  const productInformation = (data: PreApprovedApplication): ReactElement => <>
    <LoanDetail title={<LabelsPrefixTrans>PROCESS_CODE</LabelsPrefixTrans>} content={data.processCode ?? '-'} />
    <LoanDetail title={<LabelsPrefixTrans>PRINCIPAL</LabelsPrefixTrans>}
                content={data.principal ? CashHelper.addCurrencyMask(data.principal) : '-'} />
    <LoanDetail title={<LabelsPrefixTrans>INTEREST_RATE</LabelsPrefixTrans>}
                content={data.interestRate ? StringHelper.addPercentage(data.interestRate) : '-'} />
    <LoanDetail title={<LabelsPrefixTrans>PAYMENT_INTERVAL</LabelsPrefixTrans>} content={data.paymentInterval ?? '-'} />
    <LoanDetail title={<LabelsPrefixTrans>TOTAL_AMORTIZATION_NUMBER</LabelsPrefixTrans>}
                content={data.totalAmortizationNumber ?? '-'} />
    <LoanDetail title={<LabelsPrefixTrans>CIF</LabelsPrefixTrans>} content={data.customerNumber ?? '-'} />
  </>;

  const getErrors = (errors: string[]): ReactElement => {
    const errorComponents = errors.map((error, index) => <div key={index}>{error}</div>);

    return (
      <div className={styles.section}>
        <div className={styles.heading}><LabelsPrefixTrans>{BatchItemLabels.ERRORS}</LabelsPrefixTrans></div>
        <div className={styles.text}>{errorComponents}</div>
      </div>
    );
  };

  const itemDetails = <>
    <div className={styles.grid}>
      <LoanDetail title={
        <LabelsPrefixTrans>{BatchItemLabels.REFERENCE_NUMBER}</LabelsPrefixTrans>}
                  content={batchItem.referenceNo} />
      <LoanDetail title={<LabelsPrefixTrans>{BatchItemLabels.STATUS}</LabelsPrefixTrans>}
                  content={batchItem.status} />
      <LoanDetail title={<LabelsPrefixTrans>{BatchItemLabels.CREATED_ON}</LabelsPrefixTrans>}
                  content={batchItem.createdOn?.format(DATE_TIME_FORMAT) ?? '-'} />
      <LoanDetail title={<LabelsPrefixTrans>{BatchItemLabels.PROCESSED_ON}</LabelsPrefixTrans>}
                  content={batchItem.processedOn?.format(DATE_TIME_FORMAT) ?? '-'} />
      <LoanDetail title={<LabelsPrefixTrans>{BatchItemLabels.VALID}</LabelsPrefixTrans>}
                  content={<IsValidChip isValid={batchItem.valid} />} />
    </div>
    <div className={styles.descriptionTitle}><LabelsPrefixTrans>{BatchItemLabels.DESCRIPTION}</LabelsPrefixTrans></div>
    <div className={styles.descriptionValue}>{batchItem.description}</div>
  </>;

  return <div>
    <h1><Trans values={{id: batchItem.id}}>{`${DETAILS_TRANS_PREFIX}.HEADER`}</Trans></h1>
    <div className={styles.section}>
      <div className={styles.heading}>
        <span><DetailsPrefixTrans>PRODUCT_INFORMATION</DetailsPrefixTrans></span>
        {application &&
          <NxButtonLink className={styles.goToApplicationButton}
                        variant={NxButtonVariant.TEXT}
                        to={createPathToApplication(application)}>
            <DetailsPrefixTrans>GO_TO_APP</DetailsPrefixTrans>
          </NxButtonLink>
        }
      </div>
      {
        batchItem.data
          ? <div className={styles.grid}>{productInformation(batchItem.data)}</div>
          : <span className={styles.text}><DetailsPrefixTrans>NO_DATA</DetailsPrefixTrans></span>
      }
    </div>
    <div className={styles.section}>
      <div className={styles.heading}><DetailsPrefixTrans>FILE_DETAILS</DetailsPrefixTrans></div>
      <div>{itemDetails}</div>
    </div>
    {batchItem.errors && getErrors(batchItem.errors)}
  </div>;
};
