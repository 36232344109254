import {NxLoader} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {PhaseName} from '../../../../../constants/api-urls';
import {UrlParams} from '../../../../../routes/routes.model';
import usePost from '../../../../../shared/hooks/use-post.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {PrintType} from '../../../../../shared/model/print.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {optionalValidationParams} from '../../../../../utils/optional-validation-form-utils';
import {validateAndSubmitForm} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';
import {
  AgreementsPhase
} from '../../../../loan-configurations/loan-configuration/steps/agreements/agreements-phase.model';
import {LoanApplicationContext} from '../../LoanApplication';
import LoanApplicationStep from '../shared/loan-application-step/LoanApplicationStep';
import {StepPayloadType} from '../shared/step-payload.model';
import AgreementCheckbox from './agreement-checkbox/AgreementCheckbox';
import {AgreementsPhaseFormFields, AgreementsPhasePayload} from './agreements-phase-form.model';
import {getValidationSchema} from './agreements-validation-schema';

const {getPhaseConfigurationUrl, getPhaseSaveUrl} = ApiHelper;

export default function Agreements(): React.ReactElement {

  const {t} = useTranslation();
  const {applicationId} = useParams<UrlParams>();
  const {application, processId, setOpenedPhaseId} = useContext(LoanApplicationContext);
  const phaseUrl = getPhaseConfigurationUrl(processId, PhaseName.AGREEMENT);
  const saveStep = usePost<void, AgreementsPhaseFormFields>(getPhaseSaveUrl(applicationId));
  const config = useSingleQuery<AgreementsPhase>(phaseUrl);

  const submit = async (values: AgreementsPhaseFormFields): Promise<void> => {

    const {termsAndConditions, validate} = values;

    const stepPayload: AgreementsPhasePayload = {
      type: StepPayloadType.AGREEMENT,
      termsAndConditions
    };

    return saveStep(stepPayload, null, optionalValidationParams(validate));
  };

  useEffect(() => {
    if (config) {
      setOpenedPhaseId(config.id);
    }
  }, [config]);

  const AgreementsForm = (
    {values, submitForm, validateForm, setFieldValue, dirty}: FormikProps<AgreementsPhaseFormFields>
  ): ReactElement => {

    const handleSave = async (validate: boolean): Promise<void> =>
      validateAndSubmitForm<AgreementsPhaseFormFields>(values, validate, submitForm, validateForm, setFieldValue, t);

    return (
      <LoanApplicationStep handleSave={handleSave}
                           phaseId={config?.id}
                           dataChanged={dirty}
                           approvalRules={config?.approvalRules}
                           printType={PrintType.AGREEMENTS}>
        <Form>
          <div dangerouslySetInnerHTML={{__html: config?.termsAndConditions ?? ''}} />
          <AgreementCheckbox />
        </Form>
      </LoanApplicationStep>
    );
  };

  return isNil(config)
    ? <NxLoader />
    : <Formik<AgreementsPhaseFormFields>
      onSubmit={submit}
      validateOnChange={false}
      validationSchema={getValidationSchema(t)}
      initialValues={{termsAndConditions: !!application?.agreement?.termsAndConditions}}>
      {AgreementsForm}
    </Formik>;
}
