import React from 'react';
import {TransHelper} from '../../../utils/trans-helper';
import {ConfirmationPopup} from '../../shared/confirmation-popup/ConfirmationPopup';
import styles from './RefreshConfigPopup.module.scss';

const TRANS_PREFIX = 'LOAN_CONFIGURATIONS.REFRESH_POPUP';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onConfirm: () => Promise<void>;
}

export const RefreshConfigPopup = ({open, setOpen, onConfirm}: Props): React.ReactElement => {

  const messageBody = (
    <div className={styles.message}>
      <span><PrefixTrans>WARNING_LINE_1</PrefixTrans></span>
      <span><PrefixTrans>WARNING_LINE_2</PrefixTrans></span>
    </div>
  );

  return <ConfirmationPopup
    header={<PrefixTrans>HEADER</PrefixTrans>}
    description={messageBody}
    onConfirm={onConfirm}
    onDeny={(): void => setOpen(false)}
    confirmButtonLabel={<PrefixTrans>REFRESH</PrefixTrans>}
    footerClassName={styles.centeredFooter}
    open={open}
  />;
};
