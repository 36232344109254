import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, {ReactElement} from 'react';
import {SimulatedFee} from '../../../../../../../shared/model/loan-simulation.model';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import EmptyState from '../../../../../../shared/empty-state/EmptyState';
import {FeesOverrideTable} from './table/FeesOverrideTable';
import TableGroup from '../../../../../../shared/table/TableGroup';
import styles from './FeesOverride.module.scss';

interface Props {
  fees?: SimulatedFee[];
}

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA');

export const FeesOverride = ({fees}: Props): ReactElement => {
  const hasNullFeeCategory = fees?.some(fee => isNil(fee.feeCategory));

  return isNil(fees) || isEmpty(fees)
    ? <EmptyState instruction={<PrefixTrans>FEES_OVERRIDE.EMPTY_STATE</PrefixTrans>} />
    : (<>
        {hasNullFeeCategory ?
          // For old simulations, display previous design as it is difficult to migrate data to determine the fee category
          <FeesOverrideTable fees={fees} />
          : (<>
            <TableGroup visible={true} header={<PrefixTrans>FEES_OVERRIDE.NORMAL_FEES</PrefixTrans>}>
              <FeesOverrideTable fees={fees.filter(fee => fee.feeCategory === 'NORMAL')} formFees={fees} />
            </TableGroup>
            <TableGroup className={styles.forumlaTableGroup}
                        visible={true}
                        header={<PrefixTrans>FEES_OVERRIDE.FORMULA_RELATED_FEES</PrefixTrans>}>
              <FeesOverrideTable fees={fees.filter(fee => fee.feeCategory === 'FORMULA')
                .sort((f1, f2) => f1.feeName.localeCompare(f2.feeName))} formFees={fees}/>
            </TableGroup>
          </>)}
      </>
    );
};
