import {TFunction} from 'i18next';
import {boolean, object, AnySchema} from 'yup';
import {
  getOptionalValidationSchema, getDefaultRequiredError
} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';

export const getValidationSchema = (t: TFunction): AnySchema => {

  const REQUIRED_ERROR = getDefaultRequiredError(t);

  const getOptionalRequiredSchema = (partialValidation?: boolean): AnySchema =>
    partialValidation ? boolean() : boolean().oneOf([true], REQUIRED_ERROR).required(REQUIRED_ERROR)

  return object({
    validate: boolean(),
    termsAndConditions: getOptionalValidationSchema(getOptionalRequiredSchema)
  });
};
