import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import clsx from 'clsx';
import React, {useState, useEffect} from 'react';
import styles from './ButtonSwitch.module.scss';

interface Props {
  leftButtonActive: boolean;
  leftButton: React.ReactNode;
  onLeftButtonClick: () => void;
  rightButton: React.ReactNode;
  onRightButtonClick: () => void;
  disabled?: boolean;
}

export default function ButtonSwitch(
  {leftButtonActive, leftButton, onLeftButtonClick, rightButton, onRightButtonClick, disabled = false}: Props
): React.ReactElement {

  const [leftActive, setLeftActive] = useState(leftButtonActive);

  const getButtonVariant = (isContained: boolean): NxButtonVariant =>
    isContained ? NxButtonVariant.CONTAINED : NxButtonVariant.OUTLINED;

  const leftButtonClick = (): void => {
    if (leftActive) {
      // Left active then ignore this click to prevent onRightButtonActive multiple calls
      return;
    }

    onLeftButtonClick();
    setLeftActive(true);
  };

  const rightButtonClick = (): void => {
    if (!leftActive) {
      // Left active then ignore this click to prevent onRightButtonActive multiple calls
      return;
    }

    onRightButtonClick();
    setLeftActive(false);
  };

  useEffect(() => {
    setLeftActive(leftButtonActive);
  }, [leftButtonActive])

  return (
    <div className={styles.switch}>
      <NxButton className={clsx(styles.button, styles.button_left, {[styles.button_active]: leftActive})}
                variant={getButtonVariant(leftActive)}
                onClick={leftButtonClick}
                disabled={disabled}>
        {leftButton}
      </NxButton>
      <NxButton className={clsx(styles.button, styles.button_right, {[styles.button_active]: !leftActive})}
                variant={getButtonVariant(!leftActive)}
                onClick={rightButtonClick}
                disabled={disabled}>
        {rightButton}
      </NxButton>
    </div>
  );
}
