import React, {ReactElement, useCallback, useContext, useState} from 'react';
import {Trans} from 'react-i18next';
import {ReactComponent as EditIcon} from '../../../../../assets/images/icon-edit.svg';
import {Role} from '../../../../../shared/model/management.model';
import TableMenu from '../../../../shared/table/menu/TableMenu';
import TableMenuOption from '../../../../shared/table/menu/TableMenuOption';
import {AccessesContext} from '../../Access';

interface Props {
  role: Role;
}

const AccessMenu = (({role}: Props): ReactElement => {

  const {setEditPanelState} = useContext(AccessesContext);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const editRoleAccess = useCallback((): void => {
    setAnchorElement(null);
    setEditPanelState({role, panelOpen: true});
  }, [role]);

  return (
    <TableMenu menuId={`ROLE_MENU_${role.id}`} anchorElement={anchorElement} setAnchorElement={setAnchorElement}>
      <TableMenuOption label={<Trans>COMMON.EDIT</Trans>} onClick={editRoleAccess} icon={<EditIcon />} />
    </TableMenu>
  );
});

export default AccessMenu;
