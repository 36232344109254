import {Tab, Tabs} from '@material-ui/core';
import React, {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {SecurityContext} from '../../../../App';
import {RoutePaths} from '../../../../routes/routes.paths';
import {hasPermissionToPath} from '../../../../utils/permissions-utils';
import {TransHelper} from '../../../../utils/trans-helper';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import styles from './TopBarTabs.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('TOP_BAR');

const tabs = [
  {
    label: <PrefixTrans>LOAN_APPLICATION</PrefixTrans>,
    path: RoutePaths.LOAN_APPLICATIONS
  },
  {
    label: <PrefixTrans>BATCH</PrefixTrans>,
    path: RoutePaths.BATCH
  },
  {
    label: <PrefixTrans>LOAN_CONFIGURATION</PrefixTrans>,
    path: RoutePaths.LOAN_CONFIGURATIONS
  },
  {
    label: <PrefixTrans>ADMIN</PrefixTrans>,
    path: RoutePaths.ADMIN
  }
];

const tabsClasses = {
  root: styles.tabs,
  indicator: styles.indicator
};

const tabClasses = {
  root: styles.tab,
  selected: styles.tab_selected
};

const tabIndicatorProps = {
  children: <span className={styles.indicatorThumb} />
};

const TopBarTabs = (): React.ReactElement => {

  const history = useGuardedHistory();
  const {userData} = useContext(SecurityContext);
  const currentPath = useLocation().pathname;

  const filteredTabs = tabs.filter(({path}) => hasPermissionToPath(path, userData));
  const currentTab = filteredTabs.find(({path}) => currentPath.startsWith(path))?.path;

  return (
    <>
      {
        filteredTabs.length > 1 &&
          <Tabs classes={tabsClasses} TabIndicatorProps={tabIndicatorProps} value={currentTab}>
            {
              filteredTabs.map(({path, label}, index) =>
                <Tab onClick={(): void => history.push(path)}
                     classes={tabClasses}
                     label={label}
                     value={path}
                     key={index} />
              )
            }
          </Tabs>

      }
    </>
  );
};

export default TopBarTabs;
