import React, {useContext, useRef} from 'react';
import SidePanel, {CloseSidePanel} from '../../../shared/side-panel/SidePanel';
import {CategoriesContext, PrefixTrans} from '../Categories';
import {EditCategoryPanelState} from '../categories.model';
import EditCategoryForm from './edit-category-form/EditCategoryForm';

interface Props {
  state: EditCategoryPanelState;
}

export default function EditCategorySidePanel({state}: Props): React.ReactElement {

  const {panelOpen, category} = state;
  const {refreshCategories, setEditPanelState} = useContext(CategoriesContext);
  const header = <PrefixTrans>{category ? 'EDIT_CATEGORY' : 'NEW_CATEGORY'}</PrefixTrans>;
  const panelRef = useRef<CloseSidePanel>(null);

  const setOpen = (panelOpen: boolean): void => setEditPanelState({...state, panelOpen});

  const onSave = (): void => {
    setOpen(false);
    refreshCategories();
  };

  return (
    <SidePanel ref={panelRef} open={panelOpen} setOpen={setOpen} header={header}>
      <EditCategoryForm category={category} onSave={onSave} />
    </SidePanel>
  );
}
