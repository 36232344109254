import {useField} from 'formik';
import React from 'react';
import {ReactComponent as CorporateCustomerIcon} from '../../../../../../assets/images/icon-corporate-customer.svg';
import {ReactComponent as IndividualCustomerIcon} from '../../../../../../assets/images/icon-individual-customer.svg';
import {CustomerType} from '../../../../../../shared/model/customer-type.model';
import {TransHelper} from '../../../../../../utils/trans-helper';
import ButtonSwitch from '../../../../../shared/button-switch/ButtonSwitch';
import styles from './CustomerTypeSwitch.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('COMMON.CUSTOMER_TYPES');
export default function CustomerTypeSwitch(): React.ReactElement {

  const [field, , helpers] = useField<CustomerType>('customerType');

  const IndividualClientType = <div className={styles.customerType}>
    <IndividualCustomerIcon />
    <PrefixTrans>INDIVIDUAL</PrefixTrans>
  </div>;

  const CorporateClientType = <div className={styles.customerType}>
    <CorporateCustomerIcon />
    <PrefixTrans>CORPORATE</PrefixTrans>
  </div>;

  return <ButtonSwitch
    leftButtonActive={field.value === CustomerType.INDIVIDUAL}
    leftButton={IndividualClientType}
    onLeftButtonClick={(): void => helpers.setValue(CustomerType.INDIVIDUAL)}
    rightButton={CorporateClientType}
    onRightButtonClick={(): void => helpers.setValue(CustomerType.CORPORATE)}
  />;
}
