import {Phase} from '../../../../../shared/model/phase.model';
import {sortByOrderNo} from '../../../../../utils/sort-by-order';
import {
  KeyedApprovalRule, toKeyedApprovalRule
} from '../../loan-configuration-step/approval-configuration/approval-rule-items/keyed-approval-rules.model';

interface ApprovalInitValues {
  approvalRules?: KeyedApprovalRule[],
  isApprovalRequired: boolean
}

export function getInitialApprovalRulesValues(config: Phase): ApprovalInitValues {

  const keyedApprovalRules = config?.approvalRules?.map(toKeyedApprovalRule) ?? [];
  const approvalRules = sortByOrderNo(keyedApprovalRules)
    .map(rule => ({
      ...rule,
      approvers: sortByOrderNo(rule.approvers)
    }));

  return {approvalRules, isApprovalRequired: approvalRules.length > 0};
}
