import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React, {ReactElement, useState} from 'react';
import {Trans} from 'react-i18next';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import {ConfirmationPopup} from '../../../../../../shared/confirmation-popup/ConfirmationPopup';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.EDIT_CONFIRMATION_POPUP');

interface Props {
  loaded: boolean;
  revertStep: () => Promise<void>;
}

export const EditButton = ({loaded, revertStep}: Props): ReactElement => {

  const [popupOpen, setPopupOpen] = useState(false);

  const togglePopup = (): void => setPopupOpen(!popupOpen);

  const onConfirm = async (): Promise<void> => {
    togglePopup();
    await revertStep();
  }

  return (
    <>
      <NxButton variant={NxButtonVariant.TEXT} loaded={loaded} onClick={togglePopup}>
        <Trans>COMMON.EDIT</Trans>
      </NxButton>
      <ConfirmationPopup header={<PrefixTrans>HEADER</PrefixTrans>}
                         description={<PrefixTrans>DESCRIPTION</PrefixTrans>}
                         confirmButtonLabel={<PrefixTrans>CONFIRM</PrefixTrans>}
                         onConfirm={onConfirm}
                         onDeny={togglePopup}
                         open={popupOpen} />
    </>
  );
};
