import React, {ReactElement} from 'react';
import {CorporateDataTO} from '../loan-application/steps/customer-data/customer-data-form.model';
import FormColumn from '../../shared/form-column/FormColumn';
import FormGroup from '../../shared/form-column/FormGroup';
import ReadOnlyInput from './ReadOnlyInput';
import ReadOnlyPhoneInput from './ReadOnlyPhoneInput';
import style from './ConsolidateCustomerDataReview.module.scss';

interface Props {
  corporateData?: CorporateDataTO;
  getDictionaryEntryName: (id?: number) => string;
}

const CorporateCustomerDataReview = ({corporateData, getDictionaryEntryName}: Props): ReactElement => {

  const ReadOnlyInputWithStyle = ({label, value, isBoolean = false}: {
    label: string,
    value?: string,
    isBoolean?: boolean
  }): ReactElement => {
    return <ReadOnlyInput label={label} value={value} isBoolean={isBoolean} className={style.input} />;
  };

  const ReadOnlyPhoneInputWithStyle = ({label, value}: {
    label: string,
    value?: string,
  }): ReactElement => {
    return <ReadOnlyPhoneInput label={label} value={value} className={style.input} />;
  };

  return (
    <>
      <FormColumn>
        <FormGroup header='Company Data'>
          <ReadOnlyInputWithStyle label='Business name'
                                  value={corporateData?.businessName} />
          <ReadOnlyInputWithStyle label='Trade name'
                                  value={corporateData?.tradeName} />
          <ReadOnlyInputWithStyle label='Business type' 
                                  value={getDictionaryEntryName(Number(corporateData?.businessTypeId))} />
          {corporateData?.conglomerateId && <ReadOnlyInputWithStyle label='Conglomerate'
                                                                    value={corporateData?.conglomerateId}
                                                                    isBoolean />}
          <ReadOnlyInputWithStyle label='Source of funds'
                                  value={corporateData?.sourceOfFunds} />
          <ReadOnlyInputWithStyle label='Place of incorporation'
                                  value={corporateData?.placeOfIncorporation} />
          <ReadOnlyInputWithStyle label='Registration number'
                                  value={corporateData?.registrationNumber} />
          <ReadOnlyInputWithStyle label='Registration date'
                                  value={corporateData?.tradeName} />
          <ReadOnlyInputWithStyle label='Registration country'
                                  value={corporateData?.registrationCountryId} />
        </FormGroup>
        <FormGroup header='Compliance'>
          <ReadOnlyInputWithStyle label='Risk level'
                                  value={getDictionaryEntryName(Number(corporateData?.riskLevelId))} />
          <ReadOnlyInputWithStyle label='DOSRI'
                                  value={corporateData?.dosri} />
          <ReadOnlyInputWithStyle label='DOSRI Type'
                                  value={getDictionaryEntryName(Number(corporateData?.dosriTypeId))} />
          <ReadOnlyInputWithStyle label='Related party transaction'
                                  value={getDictionaryEntryName(Number(corporateData?.relatedPartyTransactionId))} />
          <ReadOnlyInputWithStyle label='Related party transaction description'
                                  value={corporateData?.relatedPartyTransactionDescription} />
        </FormGroup>
      </FormColumn>
      <FormColumn>
        <FormGroup header='Contact data'>
          <ReadOnlyInputWithStyle label='Email'
                                  value={corporateData?.email} />
          <ReadOnlyPhoneInputWithStyle label='Phone number'
                                       value={corporateData?.phoneNumber} />
        </FormGroup>
      </FormColumn>
    </>
  );
};

export default CorporateCustomerDataReview;
