import Dashboard from '../components/dashboard/Dashboard';
import Login from '../components/login/Login';
import {KeyedRouteProps} from './routes.model';
import {RoutePaths} from './routes.paths';

export const ROUTES: KeyedRouteProps[] = [
  {
    path: RoutePaths.LOGIN,
    key: 'LOGIN',
    exact: true,
    component: Login
  },
  {
    path: RoutePaths.ROOT,
    key: 'DASHBOARD',
    component: Dashboard
  }
];
