import {CancelTokenSource} from 'axios';
import {LogoFile} from '../../components/loan-configurations/loan-configuration/steps/general/general.model';
import {isDevelopmentMode} from '../../utils/app-utils';
import {blobResponseToFile} from '../../utils/file-upload-utils';
import {StringHelper} from '../../utils/string-helper';
import HttpService from '../http-service/http.service';
import {FileBasicData, NxFile} from '../model/nx-file.model';
import usePostMultipart from './use-post-multipart.hook';

const FILES_PATH_URL = '/files';

export type DownloadFileFunctionType = (fileBasicData: FileBasicData) => Promise<NxFile>;

export type UploadFileFunctionType =
  (data: LogoFile, cancelTokenSource?: CancelTokenSource | null) => Promise<number | undefined>;

interface FileUploadUtils {
  downloadFile: DownloadFileFunctionType;
  uploadFile: UploadFileFunctionType;
}

export default function useFileUpload(): FileUploadUtils {

  const downloadFile = (fileBasicData: FileBasicData): Promise<NxFile> => {
    if (!fileBasicData.url) {
      throw new Error('Existing document has no URL provided');
    }

    if (isDevelopmentMode) {
      console.warn('App in local development mode. Cannot fetch image stored on local disk');
      return Promise.resolve({id: fileBasicData.id, file: undefined, isChanged: false});
    }

    const downloadUrl = StringHelper.addHttps(fileBasicData.url);

    return HttpService.get<File>(downloadUrl, null, {responseType: 'blob'})
      .then(async fileBlob => {
        const file = await blobResponseToFile(fileBlob, fileBasicData.name ?? '');
        return {id: fileBasicData.id, file, isChanged: false} as NxFile;
      });
  };

  const uploadFile = usePostMultipart<number, LogoFile>(FILES_PATH_URL);

  return {downloadFile, uploadFile};
}
