import {CancelTokenSource} from 'axios';
import HttpService from '../http-service/http.service';
import useCatchUnauthorizedError from './use-catch-unauthorized-error.hook';

/**
 * Dedicated hook for perform POST request.
 *
 * @param url - target url for API request.
 *
 * T - type of data returned by api call.
 * E - type of data passed as POST body.
 *
 * @return function which allows execute configured POST request. It has following arguments:
 * data - Data of type E which would be passed as POST body, it can be null or undefined.
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */

export default function usePostMultipart<T, E>(url: string):
  (data: E, cancelTokenSource?: CancelTokenSource | null) => Promise<T | undefined> {

    const catchUnauthorizedError = useCatchUnauthorizedError();

    return (data: E, cancelTokenSource?: CancelTokenSource | null): Promise<T | undefined> =>
      HttpService.postMultipart<T, E>(url, data, cancelTokenSource)
        .catch(catchUnauthorizedError);
  }
