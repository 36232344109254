import {Formik, FormikProps} from 'formik';
import {omit} from 'lodash';
import isNil from 'lodash/isNil';
import values from 'lodash/values';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {PhaseName} from '../../../../../constants/api-urls';
import {LoanParametersOptions} from '../../../../../shared/hooks/use-loan-application-data-options.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import usePut from '../../../../../shared/hooks/use-put.hook';
import {PaymentType} from '../../../../../shared/model/payment.model';
import {PhaseValidationResult} from '../../../../../shared/model/phase-validation-result.model';
import {
  CalculatorFormFieldsConfiguration as FormFields
} from '../../../../../shared/model/step-forms/calculator-form.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {generateKey} from '../../../../../utils/generate-key';
import {getCalculatorInitFields} from '../../../../../utils/step-form-utils/calculator-init-fields';
import {TransHelper} from '../../../../../utils/trans-helper';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import LoanConfigurationStep from '../../loan-configuration-step/LoanConfigurationStep';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {useSetConfigurationFormChanged} from '../hooks/use-set-configuration-form-changed.hook';
import LoanParameters from '../shared/simulation/loan-parameters/LoanParameters';
import {CalculatorPhase, KeyedFixedPaymentOption, toKeyedFixedPaymentOption} from './calculator-phase.model';

export const PrefixTrans = TransHelper.getPrefixedTrans('COMMON.FIELDS');

interface Props {
  config: CalculatorPhase,
  loanParametersOptions: LoanParametersOptions
}

export default function CalculatorForm({config, loanParametersOptions}: Props): React.ReactElement {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {process, configurationChanged, setConfigurationChanged} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.CALCULATOR);

  const [isPaymentDynamic, setIsPaymentDynamic] = useState(true);
  const [fixedPaymentOptions, setFixedPaymentOptions] = useState<KeyedFixedPaymentOption[]>([{...generateKey()}]);
  const updatePhase = usePut<void, CalculatorPhase>(phaseUrl);
  const validatePhase = usePost<PhaseValidationResult, CalculatorPhase>(`${phaseUrl}/validate`);

  useEffect(() => {
    setIsPaymentDynamic(config.paymentType === PaymentType.DYNAMIC);

    if (config.fixedPaymentOptions) {
      setFixedPaymentOptions(config.fixedPaymentOptions.map(toKeyedFixedPaymentOption));
    }
  }, [config]);

  const buildPhaseConfig = (formFields: FormFields): CalculatorPhase | undefined => {
    const filteredFixedPaymentOptions = fixedPaymentOptions
      .map(option => omit(option, 'key'))
      .filter(option => !isNil(option.totalAmortizationNumber) && !isNil(option.paymentIntervalId));

    const staticFields = values(formFields)
      .filter(field => field)
      .map(field => ({...field, defaultValue: !isNil(field.defaultValue) ? field.defaultValue.toString() : undefined}));

    return {
      ...config,
      paymentType: isPaymentDynamic ? PaymentType.DYNAMIC : PaymentType.FIXED,
      fixedPaymentOptions: isPaymentDynamic ? [] : filteredFixedPaymentOptions,
      staticFields
    };
  };

  const submit = async (formFields: FormFields): Promise<void> =>
    await updatePhase(buildPhaseConfig(formFields)).catch(error => showErrorMessage(error.message));

  const markConfigurationChanged = (): void => {
    if (!configurationChanged) {
      setConfigurationChanged(true);
    }
  };

  const guardedSetFixedPaymentOptions = (fields: KeyedFixedPaymentOption[]): void => {
    setFixedPaymentOptions(fields);
    markConfigurationChanged();
  };

  const guardedSetIsPaymentDynamic = (isPaymentDynamic: boolean): void => {
    setIsPaymentDynamic(isPaymentDynamic);
    markConfigurationChanged();
  };

  const StepForm = (
    {values, setFieldValue, submitForm, dirty}: FormikProps<FormFields>
  ): ReactElement => {

    const validate = (): Promise<PhaseValidationResult> => validatePhase(buildPhaseConfig(values));

    useSetConfigurationFormChanged(dirty);

    const paymentFieldProps = {
      fixedPaymentsState: {fixedPaymentOptions, setFixedPaymentOptions: guardedSetFixedPaymentOptions},
      paymentModeState: {isPaymentDynamic, setIsPaymentDynamic: guardedSetIsPaymentDynamic},
      paymentIntervalOptionsProps: loanParametersOptions.paymentIntervalOptionsProps,
      paymentIntervalField: values.paymentIntervalId
    };

    return (
      <LoanConfigurationStep handleSave={submitForm} validationFunction={validate}>
        <LoanParameters values={values}
                        setFieldValue={setFieldValue}
                        paymentFieldProps={paymentFieldProps}
                        loanParametersOptions={loanParametersOptions} />
      </LoanConfigurationStep>
    );
  };

  return (
    <Formik<FormFields> onSubmit={submit} initialValues={getCalculatorInitFields(config)}>
      {StepForm}
    </Formik>
  );
}
