import isNil from 'lodash/isNil';
import {useContext, useEffect} from 'react';
import {GuardedRouterActionType} from '../../../../../router/guarded-router-context.model';
import {GuardedRouterContext} from '../../../../../router/GuardedRouterContext';
import {LoanApplicationContext} from '../../../LoanApplication';
import {LoanApplicationNavigationContext} from '../../../navigation/loan-navigation.model';
import {useHasApprovalStatusChangedToOrFromPending} from './use-loan-approval-status-changed.hook';

// if dataChanged not provided to step, lock guard is never set
export function useApplicationLock(dataChanged?: boolean): void {
  const {dispatchAction} = useContext(GuardedRouterContext);
  const {setApplicationChanged} = useContext(LoanApplicationNavigationContext);
  const {application} = useContext(LoanApplicationContext);
  const {approvalStatus} = application ?? {};
  const hasApprovalStatusChanged = useHasApprovalStatusChangedToOrFromPending(approvalStatus);

  /**
   * Locks view to prevent unwanted exit without save
   */
  useEffect(() => {
    if (isNil(dataChanged)) {
      return;
    }

    if (dataChanged) {
      dispatchAction({type: GuardedRouterActionType.LOCK_ROUTE_CHANGE});
      setApplicationChanged(true);
    } else {
      dispatchAction({type: GuardedRouterActionType.UNLOCK_ROUTE_CHANGE});
      setApplicationChanged(false);
    }
  }, [dataChanged]);

  /**
   * Unlocks view when application awaits for an approval or have just been approved/rejected
   */
  useEffect(() => {
    if (hasApprovalStatusChanged) {
      dispatchAction({type: GuardedRouterActionType.UNLOCK_ROUTE_CHANGE});
      setApplicationChanged(false);
    }
  }, [hasApprovalStatusChanged]);
}
