import {NxButton} from '@nextbank/ui-components';
import React, {ReactElement, useContext, useState} from 'react';
import {SecurityContext} from '../../../../../App';
import {BASE_AUTH_URL} from '../../../../../constants/api-urls';
import useGet from '../../../../../shared/hooks/use-get.hook';
import {ReactComponent as LogoutIcon} from '../../../../../assets/images/icon-log-out.svg';
import {GuardedRouterActionType} from '../../../../router/guarded-router-context.model';
import {GuardedRouterContext} from '../../../../router/GuardedRouterContext';
import styles from './LogoutButton.module.scss';

const LogoutButton = (): ReactElement => {

  const {dispatchAction} = useContext(GuardedRouterContext);
  const {setIsLogged} = useContext(SecurityContext);
  const logout = useGet<void>(`${BASE_AUTH_URL}/logout`);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const onLogout = (): void => {
    setIsLoggingOut(true);
    logout()
      .then((): void => {
        setIsLogged(false);
        dispatchAction({type: GuardedRouterActionType.UNLOCK_ROUTE_CHANGE});
      })
      .finally((): void => setIsLoggingOut(false));
  };

  return (
    <NxButton onClick={onLogout}
              className={styles.logoutButton}
              loaded={!isLoggingOut}
              type='submit'>
      <div className={styles.logoutIcon}>
        <LogoutIcon />
      </div>
    </NxButton>
  );
};

export default LogoutButton;
