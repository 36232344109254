import {debounce} from '@material-ui/core';
import {NxLoader} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {BATCH} from '../../constants/api-urls';
import {PAGE_RECORDS_LIMIT, REFRESH_DATA_DEBOUNCE_DELAY} from '../../constants/table-defaults';
import useGet from '../../shared/hooks/use-get.hook';
import {PagedRecords} from '../../shared/model/paged.model';
import {ApiHelper} from '../../utils/api-helper';
import {TransHelper} from '../../utils/trans-helper';
import styles from './ApplicationBatch.module.scss';
import {Batch, BatchSearchCriteria, BatchTO, toBatch} from './batch.model';
import CreateBatch from './create/CreateBatch';
import SearchBatchForm from './search/SearchBatchForm';
import BatchTable from './table/BatchTable';

export const TRANS_PREFIX = 'BATCH';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

const ApplicationBatch = (): React.ReactElement => {

  const getBatches = useGet<PagedRecords<BatchTO>>(BATCH);
  const [batches, setBatches] = useState<PagedRecords<Batch>>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const getBatchPage = (pageNo = 0, searchParams?: BatchSearchCriteria): Promise<void> => {
    setLoading(true);
    const urlSearchParams = ApiHelper.constructUrlParams({
      pageNo,
      pageSize: PAGE_RECORDS_LIMIT,
      ...searchParams
    });

    return getBatches(urlSearchParams)
      .then(response =>
        setBatches({
          ...response,
          result: response.result.map(toBatch)
        })
      ).finally(() => setLoading(false));
  };

  const onSubmit = (values: BatchSearchCriteria): Promise<void> => getBatchPage(0, values);
  const debouncedOnSubmit = useCallback(debounce(onSubmit, REFRESH_DATA_DEBOUNCE_DELAY), []);

  const applicationBatch = ({values}: FormikProps<BatchSearchCriteria>): ReactElement => {
    const changePage = (pageNo: number): Promise<void> => getBatchPage(pageNo, values);

    useEffect(() => {
      debouncedOnSubmit(values);
    }, [values]);

    return (
      <Form>
        <SearchBatchForm />
        {
          isLoading || isNil(batches)
            ? <NxLoader />
            : <BatchTable batches={batches} onPageChange={changePage} className={styles.table} />
        }
      </Form>
    );
  };

  const onCreated = (): Promise<void> => {
    return getBatchPage();
  };

  return (
    <div className={styles.form}>
      <h1 className={styles.title}>
        <PrefixTrans>HEADER</PrefixTrans>
      </h1>
      <div className={styles.description}>
        <PrefixTrans>DESCRIPTION</PrefixTrans>
      </div>
      <CreateBatch onCreated={onCreated} />
      <Formik<BatchSearchCriteria> initialValues={{}} onSubmit={onSubmit}>
        {applicationBatch}
      </Formik>
    </div>
  );
};

export default ApplicationBatch;
