import React from 'react';
import {Trans} from 'react-i18next';
import EmptyState from '../../../../../../shared/empty-state/EmptyState';
import {PrefixTrans} from '../../CustomerData';

const NoCustomerFoundState = (): React.ReactElement => {
  return <EmptyState header={<Trans>COMMON.NO_RESULTS</Trans>}
                     instruction={<PrefixTrans>MAKE_SURE_CORRECT_DATA_INSTRUCTION</PrefixTrans>} />;

};

export default NoCustomerFoundState;
