import {NxFormikNumberInput} from '@nextbank/ui-components';
import React from 'react';
import {TransHelper} from '../../../../../../utils/trans-helper';
import styles from './CoMakers.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.CO_MAKERS');

export default function CoMakers(): React.ReactElement {
  return (
    <div className={styles.column}>
      <NxFormikNumberInput name='maxCoMakerNumber'
                           label={<PrefixTrans>MAX_CO_MAKERS</PrefixTrans>}
                           className={styles.input}
                           min={0} />
      <NxFormikNumberInput name='minCoMakerNumber'
                           label={<PrefixTrans>MIN_CO_MAKERS</PrefixTrans>}
                           className={styles.input}
                           min={0} />
      <NxFormikNumberInput name='maxCoBorrowerNumber'
                           label={<PrefixTrans>MAX_CO_BORROWERS</PrefixTrans>}
                           className={styles.input}
                           min={0} />
      <NxFormikNumberInput name='minCoBorrowerNumber'
                           label={<PrefixTrans>MIN_CO_BORROWERS</PrefixTrans>}
                           className={styles.input}
                           min={0} />
    </div>
  );
}
