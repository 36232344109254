export enum TableType {
  MY_APPLICATIONS = 'MY_APPLICATIONS',
  ALL_APPLICATIONS = 'ALL_APPLICATIONS',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  TO_UPDATE = 'TO_UPDATE',
  TO_APPROVE = 'TO_APPROVE',
  TO_RELEASE = 'TO_RELEASE'
}

export interface FilterValues {
  customerNumber?: string,
  referenceNumber?: string,
  customerName?: string,
  tableType: TableType,
  approvalStatuses: string[],
  applicationStatuses: string[]
}
