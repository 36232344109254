import {useField} from 'formik';
import React, {ReactElement, useContext, useEffect, useMemo, useState} from 'react';
import {DictionaryRequirement, FieldRequirement} from '../../../../../../shared/model/dictionary-requirement.model';
import {DictionaryHelper} from '../../../../../../utils/dictionary-helper';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {FieldDisablementReason} from '../../shared/configured-fields/field-disablement-reason.model';
import {StepContext} from '../../shared/loan-application-step/LoanApplicationStep';
import {ApplicantContext} from '../shared/ApplicantContext';
import {EntryRequirementHelper} from '../shared/entry-requirement-helper';
import OptionalRequirementsSelect from '../shared/OptionalRequirementsSelect';
import {IdDocument} from './id-document.model';
import IdDocumentForm, {IdDocumentFormEntries} from './IdDocumentForm';
import styles from './IdDocuments.module.scss';

interface Props {
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.individualData.firstName.
   */
  formikFieldPath: string;
  documents: IdDocument[];
  entries: IdDocumentFormEntries;
  requirement?: DictionaryRequirement;
}

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CUSTOMER_DATA.DOCUMENTS');
const {getEnabledEntryRequirements} = DictionaryHelper;
const {getDocumentRequirementsOptions, getInitialDocumentRequirements} = EntryRequirementHelper;

export default function IdDocuments({formikFieldPath, documents, entries, requirement}: Props): ReactElement {

  const {isCoreCustomer} = useContext(ApplicantContext);
  const {isStepReadonly} = useContext(StepContext);
  const disablementReason = isCoreCustomer ? FieldDisablementReason.VALUE_MANAGED_BY_CBS : undefined;
  const disabled = isStepReadonly || !!disablementReason;
  const enabledRequirements = getEnabledEntryRequirements(requirement);
  const fieldPath = `${formikFieldPath}.idDocuments`;

  const [selectedOptionalRequirements, setSelectedOptionalRequirements] =
  useState<number[]>(getInitialDocumentRequirements(documents, enabledRequirements));

  const requiredDocuments = useMemo(() => enabledRequirements.filter(documents => documents.requirement === FieldRequirement.REQUIRED), [])
  
  const optionalRequirementsOptions = useMemo(() =>
    getDocumentRequirementsOptions(enabledRequirements, entries.idDocumentRequirement), [enabledRequirements]);

  const [, , helpers] = useField(fieldPath);

  const updateRequirementsSelectValue = (wasRequirementAdded: boolean, typeId: number): void => 
    helpers.setValue(wasRequirementAdded ? [...documents, {typeId, scans: []}] : filterDocuments(typeId));

  const removeDocument = (typeId: number): void => {

    const filteredRequirements = selectedOptionalRequirements.filter(requirement => requirement !== typeId);

    setSelectedOptionalRequirements(filteredRequirements);
    helpers.setValue(filterDocuments(typeId));
  };

  const filterDocuments = (typeId: number): IdDocument[] => documents.filter(document => document.typeId !== typeId);

  const addRequiredDocumentSelectValue = (typeIds: number[]): void => {
    const newRequiredDocument = typeIds.map(typeId => ({ typeId, scans: []}));
    helpers.setValue([...documents, ...newRequiredDocument]);
  }

  useEffect(() => {
    const requiredDocumentIds = requiredDocuments.map(document => document.dictionaryEntryId);
    const filteredRequiredDocumentIds = requiredDocumentIds.filter(typeId => !documents.some(document => document.typeId === typeId));
    addRequiredDocumentSelectValue(filteredRequiredDocumentIds);
    const filterSelectedDocumentIds = requiredDocumentIds.filter(id => !selectedOptionalRequirements.some(selectedId => selectedId === id));
    setSelectedOptionalRequirements([...selectedOptionalRequirements, ...filterSelectedDocumentIds]);
  }, [requiredDocuments]);

  return (
    <>
      <OptionalRequirementsSelect disabled={disabled}
                                  disablementReason={disablementReason}
                                  options={optionalRequirementsOptions}
                                  updateSelectValue={updateRequirementsSelectValue}
                                  selectedRequirements={selectedOptionalRequirements}
                                  setSelectedRequirements={setSelectedOptionalRequirements}
                                  label={<PrefixTrans>OPTIONAL_REQUIREMENTS</PrefixTrans>} />
      <div className={styles.row}>
        {
          documents.map((document, index) => {

            const entry = enabledRequirements.find(entry => entry.dictionaryEntryId === document.typeId);

            if (!entry) {
              throw Error('Requirement type for existing document not found');
            }

            return <IdDocumentForm key={index}
                                   entry={entry}
                                   entries={entries}
                                   disabled={disabled}
                                   formPath={`${fieldPath}[${index}]`}
                                   deleteFunction={removeDocument}
                                   disablementReason={disablementReason} />;
          })
        }
      </div>
    </>
  );
}
