import React, {ReactElement} from 'react';
import {ReactComponent as BanIcon} from '../../../../../assets/images/icon-ban.svg';
import {ReactComponent as CheckedIcon} from '../../../../../assets/images/icon-checked.svg';
import {ReactComponent as CloseIcon} from '../../../../../assets/images/icon-close.svg';
import {ReactComponent as QuestionMarkIcon} from '../../../../../assets/images/icon-question-mark.svg';
import {BatchItemStatus} from '../../../batch.model';
import styles from './BatchItemIsValidChip.module.scss';

interface Props {
  isValid?: boolean;
  status?: BatchItemStatus;
}

const IsValidChip = ({isValid, status}: Props): ReactElement => {

  const statusComponent = (): ReactElement => {
    switch (isValid) {
      case true:
        return status === BatchItemStatus.REVOKED ? <BanIcon /> : <CheckedIcon className={styles.valid} />;
      case false:
        return <CloseIcon className={styles.invalid} />;
      default:
        return <QuestionMarkIcon className={styles.unknown} />;
    }
  };

  return (
    <span>
      {statusComponent()}
    </span>
  );
};

export default IsValidChip;
