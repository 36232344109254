import {CancelTokenSource} from 'axios';
import HttpService from '../http-service/http.service';
import useCatchUnauthorizedError from './use-catch-unauthorized-error.hook';

export type PostRequest<T,E> =
  (data?: E | null, cancelTokenSource?: CancelTokenSource | null, params?: URLSearchParams) => Promise<T>

/**
 * Dedicated hook for perform POST request.
 *
 * @param url - target url for API request.
 *
 * T - type of data returned by api call.
 * E - type of data passed as POST body.
 *
 * @return function which allows execute configured POST request. It has following arguments:
 * data - Data of type E which would be passed as POST body, it can be null or undefined.
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */
export default function usePost<T, E>(url: string): PostRequest<T,E> {

  const catchUnauthorizedError = useCatchUnauthorizedError();

  return (data?: E | null,
          cancelTokenSource?: CancelTokenSource | null,
          params?: URLSearchParams): Promise<T> =>
    HttpService.post<T>(url, data, cancelTokenSource, {params})
      .catch(catchUnauthorizedError);
}
