import {NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {PhaseName} from '../../../../../constants/api-urls';
import {UrlParams} from '../../../../../routes/routes.model';
import useGet from '../../../../../shared/hooks/use-get.hook';
import {fromPhaseTO, Phase, PhaseTO} from '../../../../../shared/model/phase.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {DraggableCheckGroup, toDraggableCheckGroup} from '../../../shared/draggable-checks/draggable-checks.model';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import CustomPhaseForm from './CustomPhaseForm';

const CustomPhase = (): ReactElement => {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {process, configurationChanged, setConfigurationChanged} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.CUSTOM);
  const {phaseId} = useParams<UrlParams>();
  const fetchCustomPhase = useGet<PhaseTO>(`${phaseUrl}/${phaseId}`);
  const [config, setConfig] = useState<Phase>();
  const [checkGroups, setCheckGroups] = useState<DraggableCheckGroup[]>([]);
  const [isCustomPhaseLoading, setIsCustomPhaseLoading] = useState(true);

  useEffect(() => {
    setIsCustomPhaseLoading(true);
    fetchCustomPhase()
      .then(configTO => {

        const config = fromPhaseTO(configTO);
        setConfig(config);

        const draggableCheckGroups = config.checkGroups.map(checkGroup => toDraggableCheckGroup(checkGroup));
        setCheckGroups(draggableCheckGroups);

        setIsCustomPhaseLoading(false);
      })
      .catch(error => showErrorMessage(error.message));
  }, [phaseId]);

  const updateCheckGroups = (checkGroups: DraggableCheckGroup[]): void => {
    if (!configurationChanged) {
      setConfigurationChanged(true);
    }

    setCheckGroups(checkGroups);
  };

  return isNil(config) || isCustomPhaseLoading ? <NxLoader /> : <CustomPhaseForm config={config}
                                                                                 checkGroups={checkGroups}
                                                                                 updateCheckGroups={updateCheckGroups} />;
};

export default CustomPhase;
