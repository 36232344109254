import React, {ReactElement, useCallback, useContext, useState} from 'react';
import {Trans} from 'react-i18next';
import {ReactComponent as EditIcon} from '../../../../../assets/images/icon-edit.svg';
import {ReactComponent as CloseIcon} from '../../../../../assets/images/icon-close.svg';
import {Category} from '../../../../../shared/model/category.model';
import TableMenu from '../../../../shared/table/menu/TableMenu';
import TableMenuOption from '../../../../shared/table/menu/TableMenuOption';
import {CategoriesContext} from '../../Categories';

interface Props {
  category: Category;
  onDeleteCategory: (categoryId: number) => void;
}

const CategoryMenu = (({category, onDeleteCategory}: Props): ReactElement => {

  const {setEditPanelState} = useContext(CategoriesContext);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const editCategory = useCallback((): void => {
    setAnchorElement(null);
    setEditPanelState({category, panelOpen: true});
  }, [category]);

  const deleteCategory = useCallback((): void => {
    setAnchorElement(null);
    onDeleteCategory(category.id);
  }, [category.id]);

  return (
    <TableMenu menuId={`CATEGORY_MENU_${category.id}`} anchorElement={anchorElement} setAnchorElement={setAnchorElement}>
      <TableMenuOption label={<Trans>COMMON.EDIT</Trans>}
                       onClick={editCategory}
                       icon={<EditIcon />} />
      <TableMenuOption label={<Trans>COMMON.DELETE</Trans>}
                       onClick={deleteCategory}
                       icon={<CloseIcon />} />
    </TableMenu>
  );
});

export default CategoryMenu;
