import {useEffect, useContext, useRef} from 'react';
import useGet from './use-get.hook';
import {GuardedRouterContext} from '../../components/router/GuardedRouterContext';
import {GuardedRouterActionType} from '../../components/router/guarded-router-context.model';
import {BASE_AUTH_URL} from '../../constants/api-urls';

export function useUserSessionTimeout(
  setUserData: (value) => void,
  setIsLogged: (value) => void,
  isLogged: boolean | undefined
): void {
  const USER_ACTIVE_SESSION_KEY = 'userActiveSessionTime';
  const MAX_SESSION_TIMEOUT_IN_MINUTES = 15;
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const logout = useGet<void>(`${BASE_AUTH_URL}/logout`);
  const {dispatchAction} = useContext(GuardedRouterContext);

  const handleIdle = (): void => {
    setUserData(undefined);
    setIsLogged(false);

    // Logout user if timer reached the specified time
    logout()
      .then((): void => {
        setIsLogged(false);
        dispatchAction({type: GuardedRouterActionType.UNLOCK_ROUTE_CHANGE});
        localStorage.clear();
      });
  };
  
  const resetTimeout = (): void => {
    clearCurrentTimeout();
    timeoutIdRef.current = setTimeout(handleIdle, 1000 * 60 * MAX_SESSION_TIMEOUT_IN_MINUTES);
  };

  const clearCurrentTimeout = (): void => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  };

  const handleActive = (): void => {
    resetTimeout();
    // Store to localStorage
    localStorage.setItem(USER_ACTIVE_SESSION_KEY, new Date().toString());
    window.dispatchEvent(new Event('storage'));
  };

  const handleStorageEvent = (event: StorageEvent): void => {
    if (event.key === USER_ACTIVE_SESSION_KEY) {
      resetTimeout();
    }
  };

  useEffect(() => {
    resetTimeout();

    if (isLogged) {
      const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'keydown'];
      // Listens to changes if user is active on another tab
      window.addEventListener('storage', handleStorageEvent);

      events.forEach(event => window.addEventListener(event, handleActive));
      return () => {
        events.forEach(event => window.removeEventListener(event, handleActive));
        window.removeEventListener('storage', handleStorageEvent);
        clearCurrentTimeout();
      };
    }
  }, [isLogged]);
}
