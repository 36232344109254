import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {useGuardedHistory} from '../../router/GuardedHistory';

interface Props {
  className?: string;
  path: string;
  disabled?: boolean;
}

export default function DiscardChangesButton({className, path, disabled = false}: Props): ReactElement {

  const history = useGuardedHistory();

  return (
    <NxButton className={className}
              variant={NxButtonVariant.CLOSE}
              onClick={(): void => history.forcePush(path)}
              disabled={disabled}>
      <Trans>TOP_BAR.DISCARD_CHANGES</Trans>
    </NxButton>
  );
}
