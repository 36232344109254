import {createContext, useReducer} from 'react';
import {
  GuardedRouterAction,
  GuardedRouterActionType,
  GuardedRouterContextState,
  GuardedRouterContextType
} from './guarded-router-context.model';

const noop = (): void => {
  //no operation function
};

const initialState: GuardedRouterContextState = {
  routeChangeLock: false,
  showRouteChangeConfirmationPopup: false,
  onRouteChangeConfirm: noop
};

export const GuardedRouterContext = createContext<GuardedRouterContextType>({
  routerContextState: initialState,
  dispatchAction: () => ({})
});

GuardedRouterContext.displayName = 'GuardedRouterContext';

const reducer = (state: GuardedRouterContextState, action: GuardedRouterAction): GuardedRouterContextState => {
  switch (action.type) {
    case GuardedRouterActionType.LOCK_ROUTE_CHANGE:
      return {
        ...state,
        routeChangeLock: true
      };
    case GuardedRouterActionType.UNLOCK_ROUTE_CHANGE:
      return initialState;
    case GuardedRouterActionType.SHOW_CONFIRMATION_POPUP:
      return {
        ...state,
        showRouteChangeConfirmationPopup: true,
        onRouteChangeConfirm: action.onRouteChangeConfirm
      };
    case GuardedRouterActionType.HIDE_CONFIRMATION_POPUP:
      return {
        ...state,
        showRouteChangeConfirmationPopup: false,
        onRouteChangeConfirm: noop
      };
    default:
      throw new Error(`Invalid router context action type: ${action}`);
  }
};

export const useGuardedRouterContextState = (): [GuardedRouterContextState, (action: GuardedRouterAction) => void] =>
  useReducer(reducer, initialState);
