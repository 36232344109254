import {NxLoader} from '@nextbank/ui-components';
import {useField} from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, {createContext, ReactElement, useState} from 'react';
import {CustomerDataRequirementEntries} from '../../../../../../shared/hooks/use-customer-data-requirements.hook';
import {Dictionary} from '../../../../../../shared/model/dictionary.model';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {
  CustomerDataPhase
} from '../../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {ApplicationRelationshipType, CustomerDataFormFields} from '../customer-data-form.model';
import CustomerSelect from '../customer-select/CustomerSelect';
import {CustomerDataApplicationDictionaries} from '../hooks/use-customer-data-application-dictionaries.hook';
import styles from './RelatedPeopleData.module.scss';
import RelatedPerson from './RelatedPerson';

export const CUSTOMER_STEP_TRANS_PREFIX = 'LOAN_APPLICATIONS.CUSTOMER_DATA';
export const PrefixTrans = TransHelper.getPrefixedTrans(CUSTOMER_STEP_TRANS_PREFIX);

interface Props {
  formikFieldPath: string;
  relationshipType: ApplicationRelationshipType;
  config: CustomerDataPhase;
  requirementEntries: CustomerDataRequirementEntries;
  dictionaryEntries: CustomerDataApplicationDictionaries;
  customDictionaries?: Dictionary[];
}

export const BorrowerContext = createContext<{isCoreCustomer?: boolean}>({
  isCoreCustomer: undefined
});

export default function RelatedPeopleData(
  {
    formikFieldPath,
    relationshipType,
    config,
    requirementEntries,
    dictionaryEntries,
    customDictionaries
  }: Props
): ReactElement {

  const [isSelectingCustomer, setSelectingCustomer] = useState(false);
  const [field, , helpers] = useField<CustomerDataFormFields[]>(formikFieldPath);

  const removeRelatedPerson = (keyToDelete: string): void => {
    helpers.setValue(field.value.filter(person => person.key !== keyToDelete));
  };

  const renderPage = (): ReactElement =>
    (
      <>
        <CustomerSelect isSelecting={isSelectingCustomer}
                        setSelecting={setSelectingCustomer}
                        relationshipType={relationshipType}
                        lockedCustomerType={config.customerType} />
        {
          !isEmpty(field.value) && <div className={styles.container}>
            {
              field.value?.map((value, index) =>
                <RelatedPerson key={value.key}
                               relatedPerson={value}
                               personIndex={index}
                               deleteRelatedPerson={removeRelatedPerson}
                               config={config}
                               formikFieldPath={formikFieldPath}
                               dictionaryEntries={dictionaryEntries}
                               customDictionaries={customDictionaries}
                               requirementEntries={requirementEntries} />
              )
            }
          </div>
        }
      </>
    );

  return isNil(field.value)
    ? <NxLoader />
    : renderPage();
}

