const LoanStepsRoutePaths = {
  GENERAL: '/general',
  CALCULATOR: '/calculator',
  CUSTOMER_DATA: '/customer-data',
  ELIGIBILITY_CRITERIA: '/eligibility-criteria',
  AGREEMENTS: '/agreements',
  LOAN_APPLICATION_DATA: '/loan-application-data',
  PRE_RELEASE: '/pre-release',
  CUSTOM: '/custom',
  EDIT_CUSTOM_PHASES: '/edit-custom-phases'
};

export const LoanApplicationStepsRoutePaths = {
  BORROWER: '/borrower',
  CO_BORROWERS: '/co-borrowers',
  CO_MAKERS: '/co-makers',
}

export const RoutePaths = {
  ROOT: '/',
  PAGE_NOT_FOUND: '/page-not-found',
  NO_PERMISSIONS: '/no-permissions',
  LOGIN: '/login',
  ADMIN: '/admin',
  LOAN_APPLICATIONS: '/execution',
  BATCH: '/batch',
  LOAN_CONFIGURATIONS: '/configuration',
  DASHBOARD: '/dashboard',
  SUMMARY: '/summary',
  ...LoanStepsRoutePaths
};

export enum LoanCreationRouteIds {
  NEW = 'new',
  CONSOLIDATE = 'consolidate',
  ADDITIONAL_LOAN = 'additional',
  RELOAN = 'reloan'
}
