import clsx from 'clsx';
import React, {ReactElement, useContext} from 'react';
import {ReactComponent as PrintIcon} from '../../../../../../../assets/images/icon-print.svg';
import {PrintType} from '../../../../../../../shared/model/print.model';
import IconButtonSwitch from '../../../../../../shared/icon-button-switches/IconButtonSwitch';
import {SystemContext} from '../../../../../../shared/system-context-provider/SystemContextProvider';
import styles from './PrintButton.module.scss';
import usePrint from './usePrint';

interface Props {
  applicationId: number;
  phaseId: number;
  disabled: boolean;
  printType: PrintType;
}

export const PrintButton = ({applicationId, phaseId, disabled, printType}: Props): ReactElement => {

  const {onPrint, isGenerating} = usePrint(applicationId, phaseId, printType);
  const {printsEnabled} = useContext(SystemContext);

  const className = clsx(
    styles.printButton,
    {
      [styles.buttonDisabled]: disabled
    }
  );

  return (
    <>
      {printsEnabled && <IconButtonSwitch icon={<PrintIcon />}
                                          onClick={onPrint}
                                          className={className}
                                          disabled={isGenerating || disabled}
                                          loaded={!isGenerating} />}
    </>);
};
