import {TableHead, TableRow} from '@material-ui/core';
import React, {ReactElement} from 'react';
import TableHeaderCell from '../../../../shared/table/cells/TableHeaderCell';
import {PrefixTrans} from '../../Categories';
import styles from './CategoriesTableHead.module.scss';

const headers = ['NO', 'ID', 'NAME', 'PARENT'];

const CategoriesTableHead = (): ReactElement =>
  <TableHead>
    <TableRow>
      {
        headers.map(header => <TableHeaderCell key={header} header={<PrefixTrans>{header}</PrefixTrans>} />)
      }
      <TableHeaderCell className={styles.buttonHeader} />
    </TableRow>
  </TableHead>;

export default CategoriesTableHead;
