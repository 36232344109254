import {ListItem} from '@material-ui/core';
import {NxSelectOption} from '@nextbank/ui-components';
import {useField, useFormikContext} from 'formik';
import {isNil} from 'lodash';
import isString from 'lodash/isString';
import React, {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DictionaryEntry} from '../../../../../../shared/model/dictionary.model';
import {getGeolocationByParentIdAndName} from '../../../../../../utils/geolocation-helper';
import {SelectHelper} from '../../../../../../utils/select-helper';
import {LosAutocomplete} from '../../../../../shared/autocomplete/LosAutocomplete';
import {FieldDisablementReason} from '../../shared/configured-fields/field-disablement-reason.model';
import {Address} from './address.model';

export interface AddressFormEntries {
  province?: DictionaryEntry[],
  city?: DictionaryEntry[],
  barangay?: DictionaryEntry[],
}

interface Props {
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.addresses[1].number.
   */
  formPath: string;
  disablementReason?: FieldDisablementReason;
  disabled?: boolean;
  entries: AddressFormEntries;
}

const TransPath = 'LOAN_APPLICATIONS.CUSTOMER_DATA.ADDRESSES';

export default function GeolocationForm({entries, formPath, disabled, disablementReason}: Props): ReactElement {
  const {t} = useTranslation();
  const context = useFormikContext();

  const getName = (key: keyof Address): string => `${formPath}.${key}`;

  const [provinceIdField, provinceIdMeta, provinceIdHelpers] = useField(getName('provinceId'));
  const [cityField, cityMeta, cityHelpers] = useField(getName('city'));
  const [barangayField, barangayMeta, barangayHelpers] = useField(getName('barangay'));
  const isProvinceEmpty = isNil(provinceIdField.value);
  const isCityEmpty = isNil(cityField.value);

  const provinceIdOnChange = (value: string | NxSelectOption<number> | null): void => {
    cityHelpers.setValue(null, context.validateOnChange);
    barangayHelpers.setValue(null, context.validateOnChange);

    if (isString(value)) {
      return provinceIdHelpers.setValue(null, context.validateOnChange);
    }

    provinceIdHelpers.setValue(value?.value, context.validateOnChange);
  };
  const cityOnChange = (value: string | null): void => {
    barangayHelpers.setValue(null, context.validateOnChange);
    cityHelpers.setValue(value, context.validateOnChange);
  };
  const barangayOnChange = (value: string | null): void => barangayHelpers.setValue(value, context.validateOnChange);

  const provinceOptions = SelectHelper.mapToNumberOptions(entries.province);

  const filteredCities = useMemo(() => entries.city?.filter((entry) => entry.parentId === provinceIdField.value),
    [provinceIdField.value]
  );

  const cityLabels = useMemo(() => SelectHelper.toLabelArray(filteredCities), [provinceIdField.value]);

  const barangayLabels = useMemo(() => {
    const city = getGeolocationByParentIdAndName(filteredCities, provinceIdField.value, cityField.value);
    const filteredBarangays = entries.barangay?.filter(entry => entry.parentId === city?.id);
    return SelectHelper.toLabelArray(filteredBarangays);
  }, [cityField.value]);

  const provinceValue = useMemo(
    () => provinceOptions.find((value) => value.value === provinceIdField.value),
    [provinceIdField.value, provinceOptions]
  );

  return (
    <>
      <LosAutocomplete<NxSelectOption<number>>
        label={t(TransPath + '.PROVINCE')}
        value={provinceValue}
        options={provinceOptions}
        disabled={disabled}
        onChange={(newValue) => provinceIdOnChange(newValue)}
        renderOption={option => <ListItem>{option.label ?? ''}</ListItem>}
        getOptionLabel={option => option.label as string ?? ''}
        disablementReason={disablementReason}
        error={provinceIdMeta.error}
        required
      />
      <LosAutocomplete<string>
        label={t(TransPath + '.CITY')}
        value={cityField.value}
        options={cityLabels}
        disabled={disabled || isProvinceEmpty}
        onChange={(newValue) => cityOnChange(newValue)}
        hint={t(TransPath + '.TYPE_FOR_MORE_RESULTS')}
        disablementReason={isProvinceEmpty
          ? FieldDisablementReason.PROVINCE_FIELD_MUST_BE_FILLED_FIRST
          : disablementReason}
        error={cityMeta.error}
        required
      />
      <LosAutocomplete<string>
        label={t(TransPath + '.BARANGAY')}
        value={barangayField.value}
        options={barangayLabels}
        disabled={disabled || isCityEmpty}
        onChange={(newValue) => barangayOnChange(newValue)}
        hint={t(TransPath + '.TYPE_FOR_MORE_RESULTS')}
        disablementReason={isCityEmpty ? FieldDisablementReason.CITY_FIELD_MUST_BE_FILLED_FIRST : disablementReason}
        error={barangayMeta.error}
        required
      />
    </>
  );
}

