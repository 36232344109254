import React, {ReactElement, useEffect, useState} from 'react';
import useGet from '../../../shared/hooks/use-get.hook';
import {Applicant} from '../loan-application/steps/customer-data/customer-data-form.model';
import {ApiHelper} from '../../../utils/api-helper';
import {NxLoader} from '@nextbank/ui-components';
import {isEmpty, isNil} from 'lodash';
import useSingleQuery from '../../../shared/hooks/use-single-query.hook';
import {DictionaryEntry} from '../../../shared/model/dictionary.model';
import {CustomerType} from '../../../shared/model/customer-type.model';
import IndividualCustomerDataReview from './IndividualCustomerDataReview';
import CorporateCustomerDataReview from './CorporateCustomerDataReview';
import styles from './ConsolidateCustomerDataReview.module.scss';

interface Props {
  applicantSourceId: number;
  customerType?: CustomerType;
}

const ConsolidateCustomerDataReview = ({applicantSourceId, customerType}: Props): ReactElement => {

  const fetchCustomerData = useGet<Applicant>(ApiHelper.getApplicantDataUrl(applicantSourceId));
  const [applicantData, setApplicantData] = useState<Applicant>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dictionaryEntryIds, setDictionaryEntryIds] = useState<number[]>([]);
  const params = new URLSearchParams(`dictionaryEntryIds=${dictionaryEntryIds}`);
  const dictionaryEntries = useSingleQuery<DictionaryEntry[]>(ApiHelper.getDictionaryEntriesByIdsUrl(),
    null,
    !isEmpty(dictionaryEntryIds),
    params);

  useEffect(() => {
    if (applicantSourceId) {
      setLoading(true);
      fetchCustomerData()
        .then(applicant => {
            setApplicantData(applicant);
            let ids;
            if (customerType === CustomerType.INDIVIDUAL) {
              ids = [
                applicant.individualData?.titleId,
                applicant.individualData?.riskLevelId,
                applicant.individualData?.occupationId,
                applicant.individualData?.dosriTypeId,
                applicant.individualData?.dosriTypeId,
                applicant.individualData?.genderId,
                applicant.individualData?.civilStatusId,
                applicant.individualData?.birthCountryId,
                applicant.individualData?.residenceId,
                applicant.individualData?.citizenshipId
              ];
            } else {
              ids = [
                applicant.corporateData?.businessTypeId,
                applicant.corporateData?.conglomerateId,
                applicant.corporateData?.registrationCountryId,
                applicant.corporateData?.riskLevelId,
                applicant.corporateData?.dosriTypeId,
                applicant.corporateData?.relatedPartyTransactionId,
              ]
            }
            
            const filteredIds = ids.filter(entryId => !isNil(entryId)).map(entryId => Number(entryId));
            setDictionaryEntryIds(filteredIds);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [applicantSourceId]);

  const getDictionaryEntryName = (id?: number): string => {
    return isEmpty(dictionaryEntries) ? '' : dictionaryEntries?.find(entry => entry.id === id)?.name || '';
  };

  return (
    <div className={styles.row}>
      {loading ? <NxLoader /> : customerType === CustomerType.INDIVIDUAL
        ? (<IndividualCustomerDataReview individualData={applicantData?.individualData}
                                         getDictionaryEntryName={getDictionaryEntryName} />)
        : <CorporateCustomerDataReview corporateData={applicantData?.corporateData}
                                       getDictionaryEntryName={getDictionaryEntryName} />}
    </div>
  );
};

export default ConsolidateCustomerDataReview;

