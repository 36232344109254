import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import {DEFAULT_VALUE} from '../../constants/static-field-values';
import {Field, FieldOrigination, FieldType} from '../../shared/model/field.model';
import {PhaseWithStaticFields} from '../../shared/model/phase.model';

const findField = (fields: Field[], code): Field | undefined => find(fields, configField => configField.code === code);

const getInitField = <T_FIELD_VALUE = unknown>(code?: string): Field<T_FIELD_VALUE> => ({
  defaultValue: undefined,
  dictionaryId: 0,
  fieldGroupId: 0,
  id: 0,
  name: '',
  orderNo: 0,
  origination: FieldOrigination.PREDEFINED,
  phaseId: 0,
  required: true,
  enabled: true,
  selfCare: false,
  tag: '',
  type: FieldType.STRING,
  code
});

const getInitFormFields = <T_FORM_FIELDS>
(formFields: T_FORM_FIELDS, config: PhaseWithStaticFields, numberInputsKeys?: string[]): T_FORM_FIELDS => {

  const initFormFields = cloneDeep(formFields);

  forEach(initFormFields as unknown as Record<string, Field>, (value: Field, key) => {
    const field = findField(config.staticFields, value.code);

    // Some fields are optional so if no field found in configuration then remove preset init field values and exit
    if (isNil(field)) {
      initFormFields[key] = undefined;
      return;
    }

    initFormFields[key] = {...field};

    let defaultValue: string | number | undefined =
      field && !isNil(field[DEFAULT_VALUE]) ? field[DEFAULT_VALUE] : undefined;

    if (!isNil(defaultValue)) {
      defaultValue = !!numberInputsKeys && numberInputsKeys.includes(key) ? Number(defaultValue) : defaultValue;
    }

    initFormFields[key][DEFAULT_VALUE] = defaultValue;
  });

  return initFormFields;
};

const getFieldDictionaryId = (config: PhaseWithStaticFields, code: string): number => {

  const field = findField(config.staticFields, code);
  if (isNil(field) || isNil(field?.dictionaryId)) {
    throw Error(`Dictionary Id not found for ${code}`);
  }

  return field.dictionaryId;
};

const getOptionalFieldDictionaryId = (config: PhaseWithStaticFields, code: string): number | undefined =>
  findField(config.staticFields, code)?.dictionaryId;

export const StaticFieldsHelper = {getFieldDictionaryId, getOptionalFieldDictionaryId, getInitField, getInitFormFields, findField};
