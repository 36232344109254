import {RoutePaths} from '../../../../routes/routes.paths';
import {LoanStep} from '../../../../shared/model/loan-step.model';
import {PhaseType} from '../../../../shared/model/phase.model';
import {getStepLabel} from '../../../../utils/loan-steps-utils';

export const PREDEFINED_FIRST_APPLICATION_STEPS: LoanStep[] = [
  {
    path: RoutePaths.CALCULATOR,
    label: getStepLabel('CALCULATOR'),
    type: PhaseType.CALCULATOR
  },
  {
    path: RoutePaths.CUSTOMER_DATA,
    label: getStepLabel('CUSTOMER_DATA'),
    type: PhaseType.CUSTOMER_DATA
  },
  {
    path: RoutePaths.ELIGIBILITY_CRITERIA,
    label: getStepLabel('ELIGIBILITY_CRITERIA'),
    type: PhaseType.ELIGIBILITY
  },
  {
    path: RoutePaths.LOAN_APPLICATION_DATA,
    label: getStepLabel('LOAN_APPLICATION_DATA'),
    type: PhaseType.LOAN_APPLICATION_DATA
  }
];

export const PREDEFINED_LAST_APPLICATION_STEPS: LoanStep[] = [
  {
    path: RoutePaths.AGREEMENTS,
    label: getStepLabel('AGREEMENTS'),
    type: PhaseType.AGREEMENT
  },
  {
    path: RoutePaths.PRE_RELEASE,
    label: getStepLabel('PRE_RELEASE'),
    type: PhaseType.PRE_RELEASE
  },
  {
    path: RoutePaths.SUMMARY,
    label: getStepLabel('SUMMARY'),
    type: PhaseType.SUMMARY
  }
];
