import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router';
import {SecurityContext} from '../../App';
import NextbankLogo from '../../assets/images/nextbank-loan-origination-logo.svg';
import {RoutePaths} from '../../routes/routes.paths';
import {UserData} from '../../security-context.model';
import MainContainer from '../shared/main-container/MainContainer';
import LoginForm from './login-form/LoginForm';
import styles from './Login.module.scss';
import MfaForm from './mfa-form/MfaForm';

export default function Login(): React.ReactElement {

  const {setIsLogged, setUserData, userData} = useContext(SecurityContext);
  const history = useHistory();

  const logInUser = (user: UserData): void => {
    setUserData(user);
    if (!user.additionalFactorRequired) {
      setIsLogged(true);
      history.push(RoutePaths.ROOT);
      return;
    }
    setIsLogged(false);
  };

  return (
    <MainContainer>
      <div className={styles.loginFormWrapper}>
        <div className={styles.loginForm}>
          <img src={NextbankLogo} alt='Nextbank' className={styles.logo} />
          {
            userData?.additionalFactorRequired
              ? <MfaForm logInUser={logInUser} />
              : <LoginForm logInUser={logInUser} />
          }
        </div>
      </div>
      <div className={styles.hero}>
        <span className={styles.heroTitle}>
          <Trans>LOGIN.LOAN_ORIGINATION_SYSTEM</Trans>
        </span>
      </div>
    </MainContainer>
  );
}
