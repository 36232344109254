import {concat} from 'lodash';
import {LoanApplicationStepsRoutePaths, RoutePaths} from '../../../../routes/routes.paths';
import {LoanStep} from '../../../../shared/model/loan-step.model';
import {Phase} from '../../../../shared/model/phase.model';
import {LoanSteps} from '../../../../utils/loan-steps-base-class';
import {buildApplicationStepPath, getPredefinedApplicationSteps} from '../../../../utils/loan-steps-utils';
import {getCustomPhases} from '../../../../utils/phases-utils';
import {PREDEFINED_FIRST_APPLICATION_STEPS, PREDEFINED_LAST_APPLICATION_STEPS} from './predefined-steps';

export class LoanApplicationSteps extends LoanSteps {

  constructor(processId: number | string, applicationId: number | string, phases: Phase[] = []) {

    const customPhases = getCustomPhases(phases);

    const steps = [
      ...getPredefinedApplicationSteps(processId, applicationId, PREDEFINED_FIRST_APPLICATION_STEPS, phases),
      ...customPhases.map(phase => ({
        phaseId: phase.id,
        label: phase.name,
        path: buildApplicationStepPath(`${RoutePaths.CUSTOM}/${phase.id}`, processId, applicationId),
        isCustomStep: true
      })) as LoanStep[],
      ...getPredefinedApplicationSteps(processId, applicationId, PREDEFINED_LAST_APPLICATION_STEPS, phases)
    ];

    steps.forEach((value, index) => value.orderNo = `${index + 1}`);

    super(addCustomerDataSubSteps(steps));
  }
}

const addCustomerDataSubSteps = (steps: LoanStep[]): LoanStep[] => {
  const customerDataIndex = steps.findIndex(step => step.type === 'IndividualCustomerProfilingPhaseView');
  const customerDataStep = steps[customerDataIndex];

  const borrowerStep = {
    ...customerDataStep,
    isSubStep: true,
    label: 'LOAN_STEPS_LABELS.BORROWER',
    path: customerDataStep.path + LoanApplicationStepsRoutePaths.BORROWER,
    orderNo: `${customerDataStep.orderNo}.1`,
    ...customerDataStep.redirectTo
      ? {redirectTo: customerDataStep.redirectTo + LoanApplicationStepsRoutePaths.BORROWER}
      : {},
    phaseId: undefined
  };

  const coBorrowerStep = {
    ...customerDataStep,
    isSubStep: true,
    label: 'LOAN_STEPS_LABELS.CO-BORROWER',
    path: customerDataStep.path + LoanApplicationStepsRoutePaths.CO_BORROWERS,
    orderNo: `${customerDataStep.orderNo}.2`,
    ...customerDataStep.redirectTo
      ? {redirectTo: customerDataStep.redirectTo + LoanApplicationStepsRoutePaths.CO_BORROWERS}
      : {},
    phaseId: undefined
  };

  const coMakerStep = {
    ...customerDataStep,
    isSubStep: true,
    label: 'LOAN_STEPS_LABELS.CO-MAKER',
    path: customerDataStep.path + LoanApplicationStepsRoutePaths.CO_MAKERS,
    orderNo: `${customerDataStep.orderNo}.3`,
    ...customerDataStep.redirectTo
      ? {redirectTo: customerDataStep.redirectTo + LoanApplicationStepsRoutePaths.CO_MAKERS}
      : {},
    phaseId: undefined
  };

  const stepsLeftSide = steps.slice(0, customerDataIndex + 1);
  const stepsRightSide = steps.slice(customerDataIndex + 1);

  return concat(stepsLeftSide, [borrowerStep, coBorrowerStep, coMakerStep], stepsRightSide);
};
