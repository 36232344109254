import {Collapse, TableRow} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import {NxCheckbox} from '@nextbank/ui-components';
import React, {ReactElement, ReactNode, useMemo, useContext} from 'react';
import {find, union, differenceBy, some} from 'lodash';
import isNil from 'lodash/isNil';
import {LOAN_DATE_FORMAT} from '../../../../constants/format-templates';
import {
  LoanApplicationLabels
} from '../../../../constants/loan-application-labels';
import {CustomerType} from '../../../../shared/model/customer-type.model';
import {createPathToApplication} from '../../../../utils/application-utils';
import {CashHelper} from '../../../../utils/cash-helper';
import {StringHelper} from '../../../../utils/string-helper';
import {TransHelper} from '../../../../utils/trans-helper';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import DetailsButton from '../../../shared/details-button/DetailsButton';
import ApplicationStatusChip from '../../../shared/status-chip/ApplicationStatusChip';
import TableDataCell from '../../../shared/table/cells/TableDataCell';
import {ApplicationBasicData} from '../../loan-application/loan-application.model';
import {getFullClientName} from './loan-applications-table-utils';
import styles from './LoanApplicationsTableRow.module.scss';
import {SelectedApplicationsContext} from '../LoanApplicationDasboard';
import {isSysAdminOrManager} from '../../../../utils/permissions-utils';
import {SecurityContext} from '../../../../App';
import {getLoanApplicationTableHeaders} from '../../../../utils/table-helper';
import {ApplicationStatus} from '../../loan-application/loan-application.model';
import { PaymentInterval } from '../../../../shared/model/payment-interval.model';

interface Props {
  application: ApplicationBasicData;
  paymentIntervals?: PaymentInterval[];
}

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.TABLE_ROW_DETAILS');
export const ApprovalStatusPrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.APPROVAL_STATUS');

const LoanApplicationsTableRow = ({application, paymentIntervals}: Props): ReactElement => {

  const history = useGuardedHistory();
  const {userData} = useContext(SecurityContext);
  const {selectedApplications, setSelectedApplication} = useContext(SelectedApplicationsContext);
  const [open, setOpen] = React.useState(false);
  const isReassignLoanApplicationAllowed = isSysAdminOrManager(userData);
  const isSubmitted = application.status === ApplicationStatus.SUBMITTED;
  const paymentIntervalName = paymentIntervals?.find(paymentInterval => paymentInterval.id === application.paymentIntervalId)?.name;

  const isApplicationSelected = useMemo(() => {
    return some(selectedApplications, selectedApplication => selectedApplication.id === application.id);
  }, [selectedApplications]);

  const columnSpanLength = getLoanApplicationTableHeaders(isReassignLoanApplicationAllowed).length;

  const navigateToApplication = (application: ApplicationBasicData): () => void => (): void =>
    history.push(createPathToApplication(application));

  const getDetail = (label: ReactNode, content?: ReactNode): ReactElement => <div className={styles.detail}>
    <div className={styles.detailLabel}>{label}:</div>
    <div>{content ?? '-'}</div>
  </div>;

  const onSelectApplication = () => {
    const selectedApplication = find(selectedApplications, selectedApplication => selectedApplication.id === application.id);
    const applications = isNil(selectedApplication)
      ? union(selectedApplications, [application])
      : differenceBy(selectedApplications, [application], 'id');
    setSelectedApplication(applications);
  };

  const getDetails = (application: ApplicationBasicData): ReactElement => (
    <div className={styles.container}>
      <div className={styles.column}>
        {getDetail(<PrefixTrans>{LoanApplicationLabels.PAYMENT_INTERVAL}</PrefixTrans>, paymentIntervalName)}
        {getDetail(
          <PrefixTrans>{LoanApplicationLabels.TOTAL_AMORTIZATION_NUMBER}</PrefixTrans>,
          application.totalAmortizationNumber
        )}
      </div>
      <div className={styles.column}>
        {getDetail(<PrefixTrans>{LoanApplicationLabels.CREATED_BY}</PrefixTrans>, application.createdBy)}
        {getDetail(
          <PrefixTrans>{LoanApplicationLabels.CREATED_ON}</PrefixTrans>,
          application.createdOn?.format(LOAN_DATE_FORMAT)
        )}
        {getDetail(<PrefixTrans>{LoanApplicationLabels.CREATION_TYPE}</PrefixTrans>, application.creationType)}
      </div>
      <div className={styles.column}>
        {getDetail(<PrefixTrans>{LoanApplicationLabels.MODIFIED_BY}</PrefixTrans>, application.lastModifiedBy)}
        {getDetail(
          <PrefixTrans>{LoanApplicationLabels.MODIFIED_ON}</PrefixTrans>,
          application.modifiedOn?.format(LOAN_DATE_FORMAT)
        )}
      </div>
      <div className={styles.column}>
        {getDetail(
          <PrefixTrans>{LoanApplicationLabels.APPROVAL_STATUS}</PrefixTrans>,
          <ApprovalStatusPrefixTrans>{application.approvalStatus}</ApprovalStatusPrefixTrans>
        )}
      </div>
    </div>
  );

  return (
    <>
      <TableRow onClick={navigateToApplication(application)} className={styles.tableRow}>
        {
          isSysAdminOrManager(userData) &&
          <TableCell padding="checkbox" onClick={(event) => event.stopPropagation()}>
            <NxCheckbox checked={isApplicationSelected} onChange={onSelectApplication} disabled={isSubmitted}/>
          </TableCell>
        }
        <TableDataCell data={application.branchName} />
        <TableDataCell data={application.referenceNo} />
        <TableDataCell data={application.applicantNumber ?? '-'} />
        {}
        <TableDataCell width={'15%'}
                       data={application.applicantType === CustomerType.CORPORATE
                         ? application.applicantBusinessName
                         : getFullClientName(application)} />
        <TableDataCell data={application.productName} />
        <TableDataCell rightAlign={true} data={CashHelper.addCurrencyMask(application.principalAmount)} />
        <TableDataCell data={StringHelper.addPercentage(application.interestRate)} />
        <TableDataCell data={application.assignedTo} />
        <TableDataCell data={
          <ApplicationStatusChip status={application.status}
                                 phaseName={application.currentPhaseName}
                                 approvalStatus={application.approvalStatus} />
        } />
        <TableDataCell data={
          <DetailsButton isOpen={open} iconOnly onClick={(event): void => {
            event && event.stopPropagation();
            setOpen(!open);
          }
          } />
        } />
      </TableRow>
      <TableRow>
        <TableCell className={styles.expandableDetails} colSpan={columnSpanLength}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            {getDetails(application)}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default LoanApplicationsTableRow;
