import Snackbar from '@material-ui/core/Snackbar';
import clsx from 'clsx';
import {isArray} from 'lodash';
import isString from 'lodash/isString';
import React, {ReactNode} from 'react';
import styles from './AlertSnackbar.module.scss';

const MAX_MESSAGE_LENGTH = 1200;

export enum Severity {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export type SnackBarMessage = string | string[] | React.ReactNode;

interface Props {
  handleClose: () => void;
  message?: SnackBarMessage;
  open: boolean;
  severity?: Severity;
}

export const AlertSnackbar = ({handleClose, message, open, severity = Severity.ERROR}: Props): React.ReactElement => {

  const snackbarClasses = {
    message: styles.message,
    root: clsx(styles.content, {[styles.content_error]: severity === Severity.ERROR})
  };

  const buildMessage = (): ReactNode => {
    if (isString(message)) {
      return trimMessage(message);
    }

    if (isArray(message)) {
      const messages = message as string[];
      return <div className={styles.messageBody}>
        {messages.map((value, index) => (<span key={index}>{trimMessage(value)}</span>))}
      </div>;
    }

    return message;
  };

  const trimMessage = (message: string): string => message.length > MAX_MESSAGE_LENGTH
    ? `${message.substring(0, MAX_MESSAGE_LENGTH)}...`
    : message;

  return (
    <div className={styles.snackbarWrapper}>
      <Snackbar classes={{root: styles.snackbar}}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        /* if message has severity level SUCCESS auto-close it after 2s */
                autoHideDuration={severity === Severity.SUCCESS ? 3600 : undefined}
                ContentProps={{classes: snackbarClasses}}
                message={buildMessage()}
                onClose={handleClose}
                open={open} />
    </div>
  );
};
