import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PositiveIcon} from '../../../../../../../assets/images/icon-status-positive.svg';
import {ReactComponent as WaitingIcon} from '../../../../../../../assets/images/icon-status-waiting.svg';
import {Chip} from '../../../../../../shared/chip/Chip';
import {ChipVariant} from '../../../../../../shared/chip/chip.model';

interface Props {
  requiredAdded: boolean;
  min: number,
  max: number
}

export const CUSTOMER_STEP_TRANS_PREFIX = 'LOAN_APPLICATIONS.CUSTOMER_DATA.CUSTOMER_DETAILS';

export const RelatedPeopleStatus = ({requiredAdded, min, max}: Props): ReactElement => {

  const {t} = useTranslation();

  return requiredAdded
    ? <Chip variant={ChipVariant.SUCCESS}
            startIcon={<PositiveIcon />}
            message={t(`${CUSTOMER_STEP_TRANS_PREFIX}.NO_OF_CLIENTS_SUFFICIENT`, {from: min, to: max})} />
    : <Chip variant={ChipVariant.WARNING}
            startIcon={<WaitingIcon />}
            message={t(`${CUSTOMER_STEP_TRANS_PREFIX}.NO_OF_CLIENTS_REQUIRED`, {from: min, to: max})} />;
};
