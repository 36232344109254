import {NxButton, NxButtonVariant, NxCheckbox} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import React, {ChangeEvent, ReactElement, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {MANAGEMENT_ROLES_URL} from '../../../../../constants/api-urls';
import usePost from '../../../../../shared/hooks/use-post.hook';
import {Permission} from '../../../../../shared/model/permission.model';
import {PERMISSIONS_TRANS_PREFIX} from '../../Access';
import {EditAccessFormFields as FormFields} from './edit-access-form.model';
import styles from './EditAccessForm.module.scss';

interface Props {
  roleId?: number;
  permissions?: Permission[];
  onSave: () => void;
  onCancel: () => void;
}

export default function EditAccessForm({roleId, permissions = [], onSave, onCancel}: Props): ReactElement {

  const {t} = useTranslation();
  const updateAccess = usePost<void, Permission[]>(`${MANAGEMENT_ROLES_URL}/${roleId}/permissions`);
  const [error, setError] = useState<string>();

  const submit = async (fields: FormFields): Promise<void> => {
    updateAccess([...fields.permissions])
      .then(onSave)
      .catch(error => setError(error.message));
  };

  const uncheckOppositePermission = (isChecked: boolean, permission: Permission, grantedPermissions: Permission[]) => {
    if (isChecked && permission == Permission.BRANCH_MANAGEMENT) {
      grantedPermissions = grantedPermissions.filter(value => value !== Permission.SYSTEM_MANAGEMENT);
    }

    if (isChecked && permission == Permission.SYSTEM_MANAGEMENT) {
      grantedPermissions = grantedPermissions.filter(value => value !== Permission.BRANCH_MANAGEMENT);
    }
    return grantedPermissions;
  };

  const permissionsOptions = Object.values(Permission)
    .map(value => ({value, label: t(`${PERMISSIONS_TRANS_PREFIX}.${value}`)}));

  const EditAccessForm = ({isSubmitting, values, setValues}: FormikProps<FormFields>): ReactElement => {

    const isChecked = (permission: Permission): boolean => values.permissions.includes(permission);

    const checkboxToggled = (permission: Permission): any => {
      return (e: ChangeEvent): void => {
        const target = e.target;
        if (target instanceof HTMLInputElement) {
          updatePermissions(target.checked as unknown as boolean, permission);
        }
      };
    };

    const updatePermissions = (isChecked: boolean, permission: Permission): void => {
      let grantedPermissions: Permission[] = values.permissions;

      grantedPermissions = uncheckOppositePermission(isChecked, permission, grantedPermissions);

      if (grantedPermissions.includes(permission) && !isChecked) {
        setValues({permissions: grantedPermissions.filter(value => value !== permission)});
      }

      if (!grantedPermissions.includes(permission) && isChecked) {
        grantedPermissions.push(permission);
        setValues({permissions: grantedPermissions});
      }
    };

    return (
      <Form className={styles.form}>
        <div>
          {
            permissionsOptions.map(option =>
              <NxCheckbox key={option.value}
                          checked={isChecked(option.value)}
                          onChange={checkboxToggled(option.value)}>
                {option.label}
              </NxCheckbox>)
          }
          {
            error && <div className={styles.error}>{error}</div>
          }
        </div>
        <div className={styles.buttons}>
          <NxButton type='submit'
                    className={styles.button}
                    loaded={!isSubmitting}
                    disabled={isSubmitting}
                    onClick={onCancel}
                    variant={NxButtonVariant.OUTLINED}>
            <Trans>COMMON.CANCEL</Trans>
          </NxButton>
          <NxButton type='submit' className={styles.button} loaded={!isSubmitting} disabled={isSubmitting}>
            <Trans>COMMON.SAVE</Trans>
          </NxButton>
        </div>
      </Form>
    );
  };

  return (
    <Formik<FormFields>
      onSubmit={submit}
      initialValues={{permissions}}>
      {EditAccessForm}
    </Formik>
  );
}
