import React, {ReactElement} from 'react';
import {TransHelper} from '../../../../../../../../utils/trans-helper';

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.OVERRIDE_FEES');

export const FeesOverrideTableHead = (): ReactElement =>
  <>
    <span><PrefixTrans>NAME</PrefixTrans></span>
    <span><PrefixTrans>STANDARD</PrefixTrans></span>
    <span><PrefixTrans>OVERRIDE</PrefixTrans></span>
    <span><PrefixTrans>DIFFERENCE</PrefixTrans></span>
    <span><PrefixTrans>ALREADY_PAID</PrefixTrans></span>
  </>;
