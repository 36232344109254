import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {SecurityContext} from '../../App';
import {RoutePaths} from '../../routes/routes.paths';
import {HttpError} from '../http-service/http-error';
import {SimpleErrorBody} from '../http-service/http-error.model';

export default function useCatchUnauthorizedErrorHook(): (error) => Promise<never> {
  const location = useLocation();
  const {t} = useTranslation();
  const {setIsLogged, setLogoutReason} = useContext(SecurityContext);

  return (error): Promise<never> => {
    if (location.pathname !== RoutePaths.LOGIN && (error as HttpError<SimpleErrorBody>).response?.status === 401) {
      setIsLogged(false);
      setLogoutReason(error.response.data.errorMessage ?? t('LOGIN.SESSION_EXPIRED_ERROR'));
    }

    console.error(error);

    return Promise.reject(error);
  };
}
