import clsx from 'clsx';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as SettingsIcon} from '../../../../../assets/images/icon-settings.svg';
import IconButtonSwitch from '../../../../shared/icon-button-switches/IconButtonSwitch';
import {FINAL_APPROVAL_TRANS_PREFIX} from '../LoanConfigurationStep';
import styles from './ApprovalConfigurationButtonSwitch.module.scss';

interface Props {
  enabled?: boolean;
  onClick: () => void;
  value: boolean;
}

const ApprovalConfigurationButtonSwitch = ({enabled, onClick, value}: Props): React.ReactElement => {

  const {t} = useTranslation();
  const finalApprovalConfigTooltip = t(`${FINAL_APPROVAL_TRANS_PREFIX}.SHOW_FINAL_APPROVAL_CONFIGURATION`);

  return (
    <IconButtonSwitch className={clsx({[styles.enabledConfigurationButton]: enabled})}
                      tooltipTitle={enabled && finalApprovalConfigTooltip}
                      ariaLabel={finalApprovalConfigTooltip}
                      icon={<SettingsIcon />}
                      disabled={!enabled}
                      onClick={onClick}
                      value={value} />
  );
};

export default ApprovalConfigurationButtonSwitch;
