import {TableBody} from '@material-ui/core';
import React, {ReactElement} from 'react';
import {PagedRecords} from '../../../../shared/model/paged.model';
import TableHeader from '../../../shared/table/header/TableHeaders';
import LosTable from '../../../shared/table/LosTable';
import {BatchItem} from '../../batch.model';
import {BatchItemsTableHeaderLabel} from './batch-items-table.model';
import BatchItemTableRow from './BatchItemTableRow';

interface Props {
  batchItems: PagedRecords<BatchItem>;
  onPageChange: (pageNo: number) => Promise<void>;
  onItemClick: (batchItem: BatchItem) => void;
  className?: string;
}

export const TRANS_PREFIX = 'BATCH.BATCH_ITEMS.LABELS';

const BatchItemsTable = ({batchItems, onPageChange, onItemClick, className}: Props): ReactElement =>
  <LosTable
    headers={<TableHeader translationPath={TRANS_PREFIX} headers={Object.values(BatchItemsTableHeaderLabel)} />}
    body={
      <TableBody>
        {
          batchItems.result.map((item) =>
            <BatchItemTableRow key={item.id} batchItem={item} onItemClick={onItemClick} />
          )
        }
      </TableBody>}
    items={batchItems}
    onPageChange={onPageChange}
    className={className}
  />;

export default BatchItemsTable;
