import {TFunction} from 'i18next';
import {AnySchema, object, string} from 'yup';
import {getNxFileValidationSchema} from '../../../utils/step-form-utils/validation-schema/nx-file-validation-schema';
import {getDefaultRequiredError} from '../../../utils/step-form-utils/validation-schema/validation-schema-utils';

export const getCreateBatchSchema = (t: TFunction): AnySchema => {
  const defaultRequiredError = getDefaultRequiredError(t);
  return object({
    name: string().required(defaultRequiredError),
    file: getNxFileValidationSchema(true, defaultRequiredError),
    code: string().required(defaultRequiredError),
  });
};

export interface CreateBatchErrors {
  name: string;
  file?: {
    file: string;
    isChanged: boolean;
  };
  code: string;
}
