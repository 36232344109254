import values from 'lodash/values';
import {CustomerDataPhase} from '../../components/loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {areNotNil} from '../../utils/empty-checks-utils';
import {DictionaryEntry} from '../model/dictionary.model';
import useDictionaryEntriesQuery from './use-dictionary-entries.hook';

export interface CustomerDataRequirementEntriesState {
  areRequirementEntriesLoaded: boolean;
  requirementEntries: CustomerDataRequirementEntries;
}

export interface CustomerDataRequirementEntries {
  addressRequirement?: DictionaryEntry[];
  idDocumentRequirement?: DictionaryEntry[];
  incomeRequirement?: DictionaryEntry[];
}

export default function useCustomerDataRequirements(config?: CustomerDataPhase): CustomerDataRequirementEntriesState {

  const {addressRequirement, idDocumentRequirement, incomeRequirement} = config || {};

  const requirementEntries = {
    addressRequirement: useDictionaryEntriesQuery(addressRequirement?.dictionaryId),
    idDocumentRequirement: useDictionaryEntriesQuery(idDocumentRequirement?.dictionaryId),
    incomeRequirement: useDictionaryEntriesQuery(incomeRequirement?.dictionaryId),
  };

  return {
    areRequirementEntriesLoaded: areNotNil(values(requirementEntries)),
    requirementEntries
  };
}
