import {RoutePaths} from '../../../../routes/routes.paths';
import {LoanStep} from '../../../../shared/model/loan-step.model';
import {getPredefinedConfigurationSteps, getStepLabel} from '../../../../utils/loan-steps-utils';

/**
 *  Example step group configuration:
 *  {
 *     path: RoutePaths.CUSTOMER_DATA,
 *     redirectTo: RoutePaths.BORROWER_DATA,
 *     label: 'LOAN_STEPS_LABELS.CUSTOMER_DATA',
 *  },
 *  {
 *     path: RoutePaths.BORROWER_DATA,
 *     label: 'LOAN_STEPS_LABELS.BORROWER_DATA',
 *     isSubStep: true
 *  }
 */

export const PREDEFINED_FIRST_CONFIGURATION_STEPS: LoanStep[] = [
  {
    path: RoutePaths.GENERAL,
    label: getStepLabel('GENERAL'),
    hiddenFinalApprovalConfiguration: true
  },
  {
    path: RoutePaths.CALCULATOR,
    label: getStepLabel('CALCULATOR'),
    hiddenFinalApprovalConfiguration: true
  },
  {
    path: RoutePaths.CUSTOMER_DATA,
    label: getStepLabel('CUSTOMER_DATA')
  },
  {
    path: RoutePaths.ELIGIBILITY_CRITERIA,
    label: getStepLabel('ELIGIBILITY_CRITERIA')
  },
  {
    path: RoutePaths.LOAN_APPLICATION_DATA,
    label: getStepLabel('LOAN_APPLICATION_DATA')
  }
];

export const PREDEFINED_LAST_CONFIGURATION_STEPS: LoanStep[] = [
  {
    path: RoutePaths.AGREEMENTS,
    label: getStepLabel('AGREEMENTS')
  },
  {
    path: RoutePaths.PRE_RELEASE,
    label: getStepLabel('PRE_RELEASE')
  }
];

export const getFirstStepPath = (processId: number): string =>
  getPredefinedConfigurationSteps(processId, PREDEFINED_FIRST_CONFIGURATION_STEPS)[0].path;
