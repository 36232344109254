import {NxLoader, NxSelectOption} from '@nextbank/ui-components';
import React, {createContext, useContext, useState} from 'react';
import {useParams} from 'react-router';
import {MANAGEMENT_BRANCHES_URL, MANAGEMENT_ROLES_URL} from '../../../constants/api-urls';
import {UrlParams} from '../../../routes/routes.model';
import {useLoanProcess} from '../../../shared/hooks/use-loan-process.hook';
import useSingleQuery from '../../../shared/hooks/use-single-query.hook';
import {Branch, Role} from '../../../shared/model/management.model';
import {SelectHelper} from '../../../utils/select-helper';
import {GuardedRouterActionType} from '../../router/guarded-router-context.model';
import {GuardedRouterContext} from '../../router/GuardedRouterContext';
import LoanStepWrapper from '../../shared/loan-step-wrapper/LoanStepWrapper';
import LoanConfigurationSidebar from './loan-configuration-sidebar/LoanConfigurationSidebar';
import LoanConfigurationTopBar from './loan-configuration-topbar/LoanConfigurationTopBar';
import {LOAN_CONFIGURATION_ROUTES} from './routes/loan-configuration-routes';
import {LoanConfigurationSteps} from './steps/loan-configuration-steps';
import {Process, ProductStatus} from '../../../shared/model/process.model';
import RenderRoutes from '../../shared/render-routes/RenderRoutes';
import {getCustomPhases} from '../../../utils/phases-utils';
import isEmpty from 'lodash/isEmpty';

export interface LoanConfigurationContextType {
  steps: LoanConfigurationSteps,
  process: Process,
  updateProcess: (fetchPhases?: boolean) => void;
  isProcessUpdating: boolean;
  isLoanPublished: boolean;
  setIsProcessUpdating: (isLoanUpdating: boolean) => void;
  arePhasesUpdating: boolean;
  saveAndExitClicked: boolean;
  setSaveAndExitClicked: (saveClicked: boolean) => void;
  roles: NxSelectOption[] | undefined;
  branches: NxSelectOption[] | undefined;
  configurationChanged: boolean;
  setConfigurationChanged: (hasChanged: boolean) => void;
}

export const LoanConfigurationContext = createContext<LoanConfigurationContextType>(
  {saveAndExitClicked: false} as LoanConfigurationContextType
);

const {mapToStringOptions} = SelectHelper;

const LoanConfiguration = (): React.ReactElement => {
  const {processId} = useParams<UrlParams>();
  const {process, updateProcess, isProcessUpdating, setIsProcessUpdating, arePhasesUpdating} = useLoanProcess(processId);
  const roles = useSingleQuery<NxSelectOption[], Role[]>(MANAGEMENT_ROLES_URL, mapToStringOptions);
  const branches = useSingleQuery<NxSelectOption[], Branch[]>(MANAGEMENT_BRANCHES_URL, mapToStringOptions);
  const [saveAndExitClicked, setSaveAndExitClicked] = useState(false);
  const [configurationChanged, setConfigurationChangedValue] = useState(false);
  const {dispatchAction} = useContext(GuardedRouterContext);
  const isLoanPublished = process?.status === ProductStatus.PUBLISHED;
  const customPhases = getCustomPhases(process?.phases);
  const steps = new LoanConfigurationSteps(process.id, customPhases);

  const setConfigurationChanged = (changed: boolean): void => {
    if (isLoanPublished) {
      dispatchAction({
        type: GuardedRouterActionType[changed ? 'LOCK_ROUTE_CHANGE' : 'UNLOCK_ROUTE_CHANGE']}
      );
    }

    setConfigurationChangedValue(changed);
  };

  return (
    <LoanConfigurationContext.Provider
      value={{
        steps,
        branches,
        roles,
        process,
        updateProcess,
        isProcessUpdating,
        setIsProcessUpdating,
        arePhasesUpdating,
        isLoanPublished,
        saveAndExitClicked,
        setSaveAndExitClicked,
        configurationChanged,
        setConfigurationChanged
      }}>
      {
        isEmpty(process) || isEmpty(roles) || isEmpty(branches) ? <NxLoader />
          : <LoanStepWrapper content={<RenderRoutes routes={LOAN_CONFIGURATION_ROUTES} />}
                             topBar={<LoanConfigurationTopBar />}
                             aside={<LoanConfigurationSidebar />}
                             isTopBarLoaded={!isProcessUpdating} />
      }
    </LoanConfigurationContext.Provider>
  );
};

export default LoanConfiguration;
