import React, {ReactElement} from 'react';
import NumberFormat, {NumberFormatValues} from 'react-number-format';

export interface NumberMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  name: string;
  onChange: (event: {
    target: {
      name: string;
      value: string;
    }
  }) => void;
}

export const NumberMask = (props: NumberMaskProps): ReactElement => {
  const {inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      isNumericString
      getInputRef={inputRef}
      onValueChange={(values: NumberFormatValues): void => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}/>
  );
};
