import React, {ReactElement, useContext} from 'react';
import {Redirect, useLocation} from 'react-router';
import {SecurityContext} from './App';
import RenderRoutes from './components/shared/render-routes/RenderRoutes';
import {ROUTES} from './routes/routes';
import {RoutePaths} from './routes/routes.paths';
import './styles/styles.scss';
import {hasPermissionToPath} from './utils/permissions-utils';

const MainRouting = (): ReactElement => {

  const currentPath = useLocation().pathname;
  const {isLogged, userData} = useContext(SecurityContext);

  return (
    <>
      <RenderRoutes routes={ROUTES} />
      {
        !isLogged
          ? <Redirect to={RoutePaths.LOGIN} />
          : <>
            {currentPath === RoutePaths.LOGIN && <Redirect to={RoutePaths.ROOT} />}
            {!hasPermissionToPath(currentPath, userData) && <Redirect to={RoutePaths.NO_PERMISSIONS} />}
          </>
      }
    </>
  );
};

export default MainRouting;
