import React, {ReactElement} from 'react';
import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import styles from './SuggestPrincipalButton.module.scss';

const CalculatorTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.CONSOLIDATED_LOANS');

interface Props {
  onSubmit: () => void;
  isSubmitting: boolean;
}

export default function SuggestPrincipalButton({onSubmit, isSubmitting}: Props): ReactElement {
  return (<>
      <NxButton variant={NxButtonVariant.OUTLINED}
                onClick={onSubmit}
                className={styles.principalButton}
                disabled={isSubmitting}
                loaded={!isSubmitting}>
        <CalculatorTrans>SUGGEST_PRINCIPAL</CalculatorTrans>
      </NxButton>
    </>
  );
}

