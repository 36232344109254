import {useContext, useEffect} from 'react';
import {RoutePaths} from '../../../../routes/routes.paths';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import {GuardedRouterContext} from '../../../router/GuardedRouterContext';
import {LoanConfigurationContext} from '../LoanConfiguration';

export function useLoanConfigurationSave(handleSave?: () => Promise<void>): void {

  const history = useGuardedHistory();
  const {routerContextState} = useContext(GuardedRouterContext);
  const {
    isLoanPublished,
    saveAndExitClicked,
    setSaveAndExitClicked,
    updateProcess,
    configurationChanged,
    setConfigurationChanged,
    setIsProcessUpdating
  } = useContext(LoanConfigurationContext);

  /**
   * Saves loan configuration automatically on exit - only for unpublished loan
   */
  useEffect(() => (): void => saveNotPublishedStepOnExit(), [configurationChanged]);

  /**
   * Saves loan configuration when save and exit button was clicked
   */
  useEffect(() => {
    if (!routerContextState.routeChangeLock) {
      setConfigurationChanged(false);
    }

    if (saveAndExitClicked) {
      savePublishedOnSaveAndExitClicked();
    }
  }, [saveAndExitClicked, routerContextState.routeChangeLock]);

  const saveNotPublishedStepOnExit = (): void => {
    if (!isLoanPublished && configurationChanged && handleSave) {
      setIsProcessUpdating(true);
      handleSave()
        .then(() => updateProcess())
        .then(() => {
          setIsProcessUpdating(false);
          setConfigurationChanged(false);
        });
    }
  };

  const savePublishedOnSaveAndExitClicked = async (): Promise<void> => {
    if (!isLoanPublished || !routerContextState.routeChangeLock || !handleSave) {
      history.push(RoutePaths.LOAN_CONFIGURATIONS);
      setSaveAndExitClicked(false);
      return;
    }

    handleSave()
      .then(() => setConfigurationChanged(false));
  };
}
