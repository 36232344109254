import {NxCheckbox} from '@nextbank/ui-components';
import clsx from 'clsx';
import React from 'react';
import {ENABLED, REQUIRED} from '../../../constants/static-field-values';
import {Field} from '../../../shared/model/field.model';
import {SetFieldValue} from '../../../shared/model/formik.model';
import {getCheckboxValue} from '../../../utils/input-utils';
import RequiredButtonSwitch from '../icon-button-switches/RequiredButtonSwitch';
import styles from './FieldDefaultCheckbox.module.scss';

interface Props {
  children: React.ReactNode;
  name: string;
  setFieldValue: SetFieldValue;
  field: Field<unknown>;
  requiredButtonVisible?: boolean;
}

export const FieldDefaultCheckbox = (
  {children, name, field, setFieldValue, requiredButtonVisible = true}: Props): React.ReactElement => {

  const FIELD_ENABLED = `${name}.${ENABLED}`;
  const FIELD_REQUIRED = `${name}.${REQUIRED}`;

  const {defaultValue, required, enabled} = field;

  const handleFieldEnabledChange = (event: React.ChangeEvent): void =>
    setFieldValue(FIELD_ENABLED, getCheckboxValue(event));

  const handleFieldRequiredChange = (): void => {
    // if changed to required and there was no default value field it's always enabled
    if (!required && !defaultValue) {
      setFieldValue(FIELD_ENABLED, true);
    }

    setFieldValue(FIELD_REQUIRED, !required);
  };

  return (
    <NxCheckbox className={styles.checkboxRow}
                onChange={handleFieldEnabledChange}
                checked={enabled}>
      <div className={clsx(styles.input, {[styles.noButtonMargin]: !requiredButtonVisible})}>{children}</div>
      {
        requiredButtonVisible &&
        <div className={styles.button}>
          <RequiredButtonSwitch onClick={handleFieldRequiredChange} value={required} />
        </div>
      }
    </NxCheckbox>
  );
};
