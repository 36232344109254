import {NxButton, NxFormikDatePicker, NxFormikMultiSelect, NxSelectOption} from '@nextbank/ui-components';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as SearchIcon} from '../../../../../../../../assets/images/icon-search.svg';
import {TransHelper} from '../../../../../../../../utils/trans-helper';
import {LoanApplicationContext} from '../../../../../LoanApplication';
import {EventLogGroupType} from '../event-log.model';
import styles from './EventLogsSearchBar.module.scss';

export const TRANS_PREFIX = 'LOAN_APPLICATIONS.EVENT_LOG.SEARCH_BAR';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

interface EventLogsSearchBarProps {
  isSubmitting?: boolean;
}

const EventLogsSearchBar = ({isSubmitting}: EventLogsSearchBarProps): ReactElement => {
  const {steps} = useContext(LoanApplicationContext);
  const {t} = useTranslation();

  const phaseNames = steps.getSteps(t)
    .filter(step => !step.isSubStep)
    .map(value => ({
      value: value.label,
      label: value.label
    } as NxSelectOption));

  const logTypes = Object.keys(EventLogGroupType).map(value => ({
    value: value,
    label: value
  } as NxSelectOption));

  return (
    <div className={styles.inputs}>
      <NxFormikMultiSelect label={<PrefixTrans>STEP_NAMES</PrefixTrans>}
                           name={'phaseNames'}
                           options={phaseNames}
                           emptyOption />
      <NxFormikMultiSelect label={<PrefixTrans>LOG_TYPES</PrefixTrans>}
                           name={'logGroups'}
                           options={logTypes}
                           emptyOption />
      <NxFormikDatePicker className={styles.input}
                          label={<PrefixTrans>LOGGED_FROM</PrefixTrans>}
                          name='loggedFrom' />
      <NxFormikDatePicker className={styles.input}
                          label={<PrefixTrans>LOGGED_TO</PrefixTrans>}
                          name='loggedTo' />
      <NxButton disabled={isSubmitting} loaded={!isSubmitting} type='submit'>
        <div className={styles.searchIcon}>
          <SearchIcon />
        </div>
      </NxButton>
    </div>
  );
};

export default EventLogsSearchBar;
