import React, {ReactElement} from 'react';
import styles from './TopDetailsWrapper.module.scss';

interface Props {
  children: React.ReactNode;
}

export const TopDetailsWrapper = ({children}: Props): ReactElement => {
  return (
    <div className={styles.topDetails}>
      {children}
    </div>
  );
};
