import {NxLoader, NxLoaderColorVariant, NxLoaderVariant} from '@nextbank/ui-components';
import React, {ReactElement, ReactNode} from 'react';
import {TopDetailsWrapper} from '../top-details-wrapper/TopDetailsWrapper';
import styles from './LoanStepWrapper.module.scss';

interface Props {
  aside: ReactNode;
  content: ReactNode;
  topBar: ReactNode;
  isTopBarLoaded?: boolean;
}

const LoanStepWrapper = ({content, aside, topBar, isTopBarLoaded = true}: Props): ReactElement => {

  const Loader = <NxLoader variant={NxLoaderVariant.SMALL} colorVariant={NxLoaderColorVariant.TEXT} />;

  return (
    <>
      <TopDetailsWrapper>
        {isTopBarLoaded ? topBar : Loader}
      </TopDetailsWrapper>
      <div className={styles.stepContainer}>
        <aside className={styles.aside}>
          {aside}
        </aside>
        <section className={styles.stepContent}>
          {content}
        </section>
      </div>
    </>
  );
};

export default LoanStepWrapper;
