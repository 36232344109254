// Currently BE doesn't support dictionary tree so it is impossible to provide list of available options for selected dictionary value
// TODO LOS-371
export const natureTypeConfig = {
  'NOWB#1': [
    'NATURE_TYPE#1',
    'NATURE_TYPE#2',
    'NATURE_TYPE#8'
  ],
  'NOWB#4': [
    'NATURE_TYPE#3',
    'NATURE_TYPE#4',
    'NATURE_TYPE#5',
    'NATURE_TYPE#6',
    'NATURE_TYPE#7',
    'NATURE_TYPE#8'
  ]
};

export const businessTypeConfig = {
  'NOWB#3': [
    'BUSINESS_TYPE#46',
    'BUSINESS_TYPE#47',
    'BUSINESS_TYPE#48',
    'BUSINESS_TYPE#49',
    'BUSINESS_TYPE#50',
    'BUSINESS_TYPE#51',
    'BUSINESS_TYPE#52',
    'BUSINESS_TYPE#53',
    'BUSINESS_TYPE#54',
    'BUSINESS_TYPE#55',
    'BUSINESS_TYPE#56',
    'BUSINESS_TYPE#57',
    'BUSINESS_TYPE#58',
    'BUSINESS_TYPE#59',
    'BUSINESS_TYPE#60',
    'BUSINESS_TYPE#61',
    'BUSINESS_TYPE#62',
    'BUSINESS_TYPE#63',
    'BUSINESS_TYPE#64',
    'BUSINESS_TYPE#65',
    'BUSINESS_TYPE#66',
    'BUSINESS_TYPE#67',
    'BUSINESS_TYPE#68',
    'BUSINESS_TYPE#69',
    'BUSINESS_TYPE#70',
    'BUSINESS_TYPE#71',
    'BUSINESS_TYPE#72',
    'BUSINESS_TYPE#73',
    'BUSINESS_TYPE#74',
    'BUSINESS_TYPE#75',
    'BUSINESS_TYPE#76',
    'BUSINESS_TYPE#77',
    'BUSINESS_TYPE#78',
    'BUSINESS_TYPE#79',
    'BUSINESS_TYPE#80',
    'BUSINESS_TYPE#81',
    'BUSINESS_TYPE#82',
    'BUSINESS_TYPE#83',
    'BUSINESS_TYPE#84',
    'BUSINESS_TYPE#85',
    'BUSINESS_TYPE#86',
    'BUSINESS_TYPE#87',
    'BUSINESS_TYPE#88',
    'BUSINESS_TYPE#89',
    'BUSINESS_TYPE#90',
    'BUSINESS_TYPE#115'
  ],
  'NATURE_TYPE#1': [
    'BUSINESS_TYPE#1',
    'BUSINESS_TYPE#2',
    'BUSINESS_TYPE#3',
    'BUSINESS_TYPE#4',
    'BUSINESS_TYPE#5',
    'BUSINESS_TYPE#6',
    'BUSINESS_TYPE#7',
    'BUSINESS_TYPE#8',
    'BUSINESS_TYPE#9',
    'BUSINESS_TYPE#10',
    'BUSINESS_TYPE#11',
    'BUSINESS_TYPE#12',
    'BUSINESS_TYPE#13',
    'BUSINESS_TYPE#14',
    'BUSINESS_TYPE#15',
    'BUSINESS_TYPE#16',
    'BUSINESS_TYPE#17',
    'BUSINESS_TYPE#18',
    'BUSINESS_TYPE#19',
    'BUSINESS_TYPE#20',
    'BUSINESS_TYPE#21',
    'BUSINESS_TYPE#22',
    'BUSINESS_TYPE#23',
    'BUSINESS_TYPE#24',
    'BUSINESS_TYPE#25',
    'BUSINESS_TYPE#26',
    'BUSINESS_TYPE#27',
    'BUSINESS_TYPE#28',
    'BUSINESS_TYPE#29',
    'BUSINESS_TYPE#30',
    'BUSINESS_TYPE#31',
    'BUSINESS_TYPE#32',
    'BUSINESS_TYPE#115'
  ],
  'NATURE_TYPE#2': [
    'BUSINESS_TYPE#1',
    'BUSINESS_TYPE#2',
    'BUSINESS_TYPE#3',
    'BUSINESS_TYPE#4',
    'BUSINESS_TYPE#5',
    'BUSINESS_TYPE#6',
    'BUSINESS_TYPE#7',
    'BUSINESS_TYPE#8',
    'BUSINESS_TYPE#9',
    'BUSINESS_TYPE#10',
    'BUSINESS_TYPE#11',
    'BUSINESS_TYPE#12',
    'BUSINESS_TYPE#13',
    'BUSINESS_TYPE#14',
    'BUSINESS_TYPE#15',
    'BUSINESS_TYPE#16',
    'BUSINESS_TYPE#17',
    'BUSINESS_TYPE#18',
    'BUSINESS_TYPE#19',
    'BUSINESS_TYPE#20',
    'BUSINESS_TYPE#21',
    'BUSINESS_TYPE#22',
    'BUSINESS_TYPE#23',
    'BUSINESS_TYPE#24',
    'BUSINESS_TYPE#25',
    'BUSINESS_TYPE#26',
    'BUSINESS_TYPE#27',
    'BUSINESS_TYPE#28',
    'BUSINESS_TYPE#29',
    'BUSINESS_TYPE#30',
    'BUSINESS_TYPE#31',
    'BUSINESS_TYPE#32',
    'BUSINESS_TYPE#115'
  ],
  'NATURE_TYPE#3': [
    'BUSINESS_TYPE#91',
    'BUSINESS_TYPE#92',
    'BUSINESS_TYPE#93',
    'BUSINESS_TYPE#94',
    'BUSINESS_TYPE#95',
    'BUSINESS_TYPE#96',
    'BUSINESS_TYPE#97',
    'BUSINESS_TYPE#115'
  ],
  'NATURE_TYPE#4': [
    'BUSINESS_TYPE#98',
    'BUSINESS_TYPE#99',
    'BUSINESS_TYPE#100',
    'BUSINESS_TYPE#101',
    'BUSINESS_TYPE#102',
    'BUSINESS_TYPE#103',
    'BUSINESS_TYPE#115'
  ],

  'NATURE_TYPE#5': [
    'BUSINESS_TYPE#98',
    'BUSINESS_TYPE#99',
    'BUSINESS_TYPE#100',
    'BUSINESS_TYPE#101',
    'BUSINESS_TYPE#102',
    'BUSINESS_TYPE#103',
    'BUSINESS_TYPE#115'
  ],
  'NATURE_TYPE#6': [
    'BUSINESS_TYPE#104',
    'BUSINESS_TYPE#105',
    'BUSINESS_TYPE#106',
    'BUSINESS_TYPE#107',
    'BUSINESS_TYPE#108',
    'BUSINESS_TYPE#109',
    'BUSINESS_TYPE#115'

  ],
  'NATURE_TYPE#7': [
    'BUSINESS_TYPE#110',
    'BUSINESS_TYPE#111',
    'BUSINESS_TYPE#112',
    'BUSINESS_TYPE#113',
    'BUSINESS_TYPE#114',
    'BUSINESS_TYPE#115'
  ]
};
