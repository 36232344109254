// Values order is important as it is read by Object.values and passed as table headers.
// Changing order will change order of related table headers
export enum EventLogTableHeaders {
  LOGGED = 'LOGGED',
  APPLICATION_STEP = 'APPLICATION_STEP',
  LOG_TYPE = 'LOG_TYPE',
  ACTIONS_COLUMN = ''
}

export enum EventLogLabels {
  AGENT_NAME = 'AGENT_NAME',
  ROLES = 'ROLES',
  BRANCH_NAME = 'BRANCH_NAME',
  NOTE = 'NOTE'
}
