import {NxFormikInput, NxFormikSelect} from '@nextbank/ui-components';
import React, {ReactElement} from 'react';
import {DictionaryEntryRequirement} from '../../../../../../shared/model/dictionary-requirement.model';
import {DictionaryEntry} from '../../../../../../shared/model/dictionary.model';
import {SelectHelper} from '../../../../../../utils/select-helper';
import {TransHelper} from '../../../../../../utils/trans-helper';
import FormColumn from '../../../../../shared/form-column/FormColumn';
import FormGroup from '../../../../../shared/form-column/FormGroup';
import {ConfiguredFieldWrapper} from '../../shared/configured-fields/ConfiguredFieldWrapper';
import {FieldDisablementReason} from '../../shared/configured-fields/field-disablement-reason.model';
import RequirementFormHeader from '../shared/RequirementFormHeader';
import {Address} from './address.model';
import styles from './Addresses.module.scss';
import GeolocationForm from './GeolocationForm';

export interface AddressFormEntries {
  country?: DictionaryEntry[],
  province?: DictionaryEntry[],
  city?: DictionaryEntry[],
  barangay?: DictionaryEntry[],
  addressRequirementEntries?: DictionaryEntry[];
}

interface Props {
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.addresses[1].number.
   */
  formPath: string;
  entry: DictionaryEntryRequirement;
  deleteFunction: (typeId: number) => void;
  disablementReason?: FieldDisablementReason;
  disabled?: boolean;
  entries: AddressFormEntries;
}

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CUSTOMER_DATA.ADDRESSES');

export default function AddressForm(props: Props): ReactElement {
  const {entry, entries, formPath, deleteFunction, disabled, disablementReason} = props;

  // Variable defines field requirement. All form fields using this variable are required if requirement changed
  // check validation schema too
  const required = true;
  const getName = (key: keyof Address): string => `${formPath}.${key}`;

  const countryOptions = SelectHelper.mapToNumberOptions(entries.country);

  const Header =
    <RequirementFormHeader disabled={disabled}
                           entry={entry}
                           entries={entries.addressRequirementEntries}
                           deleteFunction={deleteFunction} />;

  return (
    <FormColumn>
      <FormGroup className={styles.inputs} header={Header}>
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikSelect<number> label={<PrefixTrans>COUNTRY</PrefixTrans>}
                                  name={getName('countryId')}
                                  options={countryOptions}
                                  disabled={disabled}
                                  required={required}
                                  emptyOption />
        </ConfiguredFieldWrapper>
        <GeolocationForm {...props} />
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikInput label={<PrefixTrans>STREET</PrefixTrans>}
                         name={getName('street')}
                         disabled={disabled}
                         required={required} />
        </ConfiguredFieldWrapper>
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikInput label={<PrefixTrans>POSTAL_CODE</PrefixTrans>}
                         name={getName('postalCode')}
                         disabled={disabled}
                         required={required} />
        </ConfiguredFieldWrapper>
      </FormGroup>
    </FormColumn>
  );
}
