import {TFunction} from 'i18next';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';

export const StringHelper = {
  /**
   * Check if value is blank. Values is considered as blank when it's empty and not a number or it's NaN
   * Could be moved to another helper in the future as it handles any type
   *
   * @param value to check
   *
   * isBlank(undefined)
   * // => true
   * isBlank(null)
   * // => true
   * isBlank('')
   * // => true
   * isBlank([])
   * // => true
   * isBlank({})
   * // => true
   * isBlank(NaN)
   * // => true
   * isBlank(0)
   * // => false
   */

  isBlank: (value?: string | string[] | undefined): value is undefined =>
    isEmpty(value) && !isNumber(value) || isNaN(value),

  addHttps: (value: string): string => value.startsWith('https://') ? value : `https://${value}`,

  addCurrency: (value: number | string): string => `${value} PHP`,

  addPercentage: (value: number | string): string => `${value} %`,

  addDays: (value: number | string, t: TFunction): string =>
    `${value} ${t(Number(value) === 1 ? 'COMMON.DAY' : 'COMMON.DAYS')}`,

  // Client full name patter is "Lastname M. Firstname" M. -> first letter of middle name
  getClientName: (firstName?: string | null, middleName?: string | null, lastName?: string | null): string => {

    const middleNameFirstLetter = middleName ?? '';

    return firstName && lastName ? `${lastName},  ${firstName} ${middleNameFirstLetter} ` : '-';
  },

  /**
   * Converts string to boolean.
   *
   * stringToBoolean('undefined')     // false
   * stringToBoolean('null')          // false
   * stringToBoolean('0')             // false
   * stringToBoolean('false')         // false
   * stringToBoolean('')              // false
   * stringToBoolean('true')          // true
   * stringToBoolean('anything else') // true
   */
  stringToBoolean: (value?: string | null): boolean => value === 'false' || value === 'undefined' || value === 'null' || value === '0'
    ? false
    : !!value,

  getRearrangedFullname: (value: string): string => {
    const name = value.split(',');
    return `${name[1]} ${name[0]}`;
  },
  removeWhitespaces: (value: string): string | undefined => {
    if (isNil(value)) {
      return value;
    }

    return value.replaceAll(' ', '');
  }
};
