import {isDevelopmentMode} from './app-utils';

export const downloadFile = (fileUrl: string, fileName: string): void => {
  if (isDevelopmentMode) {
    console.warn('Development mode. File can be downloaded incorrectly if provided url redirects to local disk');
  }

  const a = document.createElement('a');
  a.download = fileName;
  a.href = fileUrl;
  a.click();
  a.remove();
};

export const downloadBlobFile = (file: File | undefined): void => {
  if (file === undefined) {
    return;
  }

  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
