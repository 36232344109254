import React, {useState, useEffect, useMemo} from 'react';
import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {isEmpty} from 'lodash';
import IconButtonSwitch from '../../icon-button-switches/IconButtonSwitch';
import {ReactComponent as ViewIcon} from '../../../../assets/images/icon-view.svg';
import styles from './FilePreviewDialog.module.scss';
import {getFileExtension, getFileExtensionName, getMimeTypeByExtension} from '../../../../utils/file-utils';
import {ACCEPTED_IMAGE_FILE_TYPES} from '../../../../constants/file-uploads';

interface FilePreviewContentDialogProps {
  close: () => void;
  isVisible: boolean;
  file: File | undefined;
}

const FilePreviewContentDialog = ({close, isVisible, file}: FilePreviewContentDialogProps) => {
  const [fileUrl, setFileUrl] = useState<string>('');

  const closeDialog = (): void => {
    close();
    URL.revokeObjectURL(fileUrl);
  };

  const isFileImage = useMemo(
    () =>
      file && ACCEPTED_IMAGE_FILE_TYPES.some((fileType) => getFileExtensionName(fileType) === getFileExtension(file)),
    [file]
  );

  useEffect(() => {
    if (file !== undefined && isVisible) {
      let url;

      if (isEmpty(file.type)) {
        const fileMimeType = getMimeTypeByExtension(getFileExtension(file));
        const updatedBlob = new Blob([file], {type: fileMimeType});
        const updatedFile = new File([updatedBlob], file.name, {
          type: fileMimeType,
          lastModified: file.lastModified
        });
        url = URL.createObjectURL(updatedFile);
      } else {
        url = URL.createObjectURL(file);
      }
      setFileUrl(url);
    }
  }, [file, isVisible]);

  const renderFileContent = () => {
    if (isFileImage) {
      return <img className={styles.fileContent} src={fileUrl} />;
    }

    // Use <iframe> for PDF previews. For other file types like audio and video, consider using <audio> or <video> for
    // better control (should be supported) <iframe> can still be used as a fallback for other types but lacks
    // fine-grained control. The <img> tag above ensures a better preview experience for image files.
    return <iframe className={styles.fileContent} src={fileUrl} />;
  };

  return (
    <Dialog maxWidth={'xl'} open={isVisible} onClose={closeDialog}>
      <DialogContent className={styles.dialogContent}>
        <div className={styles.previewContentContainer}>
          {renderFileContent()}
        </div>
      </DialogContent>
      <DialogActions>
        <NxButton variant={NxButtonVariant.CLOSE} onClick={closeDialog}>
          Close
        </NxButton>
      </DialogActions>
    </Dialog>
  );
};

interface FilePreviewDialogProps {
  file: File | undefined;
}

const FilePreviewDialog = ({file}: FilePreviewDialogProps) => {
  const [visible, setVisible] = useState(false);

  const viewFile = (): void => {
    setVisible(true);
  };

  const handleClose = (): void => {
    setVisible(false);
  };

  return (
    <div>
      <IconButtonSwitch className={styles.viewIcon}
                        ariaLabel={'view file'}
                        onClick={viewFile}
                        icon={<ViewIcon />}
                        bordered={false} />
      <FilePreviewContentDialog isVisible={visible} close={handleClose} file={file} />
    </div>
  );
};

export default FilePreviewDialog;