import {TableRow} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import TableDataCell from '../../../../../../shared/table/cells/TableDataCell';
import {CBSLoanDetails} from '../../../../../../../shared/model/cbs-loans';
import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import styles from './ConsolidatedLoansTableRow.module.scss';
import {ConsolidatedLoansContext} from '../consolidated-loans-context';
import {CashHelper} from '../../../../../../../utils/cash-helper';
import {Trans} from 'react-i18next';
import {StepContext} from '../../loan-application-step/LoanApplicationStep';

const HEADERS_TRANS_PREFIX = 'LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.CONSOLIDATED_LOANS';
const COMMON_TRANS_PREFIX = 'COMMON';

interface Props {
  cbsLoanDetails: CBSLoanDetails;
  rowNumber: number;
  hasActionButton?: boolean;
  disableActionButton?: boolean;
  isCustomerLoansSelection?: boolean;
  showReleaseButton?: boolean;
  isLoading?: boolean;
  onReleaseProduct?: (value?: number) => void;
}

const ConsolidatedLoansTableRow = ({
                                     cbsLoanDetails,
                                     rowNumber,
                                     hasActionButton,
                                     isCustomerLoansSelection = false,
                                     disableActionButton,
                                     showReleaseButton = false,
                                     isLoading,
                                     onReleaseProduct
                                   }: Props): ReactElement => {
  const {removeLoan, addLoan} = useContext(ConsolidatedLoansContext);
  const {isStepReadonly} = useContext(StepContext);

  return (
    <TableRow className={styles.tableRow}>
      <TableDataCell data={rowNumber} />
      {
        isCustomerLoansSelection ? (
          <>
            <TableDataCell data={cbsLoanDetails.productNumber} />
            <TableDataCell data={cbsLoanDetails.productName} />
            <TableDataCell data={cbsLoanDetails.principalAmount && CashHelper.addCurrencyMask(cbsLoanDetails.principalAmount)} />
            <TableDataCell data={cbsLoanDetails.principalBalance && CashHelper.addCurrencyMask(cbsLoanDetails.principalBalance)} />
            <TableDataCell data={cbsLoanDetails.status} />
            {hasActionButton &&
              <TableDataCell data={
                <NxButton className={styles.removeButton}
                          disabled={disableActionButton || isStepReadonly}
                          variant={NxButtonVariant.OUTLINED}
                          onClick={(): void => addLoan(cbsLoanDetails)}>
                  <Trans>{`${COMMON_TRANS_PREFIX}.ADD`}</Trans>
                </NxButton>
              } />
            }
          </>
        ) : (
          <>
            <TableDataCell data={cbsLoanDetails.customerName} />
            <TableDataCell data={cbsLoanDetails.productNumber} />
            <TableDataCell data={cbsLoanDetails.principalBalance && CashHelper.addCurrencyMask(cbsLoanDetails.principalBalance)} />
            <TableDataCell data={cbsLoanDetails.interestBalance && CashHelper.addCurrencyMask(cbsLoanDetails.interestBalance)} />
            <TableDataCell data={CashHelper.addCurrencyMask(cbsLoanDetails.cbuAmount)} />
            <TableDataCell data={CashHelper.addCurrencyMask(cbsLoanDetails.pfAmount)} />
            <TableDataCell data={CashHelper.addCurrencyMask(cbsLoanDetails.tpAmount)} />
            <TableDataCell data={CashHelper.addCurrencyMask(cbsLoanDetails.pastDueInterestAmount)} />
            <TableDataCell data={CashHelper.addCurrencyMask(cbsLoanDetails.penaltiesAmount)} />
            <TableDataCell data={CashHelper.addCurrencyMask(cbsLoanDetails.customFeesAmount)} />
            {hasActionButton &&
              <TableDataCell data={
                <>
                  <NxButton className={styles.removeButton}
                            variant={NxButtonVariant.OUTLINED}
                            onClick={(): void => removeLoan(cbsLoanDetails)}
                            disabled={isStepReadonly}
                            loaded={!isLoading}>
                    <Trans>{`${COMMON_TRANS_PREFIX}.REMOVE`}</Trans>
                  </NxButton>
                  {showReleaseButton && cbsLoanDetails.isLocked && onReleaseProduct &&
                    <NxButton className={styles.removeButton}
                              variant={NxButtonVariant.OUTLINED}
                              onClick={(): void => onReleaseProduct(cbsLoanDetails.loanId)}
                              disabled={isStepReadonly}
                              loaded={!isLoading}>
                      <Trans>{`${HEADERS_TRANS_PREFIX}.RELEASE`}</Trans>
                    </NxButton>
                  }
                </>
              } />
            }
          </>
        )
      }

    </TableRow>
  );
};

export default ConsolidatedLoansTableRow;
