import isNil from 'lodash/isNil';
import {useEffect} from 'react';
import {CALCULATE_TERM_URL} from '../../../../../../../constants/api-urls';
import useGet from '../../../../../../../shared/hooks/use-get.hook';
import {SetFieldValue} from '../../../../../../../shared/model/formik.model';
import {CalculatorFormFields} from '../../../calculator/calculator.model';

export function useCalculateTerm(values: CalculatorFormFields,
                                 setFieldValue: SetFieldValue): void {

  const getCalculateTerm = useGet<string>(CALCULATE_TERM_URL);

  useEffect(() => {
    const totalAmortizationNumber = values.totalAmortizationNumber.value;
    const paymentIntervalId = values.paymentIntervalId.value;

    if (!isNil(totalAmortizationNumber) && paymentIntervalId) {

      const urlSearchParams = getUrlParams(paymentIntervalId.toString(), totalAmortizationNumber);
      getCalculateTerm(urlSearchParams).then(term => setFieldValue('term', term));
    }
  }, [values.totalAmortizationNumber.value, values.paymentIntervalId.value]);
}

export function getUrlParams(paymentIntervalId: string, totalAmortizationNumber: number): URLSearchParams {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('paymentIntervalId', paymentIntervalId);
  urlSearchParams.set('amortizationNumber', totalAmortizationNumber.toString());
  return urlSearchParams;
}
