import {TableCell} from '@material-ui/core';
import clsx from 'clsx';
import React, {ReactNode} from 'react';
import styles from './TableDataCell.module.scss';

interface Props {
  data: ReactNode;
  className?: string;
  title?: string;
  small?: boolean;
  rightAlign?: boolean;
  width?: string;
}

const TableDataCell = (
  {data, className, title, small = false, rightAlign = false, width}: Props
): React.ReactElement => {
  const classes = clsx(styles.cell, className, {[styles.cellSmall]: small, [styles.cellAlignRight]: rightAlign});
  return <TableCell width={width} title={title} className={classes}>{data}</TableCell>;
};
export default TableDataCell;
