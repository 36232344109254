import {NxButton, NxFormikDatePicker, NxFormikInput} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AnySchema, object, string} from 'yup';
import {ReactComponent as SearchIcon} from '../../../../../../../assets/images/icon-search.svg';
import {CustomerType} from '../../../../../../../shared/model/customer-type.model';
import {CUSTOMER_STEP_TRANS_PREFIX, PrefixTrans} from '../../CustomerData';
import {IndividualCustomerSearchFormFields} from '../customer-select.model';

import styles from './IndividualCustomerSearchForm.module.scss';

export const CUSTOMER_SEARCH_PAGE_RECORDS_LIMIT = 10;

interface Props {
  searchCustomersPage: (fields: IndividualCustomerSearchFormFields) => Promise<void>;
  formikRef: React.MutableRefObject<FormikProps<unknown> | undefined>;
}

export default function IndividualCustomerSearchForm({formikRef, searchCustomersPage}: Props): React.ReactElement {

  const {t} = useTranslation();
  const [validateOnBlur, setValidateOnBlur] = useState(false);

  const getSchema = (errorLabel: string): AnySchema =>
    string().nullable().required(t(`${CUSTOMER_STEP_TRANS_PREFIX}.${errorLabel}`));

  const validationSchema = object({
    birthDate: getSchema('FILL_DATE_OF_BIRTH'),
    firstName: getSchema('FILL_FIRST_NAME'),
    lastName: getSchema('FILL_LAST_NAME')
  });

  const SearchForm = ({isSubmitting}: FormikProps<IndividualCustomerSearchFormFields>): React.ReactElement => {

    return (<Form className={styles.searchForm}>
        <NxFormikInput className={styles.input}
                       label={<PrefixTrans>FIRST_NAME</PrefixTrans>}
                       name='firstName' />
        <NxFormikInput className={styles.input}
                       label={<PrefixTrans>LAST_NAME</PrefixTrans>}
                       name='lastName' />
        <NxFormikDatePicker className={styles.input}
                            label={<PrefixTrans>DATE_OF_BIRTH</PrefixTrans>}
                            name='birthDate' />
        <NxButton onClick={(): void => setValidateOnBlur(true)}
                  className={styles.searchButton}
                  disabled={isSubmitting}
                  loaded={!isSubmitting}
                  type='submit'>
          <div className={styles.searchIcon}>
            <SearchIcon />
          </div>
        </NxButton>
      </Form>
    );
  };

  return (
    <Formik<IndividualCustomerSearchFormFields> onSubmit={searchCustomersPage}
                                                validationSchema={validationSchema}
                                                validateOnBlur={validateOnBlur}
                                                validateOnChange={false}
                                                innerRef={(ref): void => {
                                                  formikRef.current = ref as FormikProps<unknown>;
                                                }}
                                                initialValues={{
                                                  birthDate: '',
                                                  firstName: '',
                                                  lastName: '',
                                                  customerType: CustomerType.INDIVIDUAL
                                                }}>
      {SearchForm}
    </Formik>
  );
}
