import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React, {ReactElement, useState} from 'react';
import {Trans} from 'react-i18next';
import {TransHelper} from '../../../utils/trans-helper';
import {useGuardedHistory} from '../../router/GuardedHistory';
import {ConfirmationPopup} from '../confirmation-popup/ConfirmationPopup';
import styles from './SaveAndExitButton.module.scss';

export const PrefixTrans = TransHelper.getPrefixedTrans('TOP_BAR.SAVE_AND_EXIT_POPUP');

interface Props {
  className?: string;
  configurationChanged: boolean;
  showConfirmationPopup: boolean;
  savingInProgress: boolean;
  onSave: () => Promise<void>;
  redirectPath: string;
}

const SaveAndExitButton = (
  {className, configurationChanged, showConfirmationPopup, savingInProgress, onSave, redirectPath}: Props
): ReactElement => {
  const [open, setOpen] = useState(false);
  const history = useGuardedHistory();

  const onSaveClick = (): Promise<void> => {
    return onSave().then(() => history.forcePush(redirectPath));
  };

  return (
    <div className={className}>
      {
        configurationChanged
          ? <NxButton onClick={async (): Promise<void> => showConfirmationPopup ? setOpen(true) : onSaveClick()}
                      variant={NxButtonVariant.SAVE}
                      loaded={!savingInProgress}
                      disabled={savingInProgress}>
            <Trans>TOP_BAR.SAVE_AND_EXIT</Trans>
          </NxButton>
          : <NxButton onClick={(): void => history.push(redirectPath)}
                      disabled={savingInProgress}
                      variant={NxButtonVariant.SAVE}>
            <Trans>TOP_BAR.EXIT</Trans>
          </NxButton>
      }
      <ConfirmationPopup header={<PrefixTrans>HEADER</PrefixTrans>}
                         description={(
                           <div className={styles.body}>
                             <span className={styles.description}><PrefixTrans>DESCRIPTION</PrefixTrans></span>
                             <span><PrefixTrans>WARNING</PrefixTrans></span>
                           </div>
                         )}
                         confirmButtonLabel={<Trans>TOP_BAR.SAVE_AND_EXIT</Trans>}
                         isLoading={savingInProgress}
                         onDeny={(): void => setOpen(false)}
                         onConfirm={async (): Promise<void> => onSaveClick().then(() => setOpen(false))}
                         open={open} />
    </div>
  );
};

export default SaveAndExitButton;
