import {TableHead, TableRow} from '@material-ui/core';
import React from 'react';
import {Trans} from 'react-i18next';
import {StringHelper} from '../../../../utils/string-helper';
import TableHeaderCell from '../cells/TableHeaderCell';

interface Props {
  translationPath: string,
  headers: string[];
}

const TableHeader = ({translationPath, headers}: Props): React.ReactElement => (
  <TableHead>
    <TableRow>
      {
        headers.map(header =>
          StringHelper.isBlank(header)
            ? <TableHeaderCell key={header} header={header} />
            : <TableHeaderCell key={header} header={<Trans>{`${translationPath}.${header}`}</Trans>} />
        )
      }
    </TableRow>
  </TableHead>
);

export default TableHeader;
