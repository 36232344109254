export enum StepPayloadType {
  AGREEMENT = 'AgreementPhasePayload',
  CUSTOMER_DATA = 'IndividualCustomerProfilingPhasePayload',
  CUSTOM_PHASE_PAYLOAD = 'CustomPhasePayload',
  ELIGIBILITY_CRITERIA_PAYLOAD = 'EligibilityPhasePayload',
  LOAN_APPLICATION_DATA_PAYLOAD = 'LoanApplicationDataPhasePayload',
  CALCULATOR_PAYLOAD = 'CalculatorPhasePayload',
  PRE_RELEASE = 'PreReleasePhasePayload',
  SUMMARY = 'SummaryPhasePayload'
}
