import {NxButton} from '@nextbank/ui-components';
import {cloneDeep} from 'lodash';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {DroppableProvided} from 'react-beautiful-dnd';
import {Trans} from 'react-i18next';
import {Approver} from '../../../../../../shared/model/phase.model';
import {generateKey} from '../../../../../../utils/generate-key';
import DragDropItem from '../../../../../shared/drag-drop/DragDropItem';
import DragDropItems from '../../../../../shared/drag-drop/DragDropItems';
import {ApprovalRulesContext} from '../ApprovalConfiguration';
import ApprovalRuleHeader from './ApprovalRuleHeader';
import ApproverInputs from './ApproverInputs';
import ApproverItem from './ApproverItem';
import {KeyedApprovalRule, KeyedApprover} from './keyed-approval-rules.model';
import styles from './ApprovalRuleItem.module.scss';

interface Props {
  rule: KeyedApprovalRule;
  provided: DroppableProvided;
  isDragging: boolean;
}

const newApproverInitValue: Approver = {
  roleIds: [],
  branchIds: []
};

export default function CustomFieldGroup({rule, provided, isDragging}: Props): React.ReactElement {

  const {rules, setRules} = useContext(ApprovalRulesContext);
  const [newApprover, setNewApprover] = useState<Approver>(newApproverInitValue);

  useEffect((): void => {
    if (ruleIndex <= -1) {
      throw new Error('Approver index out of range.');
    }
  }, [rules]);

  const ruleIndex = useMemo((): number =>
      rules.findIndex(({key})=> key === rule.key),
    [rules, rule]
  );

  const handleRoleChange = (roleIds: number[]): void => setNewApprover({...newApprover, roleIds});
  const handleBranchChange = (branchIds: number[]): void => setNewApprover({...newApprover, branchIds});

  const setApprovers = (approvers: KeyedApprover[]): void => {
    const updatedRules = cloneDeep(rules);
    updatedRules[ruleIndex].approvers = approvers;
    setRules(updatedRules);
  };

  const addField = (): void => {
    const updatedRules = cloneDeep(rules);
    updatedRules[ruleIndex].approvers.push(
      {
        ...newApprover,
        ...generateKey()
      }
    );

    setRules(updatedRules);
    setNewApprover(newApproverInitValue);
  };

  const approvalRuleHeader = <ApprovalRuleHeader ruleIndex={ruleIndex} rule={rule} />;

  const approverItemFunction = (provided, isDragging, item): React.ReactElement =>
    <ApproverItem provided={provided} approver={item} isDragging={isDragging} ruleIndex={ruleIndex} />;

  return (
    <DragDropItem className={styles.container} provided={provided} isDragging={isDragging} item={approvalRuleHeader}>
      <div className={styles.groupFields}>
        {
          rule.approvers.length > 0 &&
            <DragDropItems<KeyedApprover>
              droppableId={`droppableRules-${rule.key}`}
              items={rule.approvers}
              setItems={setApprovers}
              itemComponentFunction={approverItemFunction} />
        }
        <div className={styles.addRow}>
          <ApproverInputs handleBranchChange={handleBranchChange}
                          handleRoleChange={handleRoleChange}
                          approver={newApprover}
                          isAddRow />
          <NxButton onClick={addField} className={styles.addButton}>
            <Trans>COMMON.ADD</Trans>
          </NxButton>
        </div>
      </div>
    </DragDropItem>
  );
}
