import {NxCheckbox} from '@nextbank/ui-components';
import {useField} from 'formik';
import {ChangeEvent, ReactElement, ReactNode} from 'react';

export interface FormikCheckboxProps<T> {
  children: ReactNode;
  name: string;
  key: string;
  value: T;
  disabled?: boolean;
}

export const FormikCheckbox = <T, >(
  {children, name, key, value, disabled = false}: FormikCheckboxProps<T>
): ReactElement => {

  const [field, , helpers] = useField<T>(name);

  const isChecked = (checkValue: T): boolean => {
    if (Array.isArray(field.value)) {
      return field.value.includes(checkValue);
    }
    return field.value === checkValue;
  };

  const checkboxToggled = (checkValue: T): any => {
    return (e: ChangeEvent): void => {
      const target = e.target;
      if (target instanceof HTMLInputElement) {
        updateValue(target.checked as unknown as boolean, checkValue);
      }
    };
  };

  const updateValue = (isChecked: boolean, checkValue: T): void => {
    if (!Array.isArray(field.value)) {
      field.value === checkValue
        ? helpers.setValue(undefined as unknown as T)
        : helpers.setValue(checkValue);
      return;
    }

    const currentValues = field.value as unknown as T[];

    if (currentValues.includes(checkValue) && !isChecked) {
      helpers.setValue(currentValues.filter(currentValue => currentValue !== checkValue) as unknown as T);
    }

    if (!currentValues.includes(checkValue) && isChecked) {
      helpers.setValue([...currentValues, checkValue] as unknown as T);
    }
  };

  return (
    <NxCheckbox key={key}
                checked={isChecked(value)}
                onChange={checkboxToggled(value)}
                disabled={disabled}>
      {children}
    </NxCheckbox>
  );
};
