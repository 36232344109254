import clsx from 'clsx';
import React from 'react';
import {DragDropContext, Draggable, Droppable, DroppableProvided} from 'react-beautiful-dnd';
import styles from './DragDropItems.module.scss';

export interface DraggableItem {
  key: string;
}

interface Props<T extends DraggableItem> {
  droppableId: string;
  items: T[];
  setItems: (items: T[]) => void;
  itemComponentFunction: (provided: DroppableProvided, isDragging: boolean, item: T) => React.ReactElement;
}

export default function DragDropItems<T extends DraggableItem>(
  {droppableId, items, setItems, itemComponentFunction}: Props<T>): React.ReactElement {

  const reorder = (list, startIndex, endIndex): T[] => {
    const result: T[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result): void => {
    // dropped inside the list
    if (result.destination) {
      setItems(reorder(items, result.source.index, result.destination.index));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot): React.ReactNode => (
          <div {...provided.droppableProps}
               className={clsx(styles.items, {[styles.items_isDraggingOver]: snapshot.isDraggingOver})}
               ref={provided.innerRef}>
            {
              items.map((item, index) =>
                <Draggable key={item.key} draggableId={item.key} index={index}>
                  {
                    (provided, snapshot): React.ReactNode => itemComponentFunction(provided, snapshot.isDragging, item)
                  }
                </Draggable>
              )
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
