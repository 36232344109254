import {Table, TableContainer} from '@material-ui/core';
import {NxLoader} from '@nextbank/ui-components';
import React, {ReactElement} from 'react';
import EmptyState from '../../../../../../../../../shared/empty-state/EmptyState';
import TablePaginationFooter from '../../../../../../../../../shared/table/pagination-footer/TablePaginationFooter';
import {PrefixTrans} from '../../DocumentSearchSidePanel';
import {DocumentsByPage, DocumentSearchFormFields} from '../document-search.model';
import DocumentsTableBody from './documents-table-body/DocumentsTableBody';
import DocumentsTableHead from './documents-table-head/DocumentsTableHead';
import {DOCUMENT_SEARCH_PAGE_RECORDS_LIMIT} from '../DocumentSearch';
import styles from './DocumentsTable.module.scss';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

interface Props {
  documents?: DocumentsByPage;
  searchedData?: DocumentSearchFormFields;
  searchDocument: (pageNo: number) => Promise<void>;
  selectDocument: (documentId: number) => void;
  loading: boolean;
}

const DocumentsTable = ({documents, searchedData, searchDocument, selectDocument, loading}: Props): ReactElement => (
  <>
    {
      !isNil(searchedData) && !isNil(documents) && (
        <>
          {isEmpty(documents?.result)
            ? <EmptyState instruction={<PrefixTrans>DOCUMENTS_EMPTY_STATE</PrefixTrans>} />
            : <TableContainer className={styles.componentWrapper}>
                {
                  loading && <div className={styles.loaderWrapper}><NxLoader /></div>
                }
                <Table className={styles.tableWrapper}>
                  <DocumentsTableHead />
                  <DocumentsTableBody documents={documents.result} selectDocument={selectDocument} />
                  <TablePaginationFooter page={documents.pageNo}
                                         count={documents.totalCount}
                                         onPageChange={searchDocument}
                                         rowsPerPage={DOCUMENT_SEARCH_PAGE_RECORDS_LIMIT} />
                </Table>
              </TableContainer>
          }
        </>
      )
    }
  </>
);

export default DocumentsTable;
