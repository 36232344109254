import {NxInput} from '@nextbank/ui-components';
import React, {ChangeEvent, useContext} from 'react';
import {Trans} from 'react-i18next';
import {getInputValue} from '../../../../../../utils/input-utils';
import BinButton from '../../../../../shared/bin-button/BinButton';
import {CustomFieldsContext} from './CustomFields';
import styles from './CustomFieldGroupHeader.module.scss';
import {cloneDeep} from 'lodash';

interface Props {
  groupName: string;
  groupIndex: number;
}

export default function CustomFieldGroupHeader({groupName, groupIndex}: Props): React.ReactElement {
  const {fieldGroups, setFieldGroups} = useContext(CustomFieldsContext);

  const handleGroupNameChange = (event: ChangeEvent): void => {
    const updatedFieldGroups = cloneDeep(fieldGroups);
    updatedFieldGroups[groupIndex].name = getInputValue(event);
    setFieldGroups(updatedFieldGroups)
  };

  const removeGroup = (): void => {
    const updatedFieldGroups = cloneDeep(fieldGroups);
    updatedFieldGroups.splice(groupIndex, 1);
    setFieldGroups(updatedFieldGroups)
  }

  const label = <Trans>LOAN_CONFIGURATIONS.CUSTOMER_DATA.CUSTOM_FIELDS.GROUP_NAME</Trans>;

  return (
    <>
      <NxInput label={label} className={styles.input} value={groupName} onChange={handleGroupNameChange} />
      <BinButton onClick={removeGroup} />
    </>
  );
}
