import {StylesProvider, ThemeProvider} from '@material-ui/core';
import {Theme} from '@nextbank/ui-components';
import '@nextbank/ui-components/dist/index.css';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import {initReactI18next} from 'react-i18next';
import App from './App';
import {GuardedRouter} from './components/router/GuardedRouter';
import translationEN from './i18n/en.json';

const languageCode = 'en';

i18n
  .use(initReactI18next)
  .init({
    lng: languageCode,
    fallbackLng: languageCode,
    interpolation: {
      escapeValue: false
    },
    resources: {
      [languageCode]: {
        translation: translationEN
      }
    }
  });

dayjs.extend(advancedFormat);
dayjs.extend(duration);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <StylesProvider injectFirst>
        <GuardedRouter>
          <App />
        </GuardedRouter>
      </StylesProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
