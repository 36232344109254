import mapValues from 'lodash/mapValues';
import {PhaseWithStaticFields} from '../../shared/model/phase.model';
import {ThirdPartySystemDataFormFieldsConfiguration} from '../../shared/model/step-forms/third-party-system-form.model';
import {StaticFieldsHelper} from './static-fields-helper';

const {getInitField, getInitFormFields} = StaticFieldsHelper;

export const THIRD_PARTY_SYSTEM_DATA_FIELD_CODES = {
  extraNumber: 'EXTRA_NUMBER'
};

export const getThirdPartySystemDataInitFields = (config: PhaseWithStaticFields): ThirdPartySystemDataFormFieldsConfiguration => {

  const thirdPartySystemDataFields = mapValues(THIRD_PARTY_SYSTEM_DATA_FIELD_CODES, getInitField) as ThirdPartySystemDataFormFieldsConfiguration;

  return getInitFormFields(thirdPartySystemDataFields, config);
};
