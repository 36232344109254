import {NxLoader} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import {isNil} from 'lodash';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {PAGE_RECORDS_LIMIT} from '../../../../../../../constants/table-defaults';
import {UrlParams} from '../../../../../../../routes/routes.model';
import useGet from '../../../../../../../shared/hooks/use-get.hook';
import {ApiHelper} from '../../../../../../../utils/api-helper';
import {generateKey} from '../../../../../../../utils/generate-key';
import {AppSnackbarContext} from '../../../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {
  EventLogSearchResult,
  EventLogTO,
  fromEventLogTO,
  KeyedEventLog,
  KeyedEventLogSearchResult
} from './event-log.model';
import EventLogsTable from './event-logs-table/EventLogsTable';
import EventLogsSearchBar from './search-bar/EventLogSearchBar';

interface EventLogsSearchFilters {
  phaseNames?: string[],
  logGroups?: string[],
  loggedFrom?: string,
  loggedTo?: string,
}

export const EventLogs = (): ReactElement => {

  const {applicationId} = useParams<UrlParams>();
  const {showErrorMessage} = useContext(AppSnackbarContext);
  const getApplications = useGet<EventLogSearchResult>(ApiHelper.getApplicationEventLogUrl(applicationId));
  const [eventLogs, setEventLogs] = useState<KeyedEventLogSearchResult>();

  useEffect(() => {
    searchLogs({});
  }, []);

  const onSubmit = (values: EventLogsSearchFilters): Promise<void> => {
    return searchLogs(values);
  };

  const searchLogs = (values: EventLogsSearchFilters, pageNo?: number): Promise<void> => {
    return getApplications(getUrlParams(values, pageNo))
      .then(result => {
        return result;
      }).then(({result, ...page}) =>
        setEventLogs({...page, result: result.map(mapToKeyedEventLog)}))
      .catch(error => showErrorMessage(error.message));
  };

  const EventLogsSearchTable = ({isSubmitting, values}: FormikProps<EventLogsSearchFilters>): ReactElement => {
    const changePage = (pageNo: number): Promise<void> => searchLogs(values, pageNo);
    return (
      <Form>
        <EventLogsSearchBar isSubmitting={isSubmitting} />
        {
          isSubmitting || isNil(eventLogs)
            ? <NxLoader />
            : <EventLogsTable eventLogs={eventLogs} onPageChange={changePage} />
        }
      </Form>
    );
  };

  return (
    <Formik<EventLogsSearchFilters> onSubmit={onSubmit} initialValues={{}}>
      {EventLogsSearchTable}
    </Formik>
  );
};

const mapToKeyedEventLog = (eventLog: EventLogTO): KeyedEventLog => ({
  ...fromEventLogTO(eventLog),
  ...generateKey()
});

export const getUrlParams = (appliedFilters: EventLogsSearchFilters, pageNo = 0): URLSearchParams => {
  const {phaseNames, logGroups, loggedTo, loggedFrom} = appliedFilters;

  const phaseNamesFilterApplied = phaseNames && phaseNames.length > 0;
  const logGroupsFilterApplied = logGroups && logGroups.length > 0;

  return ApiHelper.constructUrlParams({
    pageNo,
    pageSize: PAGE_RECORDS_LIMIT,
    ...phaseNamesFilterApplied ? {phaseNames} : {},
    ...logGroupsFilterApplied ? {logGroups} : {},
    loggedTo,
    loggedFrom
  });
};
