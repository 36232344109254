import {TableBody, TableRow} from '@material-ui/core';
import React from 'react';
import {CustomerType} from '../../../../../../../../shared/model/customer-type.model';
import TableDataCell from '../../../../../../../shared/table/cells/TableDataCell';
import {PrefixTrans} from '../../../CustomerData';
import {ApplicantSearchResult} from '../../customer-select.model';
import styles from './CustomersTableBody.module.scss';

interface Props {
  customerType: CustomerType,
  customersData: ApplicantSearchResult[];
  selectCustomer: (id: number) => void;
}

const CustomersTableBody = ({customerType, customersData, selectCustomer}: Props): React.ReactElement => (
  <TableBody>
    {
      customersData.map((customer, index) => {

        const {birthDate, fullName, sourceNumber, sourceId, registrationDate} = customer;
        const date = customerType===CustomerType.CORPORATE ? registrationDate : birthDate;
        return (
          <TableRow key={index} className={styles.row}>
            <TableDataCell data={sourceNumber ?? '-'} />
            <TableDataCell data={fullName ?? '-'} />
            <TableDataCell data={date ?? '-'} />
            <TableDataCell data={
              <div className={styles.selectCustomer} onClick={(): void => selectCustomer(sourceId)}>
                <PrefixTrans>SELECT_CUSTOMER</PrefixTrans>
              </div>
            } />
          </TableRow>
        );
      })
    }
  </TableBody>
);

export default CustomersTableBody;
