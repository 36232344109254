import {useField} from 'formik';
import {FormikErrors} from 'formik/dist/types';
import React, {ReactElement, useContext, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {CustomerDataRequirementEntries} from '../../../../../../shared/hooks/use-customer-data-requirements.hook';
import {Dictionary} from '../../../../../../shared/model/dictionary.model';
import {StringHelper} from '../../../../../../utils/string-helper';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {
  CustomerDataPhase
} from '../../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import BinButton, {BinVariant} from '../../../../../shared/bin-button/BinButton';
import DetailsButton from '../../../../../shared/details-button/DetailsButton';
import {LoanApplicationContext} from '../../../LoanApplication';
import {CustomerDataFormFields, IndividualDataFormFields} from '../customer-data-form.model';
import {CustomerDataApplicationDictionaries} from '../hooks/use-customer-data-application-dictionaries.hook';
import {ApplicantDataForm} from '../shared/applicant-data-form/ApplicantDataForm';
import {ApplicantContext} from '../shared/ApplicantContext';
import styles from './RelatedPeopleData.module.scss';

export const CUSTOMER_STEP_TRANS_PREFIX = 'LOAN_APPLICATIONS.CUSTOMER_DATA';
export const PrefixTrans = TransHelper.getPrefixedTrans(CUSTOMER_STEP_TRANS_PREFIX);

interface Props {
  formikFieldPath: string;
  personIndex: number;
  deleteRelatedPerson: (keyToDelete: string) => void;
  relatedPerson: CustomerDataFormFields;
  config: CustomerDataPhase;
  requirementEntries: CustomerDataRequirementEntries;
  dictionaryEntries: CustomerDataApplicationDictionaries;
  customDictionaries?: Dictionary[];
}

export default function RelatedPerson(
  {
    formikFieldPath,
    personIndex,
    relatedPerson,
    deleteRelatedPerson,
    config,
    requirementEntries,
    dictionaryEntries,
    customDictionaries
  }: Props
): ReactElement {
  const objectFormikPath = `${formikFieldPath}[${personIndex}]`;

  const location = useLocation<{applicantKey?: string}>();
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(location.state?.applicantKey === relatedPerson.key);
  const [field, meta] = useField<CustomerDataFormFields>(objectFormikPath);
  const {applicationParams} = useContext(LoanApplicationContext);

  const getHeaderName = (individualData: IndividualDataFormFields): string => {
    if (individualData.firstName.value && individualData.lastName.value) {
      return getClientName(individualData);
    }

    return `${formikFieldPath} ${personIndex + 1}`;
  };

  const getClientName = (individualData: IndividualDataFormFields): string =>
    StringHelper.getClientName(
      individualData.firstName.value,
      individualData.middleName.value,
      individualData.lastName.value
    );

  return (
    <div className={styles.relatedPerson}>
      <div className={styles.header}>
        {getHeaderName(field.value.individualData)}
        <div className={styles.actionButtons}>
          <DetailsButton onClick={(): void => setMoreDetailsOpen(!moreDetailsOpen)}
                         isOpen={moreDetailsOpen}
                         className={styles.button} />
          <BinButton bordered={false}
                     variant={BinVariant.PRIMARY}
                     disabled={!applicationParams.isEditable}
                     onClick={(): void => deleteRelatedPerson(relatedPerson.key)} />
        </div>
      </div>
      {/*TODO smooth transition*/}
      {
        moreDetailsOpen &&
        <div className={styles.moreDetails}>
          <ApplicantContext.Provider value={{isCoreCustomer: relatedPerson.isCoreCustomer}}>
            <ApplicantDataForm config={config}
                               entries={dictionaryEntries}
                               values={field.value}
                               errors={meta.error as FormikErrors<CustomerDataFormFields>}
                               formikFieldPath={objectFormikPath}
                               customDictionaries={customDictionaries}
                               requirementEntries={requirementEntries}
                               showTabs={!!relatedPerson} />
          </ApplicantContext.Provider>
        </div>
      }
    </div>
  );
}

