import React, {useContext} from 'react';
import {NxButton} from '@nextbank/ui-components';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import {StepContext} from '../../loan-application-step/LoanApplicationStep';
import styles from './StartSimulationButton.module.scss';

const CalculatorTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CALCULATOR');

interface Props {
  onSubmit: () => void;
  isSubmitting: boolean;
}

export default function StartSimulationButton({onSubmit, isSubmitting}: Props): React.ReactElement {

  const {isStepReadonly} = useContext(StepContext);

  return (
    <>
      {
        !isStepReadonly &&
          <NxButton onClick={onSubmit} className={styles.simulateButton} loaded={!isSubmitting} disabled={isSubmitting}>
            <CalculatorTrans>SIMULATE</CalculatorTrans>
          </NxButton>
      }
    </>
  );
}
