import {NxFormikInput} from '@nextbank/ui-components';
import React from 'react';
import {TransHelper} from '../../../../utils/trans-helper';
import styles from './BatchItemsSearchForm.module.scss';
import BatchItemAdvancedSearch from './advanced-search/BatchItemAdvancedSearch';

export const PrefixTrans = TransHelper.getPrefixedTrans('BATCH.BATCH_ITEMS.SEARCH');

export const BatchItemsSearchForm = (): React.ReactElement => {
  return (
    <>
      <div className={styles.description}>
        <PrefixTrans>TITLE</PrefixTrans>
      </div>
      <div className={styles.inputs}>
        <NxFormikInput name={'referenceNo'}
                       label={<PrefixTrans>REFERENCE_NO</PrefixTrans>}
                       className={styles.textInput} />
        <BatchItemAdvancedSearch />
      </div>
    </>
  );
};
