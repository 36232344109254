import {NxButton} from '@nextbank/ui-components';
import React, {ReactElement, useState, useContext} from 'react';
import {TransHelper} from '../../../../../utils/trans-helper';
import styles from './ReassignLoanApplication.module.scss';
import { isEmpty } from 'lodash';
import {ReassignLoanApplicationPopup} from './popup/ReassignLoanApplicationPopup';
import {SelectedApplicationsContext} from '../../LoanApplicationDasboard';

export const TRANS_PREFIX = 'LOAN_APPLICATIONS.REASSIGN';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

const ReassignLoanApplicationButton = (): ReactElement => {
  const {selectedApplications} = useContext(SelectedApplicationsContext);
  const [reassignPopupOpen, setReassignPopupOpen] = useState(false);

  return (
    <>
      <div className={styles.reassignButton}>
        <NxButton className={styles.button}
                  onClick={(): void => setReassignPopupOpen(true)}
                  disabled={isEmpty(selectedApplications)}>
            <PrefixTrans>REASSIGN_BUTTON</PrefixTrans>
        </NxButton>
        <ReassignLoanApplicationPopup open={reassignPopupOpen} setOpen={setReassignPopupOpen}/>
      </div>
    </>
  );
};

export default ReassignLoanApplicationButton;
