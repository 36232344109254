import {NxFormikInput} from '@nextbank/ui-components';
import React, {ReactElement, useContext} from 'react';
import {TransHelper} from '../../../../utils/trans-helper';
import ActiveFilters from './active-filters/ActiveFitlers';
import ApplicationsAdvancedSearch from './advanced-search/ApplicationsAdvancedSearch';
import styles from './ApplicationsSearchBar.module.scss';
import ReassignLoanApplicationButton from './reassign/ReassignLoanApplicationButton';
import {isSysAdminOrManager} from '../../../../utils/permissions-utils';
import {SecurityContext} from '../../../../App';

export const TRANS_PREFIX = 'LOAN_APPLICATIONS.SEARCH_BAR';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

const ApplicationsSearchBar = (): ReactElement => {
  
  const {userData} = useContext(SecurityContext);

  return (
    <>
      <div className={styles.description}><PrefixTrans>SEARCH</PrefixTrans></div>
      <div className={styles.inputs}>
        <div>
          <NxFormikInput name={'customerNumber'}
                         label={<PrefixTrans>CUSTOMER_NUMBER_FIELD</PrefixTrans>} />
          <div className={styles.description}><PrefixTrans>CASE_SENSITIVE_INFO</PrefixTrans></div>
        </div>
        <NxFormikInput name={'referenceNumber'}
                       label={<PrefixTrans>REFERENCE_NUMBER_FIELD</PrefixTrans>} />
        <NxFormikInput name={'customerName'}
                       label={<PrefixTrans>APPLICANT_NAME_FIELD</PrefixTrans>} />
        <ApplicationsAdvancedSearch />
        {
          isSysAdminOrManager(userData) && 
          <ReassignLoanApplicationButton />
        }
      </div>
      <ActiveFilters />
    </>
  );
};

export default ApplicationsSearchBar;
