import {NxButton, NxInput, NxPhoneInput} from '@nextbank/ui-components';
import {FormikErrors, FormikTouched} from 'formik/dist/types';
import {cloneDeep} from 'lodash';
import React, {ChangeEvent} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ReactComponent as BinIcon} from '../../../../../../assets/images/icon-bin.svg';
import {SetFieldValue} from '../../../../../../shared/model/formik.model';
import IconButtonSwitch from '../../../../../shared/icon-button-switches/IconButtonSwitch';
import {PrefixTrans} from '../General';
import {PROVIDER_EMAIL, PROVIDER_NAME, PROVIDER_PHONE_NUMBERS, PROVIDER_WEBSITE, GeneralFormFields} from '../general.model';
import styles from './FinancingCompany.module.scss';

interface Props {
  values: GeneralFormFields;
  errors: FormikErrors<GeneralFormFields>;
  touched: FormikTouched<GeneralFormFields>;
  handleBlur: (e: ChangeEvent) => void;
  handleChange: (e: ChangeEvent) => void;
  setFieldValue: SetFieldValue;
}

export default function FinancingCompany(
    {values, touched, errors, handleBlur, handleChange, setFieldValue}: Props
  ): React.ReactElement {

  const {t} = useTranslation();
  const getPhoneLabel = (index: number): string => `${t('LOAN_CONFIGURATIONS.GENERAL.COMPANY_PHONE')} ${index + 1}`;

  const handlePhoneChange = (phoneNumberIndex: number) => (e: React.ChangeEvent): void => {
    const updatedPhones = cloneDeep(values[PROVIDER_PHONE_NUMBERS]);
    updatedPhones[phoneNumberIndex] = (e.target as HTMLInputElement).value;
    setFieldValue(PROVIDER_PHONE_NUMBERS, updatedPhones);
  };

  const addPhoneField = (): void => {
    const updatedPhones = cloneDeep([...values[PROVIDER_PHONE_NUMBERS], '']);
    setFieldValue(PROVIDER_PHONE_NUMBERS, updatedPhones);
  };

  const removePhoneField = (index: number): () => void => (): void => {
    const updatedPhones = cloneDeep(values[PROVIDER_PHONE_NUMBERS]);
    updatedPhones.splice(index, 1);
    setFieldValue(PROVIDER_PHONE_NUMBERS, updatedPhones);
  };

  return (
    <>
      <div className={styles.column}>
        <NxInput
          className={styles.input}
          label={<PrefixTrans>COMPANY_NAME</PrefixTrans>}
          value={values[PROVIDER_NAME]}
          name={PROVIDER_NAME}
          onChange={handleChange}
        />
        <NxInput
          className={styles.input}
          label={<PrefixTrans>COMPANY_WEBSITE</PrefixTrans>}
          value={values[PROVIDER_WEBSITE]}
          name={PROVIDER_WEBSITE}
          onChange={handleChange}
        />
        <NxInput
          label={<PrefixTrans>COMPANY_EMAIL</PrefixTrans>}
          value={values[PROVIDER_EMAIL]}
          name={PROVIDER_EMAIL}
          onChange={handleChange}
          onBlur={handleBlur}
          error={(!!errors[PROVIDER_EMAIL] && !!touched[PROVIDER_EMAIL]) ? errors[PROVIDER_EMAIL] : undefined }
        />
        <div className={styles.header}>
          <PrefixTrans>COMPANY_PHONES</PrefixTrans>
          <NxButton className={styles.addButton} onClick={addPhoneField}>
            <Trans>COMMON.ADD</Trans>
          </NxButton>
        </div>
        {
          values[PROVIDER_PHONE_NUMBERS].map((phone, index) =>
            <div className={styles.row} key={index}>
              <NxPhoneInput label={getPhoneLabel(index)} onChange={handlePhoneChange(index)} value={phone ?? ''} />
              {
                index > 0 && <IconButtonSwitch ariaLabel={'remove phone'}
                                               className={styles.removeButton}
                                               icon={<BinIcon />}
                                               onClick={removePhoneField(index)} />
              }
            </div>
          )
        }
      </div>
    </>
  );
}
