import {NxButton, NxFormikDatePicker, NxFormikInput, NxFormikSelect, NxLoader} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import React, {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DOCUMENTS_URL} from '../../../../../../../../../constants/api-urls';
import useGet from '../../../../../../../../../shared/hooks/use-get.hook';
import {ApiHelper} from '../../../../../../../../../utils/api-helper';
import {getDocumentTypeOptions} from '../../../../../../../../../utils/custom-fields-utils';
import {ReactComponent as SearchIcon} from '../../../../../../../../../assets/images/icon-search.svg';
import {PrefixTrans} from '../DocumentSearchSidePanel';
import {
  DocumentsByPage,
  DocumentsByPageTO,
  DocumentSearchFormFields as Fields,
  fromDocumentsByPageTO
} from './document-search.model';
import DocumentsTable from './documents-table/DocumentsTable';
import styles from './DocumentSearch.module.scss';

export const DOCUMENT_SEARCH_PAGE_RECORDS_LIMIT = 10;

interface Props {
  isSelecting: boolean;
  onDocumentSelect: (documentId: number) => void;
}

export default function DocumentSearch({isSelecting, onDocumentSelect}: Props): ReactElement {

  const {t} = useTranslation();
  const [documents, setDocuments] = useState<DocumentsByPage>();
  const [searchedData, setSearchedData] = useState<Fields>();
  const [isSearching, setSearching] = useState(false);
  const handleDocumentSearch = useGet<DocumentsByPageTO>(DOCUMENTS_URL);
  
  const setSearchResults = (documents?: DocumentsByPage, searchData?: Fields): void => {
    setDocuments(documents ?? undefined);
    setSearchedData(searchData ?? undefined);
  };

  const getSubmit = ({pageNo}: {pageNo: number}) => (fields: Fields): Promise<void> => {

    setSearching(true);

    const searchData = {
      ...fields,
      pageNo,
      pageSize: DOCUMENT_SEARCH_PAGE_RECORDS_LIMIT
    };

    return handleDocumentSearch(ApiHelper.constructUrlParams(searchData))
      .then(documentsTO => setSearchResults(fromDocumentsByPageTO(documentsTO), searchData))
      .finally(() => setSearching(false));
  };

  const SearchForm = ({isSubmitting}: FormikProps<Fields>): ReactElement => {

    const searchDocument = (pageNo): Promise<void> => getSubmit({pageNo})(searchedData as Fields);

    return (
      <>
        <Form>
          <div className={styles.formRow}>
            <NxFormikInput className={styles.input}
                           label={<PrefixTrans>APPLICATION_NUMBER</PrefixTrans>}
                           name='applicationReferenceNo' />
            <NxFormikInput className={styles.input}
                           label={<PrefixTrans>CUSTOMER_NAME</PrefixTrans>}
                           name='customerName' />
            <NxFormikSelect className={styles.input}
                            options={getDocumentTypeOptions(t)}
                            label={<PrefixTrans>TYPE</PrefixTrans>}
                            emptyOption
                            name='type' />
            <NxFormikInput className={styles.input}
                           label={<PrefixTrans>DOCUMENT_NUMBER</PrefixTrans>}
                           name='number' />
          </div>
          <div className={styles.formRow}>
            <NxFormikInput className={styles.input}
                           label={<PrefixTrans>DOCUMENT_NAME</PrefixTrans>}
                           name='name' />
            <NxFormikDatePicker className={styles.input}
                                label={<PrefixTrans>CREATED_ON_FROM</PrefixTrans>}
                                name='createdOnFrom' />
            <NxFormikDatePicker className={styles.input}
                                label={<PrefixTrans>CREATED_ON_TO</PrefixTrans>}
                                name='createdOnTo' />
            <div className={styles.searchButtonWrapper}>
              <NxButton className={styles.searchButton} disabled={isSubmitting} loaded={!isSubmitting} type='submit'>
                <div className={styles.searchIcon}>
                  <SearchIcon />
                </div>
              </NxButton>
            </div>
          </div>
        </Form>
        {
          isSearching || isSelecting
            ? <NxLoader />
            : <DocumentsTable documents={documents}
                              searchedData={searchedData} // use already searched data for page change
                              searchDocument={searchDocument}
                              selectDocument={onDocumentSelect}
                              loading={isSearching || isSubmitting} />}
      </>
    );
  };
  
  const initialValues = {
    applicationReferenceNo: '',
    createdOnFrom: '',
    createdOnTo: '',
    customerName: '',
    name: '',
    number: '',
    type: undefined ///DocumentFieldType
  };

  return (
    <Formik<Fields>
      initialValues={initialValues}
      onSubmit={getSubmit({pageNo: 0})}
      validateOnChange={false}>
      {SearchForm}
    </Formik>
  );
}
