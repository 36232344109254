import clsx from 'clsx';
import React, {ReactElement} from 'react';
import {ReactComponent as CancelledIcon} from '../../../assets/images/icon-ban.svg';
import {ReactComponent as CreatedIcon} from '../../../assets/images/icon-file.svg';
import {ReactComponent as ProcessedIcon} from '../../../assets/images/icon-processed.svg';
import {ReactComponent as ProcessingIcon} from '../../../assets/images/icon-settings.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/images/icon-status-negative.svg';
import {ReactComponent as UploadedIcon} from '../../../assets/images/icon-uploaded.svg';
import {ReactComponent as UploadingIcon} from '../../../assets/images/icon-uploading.svg';
import {ReactComponent as BanIcon} from '../../../assets/images/icon-ban.svg';
import {TransHelper} from '../../../utils/trans-helper';
import {BatchStatus} from '../batch.model';
import styles from './BatchStatusChip.module.scss';

interface Props {
  status: BatchStatus;
}

const PrefixTrans = TransHelper.getPrefixedTrans('BATCH.STATUS');
const BatchStatusChip = ({status}: Props): ReactElement => {

  const statusComponent = (children: ReactElement): ReactElement => {
    switch (status) {
      case BatchStatus.CREATED:
        return <><CreatedIcon />{children}</>;
      case BatchStatus.UPLOADED:
        return <><UploadedIcon />{children}</>;
      case BatchStatus.UPLOADING:
        return <>
          <div className={clsx(styles.animationContainer, styles.uploadingPulse)}><UploadingIcon /></div>
          {children}
        </>;
      case BatchStatus.PROCESSED:
        return <><ProcessedIcon />{children}</>;
      case BatchStatus.PROCESSING:
        return <>
          <div className={clsx(styles.animationContainer, styles.cogSpinner)}><ProcessingIcon /></div>
          {children}
        </>;
      case BatchStatus.CANCELED:
        return <><CancelledIcon />{children}</>;
      case BatchStatus.ERROR:
        return <><ErrorIcon />{children}</>;
      case BatchStatus.REVOKED:
        return <><BanIcon />{children}</>;
      default:
        return children;
    }
  };

  const statusStyles = clsx(
    styles.status,
    {
      [styles.successChip]: [BatchStatus.PROCESSED, BatchStatus.UPLOADED].includes(status),
      [styles.warningChip]: [BatchStatus.PROCESSING, BatchStatus.UPLOADING].includes(status),
      [styles.errorChip]: status == BatchStatus.ERROR
    }
  );

  return (
    <div className={statusStyles}>
      {statusComponent(<PrefixTrans>{`${status}`}</PrefixTrans>)}
    </div>
  );
};

export default BatchStatusChip;
