import {Table, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Permission} from '../../../../shared/model/permission.model';
import TableDataCell from '../../../shared/table/cells/TableDataCell';
import TableHeaderCell from '../../../shared/table/cells/TableHeaderCell';
import {AccessesContext, PERMISSIONS_TRANS_PREFIX, PrefixTrans} from '../Access';
import AccessMenu from './access-menu/AccessMenu';
import styles from './AccessesTable.module.scss';

const headers = ['NO', 'ID', 'ROLE_NAME', 'PERMISSIONS'];

const AccessesTable = (): ReactElement => {

  const {t} = useTranslation();
  const {roles} = useContext(AccessesContext);

  const getPermissions = (permissions?: Permission[]): string =>
    !isNil(permissions) && permissions.length > 0
      ? permissions.map(permission => t(`${PERMISSIONS_TRANS_PREFIX}.${permission}`)).join(', ')
      : '-';

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {
                headers.map(header => <TableHeaderCell key={header}
                                                       className={styles.header}
                                                       header={<PrefixTrans>{header}</PrefixTrans>} />)
              }
              <TableHeaderCell className={styles.buttonHeader} />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              roles?.map((role, index) =>
                <TableRow key={role.id} className={styles.tableRow}>
                  <TableDataCell data={index + 1} />
                  <TableDataCell data={role.id} />
                  <TableDataCell data={role.name} />
                  <TableDataCell data={getPermissions(role.permissions)} />
                  <TableDataCell data={<AccessMenu role={role} /> } />
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccessesTable;
