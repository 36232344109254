import {omit} from 'lodash';
import {FieldGroup} from '../../../../../../shared/model/field-group.model';
import {Field} from '../../../../../../shared/model/field.model';
import {generateKey} from '../../../../../../utils/generate-key';

export interface KeyedFieldGroup extends FieldGroup<KeyedField[]> {
  key: string;
}

export interface KeyedField extends Field {
  key: string;
}

export const toKeyedFieldGroup = (fieldGroup: FieldGroup): KeyedFieldGroup => ({
  ...fieldGroup,
  ...generateKey(),
  fields: fieldGroup.fields.map(field => toKeyedField(field))
});

export const toKeyedField = (field: Field): KeyedField => ({
  ...field,
  ...generateKey()
});

export const toFieldGroup = (keyedFieldGroup: KeyedFieldGroup): FieldGroup => {
  const {fields, ...fieldGroup} = omit(keyedFieldGroup, 'key');
  const fieldsWithoutKeys = fields.map(field => omit(field, 'key'));

  return {
    ...fieldGroup,
    fields: fieldsWithoutKeys.map((field, orderNo) => ({...field, orderNo}))
  };
};
