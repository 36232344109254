import {NxSelectOption} from '@nextbank/ui-components/dist/nxSelect/NxSelect';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import {DictionaryEntryRequirement, FieldRequirement} from '../../../../../../shared/model/dictionary-requirement.model';
import {DictionaryEntry} from '../../../../../../shared/model/dictionary.model';
import {DictionaryHelper} from '../../../../../../utils/dictionary-helper';
import {EntryRequirementValue} from '../customer-data-form.model';

const {getEntryRequirementLabel} = DictionaryHelper;

const filterRequiredOrProvidedRequirements =
  <T extends EntryRequirementValue>(entry: DictionaryEntryRequirement, values?: T[]): boolean =>
    entry.requirement === FieldRequirement.REQUIRED || !!find(values, value => value.typeId === entry.dictionaryEntryId);

const sortEntryRequirements = (first: DictionaryEntryRequirement, second: DictionaryEntryRequirement): number => {
  if (first.requirement === second.requirement) {
    return 0;
  }

  return first.requirement === FieldRequirement.REQUIRED ? -1 : 1;
};

const getInitialOptionalRequirements =
  <T extends EntryRequirementValue>(values: T[], enabledRequirements: DictionaryEntryRequirement[]): number[] =>
    values.
      map(({typeId}) => enabledRequirements.find(({dictionaryEntryId, requirement}) =>
        dictionaryEntryId === typeId && requirement === FieldRequirement.OPTIONAL)
      )
      .filter(requirement => !isNil(requirement))
      .map(requirement => requirement!.dictionaryEntryId); // eslint-disable-line @typescript-eslint/no-non-null-assertion

const getOptionalRequirementsOptions =
  (enabledRequirements: DictionaryEntryRequirement[], entries?: DictionaryEntry[]): NxSelectOption<number>[] =>
    enabledRequirements
      .filter(({requirement}) => requirement === FieldRequirement.OPTIONAL)
      .map(entry => ({
        label: getEntryRequirementLabel(entries, entry),
        value: entry.dictionaryEntryId
      } as NxSelectOption<number>));

const getDocumentRequirementsOptions =
  (enabledRequirements: DictionaryEntryRequirement[], entries?: DictionaryEntry[]): NxSelectOption<number>[] =>
    enabledRequirements
      .map(entry => ({
        label: getEntryRequirementLabel(entries, entry),
        value: entry.dictionaryEntryId,
        // Setting the disabled to true if field is required so that users can't uncheck the box
        disabled: entry.requirement === FieldRequirement.REQUIRED
      } as NxSelectOption<number>));

const getInitialDocumentRequirements =
  <T extends EntryRequirementValue>(values: T[], enabledRequirements: DictionaryEntryRequirement[]): number[] =>
    values.map(({typeId}) => enabledRequirements.find(({dictionaryEntryId}) =>
        dictionaryEntryId === typeId)
      )
      .filter(requirement => !isNil(requirement))
      .map(requirement => requirement!.dictionaryEntryId); // eslint-disable-line @typescript-eslint/no-non-null-assertion

export const EntryRequirementHelper = {
  filterRequiredOrProvidedRequirements,
  getInitialOptionalRequirements,
  getOptionalRequirementsOptions,
  sortEntryRequirements,
  getDocumentRequirementsOptions,
  getInitialDocumentRequirements
};
