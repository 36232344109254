import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {LoanStep} from '../../../../shared/model/loan-step.model';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import {LoanStepTabs} from '../../../shared/loan-step-tabs/LoanStepTabs';
import {LoanApplicationSteps} from '../steps/loan-application-steps';

interface Props {
  steps: LoanApplicationSteps;
  disabled: boolean;
  currentPhaseId?: number;
}

function LoanApplicationSidebar({steps, disabled, currentPhaseId}: Props): React.ReactElement {

  const {t} = useTranslation();
  const currentStepPath = useLocation().pathname;
  const history = useGuardedHistory();

  const redirectToStep = (step: LoanStep): () => void => (): void => history.push(step.path);

  const loanApplicationSteps =
    steps.getSteps(t)
      .map(step => ({
        ...step,
        onStepClick: redirectToStep(step),
        disabled,
        isMarkedStep: currentPhaseId ? currentPhaseId === step.phaseId : false
      }));

  return <LoanStepTabs currentStepPath={currentStepPath} steps={loanApplicationSteps} />;
}

export default LoanApplicationSidebar;
