import React, {ReactElement} from 'react';
import MainContainer from '../shared/main-container/MainContainer';
import RenderRoutes from '../shared/render-routes/RenderRoutes';
import {DASHBOARD_ROUTES} from './routes/dashboard-routes';
import TopBar from './top-bar/TopBar';

const Dashboard = (): ReactElement =>
  <MainContainer topBar={<TopBar />}>
    <RenderRoutes routes={DASHBOARD_ROUTES} />
  </MainContainer>;

export default Dashboard;
