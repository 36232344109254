import {ListItem} from '@material-ui/core';
import Autocomplete, {AutocompleteRenderInputParams} from '@material-ui/lab/Autocomplete';
import {AutocompleteProps, createFilterOptions} from '@material-ui/lab/Autocomplete/Autocomplete';
import {NxGenericTextField} from '@nextbank/ui-components';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import React, {ReactElement, ReactNode, useEffect, useState} from 'react';
import {
  ConfiguredFieldWrapper
} from '../../loan-applications/loan-application/steps/shared/configured-fields/ConfiguredFieldWrapper';
import {
  FieldDisablementReason
} from '../../loan-applications/loan-application/steps/shared/configured-fields/field-disablement-reason.model';
import styles from './LosAutocomplete.module.scss';

type LosFormikAutocompleteProps<T_Value> =
  Omit<AutocompleteProps<T_Value, false, false, false>, 'renderInput' | 'freeSolo' | 'renderOption' | 'getOptionLabel' | 'onChange'>
  & {
  label: string,
  onChange: (value: string | T_Value | null) => void
  required?: boolean,
  error?: string
  hint?: React.ReactNode;
  /**
   * When selected component can return string instead of generic type
   */
  freeSolo?: boolean;
  renderOption?: (option: T_Value) => ReactNode
  getOptionLabel?: (option: T_Value) => string
  disablementReason?: FieldDisablementReason;
}

export const LosAutocomplete = function <T_Value>(props: LosFormikAutocompleteProps<T_Value>): ReactElement {
  const {
    freeSolo, hint, disabled, required, label, error,
    value, renderOption, getOptionLabel, onChange, disablementReason
  } = props;
  const [displayValue, setDisplayValue] = useState<string>('');
  const filterOptions = createFilterOptions<T_Value>({limit: 200});

  useEffect(() => {
    if (isNil(value)) {
      setDisplayValue('');
    }
  }, [value]);

  return <ConfiguredFieldWrapper disablementReason={disablementReason} hint={hint}>
    <Autocomplete {...props}
                  onChange={(event, value) => onChange(value)}
                  renderOption={renderOption ?? (option => <ListItem>{option}</ListItem>)}
                  getOptionLabel={getOptionLabel ?? (option => isString(option) ? option : '')}
                  filterSelectedOptions
                  autoComplete
                  includeInputInList
                  freeSolo={freeSolo}
                  getOptionSelected={(val1, val2): boolean => isEqual(val1, val2)}
                  filterOptions={filterOptions}
                  ListboxProps={{
                    className: styles.menu
                  }}
                  inputValue={props.inputValue ?? displayValue}
                  onInputChange={(e, value, reason) => props.onInputChange
                    ? props.onInputChange(e, value, reason)
                    : setDisplayValue(value)}
                  renderInput={(params: AutocompleteRenderInputParams): ReactElement =>
                    <div ref={params.InputProps.ref}>
                      <NxGenericTextField
                        disabled={disabled}
                        inputProps={{...params.inputProps, 'data-test': name}}
                        required={required}
                        label={label}
                      />
                      {
                        !!error && <div className={styles.error}>{error}</div>
                      }
                    </div>
                  }
    />
  </ConfiguredFieldWrapper>;
};
