import {isEmpty, isNil} from 'lodash';
import useSingleQuery from '../../../../../../shared/hooks/use-single-query.hook';
import {CheckGroup} from '../../../../../../shared/model/check-group.model';
import {Dictionary} from '../../../../../../shared/model/dictionary.model';
import {ApiHelper} from '../../../../../../utils/api-helper';

interface CustomDictionariesState {
  areDictionariesLoaded: boolean;
  dictionaries?: Dictionary[];
}

const {getDictionariesUrl} = ApiHelper;

export default function useCustomCheckDictionaries(checkGroups?: CheckGroup[]): CustomDictionariesState {

  const dictionaryIds: number[] = [];

  const checks = checkGroups?.flatMap(checkGroup => checkGroup.checks);
  checks?.forEach(check => {

    const {dictionaryId} = check;

    if (!isNil(dictionaryId)) {
      dictionaryIds.push(dictionaryId);
    }
  });

  const params = new URLSearchParams(`dictionaryIds=${dictionaryIds}`);
  const emptyIdsList = isEmpty(dictionaryIds);
  const dictionaries = useSingleQuery<Dictionary[]>(getDictionariesUrl(), null, !emptyIdsList, params);
  const areDictionariesLoaded = !isNil(dictionaries) || (emptyIdsList && !isNil(checks));

  return {dictionaries, areDictionariesLoaded};
}
