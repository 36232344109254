import {NxSelectOption} from '@nextbank/ui-components/dist/nxSelect/NxSelect';
import React, {ReactElement, useContext} from 'react';
import {ConfiguredField} from '../../../../../../../shared/model/field.model';
import {NxFile} from '../../../../../../../shared/model/nx-file.model';
import {YES_NO_SELECT_OPTIONS} from '../../../../../../shared/form-bolean-option-values/YesNoSelectOptions';
import {ConfiguredFieldDatePicker} from '../../../shared/configured-fields/ConfiguredFieldDatePicker';
import {ConfiguredFieldFileInput} from '../../../shared/configured-fields/ConfiguredFieldFileInput';
import {ConfiguredFieldInput} from '../../../shared/configured-fields/ConfiguredFieldInput';
import {ConfiguredFieldPhoneInput} from '../../../shared/configured-fields/ConfiguredFieldPhoneInput';
import {ConfiguredFieldSelect} from '../../../shared/configured-fields/ConfiguredFieldSelect';
import {FieldDisablementReason} from '../../../shared/configured-fields/field-disablement-reason.model';
import {ApplicantContext} from '../../shared/ApplicantContext';

interface SelectInputProps extends InputProps {
  emptyOption?: boolean;
  hint?: React.ReactNode;
}

interface InputProps<T_VALUE = ConfiguredField> {
  fieldKey: string;
  value: T_VALUE;
  options?: NxSelectOption[];
}

interface ConfiguredFieldProps {
  disablementReason?: FieldDisablementReason;
  label: string;
  name: string;
}

const getProps = (key: string, label, isCoreCustomer?: boolean): ConfiguredFieldProps => ({
  disablementReason: isCoreCustomer ? FieldDisablementReason.VALUE_MANAGED_BY_CBS : undefined,
  name: key,
  label
});

export const ApplicantDataSelect = (
  {fieldKey, value, options = YES_NO_SELECT_OPTIONS, emptyOption, hint}: SelectInputProps
): ReactElement => {

  const {isCoreCustomer} = useContext(ApplicantContext);

  return <ConfiguredFieldSelect {...getProps(fieldKey, value.name, isCoreCustomer)} field={value}
                                options={options}
                                emptyOption={emptyOption}
                                hint={hint} />;
};

export const ApplicantDataInput = ({fieldKey, value}: InputProps): ReactElement => {

  const {isCoreCustomer} = useContext(ApplicantContext);

  return <ConfiguredFieldInput {...getProps(fieldKey, value.name, isCoreCustomer)} field={value} />;
};

export const ApplicantDataDatePicker = ({fieldKey, value}: InputProps): ReactElement => {

  const {isCoreCustomer} = useContext(ApplicantContext);

  return <ConfiguredFieldDatePicker {...getProps(fieldKey, value.name, isCoreCustomer)} field={value} />;
};

export const ApplicantDataFileInput = ({fieldKey, value}: InputProps<ConfiguredField<NxFile>>): ReactElement => {

  const {isCoreCustomer} = useContext(ApplicantContext);

  return <ConfiguredFieldFileInput {...getProps(fieldKey, value.name, isCoreCustomer)} configuredField={value} />;
};

export type PhoneInputProps = InputProps & {mask?: string}

export const ApplicantDataPhoneInput = ({fieldKey, value, mask}: PhoneInputProps): ReactElement => {

  const {isCoreCustomer} = useContext(ApplicantContext);

  return <ConfiguredFieldPhoneInput {...getProps(fieldKey, value.name, isCoreCustomer)} field={value} mask={mask} />;
};
