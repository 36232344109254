import {NxMultiSelect, NxSelectOption} from '@nextbank/ui-components';
import {difference} from 'lodash';
import React, {ReactElement} from 'react';
import FormColumn from '../../../../../shared/form-column/FormColumn';
import {FieldDisablementReason} from '../../shared/configured-fields/field-disablement-reason.model';
import {ConfiguredFieldWrapper} from '../../shared/configured-fields/ConfiguredFieldWrapper';
import styles from './OptionalRequirementsSelect.module.scss';

interface Props {
  label: React.ReactNode;
  options: NxSelectOption<number>[];
  selectedRequirements: number[];
  setSelectedRequirements: (requirements: number[]) => void;
  updateSelectValue: (wasRequirementAdded: boolean, typeId: number) => void;
  disablementReason?: FieldDisablementReason;
  disabled?: boolean;
}

export default function OptionalRequirementSelect(
    {label, options, selectedRequirements, setSelectedRequirements, updateSelectValue, disabled, disablementReason
  }: Props): ReactElement {

  const handleOptionalRequirementSelect = (updatedOptions: number[]): void => {

    const wasRequirementAdded = updatedOptions.length > selectedRequirements.length;
    const changedRequirements = wasRequirementAdded
      ? difference(updatedOptions, selectedRequirements)
      : difference(selectedRequirements, updatedOptions);

    // get updated requirement typeId at 0 index as it always will be one element diff
    const typeId = changedRequirements[0];

    updateSelectValue(wasRequirementAdded, typeId);
    setSelectedRequirements(updatedOptions);
  };

  return (
    <>
      {
        options.length > 0 &&
          <FormColumn>
            <ConfiguredFieldWrapper className={styles.selectWrapper} disablementReason={disablementReason}>
              <NxMultiSelect<number> onChange={handleOptionalRequirementSelect}
                                     value={selectedRequirements}
                                     disabled={disabled}
                                     options={options}
                                     label={label} />
            </ConfiguredFieldWrapper>
          </FormColumn>
      }
    </>
  );
}
