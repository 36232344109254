import mapValues from 'lodash/mapValues';
import {ConfiguredField, Field} from '../../shared/model/field.model';

export type FieldRecords = Record<string, Field<unknown>>;
export type ConfiguredFieldRecords = Record<string, ConfiguredField<unknown>>;

export const getConfiguredFields = (fieldsConfig: FieldRecords): ConfiguredFieldRecords =>
  mapValues(fieldsConfig, field => ({
    value: field.defaultValue,
    required: field.required,
    enabled: field.enabled,
    name: field.name,
    code: field.code,
    type: field.type
  }));

export const getEmptyNotRequiredConfigField = (): ConfiguredField => ({
  value: undefined,
  enabled: false,
  required: false
});
export const getEmptyRequiredConfigField = (): ConfiguredField => ({value: undefined, enabled: true, required: true});
