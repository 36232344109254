export type GuardedRouterContextState = {
  routeChangeLock: boolean;
  showRouteChangeConfirmationPopup: boolean;
  onRouteChangeConfirm: () => void;
}

export interface GuardedRouterContextType {
  routerContextState: GuardedRouterContextState;
  dispatchAction: (action: GuardedRouterAction) => void;
}

export type GuardedRouterAction = NoParamAction | ShowPopupAction;

interface NoParamAction {
  type: GuardedRouterActionType.HIDE_CONFIRMATION_POPUP
      | GuardedRouterActionType.LOCK_ROUTE_CHANGE
      | GuardedRouterActionType.UNLOCK_ROUTE_CHANGE;
}

interface ShowPopupAction {
  type: GuardedRouterActionType.SHOW_CONFIRMATION_POPUP;
  onRouteChangeConfirm: () => void;
}

export enum GuardedRouterActionType {
  LOCK_ROUTE_CHANGE = 'LOCK_ROUTE_CHANGE',
  UNLOCK_ROUTE_CHANGE = 'UNLOCK_ROUTE_CHANGE',
  SHOW_CONFIRMATION_POPUP = 'SHOW_CONFIRMATION_POPUP',
  HIDE_CONFIRMATION_POPUP = 'HIDE_CONFIRMATION_POPUP'
}
