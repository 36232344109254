import React from 'react';
import {Trans} from 'react-i18next';
import {SingleMessagePageWrapper} from '../shared/single-message-page-wrapper/SingleMessagePageWrapper';

export default function NoPermissions(): React.ReactElement {
  return (
    <SingleMessagePageWrapper>
      <Trans>NO_PERMISSIONS</Trans>
    </SingleMessagePageWrapper>
  );
}
