import {useFormikContext} from 'formik';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {TransHelper} from '../../../../../utils/trans-helper';
import AdvancedSearchMenu from '../../../../shared/search-bar/advanced-search-menu/AdvancedSearchMenu';
import {FormikCheckbox} from '../../../../shared/nx-formik-checkbox/FormikCheckbox';
import {BatchItemSearchCriteria, BatchItemStatus} from '../../../batch.model';
import {searchDefaultState} from '../../BatchDetails';
import styles from './BatchItemAdvancedSearch.module.scss';

const ADVANCED_SEARCH_ID = 'advanced-search';

const BATCH_ITEMS_PATH = 'BATCH.BATCH_ITEMS';
export const PrefixTrans = TransHelper.getPrefixedTrans(`${BATCH_ITEMS_PATH}.SEARCH`);

const BatchItemAdvancedSearch = (): ReactElement => {

  const {t} = useTranslation();
  const {values, setValues} = useFormikContext<BatchItemSearchCriteria>();

  const isFilterActive = (): boolean => {
    return values.statuses.length > 0;
  };

  const validOptions = ['TRUE', 'FALSE']
    .map(value => ({value, label: t(`${BATCH_ITEMS_PATH}.VALID.${value}`)}));

  const batchItemStatuses = Object.values(BatchItemStatus)
    .map(value => ({value, label: t(`${BATCH_ITEMS_PATH}.STATUS.${value}`)}));

  const MenuForm = (): ReactElement => {
    return (
      <>
        <div className={styles.description}>
          <PrefixTrans>VALID</PrefixTrans>
        </div>
        <div className={styles.checkboxes}>
          {
            validOptions.map(option =>
              <FormikCheckbox
                value={option.value}
                name='valid'
                key={option.value}>
                {option.label}
              </FormikCheckbox>
            )
          }
        </div>
        <div className={styles.description}>
          <PrefixTrans>STATUSES</PrefixTrans>
        </div>
        <div className={styles.checkboxes}>
          {
            batchItemStatuses.map(option =>
              <FormikCheckbox
                value={option.value}
                name='statuses'
                key={option.value}>
                {option.label}
              </FormikCheckbox>
            )
          }
        </div>
      </>
    );
  };

  return (
    <AdvancedSearchMenu menuId={ADVANCED_SEARCH_ID}
                        isActive={isFilterActive()}
                        onReset={(): void => setValues(searchDefaultState)}>
      <MenuForm />
    </AdvancedSearchMenu>
  );
};

BatchItemAdvancedSearch.displayName = 'BatchItemAdvancedSearch';

export default BatchItemAdvancedSearch;
