import {NxLoader} from '@nextbank/ui-components';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import {MANAGEMENT_BRANCHES_URL, MANAGEMENT_ROLES_URL} from '../../../constants/api-urls';
import {UrlParams} from '../../../routes/routes.model';
import useGet from '../../../shared/hooks/use-get.hook';
import {useLoanProcess} from '../../../shared/hooks/use-loan-process.hook';
import useSingleQuery from '../../../shared/hooks/use-single-query.hook';
import {Branch, Role} from '../../../shared/model/management.model';
import {ApiHelper} from '../../../utils/api-helper';
import {isSysAdminOrManager} from '../../../utils/permissions-utils';
import {AppSnackbarContext} from '../../shared/app-snackbar-provider/AppSnackbarProvider';
import LoanStepWrapper from '../../shared/loan-step-wrapper/LoanStepWrapper';
import RenderRoutes from '../../shared/render-routes/RenderRoutes';
import {LoanApplicationContextType} from './loan-application-context.model';
import LoanApplicationSidebar from './loan-application-sidebar/LoanApplicationSidebar';
import LoanApplicationTopBar from './loan-application-topbar/LoanApplicationTopBar';
import {Application, ApplicationStatus} from './loan-application.model';
import {LoanNavigation} from './navigation/LoanNavigation';
import {LOAN_APPLICATION_ROUTES} from './routes/loan-application-routes';
import {LoanApplicationSteps} from './steps/loan-application-steps';
import {SecurityContext} from '../../../App';
import {PaymentIntervalsContextType} from '../../shared/payment-interval/payment-interval-context.model';
import {PaymentInterval} from '../../../shared/model/payment-interval.model';
import {useCheckInactivePaymentIntervals} from '../../../shared/hooks/use-check-inactive-payment-intervals';
import {LoanCreationRouteIds} from '../../../routes/routes.paths';

export const LoanApplicationContext =
  createContext<LoanApplicationContextType>({} as LoanApplicationContextType);

export const PaymentIntervalsContext = createContext<PaymentIntervalsContextType>({
  paymentIntervals: []
});

const LoanApplication = (): React.ReactElement => {

  const {userData} = useContext(SecurityContext);
  const roles = useSingleQuery<Role[]>(MANAGEMENT_ROLES_URL);
  const branches = useSingleQuery<Branch[]>(MANAGEMENT_BRANCHES_URL);
  const paymentIntervals = useSingleQuery<PaymentInterval[]>(ApiHelper.getPaymentIntervalsUrl());
  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {processId, applicationId} = useParams<UrlParams>();
  const history = useHistory();
  const {process} = useLoanProcess(processId);
  const getApplication = useGet<Application>(ApiHelper.getApplicationUrl(applicationId));
  const [application, setApplication] = useState<Application>();
  const [processChanged, setProcessChanged] = useState(false);
  const [isFieldActionOwner, setIsFieldActionOwner] = useState<boolean>(false);
  const [openedPhaseId, setOpenedPhaseId] = useState<number>();

  const path = useLocation().pathname;

  const [steps, setSteps] = useState<LoanApplicationSteps>(
    new LoanApplicationSteps(processId, applicationId, [])
  );
  const isStepDone = steps.getIsStepAlreadyDone(path, application?.currentPhaseId);
  const isOpenedPhaseCurrent = openedPhaseId === application?.currentPhaseId;
  const isUserAssignee = application?.assignedTo === userData?.id;

  useEffect(() => {
    setSteps(new LoanApplicationSteps(processId, applicationId, []));
  }, [processId]);

  const refreshApplication = (): Promise<Application> => getApplication()
    .then(application => {
      setApplication(application);
      return application;
    })
    .catch(error => {
      showErrorMessage(error.message);
      throw error;
    });

  useEffect(() => {
    // for new applications applicationId will have value 'new'
    if (isNaN(Number(applicationId))) {
      return;
    }

    if (!isNil(application) && history.location.pathname.includes('customer-data')) {
      return;
    }

    refreshApplication();
  }, [history.location.pathname]);

  useEffect(() => {
    setSteps(new LoanApplicationSteps(processId, applicationId, process?.phases));
  }, [process.phases, applicationId]);

  useCheckInactivePaymentIntervals(process, application);

  const isDeferred = application?.status === ApplicationStatus.DEFERRED;

  const applicationContext = {
    roles,
    branches,
    application,
    applicationParams: {
      isEditable:
        application?.status !== ApplicationStatus.SUBMITTED &&
        application?.status !== ApplicationStatus.CANCELED &&
        !isDeferred &&
        (isSysAdminOrManager(userData) || isUserAssignee || isFieldActionOwner),
      isSubmitted: application?.status === ApplicationStatus.SUBMITTED,
      isPending: application?.status === ApplicationStatus.PENDING,
      isDeferred,
      isStepDone,
      isOpenedPhaseCurrent
    },
    setApplication,
    refreshApplication,
    processId: Number(processId),
    process,
    steps,
    applicationChanged: false,
    processChanged,
    setProcessChanged,
    openedPhaseId,
    setOpenedPhaseId,
    setIsFieldActionOwner
  };

  return isEmpty(process) ? <NxLoader /> :
    <LoanApplicationContext.Provider value={applicationContext}>
      <PaymentIntervalsContext.Provider value={{paymentIntervals}}>
        <LoanNavigation>
          <LoanStepWrapper content={<RenderRoutes routes={LOAN_APPLICATION_ROUTES} />}
                           topBar={<LoanApplicationTopBar productName={process.loanProduct.name} />}
                           aside={<LoanApplicationSidebar disabled={applicationId === LoanCreationRouteIds.NEW}
                                                          currentPhaseId={application?.currentPhaseId}
                                                          steps={steps} />} />
        </LoanNavigation>
      </PaymentIntervalsContext.Provider>
    </LoanApplicationContext.Provider>;
};

export default LoanApplication;

