import isEmpty from 'lodash/isEmpty';
import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {Dictionary} from '../../../../../../shared/model/dictionary.model';
import {FieldGroup} from '../../../../../../shared/model/field-group.model';
import {NxFile} from '../../../../../../shared/model/nx-file.model';
import {SelectHelper} from '../../../../../../utils/select-helper';
import EmptyState from '../../../../../shared/empty-state/EmptyState';
import FormGroup from '../../../../../shared/form-column/FormGroup';
import {ConfiguredCustomField} from '../../shared/configured-fields/ConfiguredCustomField';
import {CustomFieldValue} from './custom-field.model';
import styles from './CustomFields.module.scss';
import {compareByOrderNo} from '../../../../../../utils/field-helper';

interface Props {
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.individualData.firstName.
   */
  formikFieldPath: string;
  fieldGroups?: FieldGroup[];
  values: (CustomFieldValue | CustomFieldValue<NxFile>)[];
  dictionaries?: Dictionary[];
}

const {mapDictionaryToOptions} = SelectHelper;

const getFieldName = (parentFieldPath: string,
                      values: (CustomFieldValue | CustomFieldValue<NxFile>)[],
                      id?: number): string => {

  const fieldIndex = values.findIndex(({customFieldId}) => customFieldId === id);

  return `${parentFieldPath}.customFieldValues[${fieldIndex}]`;
};

export default function CustomFields({formikFieldPath, fieldGroups, values, dictionaries}: Props): ReactElement {
  return (
    <>
      {
        isEmpty(fieldGroups)
          ? <EmptyState instruction={<Trans>LOAN_APPLICATIONS.CONFIGURATION_EMPTY_STATE</Trans>} />
          : fieldGroups?.map((fieldGroup, groupIndex) =>
            <FormGroup className={styles.inputs} header={fieldGroup.name} key={groupIndex}>
              {
                fieldGroup.fields?.sort(compareByOrderNo).map((field, fieldIndex) => {

                  const {type, name, required, defaultValue, id, dictionaryId} = field;
                  const customOptions = dictionaryId ? mapDictionaryToOptions(dictionaries, dictionaryId) : [];
                  const configuredField = {
                    required,
                    value: defaultValue,
                    name: getFieldName(formikFieldPath, values, id)
                  };

                  return <ConfiguredCustomField key={`${groupIndex}-${fieldIndex}`}
                                                customOptions={customOptions}
                                                field={configuredField}
                                                label={name} // field name set during configuration
                                                type={type} />;
                })
              }
            </FormGroup>
          )
      }
    </>
  );
}
