import {NxTabs} from '@nextbank/ui-components';
import {Formik, FormikProps} from 'formik';
import {omit} from 'lodash';
import isNil from 'lodash/isNil';
import values from 'lodash/values';
import React, {ReactElement, useContext} from 'react';
import {PhaseName} from '../../../../../constants/api-urls';
import {LoanApplicationDataOptions} from '../../../../../shared/hooks/use-loan-application-data-options.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import usePut from '../../../../../shared/hooks/use-put.hook';
import {PhaseValidationResult} from '../../../../../shared/model/phase-validation-result.model';
import {Field} from '../../../../../shared/model/field.model';
import {PaymentType} from '../../../../../shared/model/payment.model';
import {APPROVAL_RULES} from '../../../../../shared/model/phase.model';
import {
  LoanApplicationDataFormFieldsConfiguration
} from '../../../../../shared/model/step-forms/loan-application-data-form.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {getLoanApplicationDataInitFields} from '../../../../../utils/step-form-utils/loan-application-data-init-fields';
import {TransHelper} from '../../../../../utils/trans-helper';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {
  KeyedApprovalRule,
  toApprovalRule
} from '../../loan-configuration-step/approval-configuration/approval-rule-items/keyed-approval-rules.model';
import LoanConfigurationStep from '../../loan-configuration-step/LoanConfigurationStep';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {toKeyedFixedPaymentOption} from '../calculator/calculator-phase.model';
import {getInitialApprovalRulesValues} from '../hooks/use-initial-approval-rules-values.hook';
import {useSetConfigurationFormChanged} from '../hooks/use-set-configuration-form-changed.hook';
import LoanParameters from '../shared/simulation/loan-parameters/LoanParameters';
import AccountInformation from './account-information/AccountInformation';
import CoMakers from './co-makers/CoMakers';
import {LoanApplicationDataPhase} from './loan-application-data-phase.model';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA');
const IS_APPROVAL_REQUIRED = 'isApprovalRequired';

interface FormFields extends LoanApplicationDataFormFieldsConfiguration {
  [APPROVAL_RULES]?: KeyedApprovalRule[];
  [IS_APPROVAL_REQUIRED]?: boolean;
}

interface Props {
  config: LoanApplicationDataPhase;
  loanApplicationDataOptions: LoanApplicationDataOptions;
}

export default function LoanApplicationDataForm({config, loanApplicationDataOptions}: Props): React.ReactElement {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {process} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.LOAN_APPLICATION_DATA);
  const updatePhase = usePut<void, LoanApplicationDataPhase>(phaseUrl);
  const validatePhase = usePost<PhaseValidationResult, LoanApplicationDataPhase>(`${phaseUrl}/validate`);

  const buildPhaseConfig = (formFields: FormFields): LoanApplicationDataPhase => {
    const fieldTypeFromFields = values(omit(formFields, [APPROVAL_RULES, IS_APPROVAL_REQUIRED]))
      .filter((value): value is (Field<number> | Field) => !isNil(value) && typeof value !== 'number');

    const staticFields = fieldTypeFromFields
      .filter(field => field)
      .map(field => ({...field, defaultValue: !isNil(field.defaultValue) ? field.defaultValue.toString() : undefined}));

    const {
      approvalRules, isApprovalRequired, maxCoMakerNumber, minCoMakerNumber, maxCoBorrowerNumber, minCoBorrowerNumber
    } = formFields;

    return {
      ...config,
      approvalRules: isApprovalRequired ? approvalRules?.map(toApprovalRule) : [],
      maxCoMakerNumber,
      minCoMakerNumber,
      maxCoBorrowerNumber,
      minCoBorrowerNumber,
      staticFields
    };
  };

  const submit = async (formFields: FormFields): Promise<void> =>
    await updatePhase(buildPhaseConfig(formFields)).catch(error => showErrorMessage(error.message));

  const isPaymentDynamic = process.paymentType === PaymentType.DYNAMIC;
  const fixedPaymentOptions = process.fixedPaymentOptions?.map(toKeyedFixedPaymentOption) ?? [];

  const StepForm = (
    {values, handleSubmit, submitForm, setFieldValue, dirty}: FormikProps<FormFields>
  ): ReactElement => {

    useSetConfigurationFormChanged(dirty);

    const validate = (): Promise<PhaseValidationResult> => validatePhase(buildPhaseConfig(values));

    const loanParametersTab = {
      key: 'LOAN_PARAMETERS',
      label: <PrefixTrans>LOAN_PARAMETERS</PrefixTrans>,
      tabPanel: <LoanParameters values={values}
                                setFieldValue={setFieldValue}
                                loanParametersOptions={loanApplicationDataOptions.loanParametersOptions}
                                paymentFieldProps={{
                                  fixedPaymentsState: {fixedPaymentOptions},
                                  paymentModeState: {isPaymentDynamic},
                                  paymentIntervalOptionsProps: loanApplicationDataOptions.loanParametersOptions.paymentIntervalOptionsProps,
                                  disabled: !isPaymentDynamic
                                }} />
    };

    const accountInformationTab = {
      key: 'ACCOUNT_INFORMATION',
      label: <PrefixTrans>ACCOUNT_INFORMATION.LABEL</PrefixTrans>,
      tabPanel: <AccountInformation values={values}
                                    setFieldValue={setFieldValue}
                                    options={loanApplicationDataOptions.accountInformationOptions} />
    };

    const coMakersTab = {
      key: 'CO_MAKERS',
      label: <PrefixTrans>CO_MAKERS.LABEL</PrefixTrans>,
      tabPanel: <CoMakers />
    };

    return (
      <LoanConfigurationStep handleSave={submitForm} validationFunction={validate}>
        <form onSubmit={handleSubmit}>
          <NxTabs tabs={[loanParametersTab, accountInformationTab, coMakersTab]} />
        </form>
      </LoanConfigurationStep>
    );
  };

  return (
    <Formik<FormFields>
      onSubmit={submit}
      initialValues={{
        ...getInitialApprovalRulesValues(config),
        ...getLoanApplicationDataInitFields(config)
      }}>
      {StepForm}
    </Formik>
  );
}
