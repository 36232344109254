import {NxSelectOption} from '@nextbank/ui-components';
import {ReactNode} from 'react';
import {DraggableCheckItem} from '../../../../shared/model/check.model';

export enum DataTestIds {
  AGE_INPUT = 'AGE_INPUT',
  INCOME_INPUT = 'INCOME_INPUT',
  CRITERION_TYPE_SELECT = 'CRITERION_TYPE_SELECT',
  PROOF_TYPE_SELECT = 'PROOF_TYPE_SELECT',
  PROVINCE_SELECT = 'PROVINCE_SELECT',
  RESIDENCE_SELECT = 'RESIDENCE_SELECT',
  NATIONALITY_SELECT = 'NATIONALITY_SELECT',
  OCCUPATION_SELECT = 'OCCUPATION_SELECT'
}

export interface CheckProps {
  check: DraggableCheckItem;
  setCheck: (check: DraggableCheckItem) => void;
  endButton: ReactNode;
  dictionaryId?: number;
  phaseId: number;
  provinceOptions?: NxSelectOption<number>[];
  residenceOptions?: NxSelectOption<number>[];
  nationalityOptions?: NxSelectOption<number>[];
  ageCheckTypeOptions?: NxSelectOption<number>[];
  occupationOptions?: NxSelectOption<number>[];
  handleAutomaticCheck?: boolean;
}
