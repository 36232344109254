import {TableRow} from '@material-ui/core';
import React, {ReactElement} from 'react';
import styles from './LosTableRow.module.scss';

interface Props {
  children: React.ReactNode;
  onClick: () => void;
}

//TODO refactor tables to use this common component
const LosTableRow = ({children, onClick}: Props): ReactElement => {

  return (
    <TableRow onClick={onClick} className={styles.tableRow}>
      {children}
    </TableRow>
  );
};

export default LosTableRow;
