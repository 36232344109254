import {NxFormikCheckbox} from '@nextbank/ui-components';
import React, {useContext} from 'react';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {StepContext} from '../../shared/loan-application-step/LoanApplicationStep';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.AGREEMENTS');

export default function AgreementCheckbox(): React.ReactElement {

  const {isStepReadonly} = useContext(StepContext);

  return (
    <NxFormikCheckbox name='termsAndConditions' disabled={isStepReadonly}>
      <PrefixTrans>CHECKBOX_LABEL</PrefixTrans>
    </NxFormikCheckbox>
  );
}
