import {NxButton, NxFormikDatePicker, NxFormikInput} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AnySchema, object, string} from 'yup';
import {ReactComponent as SearchIcon} from '../../../../../../../assets/images/icon-search.svg';
import {CustomerType} from '../../../../../../../shared/model/customer-type.model';
import {CUSTOMER_STEP_TRANS_PREFIX, PrefixTrans} from '../../CustomerData';
import {CorporateCustomerSearchFormFields} from '../customer-select.model';

import styles from './CorporateCustomerSearchForm.module.scss';

export const CUSTOMER_SEARCH_PAGE_RECORDS_LIMIT = 10;

interface Props {
  searchCustomersPage: (fields: CorporateCustomerSearchFormFields) => Promise<void>;
  formikRef: React.MutableRefObject<FormikProps<unknown> | undefined>;
}

export default function CorporateCustomerSearchForm({formikRef, searchCustomersPage}: Props): React.ReactElement {

  const {t} = useTranslation();
  const [validateOnBlur, setValidateOnBlur] = useState(false);
  const applicantSearchForm = useRef();

  const getSchema = (errorLabel: string): AnySchema =>
    string().nullable().required(t(`${CUSTOMER_STEP_TRANS_PREFIX}.${errorLabel}`));

  const validationSchema = object({
    corporateName: getSchema('FILL_CORPORATE_NAME'),
    dateOfRegistration: getSchema('FILL_REGISTRATION_DATE')
  });

  const SearchForm = ({isSubmitting}: FormikProps<CorporateCustomerSearchFormFields>): React.ReactElement => {

    return (<Form className={styles.searchForm}>
        <NxFormikInput className={styles.corporateName}
                       label={<PrefixTrans>CORPORATE_NAME</PrefixTrans>}
                       name='corporateName' />
        <NxFormikDatePicker className={styles.registrationDate}
                            label={<PrefixTrans>REGISTRATION_DATE</PrefixTrans>}
                            name='dateOfRegistration' />
        <NxButton onClick={(): void => setValidateOnBlur(true)}
                  className={styles.searchButton}
                  disabled={isSubmitting}
                  loaded={!isSubmitting}
                  type='submit'>
          <div className={styles.searchIcon}>
            <SearchIcon />
          </div>
        </NxButton>
      </Form>
    );
  };

  return (
    <Formik<CorporateCustomerSearchFormFields> onSubmit={searchCustomersPage}
                                               validationSchema={validationSchema}
                                               validateOnBlur={validateOnBlur}
                                               validateOnChange={false}
                                               innerRef={(ref): void => {
                                                 formikRef.current = ref as FormikProps<unknown>;
                                               }}
                                               initialValues={{
                                                 corporateName: '',
                                                 dateOfRegistration: '',
                                                 customerType: CustomerType.CORPORATE
                                               }}>
      {SearchForm}
    </Formik>
  );
}
