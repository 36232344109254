import mapValues from 'lodash/mapValues';
import {PhaseWithStaticFields} from '../../shared/model/phase.model';
import {CorporateDataFormFieldsConfiguration} from '../../shared/model/step-forms/corporate-data-form.model';
import {StaticFieldsHelper} from './static-fields-helper';

const {getInitField, getInitFormFields} = StaticFieldsHelper;

export const CORPORATE_DATA_FIELD_CODES = {
  // Company data
  businessName: 'BUSINESS_NAME',
  tradeName: 'TRADE_NAME',
  businessTypeId: 'BUSINESS_TYPE',
  sourceOfFunds: 'SOURCE_OF_FUNDS',
  placeOfIncorporation: 'PLACE_OF_INCORPORATION',
  conglomerateId: 'CONGLOMERATE',
  registrationDate: 'REGISTRATION_DATE',
  registrationNumber: 'REGISTRATION_NUMBER',
  registrationCountryId: 'REGISTRATION_COUNTRY',

  // Contact data
  email: 'EMAIL',
  phoneNumber: 'PHONE_NUMBER',

  // Compliance
  signature: 'SIGNATURE',
  riskLevelId: 'RISK_LEVEL',
  dosri: 'DOSRI',
  dosriTypeId: 'DOSRI_TYPE',
  picture: 'PICTURE',
  relatedPartyTransactionId: 'RELATED_PARTY_TRANSACTION',
  relatedPartyTransactionDescription: 'RELATED_PARTY_TRANSACTION_DESCRIPTION'
};

export const getCompanyDataInitFields = (config: PhaseWithStaticFields): CorporateDataFormFieldsConfiguration => {

  const companyDataInitFields = mapValues(CORPORATE_DATA_FIELD_CODES, getInitField) as CorporateDataFormFieldsConfiguration;

  return getInitFormFields(companyDataInitFields, config);
};
