import {ReportResponse, ReportStatus} from './report.model';

export interface PrintRequest {
  phaseId: number;
  applicationId: number;
  printType: PrintType;
}

export interface Print extends ReportResponse {
  id: number;
  // Print status refers to report status type because print is in fact a report but handled in different way by BE
  printType: PrintType;
}

// Print types can be checked under GET /prints/types
export enum PrintType {
  SIMULATION = 'SIMULATION',
  CUSTOMER_DATA = 'CUSTOMER_DATA',
  ELIGIBILITY_CRITERIA = 'ELIGIBILITY_CRITERIA',
  LOAN_APPLICATION_DATA = 'LOAN_APPLICATION_DATA',
  CUSTOM_PHASE = 'CUSTOM_PHASE',
  AGREEMENTS = 'AGREEMENTS',
  PRE_RELEASE = 'PRE_RELEASE',
  SUMMARY = 'SUMMARY'
}
