import {Table, TableBody, TableContainer, TableRow} from '@material-ui/core';
import {NxSelectOption} from '@nextbank/ui-components/dist/nxSelect/NxSelect';
import React, {useMemo} from 'react';
import {Trans} from 'react-i18next';
import {TABLE_DATE_FORMAT} from '../../../constants/format-templates';
import {Process, ProductStatus} from '../../../shared/model/process.model';
import ChannelIcons from '../../shared/channel-icons/ChannelIcons';
import Instruction from '../../shared/instruction/Instruction';
import ProductStatusChip from '../../shared/status-chip/ProductStatusChip';
import TableDataCell from '../../shared/table/cells/TableDataCell';
import {CopyConfigurationPopupProvider} from './copy-configuration-popup/CopyConfigurationPopupProvider';
import LoanConfigurationsTableHead from './loan-configurations-table-head/LoanConfigurationsTableHead';
import LoanMenu from './loan-menu/LoanMenu';
import styles from './LoanConfigurationsTable.module.scss';

interface Props {
  processes: Process[];
  setProcesses: (processes: Process[]) => void;
}

const LoanConfigurationsTable = ({processes, setProcesses}: Props): React.ReactElement => {
  const onLoanPublished = (publishedProcess: Process): void => {
    const modifiedProcesses = processes?.map(process =>
      process.id === publishedProcess.id ? publishedProcess : process
    );

    setProcesses(modifiedProcesses);
  };

  const processOptions = useMemo(() =>
    processes.map(process => ({
      label: process.loanProduct.name,
      value: process.id
    } as NxSelectOption<number>)), [processes]);

  return (
    <>
      <CopyConfigurationPopupProvider processes={processOptions}>
        <Instruction>
          <Trans>LOAN_CONFIGURATIONS.LIST_INSTRUCTION</Trans>
        </Instruction>
        <TableContainer>
          <Table>
            <LoanConfigurationsTableHead />
            <TableBody>
              {
                processes?.map((process, index) => (
                  <TableRow key={index} className={styles.tableRow}>
                    <TableDataCell data={index + 1} />
                    <TableDataCell data={process.loanProduct.code} />
                    <TableDataCell data={process.loanProduct.name} />
                    <TableDataCell data={process.modifiedBy ?? '-'} />
                    <TableDataCell data={process.modifiedOn?.format(TABLE_DATE_FORMAT) ?? '-'} />
                    <TableDataCell data={<ChannelIcons channels={process.channels} />} />
                    <TableDataCell data={<ProductStatusChip status={process.status} />} />
                    <TableDataCell data={<LoanMenu isLoanPublished={process.status === ProductStatus.PUBLISHED}
                                                   onLoanPublished={onLoanPublished}
                                                   processId={process.id} />} />
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </CopyConfigurationPopupProvider>
    </>);
};

export default LoanConfigurationsTable;
