import {NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {PhaseName} from '../../../../../constants/api-urls';
import {UrlParams} from '../../../../../routes/routes.model';
import useCorporateDataOptions from '../../../../../shared/hooks/use-corporate-data-options.hook';
import useCustomerDataRequirements from '../../../../../shared/hooks/use-customer-data-requirements.hook';
import useIndividualDataOptions from '../../../../../shared/hooks/use-individual-data-options.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {ApiHelper} from '../../../../../utils/api-helper';
import {sortByOrderNo} from '../../../../../utils/sort-by-order';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {KeyedFieldGroup, toKeyedFieldGroup} from './custom-fields/keyed-fields.model';
import {CustomerDataPhase} from './customer-data-phase.model';
import CustomerDataForm from './CustomerDataForm';

export default function CustomerData(): ReactElement {

  const {processId} = useParams<UrlParams>();
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(processId, PhaseName.INDIVIDUAL_CUSTOMER_PROFILING);
  const config = useSingleQuery<CustomerDataPhase>(phaseUrl);
  const {configurationChanged, setConfigurationChanged} = useContext(LoanConfigurationContext);
  const [fieldGroups, setFieldGroups] = useState<KeyedFieldGroup[]>([]);

  // TODO MOVE TO CustomerDataForm but how to not break resolver like behavior?
  const {individualDataOptions, areIndividualDataOptionsLoaded} = useIndividualDataOptions(config);
  const {corporateDataOptions, areCorporateDataOptionsLoaded} = useCorporateDataOptions(config);
  const {requirementEntries, areRequirementEntriesLoaded} = useCustomerDataRequirements(config);

  useEffect(() => {
    if (isNil(config)) {
      return;
    }

    const keyedFieldGroups = config.dynamicFieldGroups.map(toKeyedFieldGroup);
    const sortedKeyedFieldsGroup = sortByOrderNo(keyedFieldGroups).map(group => ({
      ...group,
      fields: sortByOrderNo(group.fields)
    }));

    setFieldGroups(sortedKeyedFieldsGroup);

  }, [config]);

  const guardedFieldsGroupChange = (fieldGroups: KeyedFieldGroup[]): void => {
    if (!configurationChanged) {
      setConfigurationChanged(true);
    }

    setFieldGroups(fieldGroups);
  };

  return isNil(config) || !areIndividualDataOptionsLoaded  || !areCorporateDataOptionsLoaded || !areRequirementEntriesLoaded
    ? <NxLoader />
    : <CustomerDataForm config={config}
                        fieldGroups={fieldGroups}
                        updateFieldGroups={guardedFieldsGroupChange}
                        individualDataOptions={individualDataOptions}
                        corporateDataOptions={corporateDataOptions}
                        requirementEntries={requirementEntries} />;
}
