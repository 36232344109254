import clsx from 'clsx';
import React from 'react';
import {Trans} from 'react-i18next';
import {ProductStatus} from '../../../shared/model/process.model';
import styles from './ProductStatusChip.module.scss';

const ProductStatusChip = ({status}: {status: ProductStatus}): React.ReactElement =>
  <div className={clsx(styles.status, {[styles.status_draft]: status === ProductStatus.DRAFT})}>
    <Trans>{`LOAN_CONFIGURATIONS.STATUS.${status}`}</Trans>
  </div>;

export default ProductStatusChip;
