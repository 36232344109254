import {TableBody, TableRow} from '@material-ui/core';
import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React from 'react';
import {Trans} from 'react-i18next';
import {DEFAULT_DATE_FORMAT} from '../../../../../../../../../../../constants/format-templates';
import TableDataCell from '../../../../../../../../../../shared/table/cells/TableDataCell';
import {PrefixTrans} from '../../../DocumentSearchSidePanel';
import {Document} from '../../document-search.model';
import styles from './DocumentsTableBody.module.scss';

interface Props {
  documents: Document[];
  selectDocument: (documentId: number) => void;
}

const DocumentsTableBody = ({documents, selectDocument}: Props): React.ReactElement => (
  <TableBody>
    {
      documents.map(document => {

        const {applicationReferenceNo, createdOn, documentId, name, number, type, userFullName} = document;

        return (
          <TableRow key={documentId} className={styles.row}>
            <TableDataCell small data={applicationReferenceNo ?? '-'} />
            <TableDataCell small data={userFullName ?? '-'} />
            <TableDataCell small data={type ? <Trans>{`COMMON.DOCUMENT_TYPES.${type}`}</Trans> : '-'} />
            <TableDataCell small data={number ?? '-'} />
            <TableDataCell small data={name ?? '-'} />
            <TableDataCell small data={createdOn?.format(DEFAULT_DATE_FORMAT) ?? '-'} />
            <TableDataCell small data={
              <NxButton variant={NxButtonVariant.TEXT}
                        className={styles.selectDocument}
                        onClick={(): void => selectDocument(documentId)}>
                <PrefixTrans>SELECT_DOCUMENT</PrefixTrans>
              </NxButton>
            } />
          </TableRow>
        );
      })
    }
  </TableBody>
);

export default DocumentsTableBody;
