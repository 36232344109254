import React, {useContext, useEffect} from 'react';
import {DroppableProvided} from 'react-beautiful-dnd';
import {TransHelper} from '../../../../../utils/trans-helper';
import DragDropItem from '../../../../shared/drag-drop/DragDropItem';
import Check from '../../check/Check';
import {DraggableCheck} from '../draggable-checks.model';
import {DraggableChecksContext} from '../DraggableChecks';

interface Props {
  check: DraggableCheck;
  setCheck: (check: DraggableCheck) => void;
  endButton: React.ReactNode;
  groupIndex: number;
  provided?: DroppableProvided;
  isDragging: boolean;
}

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.ELIGIBILITY_CRITERIA');

export default function DraggableCheckForm(
  {check, setCheck, endButton, groupIndex, provided, isDragging}: Props
): React.ReactElement {

  const {
    checkGroups,
    phaseId,
    handleAutomaticCheck,
    provinceOptions,
    residenceOptions,
    nationalityOptions,
    occupationOptions,
    ageCheckTypeOptions,
  } = useContext(DraggableChecksContext);

  useEffect((): void => {
    if (check.key && getCheckIndex() <= -1) {
      throw new Error('Draggable check index out of range.');
    }
  }, [checkGroups]);

  const getCheckIndex = (): number =>
    checkGroups[groupIndex].checks.findIndex(checkItem => checkItem.key === check.key);

  return <DragDropItem provided={provided}
                       isDragging={isDragging}
                       item={<Check check={check}
                                    setCheck={setCheck}
                                    phaseId={phaseId}
                                    dictionaryId={check.dictionaryId}
                                    endButton={endButton}
                                    handleAutomaticCheck={handleAutomaticCheck}
                                    provinceOptions={provinceOptions}
                                    residenceOptions={residenceOptions}
                                    ageCheckTypeOptions={ageCheckTypeOptions}
                                    nationalityOptions={nationalityOptions}
                                    occupationOptions={occupationOptions} />}
  />;
}

