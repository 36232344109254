import {TFunction} from 'i18next';
import {LoanStep} from '../shared/model/loan-step.model';
import {getStepPathPart} from './loan-steps-utils';

export class LoanSteps {
  protected readonly steps: LoanStep[];

  constructor(steps: LoanStep[]) {
    this.steps = steps;
  }

  getStepByPath(currentPath: string): LoanStep {
    return this.steps.filter(value => currentPath === value.path)[0];
  }

  getSteps(t: TFunction): LoanStep[] {
    return this.steps.map(step => ({...step, label: this.getTranslatedStepLabel(step.path, t)}));
  }

  protected getStepIndex(path: string): number {
    return this.steps.findIndex(step => getStepPathPart(step.path) === getStepPathPart(path));
  }

  getIsStepAlreadyDone(path: string, phaseId?: number): boolean {
    if (!phaseId) {
      return false;
    }
    return this.steps.findIndex(step => step.phaseId === phaseId) > this.getStepIndex(path);
  }

  getTranslatedStepLabel(path: string, t: TFunction): string {
    const index = this.getStepIndex(path);
    const step = this.steps[index];
    return  step.isCustomStep ? step.label : t(step.label);
  }

  getPreviousAndNextStepPaths(path: string): {previousStepPath?: string, nextStepPath?: string} {
    const index = this.getStepIndex(path);
    const steps = this.steps;
    const previousStepPath = index > 0 ? this.findPreviousPathFromNotSubStep(index) : undefined;
    const nextStepPath = index < steps.length - 1 ? this.findNextPathFromNotSubStep(index) : undefined;
    return {previousStepPath, nextStepPath};
  }

  private findPreviousPathFromNotSubStep(currentIndex: number): string | undefined {
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (!this.steps[i].isSubStep) {
        return this.steps[i].path;
      }
    }
    return undefined;
  }

  private findNextPathFromNotSubStep(currentIndex: number): string | undefined {
    for (let i = currentIndex + 1; i < this.steps.length; i++) {
      if (!this.steps[i].isSubStep) {
        return this.steps[i].path;
      }
    }
    return undefined;
  }

}
