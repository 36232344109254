import {NxInput, NxMultiSelect, NxNumberInput, NxSelect} from '@nextbank/ui-components';
import clsx from 'clsx';
import {isNil} from 'lodash';
import React, {ChangeEvent, ReactElement, useContext, useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ReactComponent as NextLineIcon} from '../../../../assets/images/icon-next-line.svg';
import {CheckType} from '../../../../shared/model/check.model';
import {FieldType} from '../../../../shared/model/field.model';
import {getFieldTypeOptions, isDictionaryType} from '../../../../utils/custom-fields-utils';
import {getInputValue} from '../../../../utils/input-utils';
import {getFirstRoleId} from '../../../../utils/phases-utils';
import {toSelectOption} from '../../../../utils/select-options-utils';
import {TransHelper} from '../../../../utils/trans-helper';
import CustomDictionarySidePanel from '../../../shared/custom-dictionary/CustomDictionarySidePanel';
import RequiredButtonSwitch from '../../../shared/icon-button-switches/RequiredButtonSwitch';
import SelfCareButtonSwitch from '../../../shared/icon-button-switches/SelfCareButtonSwitch';
import {LoanConfigurationContext} from '../../loan-configuration/LoanConfiguration';
import {CheckProps, DataTestIds} from './check.model';
import styles from './Check.module.scss';
import {Tooltip} from '@material-ui/core';
import {ReactComponent as InfoIcon} from '../../../../assets/images/icon-info.svg';

const SYSTEM_ACTION_OWNER_VALUE = 'SYSTEM_ACTION_OWNER_VALUE';

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.CHECKS');

const NextLineDivider = ({tablet = false}: {tablet?: boolean}): ReactElement =>
  <div className={clsx(styles.nextLineMarker, {[styles.desktopOnly]: !tablet, [styles.tabletOnly]: tablet})}>
    <NextLineIcon />
  </div>;

const automaticCheckTypeOptions = [
  toSelectOption('ADDRESS_PROVINCE_CHECK', CheckType.PROVINCE),
  toSelectOption('AGE_CHECK', CheckType.AGE),
  toSelectOption('INCOME_CHECK', CheckType.INCOME),
  toSelectOption('RESIDENCY_CHECK', CheckType.RESIDENCY),
  toSelectOption('NATIONALITY_CHECK', CheckType.NATIONALITY),
  toSelectOption('OCCUPATION', CheckType.OCCUPATION)
];

const automaticChecksInitValues = {
  provinceIds: undefined,
  residenceIds: undefined,
  nationalityIds: undefined,
  occupationIds: undefined,
  minAge: undefined,
  maxAge: undefined,
  minIncome: undefined,
  maxIncome: undefined
};

const Check = (
  {
    check,
    setCheck,
    endButton,
    phaseId,
    dictionaryId,
    provinceOptions,
    residenceOptions,
    ageCheckTypeOptions,
    nationalityOptions,
    occupationOptions,
    handleAutomaticCheck = false
  }: CheckProps
): ReactElement => {

  const {t} = useTranslation();
  const {roles} = useContext(LoanConfigurationContext);
  const {automatic, proofType, required, selfCare, type} = check;

  const actionOwnerOptions = useMemo(() => ([
    ...(handleAutomaticCheck ? [toSelectOption('System', SYSTEM_ACTION_OWNER_VALUE, false)] : []),
    ...(roles ?? [])
  ]), [roles, handleAutomaticCheck]);

  const actionOwnerSelectValue = check.automatic ? [SYSTEM_ACTION_OWNER_VALUE] : check.roleIds.map(String);

  /**
   *  When action owner select changes it sets:
   *  - automatic (true if action owner set to System, false if not)
   *  - approvalId (emptied if action owner set to System, otherwise it's set to selected value)
   *  - type (Manual Check if action owner set to System, otherwise it's emptied)
   */
  const handleActionOwnerChange = (newActionOwnerValue: string[]): void => {
    // handles when SYSTEM role is currently selected and is switch to other roles
    if (newActionOwnerValue.length > 1 && newActionOwnerValue[0] === SYSTEM_ACTION_OWNER_VALUE) {
      newActionOwnerValue = newActionOwnerValue.slice(1);
    }

    // unselects other roles when System role is selected
    if (newActionOwnerValue.length > 1 && newActionOwnerValue.some(i => i === SYSTEM_ACTION_OWNER_VALUE)) {
      newActionOwnerValue = [SYSTEM_ACTION_OWNER_VALUE];
    }

    const automatic = handleAutomaticCheck && newActionOwnerValue[0] === SYSTEM_ACTION_OWNER_VALUE;

    setCheck({
      ...check,
      automatic,
      roleIds: !automatic || !newActionOwnerValue ? newActionOwnerValue.map(Number) : [],
      type: !automatic ? CheckType.MANUAL : '' as CheckType,
      proofType: !automatic ? check.proofType : undefined,
      ...automaticChecksInitValues
    });
  };

  const handleInputChange = (prop: 'name'): (event: ChangeEvent) =>
    void => (event: ChangeEvent): void => setCheck({...check, [prop]: getInputValue(event)});

  const handleSwitchChange = (prop: 'selfCare' | 'required'): void => setCheck({...check, [prop]: !check[prop]});

  const handleDictionaryChange = (dictionaryId: number): void => setCheck({...check, dictionaryId});

  const handleProofTypeChange = (proofType: string | null): void =>
    setCheck({...check, proofType: proofType as FieldType});

  const handleNumberInputChange = (prop: 'minAge' | 'maxAge' | 'minIncome' | 'maxIncome'): (value: number | null) =>
    void => (value: number | null): void => setCheck({...check, [prop]: value});

  const handleAgeCheckTypeChange = (ageCheckTypeId: number | null): void => setCheck({
    ...check,
    ageCheckTypeId: ageCheckTypeId ?? undefined
  });

  const ageCheckInputs = (
    <>
      <NextLineDivider tablet />
      <NxNumberInput className={styles.input}
                     data-testid={DataTestIds.AGE_INPUT}
                     label={<PrefixTrans>MIN_AGE</PrefixTrans>}
                     value={check.minAge}
                     onChange={handleNumberInputChange('minAge')}
                     min={0} />
      <NextLineDivider />
      <NxNumberInput className={styles.input}
                     data-testid={DataTestIds.AGE_INPUT}
                     label={<PrefixTrans>MAX_AGE</PrefixTrans>}
                     onChange={handleNumberInputChange('maxAge')}
                     value={check.maxAge}
                     max={199} />
      <NxSelect<number> className={styles.input}
                        data-testid={DataTestIds.AGE_INPUT}
                        label={<PrefixTrans>AGE_CHECK_TYPE</PrefixTrans>}
                        onChange={handleAgeCheckTypeChange}
                        options={ageCheckTypeOptions ?? []}
                        value={check.ageCheckTypeId} />
      {endButton}
    </>
  );

  const incomeCheckInputs = (
    <>
      <NextLineDivider tablet />
      <NxNumberInput className={styles.input}
                     data-testid={DataTestIds.INCOME_INPUT}
                     label={<PrefixTrans>MIN_INCOME</PrefixTrans>}
                     value={check.minIncome}
                     onChange={handleNumberInputChange('minIncome')} />
      <NextLineDivider />
      <NxNumberInput className={styles.input}
                     data-testid={DataTestIds.INCOME_INPUT}
                     label={<PrefixTrans>MAX_INCOME</PrefixTrans>}
                     value={check.maxIncome}
                     onChange={handleNumberInputChange('maxIncome')} />
      {endButton}
    </>
  );

  const handleAddressProvinceChange = (provinceIds: number[]): void => setCheck({...check, provinceIds});
  const handleResidenceChange = (residenceIds: number[]): void => setCheck({...check, residenceIds});
  const handleNationalityChange = (nationalityIds: number[]): void => setCheck({...check, nationalityIds});
  const handleOccupationChange = (occupationIds: number[]): void => setCheck({...check, occupationIds});

  const provinceCheckInput = (
    <>
      <NextLineDivider tablet />
      <NxMultiSelect<number> className={styles.input}
                             data-testid={DataTestIds.PROVINCE_SELECT}
                             label={<PrefixTrans>ADDRESS_PROVINCE</PrefixTrans>}
                             onChange={handleAddressProvinceChange}
                             options={provinceOptions ?? []}
                             value={!isNil(check.provinceIds) ? check.provinceIds : []} />
      <NextLineDivider />
      {endButton}
    </>
  );

  const residencyCheckInput = (
    <>
      <NextLineDivider tablet />
      <NxMultiSelect<number> className={styles.input}
                             data-testid={DataTestIds.RESIDENCE_SELECT}
                             label={<PrefixTrans>RESIDENCE</PrefixTrans>}
                             onChange={handleResidenceChange}
                             options={residenceOptions ?? []}
                             value={!isNil(check.residenceIds) ? check.residenceIds : []} />
      <NextLineDivider />
      {endButton}
    </>
  );

  const nationalityCheckInput = (
    <>
      <NextLineDivider tablet />
      <NxMultiSelect<number> className={styles.input}
                             data-testid={DataTestIds.NATIONALITY_SELECT}
                             label={<PrefixTrans>NATIONALITY</PrefixTrans>}
                             onChange={handleNationalityChange}
                             options={nationalityOptions ?? []}
                             value={!isNil(check.nationalityIds) ? check.nationalityIds : []} />
      <NextLineDivider />
      {endButton}
    </>
  );

  const occupationCheckInput = (
    <>
      <NextLineDivider tablet />
      <NxMultiSelect<number> className={styles.input}
                             data-testid={DataTestIds.OCCUPATION_SELECT}
                             label={<PrefixTrans>OCCUPATION</PrefixTrans>}
                             onChange={handleOccupationChange}
                             options={occupationOptions ?? []}
                             value={!isNil(check.occupationIds) ? check.occupationIds : []} />
      <NextLineDivider />
      {endButton}
    </>
  );

  const handleTypeChange = (type: string | null): void => {
    setCheck({
      ...check,
      type: type as CheckType,
      ...automaticChecksInitValues
    });
  };

  const manualCheckForm = <>
    <NxSelect className={styles.input}
              data-testid={DataTestIds.PROOF_TYPE_SELECT}
              label={<PrefixTrans>CRITERION_TYPE</PrefixTrans>}
              onChange={handleProofTypeChange}
              options={getFieldTypeOptions(t)}
              value={proofType ?? ''} />
    <NextLineDivider tablet />
    <div className={styles.buttons}>
      {
        isDictionaryType(proofType) &&
        <CustomDictionarySidePanel handleSave={handleDictionaryChange}
                                   dictionaryId={dictionaryId}
                                   phaseId={phaseId} />
      }
      <SelfCareButtonSwitch onClick={(): void => handleSwitchChange('selfCare')} value={selfCare} />
      <RequiredButtonSwitch onClick={(): void => handleSwitchChange('required')} value={required} />
      {endButton}
    </div>
  </>;

  const automaticCheckForm = <>
    <NxSelect className={styles.input}
              data-testid={DataTestIds.CRITERION_TYPE_SELECT}
              label={<PrefixTrans>CRITERION_TYPE</PrefixTrans>}
              onChange={handleTypeChange}
              value={type ?? ''}
              options={automaticCheckTypeOptions} />
    {
      type === CheckType.AGE && ageCheckInputs
    }
    {
      type === CheckType.INCOME && incomeCheckInputs
    }
    {
      type === CheckType.PROVINCE && provinceCheckInput
    }
    {
      type === CheckType.RESIDENCY && residencyCheckInput
    }
    {
      type === CheckType.NATIONALITY && nationalityCheckInput
    }
    {
      type === CheckType.OCCUPATION && occupationCheckInput
    }
  </>;

  return (
    <div className={styles.row}>
      <div className={clsx(styles.input, styles.inputSelectWrapper, {[styles.input_long]: false})}>
        <NxMultiSelect
          className={styles.inputSelect}
          label={<Trans>COMMON.FIELDS.ACTION_OWNER</Trans>}
          options={actionOwnerOptions}
          onChange={handleActionOwnerChange}
          value={actionOwnerSelectValue} />

        <Tooltip title={'Any of the selected action owners can input in the field.'}>
          <InfoIcon />
        </Tooltip>
      </div>

      <NxInput className={styles.input}
               label={<PrefixTrans>CRITERION_NAME</PrefixTrans>}
               onChange={handleInputChange('name')}
               value={check.name} />
      {automatic ? automaticCheckForm : manualCheckForm}
    </div>
  );
};

export default Check;
