import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as BranchIcon} from '../../../assets/images/channel-branch.svg';
import {ReactComponent as DesktopIcon} from '../../../assets/images/channel-desktop.svg';
import {ReactComponent as MobileIcon} from '../../../assets/images/channel-phone.svg';
import {Channel} from '../../../shared/model/process.model';
import styles from './ChannelIcons.module.scss';

export default function ChannelIcons({channels}: {channels: Channel[]}): React.ReactElement {
  const {t} = useTranslation();

  return (
    <div className={styles.channels}>
      {
        !channels
          ? '-'
          : channels.map((channel, index) => {
            switch (channel) {
              case Channel.OTC:
                return <BranchIcon key={index} className={styles.channel} title={t('COMMON.CHANNELS.BRANCH')} />;
              case Channel.MOBILE:
                return <MobileIcon key={index} className={styles.channel} title={t('COMMON.CHANNELS.MOBILE')} />;
              case Channel.WEB:
                return <DesktopIcon key={index} className={styles.channel} title={t('COMMON.CHANNELS.DESKTOP')} />;
              default:
                return <></>;
            }
          })
      }
    </div>
  );
}
