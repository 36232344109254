import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import clsx from 'clsx';
import isNil from 'lodash/isNil';
import React, {ReactElement, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {LoanSimulation} from '../../../../../../../shared/model/loan-simulation.model';
import {CashHelper} from '../../../../../../../utils/cash-helper';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import TableDataCell from '../../../../../../shared/table/cells/TableDataCell';
import TableHeaderCell from '../../../../../../shared/table/cells/TableHeaderCell';
import styles from './AmortizationSchedule.module.scss';

const SCHEDULE_TRANS_PREFIX = 'LOAN_APPLICATIONS.AMORTIZATION_SCHEDULE';
const PrefixTrans = TransHelper.getPrefixedTrans(SCHEDULE_TRANS_PREFIX);

const headers =
  ['NO', 'DUE_DATE', 'PRINCIPAL', 'INTEREST', 'CONTRACTUAL_SAVINGS', 'FEES', 'TOTAL', 'OUTSTANDING_BALANCE'];

interface Props {
  simulation: LoanSimulation;
}

const addCurrencyOrNull = (value?: number): ReactNode => isNil(value) ? '-' : CashHelper.addCurrencyMask(value);

export default function AmortizationSchedule({simulation}: Props): ReactElement {

  const {t} = useTranslation();
  const translatedHeaders = headers.map(header => t(`${SCHEDULE_TRANS_PREFIX}.${header}`));

  return (
    <div className={styles.schedule}>
      <div className={styles.scheduleHeader}>
        <PrefixTrans>HEADER</PrefixTrans>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {
                translatedHeaders.map((header, index) =>
                  // Styles apply dummy but works, only first two columns are not currency related.
                  <TableHeaderCell className={clsx(styles.headerCell, {[styles.currencyCell]: index > 1})}
                                   key={index}
                                   header={header} />
                )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              simulation.simulatedAmortizationSchedule.amortizations.map(
                (entry, index) => (
                  <TableRow key={index}>
                    {/* cell title set to appropriate table headers to provide basic tooltips */}
                    <TableDataCell title={translatedHeaders[0]} data={index + 1} />
                    <TableDataCell title={translatedHeaders[1]} data={entry.dueDate} />
                    <TableDataCell className={styles.currencyCell}
                                   title={translatedHeaders[2]}
                                   data={CashHelper.addCurrencyMask(entry.principalAmount)} />
                    <TableDataCell className={styles.currencyCell}
                                   title={translatedHeaders[3]}
                                   data={CashHelper.addCurrencyMask(entry.interestAmount)} />
                    <TableDataCell className={styles.currencyCell} title={translatedHeaders[4]}
                                   data={CashHelper.addCurrencyMask(entry.contractualSavings)} />
                    <TableDataCell className={styles.currencyCell}
                                   title={translatedHeaders[5]}
                                   data={CashHelper.addCurrencyMask(entry.customFeesAmount)} />
                    <TableDataCell className={styles.currencyCell}
                                   title={translatedHeaders[6]}
                                   data={CashHelper.addCurrencyMask(entry.total)} />
                    <TableDataCell className={styles.currencyCell} title={translatedHeaders[7]}
                                   data={CashHelper.addCurrencyMask(entry.outstandingBalance)} />
                  </TableRow>
                )
              )
            }
            <TableRow>
              <TableCell colSpan={2}>
                <PrefixTrans>TOTAL</PrefixTrans>
              </TableCell>
              {/* cell title set to appropriate table headers to provide basic tooltips */}
              <TableDataCell className={styles.currencyCell}
                             title={translatedHeaders[2]}
                             data={addCurrencyOrNull(simulation.principalAmount)} />
              <TableDataCell className={styles.currencyCell}
                             title={translatedHeaders[3]}
                             data={addCurrencyOrNull(simulation.interestAmount)} />
              <TableDataCell className={styles.currencyCell}
                             title={translatedHeaders[4]}
                             data={addCurrencyOrNull(simulation.totalContractualSavings)} />
              <TableDataCell className={styles.currencyCell}
                             title={translatedHeaders[5]}
                             data={addCurrencyOrNull(simulation.totalCustomFees)} />
              <TableDataCell className={styles.currencyCell}
                             title={translatedHeaders[6]}
                             data={addCurrencyOrNull(simulation.totalCost)} />
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

