export interface SimpleErrorBody {
  errorMessage: string;
}

export interface ErrorBody extends SimpleErrorBody {
  commandId: string;
  errorCode: string;
  errorId: string;
  errorMessage: string;
  errorTimestamp: string;
  path: string;
  requestUuid: string;
  systemVersion: string;
  username: string;
}

export const ABORT_ERROR_NAME = 'AbortError';
