import React, {useContext, useEffect, useState} from 'react';
import CustomerSelect from '../loan-application/steps/customer-data/customer-select/CustomerSelect';
import {ReactComponent as BackArrowIcon} from '../../../assets/images/icon-arrow-back.svg';
import {ApplicationRelationshipType} from '../loan-application/steps/customer-data/customer-data-form.model';
import {NxButton, NxButtonVariant, NxLoader} from '@nextbank/ui-components';
import {useGuardedHistory} from '../../router/GuardedHistory';
import {RoutePaths} from '../../../routes/routes.paths';
import {TransHelper} from '../../../utils/trans-helper';
import Instruction from '../../shared/instruction/Instruction';
import {LoanCreationType} from '../../../shared/model/creation-type.model';
import styles from './ConsolidateLoanApplication.module.scss';
import {PROCESSES_URL} from '../../../constants/api-urls';
import useGet from '../../../shared/hooks/use-get.hook';
import {Process, ProcessTO, ProductStatus, fromProcessTO} from '../../../shared/model/process.model';
import isNil from 'lodash/isNil';
import {AppSnackbarContext} from '../../shared/app-snackbar-provider/AppSnackbarProvider';
import LoanTypeSelectionTable from '../new-loan-application/loan-type-selection-table/LoanTypeSelectionTable';
import EmptyState from '../../shared/empty-state/EmptyState';
import {CustomerType} from '../../../shared/model/customer-type.model';
import ConsolidateCustomerDataReview from './ConsolidateCustomerDataReview';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS');
const statusPublishedParam = new URLSearchParams(`status=${ProductStatus.PUBLISHED}`);

enum ConsolidateLoanStep {
  CUSTOMER_SELECTION,
  CUSTOMER_DATA_REVIEW,
  LOAN_PRODUCT_SELECTION
}

const ConsolidateLoanApplication = (): React.ReactElement => {
  const history = useGuardedHistory();

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const fetchProcesses = useGet<ProcessTO[]>(PROCESSES_URL);
  const [isSelectingCustomer, setSelectingCustomer] = useState(false);
  const [processes, setProcesses] = useState<Process[]>();
  const [selectedApplicantSourceId, setSelectedApplicantSourceId] = useState<number | undefined>();
  const [selectedCustomerType, setSelectedCustomerType] = useState<CustomerType | undefined>(CustomerType.INDIVIDUAL);
  const [step, setStep] = useState<ConsolidateLoanStep>(ConsolidateLoanStep.CUSTOMER_SELECTION);

  const onSelectCustomer = (applicantSourceId?: number, customerType?: CustomerType): void => {
    setSelectedApplicantSourceId(applicantSourceId);
    setSelectedCustomerType(customerType);
    setStep(ConsolidateLoanStep.CUSTOMER_DATA_REVIEW);
  };

  const LoanProcesses = processes && processes.length > 0
    ? <LoanTypeSelectionTable processes={processes}
                              loanCreationType={LoanCreationType.CONSOLIDATION}
                              applicantSourceId={selectedApplicantSourceId}
                              customerType={selectedCustomerType} />
    : <EmptyState instruction={<PrefixTrans>NEW_LOAN_APPLICATION_EMPTY_STATE</PrefixTrans>} />;

  const onBack = (): void => {
    if (step === ConsolidateLoanStep.LOAN_PRODUCT_SELECTION) {
      setStep(ConsolidateLoanStep.CUSTOMER_DATA_REVIEW);
    } else if (step === ConsolidateLoanStep.CUSTOMER_DATA_REVIEW) {
      setStep(ConsolidateLoanStep.CUSTOMER_SELECTION);
    } else {
      history.push(RoutePaths.LOAN_APPLICATIONS);
    }
  };

  useEffect(() => {
    fetchProcesses(statusPublishedParam)
      .then(result => setProcesses(result.map(fromProcessTO)))
      .catch(error => showErrorMessage(error.message));
  }, []);

  return <div className={styles.container}>
    <h1 className={styles.header}>
      <NxButton variant={NxButtonVariant.OUTLINED}
                className={styles.backButton}
                onClick={onBack}>
      <span className={styles.backArrow}>
        <BackArrowIcon />
      </span>
      </NxButton>
      <PrefixTrans>CONSOLIDATE_LOAN_APPLICATION</PrefixTrans>
    </h1>

    {
      step === ConsolidateLoanStep.CUSTOMER_SELECTION && <>
        <Instruction>
          <PrefixTrans>SEARCH_CUSTOMER</PrefixTrans>
        </Instruction>
        <div className={styles.customerSelectContainer}>
          <CustomerSelect isSelecting={isSelectingCustomer}
                          setSelecting={setSelectingCustomer}
                          relationshipType={ApplicationRelationshipType.BORROWER}
                          loanCreationType={LoanCreationType.CONSOLIDATION}
                          onSelectCustomer={onSelectCustomer} />

        </div>
      </>
    }
    {selectedApplicantSourceId && step === ConsolidateLoanStep.CUSTOMER_DATA_REVIEW &&
      (
        <div className={styles.customerReviewContainer}>
          <Instruction>
            <PrefixTrans>CUSTOMER_DATA_REVIEW</PrefixTrans>
          </Instruction>
          <div className={styles.nextButtonWrapper}>
            <NxButton onClick={(): void => setStep(ConsolidateLoanStep.LOAN_PRODUCT_SELECTION)} type={NxButtonVariant.NEXT}> Next</NxButton>
          </div>
          <ConsolidateCustomerDataReview applicantSourceId={selectedApplicantSourceId} customerType={selectedCustomerType} />
        </div>
      )
    }
    {step === ConsolidateLoanStep.LOAN_PRODUCT_SELECTION && (isNil(processes) ? <NxLoader /> : LoanProcesses)}
  </div>;
};

export default ConsolidateLoanApplication;
