import React, {ReactElement, useContext} from 'react';
import {ConfiguredField} from '../../../../../../shared/model/field.model';
import {NxFormikNumberMaskInput} from '../../../../../shared/inputs/number-input/NxFormikNumberMaskInput';
import {StepContext} from '../loan-application-step/LoanApplicationStep';
import {ConfiguredFieldWrapper} from './ConfiguredFieldWrapper';
import {FieldDisablementReason} from './field-disablement-reason.model';

interface Props {
  field: ConfiguredField<number>;
  label: React.ReactNode;
  max?: number;
  min?: number;
  name: string;
  step?: number;
  disablementReason?: FieldDisablementReason;
  hint?: React.ReactNode;
}

export const ConfiguredFieldNumberInput = (
  {name, label, field, max, min, step, disablementReason, hint}: Props
): ReactElement => {

  const {isStepReadonly} = useContext(StepContext);

  return (
    <ConfiguredFieldWrapper enabled={field.enabled} disablementReason={disablementReason} hint={hint}>
      <NxFormikNumberMaskInput disabled={isStepReadonly || !!disablementReason}
                               required={field.required}
                               name={`${name}.value`}
                               label={label}
                               step={step}
                               max={max}
                               min={min} />
    </ConfiguredFieldWrapper>
  );
};
