import mapValues from 'lodash/mapValues';
import {LoanApplicationDataPhase} from '../../components/loan-configurations/loan-configuration/steps/loan-application-data/loan-application-data-phase.model';
import {
  LOAN_APPLICATION_FIELD_CODES,
  LoanApplicationDataFormFieldsConfiguration as FormFields,
  LoanApplicationDataFormFieldsConfiguration
} from '../../shared/model/step-forms/loan-application-data-form.model';
import {StaticFieldsHelper} from './static-fields-helper';

const {getInitField, getInitFormFields} = StaticFieldsHelper;

export const getLoanApplicationDataInitFields = (config: LoanApplicationDataPhase): LoanApplicationDataFormFieldsConfiguration => {

  const initFormFields = mapValues(LOAN_APPLICATION_FIELD_CODES, getInitField) as FormFields;

  const numberInputsKeys = [
    'principal',
    'totalAmortizationNumber',
    'overrideAmortizationAmount',
    'diminishingAmortizationNumber',
    'advanceInterestNo',
    'interestRate',
    'interestCalculationParameter',
    'uidAmortizationNumber'
  ];

  const {maxCoMakerNumber, minCoMakerNumber, maxCoBorrowerNumber, minCoBorrowerNumber} = config;

  return {
    ...getInitFormFields<FormFields>(initFormFields, config, numberInputsKeys),
    maxCoMakerNumber,
    minCoMakerNumber,
    maxCoBorrowerNumber,
    minCoBorrowerNumber
  };
};
