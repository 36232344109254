import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {PaymentIntervalOptionsProps} from '../../../../../../shared/hooks/use-payment-interval-options.hook';
import {SetFieldValue} from '../../../../../../shared/model/formik.model';
import {FixedPaymentOption} from '../../../../../../shared/model/payment.model';
import {StringHelper} from '../../../../../../utils/string-helper';
import {LoanApplicationContext} from '../../../LoanApplication';
import {FieldTrans} from '../../calculator/Calculator';
import {CalculatorFormFields} from '../../calculator/calculator.model';
import {ConfiguredFieldNumberInput as NumberInput} from '../configured-fields/ConfiguredFieldNumberInput';
import {ConfiguredFieldSelect as Select} from '../configured-fields/ConfiguredFieldSelect';
import {FieldDisablementReason} from '../configured-fields/field-disablement-reason.model';
import SimulationParameterHint from '../hint/SimulationParameterHint';

interface Props {
  values: CalculatorFormFields;
  fixedOptions?: FixedPaymentOption[];
  paymentIntervalOptionsProps: PaymentIntervalOptionsProps;
  setFieldValue: SetFieldValue;
}

export const PaymentConfiguredField = (
  {
    fixedOptions, paymentIntervalOptionsProps, values, setFieldValue
  }: Props
): ReactElement => {

  const {process} = useContext(LoanApplicationContext);
  const loanConfig = process.loanProduct;
  const {t} = useTranslation();

  const termHint = <SimulationParameterHint fieldName={'TOTAL_AMORTIZATION_AMOUNT'}
                                            min={StringHelper.addDays(loanConfig.minTerm, t)}
                                            max={StringHelper.addDays(loanConfig.maxTerm, t)}
  />;

  const {paymentIntervalOptions, getIsSinglePaymentInterval} = paymentIntervalOptionsProps;
  const isSinglePaymentInterval = getIsSinglePaymentInterval(values.paymentIntervalId.value);

  useEffect(() => {
    // In case of single payment interval the value is set to 1 permanently
    if (isSinglePaymentInterval) {
      setFieldValue('totalAmortizationNumber.value', 1);
    }
  }, [isSinglePaymentInterval]);

  const fixedPaymentOptions = fixedOptions?.map(
    ({paymentIntervalId, totalAmortizationNumber}) => ({
      paymentIntervalId: String(paymentIntervalId),
      totalAmortizationNumberOption: {
        label: String(totalAmortizationNumber),
        value: String(totalAmortizationNumber)
      }
    })
  );

  const {isPaymentDynamic, paymentIntervalId} = values;
  const filteredPaymentOptions = paymentIntervalOptions?.filter(option =>
    isPaymentDynamic || some(fixedPaymentOptions, ({paymentIntervalId}) => paymentIntervalId === option.value));

  const amortizationDisablementReason =
    isSinglePaymentInterval ? FieldDisablementReason.SINGLE_PAYMENT_INTERVAL_CHOSEN : undefined;

  const fixedAmortizationDisablementReason =
    isEmpty(paymentIntervalId.value) ? FieldDisablementReason.PAYMENT_INTERVAL_NOT_CHOSEN : amortizationDisablementReason;

  const fixedAmortizationOptions =
    fixedPaymentOptions?.filter((fixedPaymentOption) => Number(paymentIntervalId.value) === Number(fixedPaymentOption.paymentIntervalId))
      .map(option => option.totalAmortizationNumberOption);

  return (
    <>
      <Select name='paymentIntervalId'
              field={values.paymentIntervalId}
              options={filteredPaymentOptions ?? []}
              label={<FieldTrans>PAYMENT_INTERVAL</FieldTrans>}
              emptyOption={false} />
      {
        isPaymentDynamic ?
          <NumberInput name='totalAmortizationNumber'
                       disablementReason={amortizationDisablementReason}
                       field={values.totalAmortizationNumber}
                       label={<FieldTrans>TOTAL_AMORTIZATION_NUMBER</FieldTrans>}
                       min={0}
                       hint={termHint} />
          : <Select name='totalAmortizationNumber'
                    options={fixedAmortizationOptions ?? []}
                    disablementReason={fixedAmortizationDisablementReason}
                    field={values.totalAmortizationNumber}
                    label={<FieldTrans>TOTAL_AMORTIZATION_NUMBER</FieldTrans>}
                    emptyOption={false} />
      }
    </>
  );
};
