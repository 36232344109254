import isNil from 'lodash/isNil';
import some from 'lodash/some';
import {UserData} from '../../../../../../security-context.model';
import {isSysAdminOrManager} from '../../../../../../utils/permissions-utils';
import {FieldDisablementReason} from '../configured-fields/field-disablement-reason.model';

export const getDisablementReason =
  (permittedRolesIds?: number[], userData?: UserData): FieldDisablementReason | undefined => 
   isUserHasPermission(permittedRolesIds, userData) ? undefined : FieldDisablementReason.NO_PERMISSIONS_TO_EDIT;

export const isUserHasPermission = (permittedRolesIds?: number[], userData?: UserData): boolean => {
  if (isNil(userData)) {
    return false;
  }

  if (isSysAdminOrManager(userData)) {
    return true;
  }

  return some(permittedRolesIds, role => some(userData.roleIds, userRole => userRole === role));
}
