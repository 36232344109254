import {NxSelectOption, NxTabs} from '@nextbank/ui-components';
import {Formik, FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext} from 'react';
import * as Yup from 'yup';
import {PhaseName} from '../../../../../constants/api-urls';
import useFileUpload from '../../../../../shared/hooks/use-file-upload.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import usePut from '../../../../../shared/hooks/use-put.hook';
import {NxFile} from '../../../../../shared/model/nx-file.model';
import {PhaseValidationResult} from '../../../../../shared/model/phase-validation-result.model';
import {Channel} from '../../../../../shared/model/process.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {TransHelper} from '../../../../../utils/trans-helper';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import LoanConfigurationStep from '../../loan-configuration-step/LoanConfigurationStep';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {useSetConfigurationFormChanged} from '../hooks/use-set-configuration-form-changed.hook';
import Channels from './channels/Channels';
import Description from './description/Description';
import ExpiryDate from './expiry-date/ExpiryDate';
import FinancingCompany from './financing-company/FinancingCompany';
import {GeneralPhase} from './general-phase.model';
import {
  CATEGORY_ID,
  DAYS_AFTER_EXPIRE,
  FEATURES,
  GeneralFormFields,
  IMAGE,
  MOBILE_ENABLED,
  OTC_ENABLED,
  PROVIDER_EMAIL,
  PROVIDER_NAME,
  PROVIDER_PHONE_NUMBERS,
  PROVIDER_WEBSITE,
  SHOULD_EXPIRE,
  SUBMISSION_DATE_LIMIT,
  SUBMISSION_DATE_LIMIT_ENABLED,
  WEB_ENABLED
} from './general.model';
import useLogoImage from './use-logo-image';

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.GENERAL');

interface Props {
  config: GeneralPhase;
  categoryOptions: NxSelectOption<number>[];
}

export default function GeneralFrom({config, categoryOptions}: Props): React.ReactElement {

  const {uploadFile} = useFileUpload();
  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {process, configurationChanged, setConfigurationChanged} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.GENERAL);
  const updatePhase = usePut<void, GeneralPhase>(phaseUrl);
  const validatePhase = usePost<PhaseValidationResult, GeneralPhase>(`${phaseUrl}/validate`);
  const logoFile = useLogoImage(config?.image);

  const buildPhaseConfig = (formValues: GeneralFormFields): GeneralPhase => {
    const isSubmissionDateSet = formValues[SUBMISSION_DATE_LIMIT_ENABLED] && formValues[SUBMISSION_DATE_LIMIT];
    const isExpireDateSet = formValues[SHOULD_EXPIRE] && formValues[DAYS_AFTER_EXPIRE];

    const providerPhoneNumbers = formValues[PROVIDER_PHONE_NUMBERS]
      .filter(number => number !== '')
      .map(number => number.replace(/ /g, ''));

    const channels = [
      ...formValues[OTC_ENABLED] ? [Channel.OTC] : [],
      ...formValues[WEB_ENABLED] ? [Channel.WEB] : [],
      ...formValues[MOBILE_ENABLED] ? [Channel.MOBILE] : []
    ];

    return {
      ...config,
      channels,
      categoryId: formValues[CATEGORY_ID],
      features: formValues[FEATURES],
      expireAfter: isExpireDateSet ? formValues[DAYS_AFTER_EXPIRE] : undefined,
      submissionDateLimit: isSubmissionDateSet ? formValues[SUBMISSION_DATE_LIMIT] : undefined,
      providerPhoneNumbers: providerPhoneNumbers.length > 0 ? providerPhoneNumbers : undefined,
      providerName: formValues[PROVIDER_NAME] === '' ? undefined : formValues[PROVIDER_NAME],
      providerEmail: formValues[PROVIDER_EMAIL] === '' ? undefined : formValues[PROVIDER_EMAIL],
      providerWebsite: formValues[PROVIDER_WEBSITE] === '' ? undefined : formValues[PROVIDER_WEBSITE]
    };
  };

  const submit = async (formValues: GeneralFormFields): Promise<void> => {
    const newConfig = buildPhaseConfig(formValues);

    if (isNil(newConfig)) {
      return;
    }

    const image = formValues[IMAGE];
    return updatePhaseWithUpdatedIcon(newConfig, image)
      .catch(error => showErrorMessage(error.message));
  };

  const updatePhaseWithUpdatedIcon = async (newConfig: GeneralPhase, image?: NxFile): Promise<void> => {

    if (!image?.isChanged) {
      return await updatePhase(newConfig);
    }

    if (!image.file) {
      return await updatePhase({
        ...newConfig,
        image: undefined
      });
    }

    return uploadFile({file: image.file, publiclyAccessible: false})
      .then(imageId => updatePhase({...newConfig, image: imageId ? {id: imageId} : undefined}));
  };

  const GeneralStepForm = (
    {values, errors, touched, handleChange, handleBlur, handleSubmit, submitForm, setFieldValue, dirty}
      : FormikProps<GeneralFormFields>): ReactElement => {

    useSetConfigurationFormChanged(dirty);

    const validate = (): Promise<PhaseValidationResult> => validatePhase(buildPhaseConfig(values));

    const handleFileChange = (file?: File): Promise<void> | void => {
      setFieldValue('image', {file: file, isChanged: true} as NxFile);

      if (!configurationChanged) {
        setConfigurationChanged(true);
      }
    };

    const expiryDateTab = {
      key: 'EXPIRY_DATE',
      label: <PrefixTrans>EXPIRY_DATE</PrefixTrans>,
      tabPanel: <ExpiryDate values={values} handleChange={handleChange} setFieldValue={setFieldValue} />
    };

    const channelsTab = {
      key: 'CHANNELS',
      label: <PrefixTrans>CHANNELS</PrefixTrans>,
      tabPanel: <Channels values={values} handleChange={handleChange} />
    };

    const descriptionTab = {
      key: 'DESCRIPTION',
      label: <PrefixTrans>DESCRIPTION</PrefixTrans>,
      tabPanel: <Description values={values}
                             logoFile={logoFile}
                             setFieldValue={setFieldValue}
                             onFileChanged={handleFileChange}
                             categoryOptions={categoryOptions} />
    };

    const financingCompanyTab = {
      key: 'FINANCING_COMPANY',
      label: <PrefixTrans>FINANCING_COMPANY</PrefixTrans>,
      tabPanel: <FinancingCompany values={values}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors} />
    };

    return (
      <LoanConfigurationStep handleSave={submitForm} validationFunction={validate}>
        <form onSubmit={handleSubmit}>
          <NxTabs tabs={[expiryDateTab, channelsTab, descriptionTab, financingCompanyTab]} />
        </form>
      </LoanConfigurationStep>
    );
  };

  const generateInitialValues = (): GeneralFormFields => {
    if (isNil(config)) {
      throw new Error('Cannot generate General form initial values without fetched config');
    }

    const isChannelEnabled = (channelToCheck: Channel): boolean =>
      !isNil(config.channels) && !!config.channels.find(channel => channel === channelToCheck);

    return {
      [SHOULD_EXPIRE]: !!config.expireAfter,
      [DAYS_AFTER_EXPIRE]: config.expireAfter,
      [SUBMISSION_DATE_LIMIT_ENABLED]: !!config.submissionDateLimit,
      [SUBMISSION_DATE_LIMIT]: config.submissionDateLimit,
      [OTC_ENABLED]: isChannelEnabled(Channel.OTC),
      [MOBILE_ENABLED]: isChannelEnabled(Channel.MOBILE),
      [WEB_ENABLED]: isChannelEnabled(Channel.WEB),
      [CATEGORY_ID]: config.categoryId,
      [FEATURES]: config.features ?? '',
      [PROVIDER_EMAIL]: config.providerEmail ?? '',
      [PROVIDER_NAME]: config.providerName ?? '',
      [PROVIDER_PHONE_NUMBERS]: isNil(config.providerPhoneNumbers) ? [''] : config.providerPhoneNumbers,
      [PROVIDER_WEBSITE]: config.providerWebsite ?? '',
      [IMAGE]: logoFile
    };
  };

  const validationSchema = Yup.object().shape({
    [PROVIDER_EMAIL]: Yup.string().email('Invalid email')
  });

  return (
    <Formik<GeneralFormFields>
      onSubmit={submit}
      initialValues={generateInitialValues()}
      validationSchema={validationSchema}>
      {GeneralStepForm}
    </Formik>
  );
}
