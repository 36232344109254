import React from 'react';
import {Trans} from 'react-i18next';
import Instruction from '../../../shared/instruction/Instruction';
import styles from './LoanConfigurationStepWrapper.module.scss';
import {useLoanConfigurationSave} from './use-loan-configuration-save.hook';

interface Props {
  children: React.ReactNode;
  label: React.ReactNode;
  handleSave?: () => Promise<void>;
  stepNavigation?: React.ReactNode;
  stepInstruction?: React.ReactNode;
  hideStepInstruction?: boolean;
}

const LoanConfigurationStepWrapper = (
    {children, stepNavigation, label, stepInstruction, handleSave, hideStepInstruction = false}: Props
  ): React.ReactElement => {

  useLoanConfigurationSave(handleSave);

  return (
    <>
      <div className={styles.stepHeading}>
        <h1 className={styles.stepName}>{label}</h1>
        {stepNavigation}
      </div>
      <div>
        {
          !hideStepInstruction &&
            <Instruction>
              {
                stepInstruction ?? <Trans>LOAN_CONFIGURATIONS.STEPS_INSTRUCTION</Trans>
              }
            </Instruction>
        }
        {children}
      </div>
    </>
  );
};

export default LoanConfigurationStepWrapper;
