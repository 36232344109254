import React from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {SetFieldValue} from '../../../shared/model/formik.model';

interface NxWysiwygProps {
  name: string;
  value: string;
  setFieldValue: SetFieldValue;
}

const NxWysiwyg = ({name, setFieldValue, value}: NxWysiwygProps): React.ReactElement => {
  const config = {
    toolbar:
      [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'undo', 'redo' ]
  };

  return (
    <CKEditor
      config={config}
      editor={ClassicEditor}
      data={value}
      name={name}
      onChange={(event, editor): void => setFieldValue(name, editor.getData())}
    />
  );
};

export default NxWysiwyg;