import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import useCustomerDataRequirements, {
  CustomerDataRequirementEntries
} from '../../../../../../shared/hooks/use-customer-data-requirements.hook';
import {Dictionary} from '../../../../../../shared/model/dictionary.model';
import {
  CustomerDataPhase
} from '../../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {Applicant, CustomerDataFormFields} from '../customer-data-form.model';
import useApplicantsData from './use-applicants-data-start-data.hook';
import useCustomFieldDictionaries from './use-custom-field-dictionaries-query.hook';
import useCustomerDataApplicationDictionaryEntries, {
  CustomerDataApplicationDictionaries
} from './use-customer-data-application-dictionaries.hook';

export interface CustomerPhaseData {
  borrowerData?: CustomerDataFormFields;
  coMakersData?: CustomerDataFormFields[];
  coBorrowersData?: CustomerDataFormFields[];
  requirementEntries: CustomerDataRequirementEntries;
  dictionaryEntries: CustomerDataApplicationDictionaries;
  customDictionaries?: Dictionary[];
}

interface CustomerDataState {
  customerDataLoaded: boolean;
  customerData?: CustomerPhaseData;
}

export default function useCustomerData(
  config?: CustomerDataPhase,
  borrower?: Applicant<number>,
  coMakers?: Applicant<number>[],
  coBorrowers?: Applicant<number>[]
): CustomerDataState {
  const originalBorrowers = isNil(borrower) ? [] : [borrower];
  const {applicantsData: borrowersData, applicantsDataLoaded: borrowerDataLoaded} =
    useApplicantsData(originalBorrowers, config);
  const {applicantsData: coMakersData, applicantsDataLoaded: coMakersLoaded} =
    useApplicantsData(coMakers, config);
  const {applicantsData: coBorrowersData, applicantsDataLoaded: coBorrowersLoaded} =
    useApplicantsData(coBorrowers, config);

  const {customDictionaries, areCustomDictionariesLoaded} = useCustomFieldDictionaries(config?.dynamicFieldGroups);
  const {entries, areEntriesLoaded} = useCustomerDataApplicationDictionaryEntries(config);
  const {requirementEntries, areRequirementEntriesLoaded} = useCustomerDataRequirements(config);

  const initialDataLoaded = areEntriesLoaded && areRequirementEntriesLoaded && areCustomDictionariesLoaded;
  const customerDataLoaded = !isNil(config) && !isNil(requirementEntries) && borrowerDataLoaded && coMakersLoaded && coBorrowersLoaded && !!initialDataLoaded;

  return {
    customerDataLoaded,
    customerData: {
      borrowerData: !isNil(borrowersData) && !isEmpty(borrowersData) ? borrowersData[0] : undefined,
      coMakersData,
      coBorrowersData,
      customDictionaries,
      dictionaryEntries: entries,
      requirementEntries
    }
  };
}
