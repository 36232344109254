import {Dialog} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import styles from './NxPopup.module.scss';

interface PopupProps {
  children: React.ReactNode;
  description?: React.ReactNode;
  header: React.ReactNode;
  open: boolean;
  footerClassName?: string;
}

const NxPopup = ({children, description, header, open, footerClassName}: PopupProps): React.ReactElement => {
  return (
    <Dialog open={open}>
      <div className={styles.popup}>
        <div className={styles.header}>{header}</div>
        {description && <div className={styles.description}>{description}</div>}
        <div className={clsx(styles.footer, footerClassName)}>{children}</div>
      </div>
    </Dialog>
  );
};

export default NxPopup;
