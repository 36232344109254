import React from 'react';
import {DictionaryRequirement} from '../../../../../../shared/model/dictionary-requirement.model';
import {DictionaryEntry} from '../../../../../../shared/model/dictionary.model';
import {DictionaryHelper} from '../../../../../../utils/dictionary-helper';
import DictionaryEntryRow from './DictionaryEntryRow';

interface Props {
  entries?: DictionaryEntry[];
  requirement: DictionaryRequirement;
  requirementKey: string;
}

export default function Requirements({entries, requirement, requirementKey}: Props): React.ReactElement {

  const {getEntryRequirementLabel} = DictionaryHelper;

  return (
    <>
      {
        requirement.entryRequirements.map((requirement, index) => (
            <DictionaryEntryRow key={requirement.id}
                                enabled={requirement.enabled}
                                checkboxName={`${requirementKey}.entryRequirements.${index}.enabled`}
                                radioName={`${requirementKey}.entryRequirements.${index}.requirement`}
                                label={getEntryRequirementLabel(entries, requirement)} />
          )
        )
      }
    </>
  );
}
