import React, {ReactElement} from 'react';
import {TableFooter, TablePagination} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import styles from './TablePaginationFooter.module.scss';

export const TABLE_PAGINATION_FOOTER_TEST_ID = 'TABLE_PAGINATION_FOOTER';

interface Props {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (pageNo: number) => Promise<void>;
}

const TablePaginationFooter = ({count, page, rowsPerPage = 10, onPageChange}: Props): ReactElement => {

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): Promise<void> =>
    onPageChange(newPage);

  return (
    <>
      {
        count > rowsPerPage && (
          <TableFooter>
            <TableRow>
              <TablePagination page={page}
                               count={count}
                               rowsPerPageOptions={[]} // disable rows per page change
                               rowsPerPage={rowsPerPage}
                               onChangePage={handleChangePage}
                               classes={{root: styles.pagination}}
                               data-testid={TABLE_PAGINATION_FOOTER_TEST_ID} />
            </TableRow>
          </TableFooter>
        )
      }
    </>
  );
};

export default TablePaginationFooter;
