import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import {useMemo} from 'react';
import {CustomerDataPhase} from '../../components/loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {DictionaryHelper} from '../../utils/dictionary-helper';
import {areNotNil} from '../../utils/empty-checks-utils';
import {StaticFieldsHelper} from '../../utils/step-form-utils/static-fields-helper';
import {DictionaryEntry} from '../model/dictionary.model';
import useDictionariesQuery from './use-dictionaries-query.hook';

export interface IndividualDataDictionariesState {
  areIndividualDataDictionaryEntriesLoaded?: boolean;
  individualDataDictionaryEntries: IndividualDataDictionaries;
}

export interface IndividualDataDictionaries {
  title?: DictionaryEntry[];
  gender?: DictionaryEntry[];
  residence?: DictionaryEntry[];
  citizenship?: DictionaryEntry[];
  riskLevel?: DictionaryEntry[];
  civilStatus?: DictionaryEntry[];
  dosriType?: DictionaryEntry[];
  birthCountry?: DictionaryEntry[];
}

export interface CustomerDataDictionaryIds {
  title?: number;
  gender?: number;
  residence?: number;
  citizenship?: number;
  riskLevel?: number;
  civilStatus?: number;
  dosriType?: number;
  birthCountry?: number;
}

const getDictionaryIds = (config: CustomerDataPhase): CustomerDataDictionaryIds =>
  mapValues({
    title: 'TITLE',
    gender: 'GENDER',
    residence: 'RESIDENCE',
    citizenship: 'CITIZENSHIP',
    riskLevel: 'RISK_LEVEL',
    civilStatus: 'CIVIL_STATUS',
    dosriType: 'DOSRI_TYPE',
    birthCountry: 'BIRTH_COUNTRY',
    occupation: 'OCCUPATION'
  }, code => StaticFieldsHelper.getFieldDictionaryId(config, code));

export default function useIndividualDataDictionaries(config?: CustomerDataPhase): IndividualDataDictionariesState {

  const dictionaryIds: CustomerDataDictionaryIds = useMemo(
    () => config ? getDictionaryIds(config) : {},
    [config]
  );

  const {dictionaries,loaded} = useDictionariesQuery(dictionaryIds);
  const individualDataEntries = mapValues(dictionaryIds, id => DictionaryHelper.getEntries(dictionaries, id));

  return {
    individualDataDictionaryEntries: {
      ...individualDataEntries,
      citizenship: DictionaryHelper.movePhilippinesDictionaryToTop(individualDataEntries.citizenship),
      residence: DictionaryHelper.movePhilippinesDictionaryToTop(individualDataEntries.residence),
      birthCountry: DictionaryHelper.movePhilippinesDictionaryToTop(individualDataEntries.birthCountry)
    },
    areIndividualDataDictionaryEntriesLoaded: loaded && areNotNil(values(individualDataEntries))
  };
}

