import React, {ReactElement} from 'react';
import {ReactComponent as BanIcon} from '../../../../assets/images/icon-ban.svg';
import usePost from '../../../../shared/hooks/use-post.hook';
import {ApiHelper} from '../../../../utils/api-helper';
import {ApplicationStatus} from '../loan-application.model';
import LoanStatusChangeButton from './status-change-button/LoanStatusChangeButton';
import {StatusChangePayload} from './status-change-button/status-change-payload.model';

interface Props {
  className?: string;
  productName: string;
  applicationId: number;
}

export default function DeferLoanButton({className, productName, applicationId}: Props): ReactElement {

  const defer = usePost<void, StatusChangePayload>(`${ApiHelper.getApplicationUrl(applicationId)}/change-status`);

  return <LoanStatusChangeButton className={className}
                                 status={ApplicationStatus.DEFERRED}
                                 productName={productName}
                                 changeStatus={defer}
                                 icon={<BanIcon />}
                                 translationPath={'TOP_BAR.DEFER'} />;
}
