import {Menu} from '@material-ui/core';
import {NxIconButton} from '@nextbank/ui-components';
import clsx from 'clsx';
import React, {ReactElement, ReactNode} from 'react';
import {ReactComponent as MoreIcon} from '../../../../assets/images/icon-more.svg';
import styles from './TableMenu.module.scss';

interface Props {
  children: ReactNode;
  anchorElement: HTMLElement | null;
  setAnchorElement: (anchorElement: HTMLElement | null) => void;
  menuId: string;
}

const TableMenu = React.forwardRef(({children, anchorElement, setAnchorElement, menuId}: Props, ref): ReactElement => {

  const open = Boolean(anchorElement);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => setAnchorElement(event.currentTarget);
  const StyledMoreIcon = <MoreIcon className={clsx(styles.moreIcon, {[styles.moreIcon_active]: open})} />;

  return (
    <>
      <NxIconButton ariaLabel='more' ariaControlsId={menuId} icon={StyledMoreIcon} onClick={handleClick} />
      <Menu ref={ref}
            id={menuId}
            open={open}
            keepMounted
            elevation={6}
            anchorEl={anchorElement}
            getContentAnchorEl={null}
            classes={{paper: styles.menu, list: styles.list}}
            onClose={(): void => setAnchorElement(null)}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
        {children}
      </Menu>
    </>
  );
});

TableMenu.displayName = 'TableMenu';

export default TableMenu;
