import React, {ReactElement} from 'react';
import LoanOriginationSystemLogo from '../../../assets/images/nextbank-loan-origination-logo.svg';
import {RoutePaths} from '../../../routes/routes.paths';
import {GuardedLink} from '../../router/link/GuardedLink';
import TopBarTabs from './top-bar-tabs/TopBarTabs';
import styles from './TopBar.module.scss';
import UserDetails from './user-details/UserDetails';

const TopBar = (): ReactElement => (
  <header className={styles.header}>
    <div className={styles.items}>
      <div className={styles.item}>
        <GuardedLink to={RoutePaths.ROOT} className={styles.logo}>
          <img src={LoanOriginationSystemLogo} alt='Nextbank' />
        </GuardedLink>
      </div>
      <div className={styles.item}>
        <TopBarTabs />
      </div>
      <div className={styles.item}>
        <UserDetails />
      </div>
    </div>
  </header>
);

export default TopBar;
