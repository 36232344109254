import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {Table, TableContainer} from '@material-ui/core';
import TableHeader from '../../../../../shared/table/header/TableHeaders';
import ConsolidatedLoansTableBody from './table/ConsolidatedLoansTableBody';
import {getConsolidatedLoansTableHeaders} from '../../../../../../utils/table-helper';
import {NxButton, NxButtonVariant, NxLoader} from '@nextbank/ui-components';
import {Trans} from 'react-i18next';
import {CBSLoanDetails} from '../../../../../../shared/model/cbs-loans';
import {ConsolidatedLoansContext} from './consolidated-loans-context';
import {SetFieldValue} from '../../../../../../shared/model/formik.model';
import AddConsolidatedLoansDialog from './dialog/AddConsolidatedLoansDialog';
import isEmpty from 'lodash/isEmpty';
import useGet from '../../../../../../shared/hooks/use-get.hook';
import {CBS_LOANS} from '../../../../../../constants/api-urls';
import {ApiHelper} from '../../../../../../utils/api-helper';
import {StepContext} from '../loan-application-step/LoanApplicationStep';
import styles from './ConsolidatedLoans.module.scss';

interface Props {
  remadeFromLoanIds?: number[];
  setFieldValue: SetFieldValue;
}

const HEADERS_TRANS_PREFIX = 'LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.CONSOLIDATED_LOANS';

const ConsolidatedLoans = ({remadeFromLoanIds, setFieldValue}: Props): ReactElement => {

  const {isStepReadonly} = useContext(StepContext);
  const [consolidatedLoans, setConsolidatedLoans] = useState<CBSLoanDetails[]>([]);
  const columnHeaders = getConsolidatedLoansTableHeaders();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getCbsLoans = useGet<CBSLoanDetails[]>(CBS_LOANS);

  const handleClose = (): void => setOpenDialog(false);
  const getLoanIds = (cbsLoans: CBSLoanDetails[]): (number | undefined)[] => cbsLoans.map(loan => loan.loanId);

  const setFieldAndState = (cbsLoans: CBSLoanDetails[]): void => {
    setConsolidatedLoans(cbsLoans);
    setFieldValue('remadeFromLoanIds', getLoanIds(cbsLoans));
  };

  const addLoan = (loanDetail: CBSLoanDetails): void => {
    setFieldAndState([...consolidatedLoans, loanDetail]);
  };

  const removeLoan = (loanDetail: CBSLoanDetails): void => {
    setFieldAndState(consolidatedLoans.filter(loan => loan.loanId !== loanDetail.loanId));
  };

  const fetchCbsLoans = (): void => {
    if (!isEmpty(remadeFromLoanIds)) {
      setIsLoading(true);
      getCbsLoans(ApiHelper.constructUrlParams({loanIds: remadeFromLoanIds}))
        .then(result => setConsolidatedLoans(result))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchCbsLoans();
  }, []);

  return (
    <ConsolidatedLoansContext.Provider value={{
      consolidatedLoans: consolidatedLoans,
      addLoan: addLoan,
      removeLoan: removeLoan
    }}>
      <div className={styles.container}>
        <NxButton disabled={isStepReadonly} variant={NxButtonVariant.ADD} onClick={(): void => setOpenDialog(true)}>
          <Trans>{`${HEADERS_TRANS_PREFIX}.ADD_NEW`}</Trans>
        </NxButton>
      </div>
      <TableContainer>
        {isLoading ? <NxLoader /> :
          <>
            <Table>
              <TableHeader translationPath={HEADERS_TRANS_PREFIX} headers={columnHeaders} />
              <ConsolidatedLoansTableBody cbsLoans={consolidatedLoans} refetchCbsLoans={fetchCbsLoans} />
            </Table>
          </>
        }
      </TableContainer>
      <AddConsolidatedLoansDialog isVisible={openDialog} close={handleClose} />
    </ConsolidatedLoansContext.Provider>
  );
};

export default ConsolidatedLoans;
