import React from 'react';
import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import styles from './ResetSimulationParametersButton.module.scss';

const CalculatorTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CALCULATOR');

interface Props {
  onSubmit: () => void;
  isSubmitting: boolean;
}

export default function ResetSimulationParametersButton({onSubmit, isSubmitting}: Props): React.ReactElement {

  return (
    <>
        <NxButton onClick={onSubmit} className={styles.simulateButton} loaded={!isSubmitting} disabled={isSubmitting} variant={NxButtonVariant.OUTLINED}>
          <CalculatorTrans>RESET</CalculatorTrans>
        </NxButton>
    </>
  );
}
