import {useField} from 'formik';
import React, {ReactElement, useContext, useMemo, useState} from 'react';
import {DictionaryRequirement} from '../../../../../../shared/model/dictionary-requirement.model';
import {DictionaryHelper} from '../../../../../../utils/dictionary-helper';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {FieldDisablementReason} from '../../shared/configured-fields/field-disablement-reason.model';
import {StepContext} from '../../shared/loan-application-step/LoanApplicationStep';
import {ApplicantContext} from '../shared/ApplicantContext';
import {EntryRequirementHelper} from '../shared/entry-requirement-helper';
import OptionalRequirementsSelect from '../shared/OptionalRequirementsSelect';
import {Address} from './address.model';
import styles from './Addresses.module.scss';
import AddressForm, {AddressFormEntries} from './AddressForm';

interface Props {
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.individualData.firstName.
   */
  formikFieldPath: string;
  entries: AddressFormEntries;
  requirement?: DictionaryRequirement;
  addresses: Address[];
}

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CUSTOMER_DATA.ADDRESSES');
const {getEnabledEntryRequirements} = DictionaryHelper;
const {getOptionalRequirementsOptions, getInitialOptionalRequirements} = EntryRequirementHelper;

export default function Addresses({formikFieldPath, addresses, entries, requirement}: Props): ReactElement {

  const {isCoreCustomer} = useContext(ApplicantContext);
  const {isStepReadonly} = useContext(StepContext);
  const disablementReason = isCoreCustomer ? FieldDisablementReason.VALUE_MANAGED_BY_CBS : undefined;
  const disabled = isStepReadonly || !!disablementReason;
  const enabledRequirements = getEnabledEntryRequirements(requirement);
  const fieldPath = `${formikFieldPath}.addresses`;

  const [selectedOptionalRequirements, setSelectedOptionalRequirements] =
    useState<number[]>(getInitialOptionalRequirements(addresses, enabledRequirements));

  const optionalRequirementsOptions = useMemo(() =>
    getOptionalRequirementsOptions(enabledRequirements, entries.addressRequirementEntries), [enabledRequirements]);

  const [, , helpers] = useField(fieldPath);

  const updateRequirementsSelectValue = (wasRequirementAdded: boolean, typeId: number): void =>
    helpers.setValue(wasRequirementAdded ? [...addresses, {typeId}] : filterAddresses(typeId));

  const removeAddress = (typeId: number): void => {

    const filteredRequirements = selectedOptionalRequirements.filter(requirement => requirement !== typeId);

    setSelectedOptionalRequirements(filteredRequirements);
    helpers.setValue(filterAddresses(typeId));
  };

  const filterAddresses = (typeId: number): Address[] => addresses.filter(source => source.typeId !== typeId);

  return (
    <>
      <OptionalRequirementsSelect disabled={disabled}
                                  disablementReason={disablementReason}
                                  options={optionalRequirementsOptions}
                                  updateSelectValue={updateRequirementsSelectValue}
                                  selectedRequirements={selectedOptionalRequirements}
                                  setSelectedRequirements={setSelectedOptionalRequirements}
                                  label={<PrefixTrans>OPTIONAL_REQUIREMENTS</PrefixTrans>} />
      <div className={styles.row}>
        {
          addresses.map((address, index) => {

            const entry = enabledRequirements.find(entry => entry.dictionaryEntryId === address.typeId);

            if (!entry) {
              throw Error('Requirement type for existing address not found');
            }

            return (
              <AddressForm key={index}
                           formPath={`${fieldPath}[${index}]`}
                           entry={entry}
                           entries={entries}
                           disabled={disabled}
                           deleteFunction={removeAddress}
                           disablementReason={disablementReason} />
            );
          })
        }
      </div>
    </>
  );
}
