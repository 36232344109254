import {isNil} from 'lodash';
import {useEffect} from 'react';
import {Phase} from '../../../../../shared/model/phase.model';
import {DraggableCheckGroup, toDraggableCheckGroup} from '../../../shared/draggable-checks/draggable-checks.model';

export function useSetCheckGroupsFromConfig(config: Phase | undefined,
                                            setCheckGroups: (checks: DraggableCheckGroup[]) => void): void {
  useEffect(() => {
    if (isNil(config)) {
      return;
    }

    const checkGroups = config.checkGroups.map(checkGroup => toDraggableCheckGroup(checkGroup));
    setCheckGroups(checkGroups);
  }, [config]);
}
