import dayjs, {Dayjs} from 'dayjs';

export interface SystemStatusTO<T_DATE = string> {
  appVersion: string;
  buildTime: string;
  gitCommit: string;
  systemTimeZone: string;
  dbServerTime: T_DATE;
  webServerTime: T_DATE;
}

export type SystemStatus = SystemStatusTO<Dayjs>

export const fromSystemStatusTO = (statusTO: SystemStatusTO): SystemStatus => ({
  ...statusTO,
  dbServerTime: dayjs(statusTO.dbServerTime),
  webServerTime: dayjs(statusTO.webServerTime)
});
