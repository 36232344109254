import {TableHead, TableRow} from '@material-ui/core';
import React from 'react';
import {CustomerType} from '../../../../../../../../shared/model/customer-type.model';
import TableHeaderCell from '../../../../../../../shared/table/cells/TableHeaderCell';
import {PrefixTrans} from '../../../CustomerData';
import styles from './CustomersTableHead.module.scss';

interface Props {
  customerType: CustomerType;
}

const CustomersTableHead = ({customerType}: Props): React.ReactElement => (
  <TableHead>
    <TableRow>
      <TableHeaderCell className={styles.header} header={<PrefixTrans>CUSTOMER_NUMBER</PrefixTrans>} />
      <TableHeaderCell className={styles.header} header={<PrefixTrans>FULL_NAME</PrefixTrans>} />
      <TableHeaderCell className={styles.header} header={
        customerType === CustomerType.CORPORATE
          ? <PrefixTrans>DATE_OF_BIRTH</PrefixTrans>
          : <PrefixTrans>REGISTRATION_DATE</PrefixTrans>
      } />
      <TableHeaderCell className={styles.header} />
    </TableRow>
  </TableHead>
);

export default CustomersTableHead;
