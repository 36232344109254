import React, {ReactElement, useContext} from 'react';
import {NumberedApprover} from '../../../../../../../shared/model/phase.model';
import {ApprovalExecution} from '../../../../loan-application.model';
import {LoanApplicationContext} from '../../../../LoanApplication';
import {ApprovalStatus} from './approval-status/ApprovalStatus';
import {getNamesChain, PrefixTrans} from './approval-utils';
import styles from './ApproverDetail.module.scss';

interface Props {
  approver: NumberedApprover;
  approvalExecutions: ApprovalExecution[];
}

export const ApproverDetail = ({approver, approvalExecutions}: Props): ReactElement => {

  const {roles, branches} = useContext(LoanApplicationContext);
  const approvalExecution = approvalExecutions.find(({approverId}) => approverId === approver.id);
  const {status, comment} = approvalExecution ?? {};
  const approverRoles = roles?.filter(({id}) => approver.roleIds.includes(id)) ?? [];
  const approverBranches = branches?.filter(({id}) => approver.branchIds.includes(id)) ?? [];

  return (
    <div className={styles.approver}>
      <div className={styles.number}>#{approver.ordinalNumber}</div>
      <div className={styles.column}>
        <ApprovalStatus status={status} />
        <div className={styles.roles}>
          <PrefixTrans>ROLES</PrefixTrans>: {getNamesChain(approverRoles)}
        </div>
        <div className={styles.branches}>
          <PrefixTrans>FROM_BRANCHES</PrefixTrans>: {getNamesChain(approverBranches)}
        </div>
        {
          comment &&
            <div className={styles.comment}>
              <PrefixTrans>COMMENT</PrefixTrans>: {comment}
            </div>
        }
      </div>
    </div>
  );
};
