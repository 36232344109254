import {Table, TableContainer} from '@material-ui/core';
import {NxLoader} from '@nextbank/ui-components';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, {ReactElement} from 'react';
import {CustomerType} from '../../../../../../../shared/model/customer-type.model';
import TablePaginationFooter from '../../../../../../shared/table/pagination-footer/TablePaginationFooter';
import {CustomersByPage, DataTestIds} from '../customer-select.model';
import {CUSTOMER_SEARCH_PAGE_RECORDS_LIMIT} from '../CustomerSelect';
import CustomersTableBody from './customers-table-body/CustomersTableBody';
import CustomersTableHead from './customers-table-head/CustomersTableHead';
import styles from './CustomersTable.module.scss';
import NewCustomerSelect from './new-customer-select/NewCustomerSelect';
import NoCustomerFoundState from './NoCustomerFoundState';

interface Props {
  customerType: CustomerType;
  customers: CustomersByPage | undefined;
  searchCustomer: (pageNo: number) => Promise<void>;
  selectCustomer: (id?: number) => void;
  loading: boolean;
  showCreateNewCustomer?: boolean;
}

const CustomersTable = ({customerType, customers, searchCustomer, selectCustomer, loading, showCreateNewCustomer = true}: Props): ReactElement => {

  const resultsFound = !isEmpty(customers?.result);

  return (
    <>
      {
        !isNil(customers) &&
        (
          <>
            {
              resultsFound && (
                <TableContainer className={styles.componentWrapper} data-testid={DataTestIds.CUSTOMERS_TABLE}>
                  {
                    loading && (
                      <div className={styles.loaderWrapper} data-testid={DataTestIds.TABLE_LOADER}>
                        <NxLoader />
                      </div>
                    )
                  }
                  <Table className={styles.tableWrapper}>
                    <CustomersTableHead customerType={customerType} />
                    <CustomersTableBody customerType={customerType}
                                        customersData={customers.result}
                                        selectCustomer={selectCustomer} />
                    <TablePaginationFooter page={customers.pageNo}
                                           count={customers.totalCount}
                                           onPageChange={searchCustomer}
                                           rowsPerPage={CUSTOMER_SEARCH_PAGE_RECORDS_LIMIT} />
                  </Table>
                </TableContainer>
              )
            }
            { showCreateNewCustomer ? <NewCustomerSelect data-testid={DataTestIds.NEW_CUSTOMER_SELECT}
                               addNewCustomer={(): void => selectCustomer()}
                               resultsFound={resultsFound} /> : !resultsFound && <NoCustomerFoundState />
            }
            
          </>
        )
      }
    </>
  );
};

export default CustomersTable;
