import find from 'lodash/find';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import {RoutePaths} from '../routes/routes.paths';
import {UserData} from '../security-context.model';
import {Permission} from '../shared/model/permission.model';

const {PROCESS_EXECUTION, PROCESS_EXECUTION_READ, PROCESS_CONFIGURATION, BATCH_MANAGEMENT, ADMIN_PANEL} = Permission;

export const permissionsToPathsMap = {
  [PROCESS_EXECUTION]: RoutePaths.LOAN_APPLICATIONS,
  [PROCESS_EXECUTION_READ]: RoutePaths.LOAN_APPLICATIONS,
  [PROCESS_CONFIGURATION]: RoutePaths.LOAN_CONFIGURATIONS,
  [BATCH_MANAGEMENT]: RoutePaths.BATCH,
  [ADMIN_PANEL]: RoutePaths.ADMIN
};

export const isSysAdminOrManager = (userData?: UserData): boolean =>
  !isNil(userData) && (userData.permissions.includes(Permission.BRANCH_MANAGEMENT) || userData.permissions.includes(
    Permission.SYSTEM_MANAGEMENT));

export const hasExecutionReadonlyAccess = (userData?: UserData): boolean =>
  !!userData &&
  userData.permissions.includes(Permission.PROCESS_EXECUTION_READ) &&
  !userData.permissions.includes(Permission.PROCESS_EXECUTION);

const hasPermission = (permission: Permission, userData?: UserData): boolean =>
  !!find(userData?.permissions, userPermission => userPermission === permission);

export const hasPermissionToPath = (path: string, userData?: UserData): boolean =>
  path === RoutePaths.ROOT ? true : keys(permissionsToPathsMap).some(permission =>
    path.startsWith(permissionsToPathsMap[permission]) && hasPermission(Permission[permission], userData)
  );

export const getPermittedRootPath = (userData?: UserData): string => {
  const hasExecutionPermission = hasPermission(PROCESS_EXECUTION, userData) || hasPermission(PROCESS_EXECUTION_READ,
    userData);
  if (hasExecutionPermission) {
    return permissionsToPathsMap[PROCESS_EXECUTION];
  }

  const hasConfigurationPermission = hasPermission(PROCESS_CONFIGURATION, userData);
  if (hasConfigurationPermission) {
    return permissionsToPathsMap[PROCESS_CONFIGURATION];
  }

  const hasAdminPermission = hasPermission(ADMIN_PANEL, userData);
  if (hasAdminPermission) {
    return permissionsToPathsMap[ADMIN_PANEL];
  }

  return RoutePaths.NO_PERMISSIONS;
};
