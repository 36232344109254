import {NxButton, NxInput, NxPasswordInput} from '@nextbank/ui-components';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import React, {ReactElement, useContext} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {SecurityContext} from '../../../App';
import {LOGIN_URL} from '../../../constants/api-urls';
import {UserData} from '../../../security-context.model';
import usePost from '../../../shared/hooks/use-post.hook';
import {HttpError} from '../../../shared/http-service/http-error';
import {SimpleErrorBody} from '../../../shared/http-service/http-error.model';
import styles from './LoginForm.module.scss';

interface Props {
  logInUser: (user: UserData) => void;
}

interface LoginFields {
  login: string;
  password: string;
}

const initialValues = {
  login: '',
  password: ''
};

export default function LoginForm({logInUser}: Props): React.ReactElement {

  const GENERAL_ERROR_KEY = 'authentication';

  const {logoutReason, setLogoutReason} = useContext(SecurityContext);
  const {t} = useTranslation();
  const login = usePost<UserData, FormData>(LOGIN_URL);

  const getAuthErrorMessage = (httpError: HttpError<SimpleErrorBody>): string => {
    return httpError.response.status === 401 ? getErrorMessage(httpError) : t('LOGIN.SERVER_ERROR');
  };

  const getErrorMessage = (httpError: HttpError<SimpleErrorBody>): string => {
    return httpError.response.data.errorMessage ?? t('LOGIN.ERROR');
  };

  const submit = (values: LoginFields, actions: FormikHelpers<LoginFields>): void => {

    setLogoutReason(undefined);

    const formData = new FormData();
    formData.append('username', values.login);
    formData.append('password', values.password);

    login(formData)
      .then(userData => logInUser(userData))
      .catch(error => {
        const httpError = (error as HttpError<SimpleErrorBody>);
        actions.setFieldError(GENERAL_ERROR_KEY, getAuthErrorMessage(httpError));
      })
      .finally(() => actions.setSubmitting(false));
  };

  const LoginForm = ({
                       values, handleChange, handleBlur, handleSubmit, isSubmitting, errors
                     }: FormikProps<LoginFields>): ReactElement => (
    <form onSubmit={handleSubmit}>
      <NxInput
        className={styles.input}
        label={<Trans>LOGIN.USERNAME</Trans>}
        name='login'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.login}
      />
      <NxPasswordInput
        className={styles.input}
        label={<Trans>LOGIN.PASSWORD</Trans>}
        name='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
      />
      <NxButton type='submit' className={styles.button} disabled={isSubmitting} loaded={!isSubmitting}>
        <Trans>LOGIN.SIGN_IN</Trans>
      </NxButton>
      <div className={styles.error}>
        {logoutReason ?? errors[GENERAL_ERROR_KEY]}
      </div>
    </form>
  );

  return (
    <Formik<LoginFields> initialValues={initialValues} onSubmit={submit}>
      {LoginForm}
    </Formik>
  );
}
