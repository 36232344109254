import React, {ReactElement} from 'react';
import {NxPhoneInput} from '@nextbank/ui-components';

interface Props {
  label: string,
  value?: string,
  className?: string;
}

const ReadOnlyPhoneInput = ({label, value, className}: Props): ReactElement => {
  return <NxPhoneInput className={className}
                       label={label}
                       disabled
                       value={value} />;
};

export default ReadOnlyPhoneInput;
