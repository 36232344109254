import {DictionaryCode} from '../../constants/dictionary-code';
import {ApiHelper} from '../../utils/api-helper';
import {DictionaryEntry} from '../model/dictionary.model';
import useSingleQuery from './use-single-query.hook';

const {getDictionaryByCodeUrl} = ApiHelper;

export default function useDictionaryEntriesByCode(dictionaryCode?: DictionaryCode): DictionaryEntry[] | undefined {

  const urlParams = new URLSearchParams(`dictionaryCode=${dictionaryCode}`);

  return useSingleQuery<DictionaryEntry[]>(getDictionaryByCodeUrl(), null, true, urlParams);
}
