import React, {ReactElement, ReactNode} from 'react';
import styles from './SingleMessagePageWrapper.module.scss';

interface Props {
  children: ReactNode;
}

export const SingleMessagePageWrapper = ({children}: Props): ReactElement =>
  <h1 className={styles.title}>
    {children}
  </h1>;
