import {NxButton, NxButtonVariant, NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {useContext, useEffect, useState} from 'react';
import {ReactComponent as BackArrowIcon} from '../../../assets/images/icon-arrow-back.svg';
import {PROCESSES_URL} from '../../../constants/api-urls';
import {RoutePaths} from '../../../routes/routes.paths';
import useGet from '../../../shared/hooks/use-get.hook';
import {fromProcessTO, Process, ProcessTO, ProductStatus} from '../../../shared/model/process.model';
import {TransHelper} from '../../../utils/trans-helper';
import {useGuardedHistory} from '../../router/GuardedHistory';
import {AppSnackbarContext} from '../../shared/app-snackbar-provider/AppSnackbarProvider';
import EmptyState from '../../shared/empty-state/EmptyState';
import LoanTypeSelectionTable from './loan-type-selection-table/LoanTypeSelectionTable';
import styles from './NewLoanApplication.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS');
const statusPublishedParam = new URLSearchParams(`status=${ProductStatus.PUBLISHED}`);

const NewLoanApplication = (): React.ReactElement => {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const history = useGuardedHistory();
  const fetchProcesses = useGet<ProcessTO[]>(PROCESSES_URL);

  const [processes, setProcesses] = useState<Process[]>();

  useEffect(() => {
    fetchProcesses(statusPublishedParam)
      .then(result => setProcesses(result.map(fromProcessTO)))
      .catch(error => showErrorMessage(error.message));
  }, []);

  const LoanProcesses = processes && processes.length > 0
    ? <LoanTypeSelectionTable processes={processes} />
    : <EmptyState instruction={<PrefixTrans>NEW_LOAN_APPLICATION_EMPTY_STATE</PrefixTrans>} />;

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>
        <NxButton variant={NxButtonVariant.OUTLINED}
                  className={styles.backButton}
                  onClick={(): void => history.push(RoutePaths.LOAN_APPLICATIONS)}>
          <span className={styles.backArrow}>
            <BackArrowIcon />
          </span>
        </NxButton>
        <PrefixTrans>NEW_LOAN_APPLICATION</PrefixTrans>
      </h1>
      {
        isNil(processes) ? <NxLoader /> : LoanProcesses
      }
    </div>
  );
};

export default NewLoanApplication;
