import clsx from 'clsx';
import React from 'react';
import {EventLogGroupType} from '../../event-log.model';
import styles from './ProductStatusChip.module.scss';

const LogGroupChip = ({type}: {type: EventLogGroupType}): React.ReactElement => (
  <div className={clsx(styles.status, {
    [styles.status_status]: type === EventLogGroupType.STATUS,
    [styles.status_application]: type === EventLogGroupType.APPLICATION,
    [styles.status_input]: type === EventLogGroupType.INPUT,
    [styles.status_approval]: type === EventLogGroupType.APPROVAL
  })}>
    {type}
  </div>
);

export default LogGroupChip;
