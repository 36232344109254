import {NxFormikProps} from '@nextbank/ui-components/dist/model/SharedTypes';
import {useField} from 'formik';
import React, {ReactElement, useState} from 'react';
import {NxFile} from '../../../../../../shared/model/nx-file.model';
import NxFileUpload, {FileUploadProps} from '../../../../../shared/inputs/nx-file-upload/NxFileUpload';

export type NxFormikFileInputProps = Omit<FileUploadProps, NxFormikProps> & {
  name: string;
  validate?: (value: number | null) => string | void | Promise<string | void>;
};

export const ScanIdsFileInput = (props: NxFormikFileInputProps): ReactElement => {
  // array state to handle more than one file in the future
  const [field, meta, helpers] = useField(props);
  const [files, setFiles] = useState<NxFile[]>(field.value as unknown as NxFile[]);

  const handleChange = (value?: File): void => {
    const fileArray = value ? [{file: value, isChanged: true} as NxFile] : [];
    setFiles(fileArray);
    helpers.setValue(fileArray, true);
  };

  return <NxFileUpload {...props} value={files && files[0] ? files[0].file : undefined}
                       onChange={handleChange}
                       externalError={meta.error} />;
};
