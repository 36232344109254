import {TFunction} from 'i18next';
import {AnySchema, boolean, number, object, string} from 'yup';
import {ConfiguredField, FieldType} from '../../../shared/model/field.model';
import {getNxFileValidationSchema} from './nx-file-validation-schema';
import {getDefaultRequiredError, getFieldNegativeError} from './validation-schema-utils';

export const getConfiguredFieldValidationDefaultSchema =
  (partialValidation: boolean, field: ConfiguredField<unknown>, t: TFunction, overwriteType?: FieldType): AnySchema => {
    const required = !!(field.required && field.enabled && !partialValidation);
    const requiredError = getDefaultRequiredError(t);

    const getConfiguredFieldSchema = (schema: AnySchema, isRequired?: boolean): AnySchema => object({
      value: isRequired ? schema.required(requiredError) : schema,
      enabled: boolean(),
      required: boolean(),
      name: string(),
      code: string()
    });

    const finalType = overwriteType ?? field.type;
    switch (finalType) {
      case FieldType.CASH:
      case FieldType.INTEGER:
      case FieldType.PERCENTAGE:
        return getConfiguredFieldSchema(number().nullable().min(0, getFieldNegativeError(t)), required);
      case FieldType.FILE:
        return getConfiguredFieldSchema(getNxFileValidationSchema(required, requiredError));
      default:
        return getConfiguredFieldSchema(string().nullable(), required);
    }
  };

export const getConfiguredFieldValidationSchema =
  (partialValidation: boolean, field: ConfiguredField<unknown>, t: TFunction, valueFieldSchema: AnySchema): AnySchema => {
    const required = !!(field.required && field.enabled && !partialValidation);
    const requiredError = getDefaultRequiredError(t);

    const getConfiguredFieldSchema = (schema: AnySchema, isRequired?: boolean): AnySchema => object({
      value: isRequired ? schema.required(requiredError) : schema,
      enabled: boolean(),
      required: boolean(),
      name: string(),
      code: string()
    });

    return getConfiguredFieldSchema(valueFieldSchema, required);
  };
