import {NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {createContext, ReactElement, useContext, useEffect, useState} from 'react';
import {MANAGEMENT_ROLES_URL} from '../../../constants/api-urls';
import useGet from '../../../shared/hooks/use-get.hook';
import {Role} from '../../../shared/model/management.model';
import {TransHelper} from '../../../utils/trans-helper';
import {AppSnackbarContext} from '../../shared/app-snackbar-provider/AppSnackbarProvider';
import AccessesTable from './accesses-table/AccessesTable';
import {AccessesContextType, EditAccessPanelState} from './access.model';
import EditAccessSidePanel from './edit-access-side-panel/EditAccessSidePanel';
import styles from './Access.module.scss';

export const AccessesContext = createContext<AccessesContextType>({} as AccessesContextType);

const TRANS_PREFIX = 'ADMIN.ACCESS';
export const PERMISSIONS_TRANS_PREFIX = `${TRANS_PREFIX}.PERMISSION_TYPES`;
export const PrefixTrans = TransHelper.getPrefixedTrans('ADMIN.ACCESS');

export const Access = (): ReactElement => {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const fetchRoles = useGet<Role[]>(MANAGEMENT_ROLES_URL);
  const [roles, setRoles] = useState<Role[]>();
  const [editPanelState, setEditPanelState] = useState<EditAccessPanelState>({panelOpen: false});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updateAccesses();
  }, []);

  const updateAccesses = (): void => {

    setLoading(true);

    fetchRoles()
      .then(setRoles)
      .catch(error => showErrorMessage(error.message))
      .finally(() => setLoading(false));
  }

  const areRolesNotEmpty = roles && roles.length > 0;

  return (
    <>
      {
        isNil(roles) ? <NxLoader /> :
          <AccessesContext.Provider value={{roles, updateAccesses, setEditPanelState, setLoading}}>
            <div className={styles.header}>
              {
                areRolesNotEmpty ? <PrefixTrans>INSTRUCTION</PrefixTrans> : <PrefixTrans>EMPTY_STATE</PrefixTrans>
              }
            </div>
            {
              loading ? <NxLoader /> : areRolesNotEmpty && <AccessesTable />
            }
            <EditAccessSidePanel state={editPanelState} />
          </AccessesContext.Provider>
      }
    </>
  );
};
