import React from 'react';
import {TransHelper} from '../../../utils/trans-helper';
import {ConfirmationPopup} from '../../shared/confirmation-popup/ConfirmationPopup';
import styles from './RouteChangeConfirmationPopup.module.scss'

const PrefixTrans = TransHelper.getPrefixedTrans('COMMON.SAVE_CHANGES_POPUP');

interface Props {
  onConfirm: () => void;
  onDeny: () => void;
  open: boolean;
}

export const RouteChangeConfirmationPopup = ({onConfirm, onDeny, open}: Props): React.ReactElement => (
  <ConfirmationPopup
    header={<PrefixTrans>YOU_HAVE_UNSAVED_CHANGES</PrefixTrans>}
    description={<div className={styles.message}><PrefixTrans>HELP_MESSAGE</PrefixTrans></div>}
    confirmButtonLabel={<PrefixTrans>EXIT_WITHOUT_SAVING</PrefixTrans>}
    onConfirm={onConfirm}
    onDeny={onDeny}
    open={open} />
);
