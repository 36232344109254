import {Table, TableContainer} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, {ReactElement, ReactNode} from 'react';
import {Trans} from 'react-i18next';
import {PAGE_RECORDS_LIMIT} from '../../../constants/table-defaults';
import {PagedRecords} from '../../../shared/model/paged.model';
import EmptyState from '../empty-state/EmptyState';
import TablePaginationFooter from './pagination-footer/TablePaginationFooter';

interface Props<T> {
  items: PagedRecords<T>,
  headers: ReactNode,
  body: ReactNode,
  onPageChange: (pageNo: number) => Promise<void>;
  className?: string;
}

export const TRANS_PREFIX = 'BATCH.BATCH_ITEMS.LABELS';

//TODO refactor tables to use this common component
const LosTable = <T, >({items, headers, body, onPageChange, className}: Props<T>): ReactElement =>
  <div className={className}>
    {
      isEmpty(items.result)
        ? <EmptyState instruction={<Trans>COMMON.NO_RESULTS</Trans>} />
        : <>
          <TableContainer>
            <Table>
              {headers}
              {body}
              <TablePaginationFooter page={items.pageNo}
                                     onPageChange={onPageChange}
                                     count={items.totalCount}
                                     rowsPerPage={PAGE_RECORDS_LIMIT} />
            </Table>
          </TableContainer>
        </>
    }
  </div>;

export default LosTable;
