import React, {ReactElement, ReactNode} from 'react';
import {DocumentFieldTO} from '../../../../../../../shared/model/field.model';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import DocumentSearchSidePanel from './document-search-side-panel/DocumentSearchSidePanel';
import styles from './ConfiguredDocumentInputGroupHeader.module.scss';

export interface Props {
  onSelect: (document: DocumentFieldTO) => void;
  label: ReactNode;
  disabled: boolean;
}

export const ConfiguredDocumentInputGroupHeader = ({label, disabled, onSelect}: Props): ReactElement =>
  <div className={styles.header}>
    <div>
      {label}
    </div>
    <DocumentSearchSidePanel disabled={disabled} onSelect={onSelect} />
  </div>;

