import {Collapse, TableRow} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import React, {ReactElement, ReactNode} from 'react';
import {DATE_TIME_FORMAT} from '../../../../../../../../constants/format-templates';
import {TransHelper} from '../../../../../../../../utils/trans-helper';
import DetailsButton from '../../../../../../../shared/details-button/DetailsButton';
import TableDataCell from '../../../../../../../shared/table/cells/TableDataCell';
import {KeyedEventLog} from '../event-log.model';
import {EventLogLabels, EventLogTableHeaders} from './event-log-table-labels';
import styles from './EventLogTableRow.module.scss';
import LogGroupChip from './log-group-chip/ProductStatusChip';

interface Props {
  eventLog: KeyedEventLog;
}

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.EVENT_LOG.TABLE_ROW');

const EventLogsTableRow = ({eventLog}: Props): ReactElement => {
  const [open, setOpen] = React.useState(false);

  const expandRow = (event): void => {
    event && event.stopPropagation();
    setOpen(!open);
  };

  const getDetail = (label: ReactNode, content?: ReactNode): ReactElement => <div className={styles.detail}>
    <div className={styles.detailLabel}>{label}:</div>
    <div>{content ?? '-'}</div>
  </div>;

  const getDetails = (): ReactElement => (
    <div className={styles.container}>
      <div className={styles.column}>
        {getDetail(<PrefixTrans>{EventLogLabels.AGENT_NAME}</PrefixTrans>, eventLog.userFullName)}
        {getDetail(<PrefixTrans>{EventLogLabels.BRANCH_NAME}</PrefixTrans>, eventLog.branchName)}
        {getDetail(<PrefixTrans>{EventLogLabels.ROLES}</PrefixTrans>, eventLog.roleNames)}
      </div>
      <div>
        {getDetail(<PrefixTrans>{EventLogLabels.NOTE}</PrefixTrans>, eventLog.message)}
      </div>
    </div>
  );

  return (
    <>
      <TableRow className={styles.tableRow}>
        <TableDataCell width={'30%'} data={eventLog.registeredOn.format(DATE_TIME_FORMAT)} />
        <TableDataCell width={'30%'} data={eventLog.phaseName} />
        <TableDataCell width={'30%'} data={<LogGroupChip type={eventLog.logGroup} />} />
        <TableDataCell className={styles.actionsColumn} data={
          <DetailsButton isOpen={open} onClick={expandRow} />
        } />
      </TableRow>
      <TableRow>
        <TableCell className={clsx(styles.expandableDetails, {[styles.expandableDetailsOpen]: open})}
                   colSpan={Object.values(EventLogTableHeaders).length}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            {getDetails()}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EventLogsTableRow;
