import {ConfiguredField} from '../../../../../shared/model/field.model';
import {LoanSimulation, LoanSimulationParams} from '../../../../../shared/model/loan-simulation.model';
import {CalculatorFormFieldsConfiguration} from '../../../../../shared/model/step-forms/calculator-form.model';
import {StepPayloadType} from '../shared/step-payload.model';

export const SIMULATION_REQUIRED_INDICATOR_FIELD = 'simulationRequired';
export const SIMULATION_PARAMS_CHANGED_INDICATOR_FIELD = 'simulationParamsChanged';

export interface CalculatorFormFields extends CalculatorFormFieldsConfiguration<ConfiguredField, ConfiguredField<number>> {
  isPaymentDynamic?: boolean;
  simulation?: {
    result: LoanSimulation,
    input: LoanSimulationParams
  };
  // Helper field to validate term selected by combination of paymentInterval and totalAmortizationNumber
  term?: number,
  remadeFromLoanIds?: number[];
  [SIMULATION_REQUIRED_INDICATOR_FIELD]?: boolean;  // field only for yup validation
  [SIMULATION_PARAMS_CHANGED_INDICATOR_FIELD]?: boolean;  // field only for yup validation
}

export interface LoanApplicationInitializeParams {
  principalAmount: number;
  processId: number;
  simulation: {
    phaseId: number;
    input: LoanSimulationParams
  };
}

export interface CalculatorPayload {
  type: StepPayloadType.CALCULATOR_PAYLOAD,
  loanParameters: {
    applicationId?: number,
    id?: number,
    phaseId?: number,
    result?: LoanSimulation,
    input?: LoanSimulationParams
  },
  processId: number
}
