import clsx from 'clsx';
import React, {ReactElement} from 'react';
import {ChipVariant} from './chip.model';
import styles from './Chip.module.scss';

interface Props {
  variant: ChipVariant,
  message: string,
  startIcon?: React.ReactNode,
  className?: string
}

export const Chip = ({variant, message, startIcon, className}: Props): ReactElement => {

  const statusClassName = clsx(
    styles.status,
    {
      [styles.status_success]: variant == ChipVariant.SUCCESS,
      [styles.status_warning]: variant == ChipVariant.WARNING,
      [styles.status_error]: variant == ChipVariant.ERROR
    },
    className
  );

  return (
    <div className={statusClassName}>
      {startIcon}
      <span className={styles.message}>{message}</span>
    </div>
  );
};
