import {debounce} from '@material-ui/core';
import {NxLoader} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {ReactElement, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {APPLICATIONS, BATCH} from '../../../constants/api-urls';
import {PAGE_RECORDS_LIMIT, REFRESH_DATA_DEBOUNCE_DELAY} from '../../../constants/table-defaults';
import {BatchUrlParams} from '../../../routes/routes.model';
import useGet from '../../../shared/hooks/use-get.hook';
import {PagedRecords} from '../../../shared/model/paged.model';
import {ApiHelper} from '../../../utils/api-helper';
import {TransHelper} from '../../../utils/trans-helper';
import {
  ApplicationBasicData,
  ApplicationBasicDataByPageTO,
  fromApplicationBasicDataTO
} from '../../loan-applications/loan-application/loan-application.model';
import SidePanel, {CloseSidePanel} from '../../shared/side-panel/SidePanel';
import {SystemContext} from '../../shared/system-context-provider/SystemContextProvider';
import {TopDetailsWrapper} from '../../shared/top-details-wrapper/TopDetailsWrapper';
import {Batch, BatchItem, BatchItemSearchCriteria, BatchItemTO, BatchTO, toBatch, toBatchItems} from '../batch.model';
import styles from './BatchDetails.module.scss';
import {DownloadSourceFileButton} from './download-source-file-button/DownloadSourceFIleButton';
import {GenerateReportButton} from './generate-report-button/GenerateReportButton';
import {BatchItemDetails} from './item-details/BatchItemDetails';
import {ApprovePopup} from './popup/ApprovePopup';
import {ProcessingConfirmationPopup} from './popup/ProcessingConfirmationPopup';
import {RejectPopup} from './popup/RejectPopup';
import {RevokePopup} from './popup/RevokePopup';
import {BatchItemsSearchForm} from './search/BatchItemsSearchForm';
import BatchItemsTable from './table/BatchItemsTable';
import {BatchTopDetails} from './top-details/BatchTopDetails';

export const getBatchUrl = (batchId: string): string => `${BATCH}/${batchId}`;
export const TRANS_PREFIX = 'BATCH.DETAILS';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

export interface ItemDetailsPanelState {
  batchItem?: BatchItem;
  applicationBasicData?: ApplicationBasicData;
  panelOpen: boolean;
}

export const searchDefaultState = {statuses: []};

export const BatchDetails = (): React.ReactElement => {
  const {printsEnabled} = useContext(SystemContext);
  const {batchId} = useParams<BatchUrlParams>();
  const fetchBatch = useGet<BatchTO>(getBatchUrl(batchId));
  const getApplications = useGet<ApplicationBasicDataByPageTO>(APPLICATIONS);
  const [batch, setBatch] = useState<Batch>();
  const [applications, setApplications] = useState<ApplicationBasicData[]>();
  const [batchIsLoading, setBatchLoading] = useState<boolean>(false);
  const fetchBatchItems = useGet<PagedRecords<BatchItemTO>>(`${getBatchUrl(batchId)}/items`);
  const [batchItems, setBatchItems] = useState<PagedRecords<BatchItem>>();
  const [batchItemsIsLoading, setBatchItemsLoading] = useState<boolean>(false);
  const [rejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [revokePopupOpen, setRevokePopupOpen] = useState(false);
  const [approvePopupOpen, setApprovePopupOpen] = useState(false);
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);

  const panelRef = useRef<CloseSidePanel>(null);
  const [sidePanelState, setSidePanelSate] = useState<ItemDetailsPanelState>({panelOpen: false});

  const refreshBatchData = (): Promise<void> => {
    setBatchLoading(true);
    return fetchBatch().then(rawBatch => {
      setBatch(toBatch(rawBatch));
      setBatchLoading(false);
    });
  };

  useEffect(() => {
    refreshBatchData();
    getBatchItemsPage();
    getApplications(ApiHelper.constructUrlParams({
      pageNo: 0,
      pageSize: batch?.validItemsCount,
      batchId: batch?.id
    })).then(result => setApplications(result.result.map(fromApplicationBasicDataTO)));
  }, []);

  const getBatchItemsPage = (pageNo = 0, searchParams?: BatchItemSearchCriteria): Promise<void> => {
    setBatchItemsLoading(true);
    const urlSearchParams = ApiHelper.constructUrlParams({
      pageNo,
      pageSize: PAGE_RECORDS_LIMIT,
      ...searchParams
    });

    return fetchBatchItems(urlSearchParams)
      .then(response => {
        const result = toBatchItems(response.result);
        setBatchItems({
          ...response,
          result
        });
        setBatchItemsLoading(false);
      });
  };

  const onSubmit = (values: BatchItemSearchCriteria): Promise<void> => getBatchItemsPage(0, values);
  const debouncedOnSubmit = useCallback(debounce(onSubmit, REFRESH_DATA_DEBOUNCE_DELAY), []);

  const batchItemsTableForm = ({values}: FormikProps<BatchItemSearchCriteria>): ReactElement => {
    const changePage = (pageNumber: number): Promise<void> => getBatchItemsPage(pageNumber, values);

    useEffect(() => {
      debouncedOnSubmit(values);
    }, [values]);

    return (
      <Form>
        <BatchItemsSearchForm />
        {
          batchItemsIsLoading || isNil(batchItems)
            ? <NxLoader />
            : <BatchItemsTable batchItems={batchItems}
                               onPageChange={changePage}
                               onItemClick={batchItemClicked} />
        }
      </Form>
    );
  };

  const setOpen = (panelOpen: boolean): void => setSidePanelSate({...sidePanelState, panelOpen});
  const batchItemClicked = (batchItem: BatchItem): void => setSidePanelSate({
    batchItem: batchItem,
    applicationBasicData: applications?.filter(app => app.id === batchItem.data?.applicationId)[0],
    panelOpen: true
  });

  return isNil(batch)
    ? <NxLoader />
    : <div>
      <TopDetailsWrapper>
        {
          batchIsLoading || isNil(batch)
            ? <NxLoader />
            : <BatchTopDetails batch={batch}
                               setApprovePopupOpen={setApprovePopupOpen}
                               setRejectPopupOpen={setRejectPopupOpen}
                               setRevokePopupOpen={setRevokePopupOpen} />
        }

      </TopDetailsWrapper>
      <div className={styles.header}>
        <h1><PrefixTrans>HEADER</PrefixTrans></h1>
        <div className={styles.fileButtons}>
          <DownloadSourceFileButton batchId={batch.id.toString()} />
          {printsEnabled && batch?.invalidItemsCount > 0 && <GenerateReportButton batch={batch} />}
        </div>
      </div>
      <div>
        <Formik<BatchItemSearchCriteria> initialValues={searchDefaultState} onSubmit={onSubmit}>
          {batchItemsTableForm}
        </Formik>
      </div>
      <SidePanel ref={panelRef} open={sidePanelState.panelOpen} setOpen={setOpen} header={<></>}>
        {
          !sidePanelState.batchItem
            ? <NxLoader />
            : <BatchItemDetails batchItem={sidePanelState.batchItem}
                                application={sidePanelState.applicationBasicData} />
        }
      </SidePanel>
      <RejectPopup open={rejectPopupOpen} setOpen={setRejectPopupOpen} refreshBatch={refreshBatchData} />
      <RevokePopup open={revokePopupOpen} setOpen={setRevokePopupOpen} refreshBatch={refreshBatchData} />
      <ApprovePopup open={approvePopupOpen}
                    setOpen={setApprovePopupOpen}
                    invalidRowsNo={batch.invalidItemsCount}
                    validRowsNo={batch.validItemsCount}
                    refreshBatch={refreshBatchData}
                    onClose={(): void => setConfirmationPopupOpen(true)} />
      <ProcessingConfirmationPopup batchName={batch.name}
                                   open={confirmationPopupOpen}
                                   setOpen={setConfirmationPopupOpen} />

    </div>;
};
