import React, {ReactElement, useContext, useState} from 'react';
import {Trans} from 'react-i18next';
import {ReactComponent as CopyIcon} from '../../../../assets/images/icon-collections.svg';
import {ReactComponent as EditIcon} from '../../../../assets/images/icon-edit.svg';
import {ReactComponent as GoLiveIcon} from '../../../../assets/images/icon-live.svg';
import {PROCESSES_URL} from '../../../../constants/api-urls';
import useGet from '../../../../shared/hooks/use-get.hook';
import usePost from '../../../../shared/hooks/use-post.hook';
import {fromProcessTO, Process, ProcessTO} from '../../../../shared/model/process.model';
import {ApiHelper} from '../../../../utils/api-helper';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import TableMenu from '../../../shared/table/menu/TableMenu';
import TableMenuOption from '../../../shared/table/menu/TableMenuOption';
import {getFirstStepPath} from '../../loan-configuration/steps/predefined-steps';
import {CopyConfigurationPopupContext} from '../copy-configuration-popup/CopyConfigurationPopupProvider';
import {LoanPublishingErrorPopup} from './loan-publishing-error-popup/LoanPublishingErrorPopup';

interface Props {
  isLoanPublished: boolean;
  onLoanPublished: (process: Process) => void;
  processId: number;
}

const LoanMenu = (({isLoanPublished, onLoanPublished, processId}: Props): ReactElement => {

  const history = useGuardedHistory();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const fetchPublishedProcess = useGet<ProcessTO>(`${PROCESSES_URL}/${processId}`);
  const handlePublish = usePost<void, void>(ApiHelper.getLoanPublishUrl(processId));
  const {setOpen} = useContext(CopyConfigurationPopupContext);

  const publishLoan = async (): Promise<void> => {
    setIsPublishing(true);

    handlePublish()
      .then(() => fetchPublishedProcess())
      .then(process => {
        setIsPublishing(false);
        handleClose();
        onLoanPublished(fromProcessTO(process));
      })
      .catch(() => {
        setIsPublishing(false);
        setShowErrorPopup(true);
      });
  };

  const closePopupAndMenu = (): void => {
    setShowErrorPopup(false);
    handleClose();
  };

  const handleClose = (): void => setAnchorElement(null);
  const goToLoanConfiguration = (): void => history.push(getFirstStepPath(processId));
  const showCopyConfigPopup = (): void => {
    setOpen(processId);
    handleClose();
  };

  return (
    <>
      <TableMenu menuId={`LOAN_MENU_${processId}`} anchorElement={anchorElement} setAnchorElement={setAnchorElement}>
        {
          !isLoanPublished &&
          <TableMenuOption icon={<GoLiveIcon />}
                           onClick={publishLoan}
                           loaded={!isPublishing}
                           label={<Trans>COMMON.PUBLISH</Trans>} />
        }
        <TableMenuOption onClick={goToLoanConfiguration} icon={<EditIcon />} label={<Trans>COMMON.EDIT</Trans>} />
        {
          !isLoanPublished &&
          <TableMenuOption onClick={showCopyConfigPopup}
                           icon={<CopyIcon />}
                           label={<Trans>COMMON.COPY_CONFIG</Trans>} />
        }
      </TableMenu>
      <LoanPublishingErrorPopup open={showErrorPopup} onConfirm={goToLoanConfiguration} onDeny={closePopupAndMenu} />
    </>
  );
});

export default LoanMenu;
