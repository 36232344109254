import React from 'react';
import {AccountInformationOptions} from '../../../../../../shared/hooks/use-loan-application-data-options.hook';
import {SetFieldValue} from '../../../../../../shared/model/formik.model';
import {LoanApplicationDataFormFieldsConfiguration} from '../../../../../../shared/model/step-forms/loan-application-data-form.model';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {FieldDefaultSelect} from '../../../../../shared/field-default/FieldDefaultSelect';
import styles from './AccountInformation.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.ACCOUNT_INFORMATION');

interface Props {
  values: LoanApplicationDataFormFieldsConfiguration;
  setFieldValue: SetFieldValue;
  options: AccountInformationOptions;
}

export default function AccountInformation({values, setFieldValue, options}: Props): React.ReactElement {
  return (
    <>
      <FieldDefaultSelect name='loanClassId'
                          field={values.loanClassId}
                          label={<PrefixTrans>LOAN_CLASS</PrefixTrans>}
                          setFieldValue={setFieldValue}
                          options={options.loanClassId} />
      <FieldDefaultSelect name='loanPurposeId'
                          field={values.loanPurposeId}
                          label={<PrefixTrans>LOAN_PURPOSE</PrefixTrans>}
                          setFieldValue={setFieldValue}
                          options={options.loanPurposeId} />
      <FieldDefaultSelect name='loanSecurityId'
                          field={values.loanSecurityId}
                          label={<PrefixTrans>LOAN_SECURITY</PrefixTrans>}
                          setFieldValue={setFieldValue}
                          options={options.loanSecurityId} />
      <FieldDefaultSelect name='loanEconomicActivityId'
                          field={values.loanEconomicActivityId}
                          label={<PrefixTrans>LOAN_ECONOMIC_ACTIVITY</PrefixTrans>}
                          setFieldValue={setFieldValue}
                          options={options.loanEconomicActivityId} />
      <FieldDefaultSelect name='microfinanceClassificationId'
                          field={values.microfinanceClassificationId}
                          label={<PrefixTrans>MICROFINANCE_CLASSIFICATION</PrefixTrans>}
                          setFieldValue={setFieldValue}
                          options={options.microfinanceClassificationId} />
      <FieldDefaultSelect name='borrowerTypeId'
                          field={values.borrowerTypeId}
                          label={<PrefixTrans>BORROWER_TYPE</PrefixTrans>}
                          setFieldValue={setFieldValue}
                          options={options.borrowerTypeId} />
      <FieldDefaultSelect name='transactionTypeId'
                          field={values.transactionTypeId}
                          label={<PrefixTrans>TRANSACTION_TYPE</PrefixTrans>}
                          setFieldValue={setFieldValue}
                          options={options.transactionTypeId} />
      <FieldDefaultSelect name='cicContractTypeId'
                          field={values.cicContractTypeId}
                          label={<PrefixTrans>CIC_CONTRACT_TYPE</PrefixTrans>}
                          setFieldValue={setFieldValue}
                          options={options.cicContractTypeId} />
      <FieldDefaultSelect name='loanPurposeToIndustryId'
                          field={values.loanPurposeToIndustryId}
                          label={<PrefixTrans>LOAN_PURPOSE_TO_INDUSTRY</PrefixTrans>}
                          setFieldValue={setFieldValue}
                          options={options.loanPurposeToIndustryId?.map(
                            option => ({
                              ...option,
                              label: <span className={styles.purposeToIndustryLabel}>{option.label}</span>
                            })
                          )} />
      <FieldDefaultSelect name='misGroupId'
                          field={values.misGroupId}
                          label={<PrefixTrans>MIS_GROUP</PrefixTrans>}
                          setFieldValue={setFieldValue}
                          options={options.misGroupId} />
    </>
  );
}
