import {isNil} from 'lodash';
import isEqual from 'lodash/isEqual';
import {useEffect, useRef, useState} from 'react';
import useFileUpload from '../../../../../../../shared/hooks/use-file-upload.hook';
import {FileBasicData, NxFile} from '../../../../../../../shared/model/nx-file.model';
import {Applicant} from '../../customer-data-form.model';

interface ApplicantDataFiles {
  applicantId: number;
  picture?: NxFile;
  signature?: NxFile;
}

interface ApplicantFileBasicData {
  applicantId?: number;
  picture?: FileBasicData;
  signature?: FileBasicData;
}

interface NxFileIdDocumentsState {
  applicantDataDocuments: ApplicantDataFiles[],
  areApplicantDataDocumentsLoaded: boolean;
}

export default function useApplicantDataDocuments(corporateClient: boolean, applicants?: Applicant<number>[]): NxFileIdDocumentsState {

  const lastValue = useRef<ApplicantFileBasicData[] | undefined>();
  const newApplicantData: ApplicantFileBasicData[] | undefined = applicants?.map(applicant => ({
    applicantId: applicant.id,
    picture: corporateClient ? applicant.corporateData?.picture : applicant.individualData?.picture,
    signature: corporateClient ? applicant.corporateData?.signature :applicant.individualData?.signature
  }));

  const {downloadFile} = useFileUpload();
  const [applicantDataDocuments, setApplicantDataDocuments] = useState<ApplicantDataFiles[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
      if (isEqual(lastValue.current, newApplicantData)) {
        return;
      }
      lastValue.current = newApplicantData;

      if (isNil(newApplicantData)) {
        setLoaded(true);
        return;
      }

      setLoaded(false);
      const promises = newApplicantData.map(async data => ({
          applicantId: data.applicantId,
          picture: data.picture ? await downloadFile(data.picture) : undefined,
          signature: data.signature ? await downloadFile(data.signature) : undefined
        })
      );

      Promise.all(promises)
        .then(result => {
          setApplicantDataDocuments(result as ApplicantDataFiles[]);
          setLoaded(true);
        });
    }
    // TODO check dependencies
    , [newApplicantData]);

  return {applicantDataDocuments, areApplicantDataDocumentsLoaded: loaded};
}
