import {NxSelectOption} from '@nextbank/ui-components';
import mapValues from 'lodash/mapValues';
import {
  CustomerDataPhase
} from '../../components/loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {SelectHelper} from '../../utils/select-helper';
import {movePhilippinesOptionToTop} from '../../utils/select-options-utils';
import {StaticFieldsHelper} from '../../utils/step-form-utils/static-fields-helper';
import useIndividualDataDictionaries from './use-individual-data-dictionaries.hook';

export interface IndividualDataOptionsState {
  areIndividualDataOptionsLoaded?: boolean;
  individualDataOptions: IndividualDataOptions;
}

export interface IndividualDataOptions {
  title?: NxSelectOption[];
  gender?: NxSelectOption[];
  residence?: NxSelectOption[];
  citizenship?: NxSelectOption[];
  riskLevel?: NxSelectOption[];
  civilStatus?: NxSelectOption[];
}

export interface CustomerDataDictionaryIds {
  title?: number;
  gender?: number;
  residence?: number;
  citizenship?: number;
  riskLevel?: number;
  civilStatus?: number;
}

const getDictionaryIds = (config: CustomerDataPhase): CustomerDataDictionaryIds =>
  mapValues({
    title: 'TITLE',
    gender: 'GENDER',
    residence: 'RESIDENCE',
    citizenship: 'CITIZENSHIP',
    riskLevel: 'RISK_LEVEL',
    civilStatus: 'CIVIL_STATUS',
    dosriType: 'DOSRI_TYPE'
  }, code => StaticFieldsHelper.getFieldDictionaryId(config, code));

export default function useIndividualDataOptions(config?: CustomerDataPhase): IndividualDataOptionsState {

  const {
    individualDataDictionaryEntries,
    areIndividualDataDictionaryEntriesLoaded
  } = useIndividualDataDictionaries(config);

  const individualDataOptions = mapValues(individualDataDictionaryEntries,
    entries => SelectHelper.mapToStringOptions(entries));

  return {
    individualDataOptions: {
      ...individualDataOptions,
      citizenship: movePhilippinesOptionToTop(individualDataOptions.citizenship),
      residence: movePhilippinesOptionToTop(individualDataOptions.residence)
    },
    areIndividualDataOptionsLoaded: areIndividualDataDictionaryEntriesLoaded
  };
}

