import {NxInput} from '@nextbank/ui-components';
import {cloneDeep} from 'lodash';
import React, {ChangeEvent, useContext} from 'react';
import {Trans} from 'react-i18next';
import {getInputValue} from '../../../../../utils/input-utils';
import BinButton from '../../../../shared/bin-button/BinButton';
import {DraggableChecksContext} from '../DraggableChecks';
import styles from './DraggableCheckGroupHeader.module.scss';

interface Props {
  groupName: string;
  groupIndex: number;
}

export default function DraggableCheckGroupHeader({groupName, groupIndex}: Props): React.ReactElement {
  const {checkGroups, setCheckGroups} = useContext(DraggableChecksContext);

  const handleGroupNameChange = (event: ChangeEvent): void => {
    const updatedCheckGroups = cloneDeep(checkGroups);
    updatedCheckGroups[groupIndex].name = getInputValue(event);
    setCheckGroups(updatedCheckGroups);
  };

  const removeGroup = (): void => {
    const updatedCheckGroups = cloneDeep(checkGroups);
    updatedCheckGroups.splice(groupIndex, 1);
    setCheckGroups(updatedCheckGroups);
  };

  const label = <Trans>LOAN_CONFIGURATIONS.CHECKS.GROUP_NAME</Trans>;

  return (
    <>
      <NxInput label={label} className={styles.input} value={groupName} onChange={handleGroupNameChange} />
      <BinButton onClick={removeGroup} />
    </>
  );
}
