import React, {ReactElement} from 'react';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import ConsolidatedLoansDialogContent from './ConsolidatedLoansDialogContent';
import {Trans} from 'react-i18next';

const COMMON_TRANS_PREFIX = 'COMMON';

interface Props {
  close: () => void;
  isVisible: boolean;
}

const AddConsolidatedLoansDialog = ({close, isVisible}: Props): ReactElement => {
  return (
    <Dialog maxWidth='xl' open={isVisible} onClose={close}>
      <DialogContent>
        <ConsolidatedLoansDialogContent />
      </DialogContent>
      <DialogActions>
        <NxButton variant={NxButtonVariant.CLOSE} onClick={close}>
          <Trans>{`${COMMON_TRANS_PREFIX}.CLOSE`}</Trans>
        </NxButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddConsolidatedLoansDialog;
