import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React from 'react';
import {Trans} from 'react-i18next';
import EmptyState from '../../../../../../../shared/empty-state/EmptyState';
import {PrefixTrans} from '../../../CustomerData';
import styles from './NewCustomerSelect.module.scss';

interface Props {
  addNewCustomer: () => void;
  resultsFound: boolean;
}

const NewCustomerSelect = ({addNewCustomer, resultsFound}: Props): React.ReactElement => {

  const StillNoMatchingInstruction = (
    <div className={styles.noMatchingInstruction}>
      <PrefixTrans>MAKE_SURE_NOT_MATCHES_INSTRUCTION</PrefixTrans>
    </div>
  );

  const NoResultsInstruction = (
    <>
      <div>
        <PrefixTrans>MAKE_SURE_CORRECT_DATA_INSTRUCTION</PrefixTrans>
      </div>
      <PrefixTrans>CLICK_INSTRUCTION</PrefixTrans>
    </>
  );

  const ActionButton = (
    <NxButton variant={NxButtonVariant.ADD} onClick={addNewCustomer}>
      <PrefixTrans>NEW_CUSTOMER</PrefixTrans>
    </NxButton>
  );

  const header = resultsFound
    ? <PrefixTrans>STILL_NO_MATCHING_RESULTS</PrefixTrans>
    : <Trans>COMMON.NO_RESULTS</Trans>;

  return <EmptyState header={header}
                     instruction={resultsFound ? StillNoMatchingInstruction : NoResultsInstruction}
                     actionButton={ActionButton} />;

};

export default NewCustomerSelect;
