import {NxFormikTextarea} from '@nextbank/ui-components';
import React, {useContext} from 'react';
import {TransHelper} from '../../../../../utils/trans-helper';
import {StepContext} from '../shared/loan-application-step/LoanApplicationStep';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.PRE_RELEASE');

export default function PreReleaseCommentsField(): React.ReactElement {

  const {isStepReadonly} = useContext(StepContext);

  return (
    <NxFormikTextarea name='comments'
                      label={<PrefixTrans>RELEASE_COMMENTS</PrefixTrans>}
                      disabled={isStepReadonly} />
  );
}
