import {NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {useContext, useState} from 'react';
import {PhaseName} from '../../../../../constants/api-urls';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {Phase} from '../../../../../shared/model/phase.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {DraggableCheckGroup} from '../../../shared/draggable-checks/draggable-checks.model';
import {
  KeyedApprovalRule
} from '../../loan-configuration-step/approval-configuration/approval-rule-items/keyed-approval-rules.model';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {useSetCheckGroupsFromConfig} from '../hooks/use-set-check-groups-from-config';
import PreReleaseForm from './PreReleaseForm';

export default function PreRelease(): React.ReactElement {

  const {process, configurationChanged, setConfigurationChanged} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.PRE_RELEASE);
  const config = useSingleQuery<Phase>(phaseUrl);
  const [checkGroups, setCheckGroups] = useState<DraggableCheckGroup[]>([]);

  useSetCheckGroupsFromConfig(config, setCheckGroups);

  const updateCheckGroups = (checkGroups: DraggableCheckGroup[]): void => {
    if (!configurationChanged) {
      setConfigurationChanged(true);
    }

    setCheckGroups(checkGroups);
  };

  return isNil(config)
    ? <NxLoader />
    : <PreReleaseForm config={config} checkGroups={checkGroups} updateCheckGroups={updateCheckGroups} />;
}
