import {DictionaryCode, DictionaryEntryGeolocationLevel} from '../../../../../../constants/dictionary-code';
import useCorporateDataDictionaries, {
  CorporateDataDictionaries
} from '../../../../../../shared/hooks/use-corporate-data-dictionaries.hook';
import useDictionaryEntriesByCode from '../../../../../../shared/hooks/use-dictionary-entries-by-code.hook';
import useIndividualDataDictionaries, {
  IndividualDataDictionaries
} from '../../../../../../shared/hooks/use-individual-data-dictionaries.hook';
import {DictionaryEntry} from '../../../../../../shared/model/dictionary.model';
import {DictionaryHelper} from '../../../../../../utils/dictionary-helper';
import {areNotNil} from '../../../../../../utils/empty-checks-utils';
import {
  CustomerDataPhase
} from '../../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';

export interface CustomerDataApplicationDictionaryState {
  areEntriesLoaded?: boolean;
  entries: CustomerDataApplicationDictionaries;
}

export interface CustomerDataApplicationDictionaries {
  individualDataDictionaryEntries: IndividualDataDictionaries;
  corporateDataDictionaryEntries: CorporateDataDictionaries;
  province?: DictionaryEntry[];
  city?: DictionaryEntry[];
  barangay?: DictionaryEntry[];
  country?: DictionaryEntry[];
  natureOfWork?: DictionaryEntry[];
  natureType?: DictionaryEntry[];
  businessType?: DictionaryEntry[];
  employerType?: DictionaryEntry[];
  birthCountry?: DictionaryEntry[];
}

export default function
  useCustomerDataApplicationDictionaryEntries(config?: CustomerDataPhase): CustomerDataApplicationDictionaryState {

  const {individualDataDictionaryEntries, areIndividualDataDictionaryEntriesLoaded} =
    useIndividualDataDictionaries(config);
  const {corporateDataDictionaryEntries, areCorporateDataDictionaryEntriesLoaded} =
    useCorporateDataDictionaries(config);
  const geolocation = useDictionaryEntriesByCode(DictionaryCode.GEOLOCATION);

  const province = geolocation?.filter(geolocation => geolocation?.level === DictionaryEntryGeolocationLevel.PROVINCE);
  const city = geolocation?.filter(geolocation => geolocation?.level === DictionaryEntryGeolocationLevel.CITY);
  const barangay = geolocation?.filter(geolocation => geolocation?.level === DictionaryEntryGeolocationLevel.BARANGAY);
  const country = useDictionaryEntriesByCode(DictionaryCode.COUNTRY);
  const natureOfWork = useDictionaryEntriesByCode(DictionaryCode.NATURE_OF_WORK_OR_BUSINESS);
  const natureType = useDictionaryEntriesByCode(DictionaryCode.NATURE_TYPE);
  const businessType = useDictionaryEntriesByCode(DictionaryCode.BUSINESS_TYPE);
  const employerType = useDictionaryEntriesByCode(DictionaryCode.EMPLOYER_TYPE);

  return {
    areEntriesLoaded: areIndividualDataDictionaryEntriesLoaded && areCorporateDataDictionaryEntriesLoaded &&
      areNotNil([province, country, natureOfWork, natureType, businessType]),
    entries: {
      individualDataDictionaryEntries,
      corporateDataDictionaryEntries,
      province,
      city,
      barangay,
      country: DictionaryHelper.movePhilippinesDictionaryToTop(country),
      natureOfWork,
      natureType,
      businessType,
      employerType,
      birthCountry: DictionaryHelper.movePhilippinesDictionaryToTop(country)
    }
  };
}
