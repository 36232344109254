import React, {useContext, useRef} from 'react';
import SidePanel, {CloseSidePanel} from '../../../shared/side-panel/SidePanel';
import {AccessesContext, PrefixTrans} from '../Access';
import {EditAccessPanelState} from '../access.model';
import EditAccessForm from './edit-access-form/EditAccessForm';
import styles from './EditAccessSidePanel.module.scss';

interface Props {
  state: EditAccessPanelState;
}

export default function EditAccessSidePanel({state}: Props): React.ReactElement {

  const {panelOpen, role} = state;
  const {id, name, permissions} = role ?? {};
  const {updateAccesses, setEditPanelState} = useContext(AccessesContext);
  const panelRef = useRef<CloseSidePanel>(null);

  const setOpen = (panelOpen: boolean): void => setEditPanelState({...state, panelOpen});

  const closePanel = (): void =>
    setOpen(false);

  const onSave = (): void => {
    closePanel();
    updateAccesses();
  };

  const header = <PrefixTrans>EDIT_ACCESS</PrefixTrans>;

  return (
    <SidePanel ref={panelRef} open={panelOpen} setOpen={setOpen} header={header}>
      <>
        <div className={styles.description}>
          <span className={styles.text}><PrefixTrans>ROLE</PrefixTrans></span> {name}
        </div>
        <EditAccessForm permissions={permissions} roleId={id} onSave={onSave} onCancel={closePanel} />
      </>
    </SidePanel>
  );
}
