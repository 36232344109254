import {AxiosResponse} from 'axios';
import isNil from 'lodash/isNil';
import {SimpleErrorBody} from './http-error.model';

export class HttpError<T extends SimpleErrorBody> extends Error {
  constructor(public response: AxiosResponse, public error: T) {
    super(HttpError.getHttpError(response, error));
  }

  private static getHttpError = <E extends SimpleErrorBody>(response: AxiosResponse, error: E): string => {
    if (!isNil(error?.errorMessage)) {
      return error.errorMessage;
    }

    const statusMessage = response?.status ? `\nStatus: ${response.status}` : '';
    const errorMessage = error ? `\n${JSON.stringify(error)}` : '';

    return `Http Error${statusMessage}${errorMessage}`;
  };
}
