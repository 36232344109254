import React, {ReactElement, ReactNode, useContext} from 'react';
import {Redirect, RouteComponentProps} from 'react-router';
import {Route, Switch} from 'react-router-dom';
import {SecurityContext} from '../../../App';
import {KeyedRouteProps} from '../../../routes/routes.model';
import {RoutePaths} from '../../../routes/routes.paths';
import {getPermittedRootPath} from '../../../utils/permissions-utils';
import PageNotFound from './page-not-found/PageNotFound';

interface Props {
  routes: KeyedRouteProps[];
}

const renderRoute = (route: KeyedRouteProps): (props: RouteComponentProps) => ReactNode =>
  route.render ?? ((props): ReactNode => {
    if (!route.component) {
      throw new Error('Render or component property must be provided in route configuration');
    }

    return <route.component {...props} />;
  });

const RenderRoutes = ({routes}: Props): ReactElement => {

  const {userData} = useContext(SecurityContext);

  return (
    <Switch>
      {
        routes.map(route => <Route key={route.key} path={route.path} exact={route.exact} render={renderRoute(route)} />)
      }
      <Route exact path={RoutePaths.ROOT}>
        <Redirect to={getPermittedRootPath(userData)} />
      </Route>
      <PageNotFound />
    </Switch>
  );
};

export default RenderRoutes;
