import {NxFormikCheckbox, NxFormikNumberInput} from '@nextbank/ui-components';
import {Formik, FormikProps} from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import values from 'lodash/values';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {PhaseName} from '../../../../../constants/api-urls';
import {UrlParams} from '../../../../../routes/routes.model';
import {CorporateDataOptions} from '../../../../../shared/hooks/use-corporate-data-options.hook';
import {CustomerDataRequirementEntries} from '../../../../../shared/hooks/use-customer-data-requirements.hook';
import {IndividualDataOptions} from '../../../../../shared/hooks/use-individual-data-options.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import usePut from '../../../../../shared/hooks/use-put.hook';
import {CustomerType} from '../../../../../shared/model/customer-type.model';
import {Field} from '../../../../../shared/model/field.model';
import {PhaseValidationResult} from '../../../../../shared/model/phase-validation-result.model';
import {CorporateDataFormFieldsConfiguration} from '../../../../../shared/model/step-forms/corporate-data-form.model';
import {IndividualDataFormFieldsConfiguration} from '../../../../../shared/model/step-forms/individual-data-form.model';
import {
  ThirdPartySystemDataFormFieldsConfiguration
} from '../../../../../shared/model/step-forms/third-party-system-form.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {getCompanyDataInitFields} from '../../../../../utils/step-form-utils/corporate-data-init-fields';
import {
  getThirdPartySystemDataInitFields
} from '../../../../../utils/step-form-utils/third-party-system-data-init-fields';
import {
  getIndividualDataInitFields,
  INDIVIDUAL_DATA_FIELD_CODES
} from '../../../../../utils/step-form-utils/individual-data-init-fields';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import CustomerDataTabs from '../../../../shared/customer-data-tabs/CustomerDataTabs';
import {
  toApprovalRule
} from '../../loan-configuration-step/approval-configuration/approval-rule-items/keyed-approval-rules.model';
import LoanConfigurationStep from '../../loan-configuration-step/LoanConfigurationStep';
import {getInitialApprovalRulesValues} from '../hooks/use-initial-approval-rules-values.hook';
import {useSetConfigurationFormChanged} from '../hooks/use-set-configuration-form-changed.hook';
import CorporateData from './corporate-data/CorporateData';
import CustomFields from './custom-fields/CustomFields';
import {KeyedFieldGroup, toFieldGroup} from './custom-fields/keyed-fields.model';
import {CustomerDataConfigFormFields} from './customer-data-form.model';
import {CustomerDataPhase} from './customer-data-phase.model';
import {getFieldNameByCode} from './customer-data-util';
import CustomerTypeSwitch from './customer-type-switch/CustomerTypeSwitch';
import styles from './CustomerDataForm.module.scss';
import IndividualData from './individual-data/IndividualData';
import Requirements from './requirements/Requirements';

interface Props {
  config: CustomerDataPhase;
  fieldGroups: KeyedFieldGroup[];
  updateFieldGroups: (fieldGroups: KeyedFieldGroup[]) => void;
  requirementEntries: CustomerDataRequirementEntries;
  individualDataOptions: IndividualDataOptions;
  corporateDataOptions: CorporateDataOptions;
}

const customerTypeLocalStoragePath = 'config.customerData.customerType';

const CUSTOMER_DATA_CONFIG_TRANS = 'LOAN_CONFIGURATIONS.CUSTOMER_DATA';

export default function CustomerDataForm(
  {config, fieldGroups, updateFieldGroups, requirementEntries, individualDataOptions, corporateDataOptions}: Props
): ReactElement {

  const {t} = useTranslation();
  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {processId} = useParams<UrlParams>();
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(processId, PhaseName.INDIVIDUAL_CUSTOMER_PROFILING);
  const updatePhase = usePut<void, CustomerDataPhase>(phaseUrl);
  const validatePhase = usePost<PhaseValidationResult, CustomerDataPhase>(`${phaseUrl}/validate`);

  /**
   * Returns static fields list. Field for active customer type will be taken in case of field common for both customer
   * type like `Risk level`;
   */
  const getStaticFields = (
    customerType: CustomerType,
    individualData: IndividualDataFormFieldsConfiguration,
    corporateData: CorporateDataFormFieldsConfiguration,
    thirdPartySystemData: ThirdPartySystemDataFormFieldsConfiguration
  ): Field[] => {
    const duplicatedStaticFields =
      customerType == CustomerType.CORPORATE
        ? [...values(individualData), ...values(corporateData), ...values(thirdPartySystemData)]
        : [...values(corporateData), ...values(individualData), ...values(thirdPartySystemData)];

    return Array.from(new Map(duplicatedStaticFields.map((item) => [item['id'], item])).values());
  };

  const buildPhaseConfig = (formFields: CustomerDataConfigFormFields): CustomerDataPhase => {
    const {
      approvalRules,
      isApprovalRequired,
      individualData,
      corporateData,
      idDocumentRequirement,
      addressRequirement,
      incomeRequirement,
      minIdDocumentsAmount,
      minIncomeSourcesAmount,
      minAddressesAmount,
      customerType,
      customerTypeLimited,
      thirdPartySystemData
    } = formFields;

    const staticFields = getStaticFields(customerType, individualData, corporateData, thirdPartySystemData);

    return {
      ...config,
      approvalRules: isApprovalRequired ? approvalRules?.map(toApprovalRule) : [],
      dynamicFieldGroups: fieldGroups.map(toFieldGroup),
      staticFields,
      idDocumentRequirement,
      addressRequirement,
      incomeRequirement,
      minIdDocumentsAmount,
      minIncomeSourcesAmount,
      minAddressesAmount,
      customerType: customerTypeLimited ? customerType : undefined
    };
  };

  const submit = async (formFields: CustomerDataConfigFormFields): Promise<void> =>
    await updatePhase(buildPhaseConfig(formFields)).catch(error => showErrorMessage(error.message));

  const getFormInitialValues = (config: CustomerDataPhase): CustomerDataConfigFormFields => {

    const individualData = getIndividualDataInitFields(config);
    const corporateData = getCompanyDataInitFields(config);
    const thirdPartySystemData = getThirdPartySystemDataInitFields(config);
    const {
      idDocumentRequirement,
      addressRequirement,
      incomeRequirement,
      minIdDocumentsAmount,
      minIncomeSourcesAmount,
      minAddressesAmount,
      customerType
    } = config;

    return {
      ...getInitialApprovalRulesValues(config),
      individualData,
      corporateData,
      idDocumentRequirement,
      addressRequirement,
      incomeRequirement,
      minIdDocumentsAmount,
      minIncomeSourcesAmount,
      minAddressesAmount,
      customerType: customerType ?? localStorage.getItem(customerTypeLocalStoragePath) as CustomerType ?? CustomerType.INDIVIDUAL,
      customerTypeLimited: !isNil(customerType),
      thirdPartySystemData
    };
  };

  const StepForm = ({
                      values, handleSubmit, submitForm, setFieldValue, dirty, setFieldError, errors
                    }: FormikProps<CustomerDataConfigFormFields>): ReactElement => {

    useEffect(() => {
      if (!isNil(values.customerType)) {
        localStorage.setItem(customerTypeLocalStoragePath, values.customerType);
      }
    }, [values.customerType]);

    useSetConfigurationFormChanged(dirty);

    const validate = (): Promise<PhaseValidationResult> => validatePhase(buildPhaseConfig(values));

    const handleValidation = (validationResult: PhaseValidationResult): void => {
      validationResult.errors.forEach(result => {
        let fieldPath = result.fieldPath;
        const pathTokens = fieldPath.split('.');

        if (pathTokens[0] === 'staticFields') {
          // Replaces field code to field name in object
          pathTokens[pathTokens.length - 1] = getFieldNameByCode(pathTokens[pathTokens.length - 1],
            INDIVIDUAL_DATA_FIELD_CODES);
          pathTokens[0] = 'individualData';
          fieldPath = pathTokens.join('.');
        }

        setFieldError(fieldPath, result.messages.join('. '));
      });
    };

    const getRequirementsTabPanel = (key: keyof CustomerDataRequirementEntries,
                                     minAmountFieldKey: string,
                                     minAmountFieldTransKey: string): ReactElement => (
      <>
        <NxFormikNumberInput label={t(CUSTOMER_DATA_CONFIG_TRANS + '.' + minAmountFieldTransKey)}
                             name={minAmountFieldKey}
                             className={styles.minAmount}
                             min={0} />
        <Requirements requirementKey={key} requirement={values[key]} entries={requirementEntries?.[key]} />;
      </>
    );

    const customFieldsTab =
      <CustomFields setFieldGroups={updateFieldGroups} fieldGroups={fieldGroups} phaseId={config.id} />;

    const customerDataTab = values.customerType === CustomerType.CORPORATE ?
      {
        corporateDataTab: {
          error: !isEmpty(errors.corporateData),
          tabPanel: <CorporateData values={values.corporateData}
                                   setFieldValue={setFieldValue}
                                   thirdPartySystemValues={values.thirdPartySystemData}
                                   options={corporateDataOptions} />
        }
      } : {
        individualDataTab: {
          error: !isEmpty(errors.individualData),
          tabPanel: <IndividualData options={individualDataOptions}
                                    values={values.individualData}
                                    thirdPartySystemValues={values.thirdPartySystemData}
                                    setFieldValue={setFieldValue} />
        }
      };

    const tabs = {
      ...customerDataTab,
      documentsTab: {
        error: !isEmpty(errors.idDocumentRequirement) || !isEmpty(errors.minIdDocumentsAmount),
        tabPanel: getRequirementsTabPanel('idDocumentRequirement',
          'minIdDocumentsAmount',
          'DOCUMENTS_MIN_AMOUNT')
      },
      addressesTab: {
        error: !isEmpty(errors.addressRequirement) || !isEmpty(errors.minAddressesAmount),
        tabPanel: getRequirementsTabPanel('addressRequirement', 'minAddressesAmount', 'ADDRESSES_MIN_AMOUNT')
      },
      incomeTab: {
        error: !isEmpty(errors.incomeRequirement) || !isEmpty(errors.minIncomeSourcesAmount),
        tabPanel: getRequirementsTabPanel('incomeRequirement', 'minIncomeSourcesAmount', 'INCOMES_MIN_AMOUNT')
      },
      customFieldsTab: {
        tabPanel: customFieldsTab
      }
    };

    return (
      <LoanConfigurationStep handleSave={submitForm}
                             validationFunction={validate}
                             handleValidation={handleValidation}>
        <form onSubmit={handleSubmit}>
          <NxFormikCheckbox name={'customerTypeLimited'}>
            {t(CUSTOMER_DATA_CONFIG_TRANS + '.APPLICANT_DATA.CUSTOMER_TYPE_CONSTRAINT')}
          </NxFormikCheckbox>
          <CustomerTypeSwitch />
          <CustomerDataTabs tabs={tabs} />
        </form>
      </LoanConfigurationStep>
    );
  };

  return (
    <Formik<CustomerDataConfigFormFields> onSubmit={submit} initialValues={getFormInitialValues(config)}>
      {StepForm}
    </Formik>
  );
}
