import {TFunction} from 'i18next';
import {AnySchema, object, string, number} from 'yup';
import {getDefaultRequiredError} from '../../../../../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';

export const getApprovalExecutionValidationSchema = (t: TFunction, rejectVariant: boolean): AnySchema => {

  const REQUIRED_ERROR = getDefaultRequiredError(t);

  const approveSchema = object().shape({
    approverId: number().required(REQUIRED_ERROR),
    comment: string()
  });

  const rejectSchema = object().shape({
    approverId: number().required(REQUIRED_ERROR),
    comment: string().required(REQUIRED_ERROR)
  });

  return rejectVariant ? rejectSchema : approveSchema;
};
