import {NxButton, NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {createContext, ReactElement, useContext, useEffect, useState} from 'react';
import {CATEGORIES_URL} from '../../../constants/api-urls';
import useGet from '../../../shared/hooks/use-get.hook';
import {Category} from '../../../shared/model/category.model';
import {TransHelper} from '../../../utils/trans-helper';
import {AppSnackbarContext} from '../../shared/app-snackbar-provider/AppSnackbarProvider';
import CategoriesTable from './categories-table/CategoriesTable';
import {CategoriesContextType, EditCategoryPanelState} from './categories.model';
import EditCategorySidePanel from './edit-category-side-panel/EditCategorySidePanel';
import styles from './Categories.module.scss';

export const CategoriesContext = createContext<CategoriesContextType>({} as CategoriesContextType);

export const PrefixTrans = TransHelper.getPrefixedTrans('ADMIN.CATEGORIES');

export const Categories = (): ReactElement => {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const fetchCategories = useGet<Category[]>(CATEGORIES_URL);
  const [categories, setCategories] = useState<Category[]>();
  const [editPanelState, setEditPanelState] = useState<EditCategoryPanelState>({panelOpen: false});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = (): void => {

    setLoading(true);

    fetchCategories()
      .then(categories => setCategories(categories))
      .catch(error => showErrorMessage(error.message))
      .finally(() => setLoading(false));
  }

  const areCategoriesNotEmpty = categories && categories.length > 0;
  const openNewCategoryPanel = (): void => setEditPanelState({panelOpen: true});

  return (
    <>
      {
        isNil(categories) ? <NxLoader /> :
          <CategoriesContext.Provider value={{categories, refreshCategories: loadCategories, setEditPanelState, setLoading}}>
            <div className={styles.header}>
              {
                areCategoriesNotEmpty ? <PrefixTrans>INSTRUCTION</PrefixTrans> : <PrefixTrans>EMPTY_STATE</PrefixTrans>
              }
              <NxButton className={styles.button} onClick={openNewCategoryPanel}>
                <PrefixTrans>NEW_CATEGORY</PrefixTrans>
              </NxButton>
            </div>
            {
              loading ? <NxLoader /> :
                areCategoriesNotEmpty && <CategoriesTable />
            }
            <EditCategorySidePanel state={editPanelState} />
          </CategoriesContext.Provider>
      }
    </>
  );
};
