import {Tooltip} from '@material-ui/core';
import isNil from 'lodash/isNil';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as InfoIcon} from '../../../../../../assets/images/icon-info.svg';
import styles from './ConfiguredFieldWrapper.module.scss';
import {FieldDisablementReason} from './field-disablement-reason.model';

interface Props {
  className?: string;
  children: React.ReactNode;
  enabled?: boolean;
  disablementReason?: FieldDisablementReason;
  hint?: React.ReactNode;
}

export const ConfiguredFieldWrapper = (
  {className, children, enabled = true, disablementReason, hint}: Props
): ReactElement => {

  const {t} = useTranslation();
  // TODO move disablement reason to hint
  const getTooltip = (reason: FieldDisablementReason): string => t(`COMMON.TOOLTIPS.DISABLED_FIELDS.${reason}`);

  return (
    <div className={className}>
      {
        enabled && (
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              {children}
            </div>
            {
              !isNil(disablementReason) &&
              <Tooltip arrow title={getTooltip(disablementReason)} className={styles.icon} placement='right'>
                <InfoIcon />
              </Tooltip>
            }
            {
              !isNil(hint) &&
              isNil(disablementReason) &&
              <Tooltip arrow title={hint} className={styles.icon} placement='right'>
                <InfoIcon />
              </Tooltip>
            }
          </div>
        )
      }
    </div>
  );
};
