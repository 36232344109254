import {Tooltip} from '@material-ui/core';
import {NxSelectOption} from '@nextbank/ui-components';
import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {ReactComponent as InfoIcon} from '../../../../../../assets/images/icon-info.svg';
import {ConfiguredField, DocumentField, FieldType} from '../../../../../../shared/model/field.model';
import {NxFile} from '../../../../../../shared/model/nx-file.model';
import {NumberHelper} from '../../../../../../utils/number-helper';
import {YES_NO_SELECT_OPTIONS} from '../../../../../shared/form-bolean-option-values/YesNoSelectOptions';
import {
  ConfiguredDocumentInputGroup as DocumentInput
} from './configured-document-input-group/ConfiguredDocumentInputGroup';
import styles from './ConfiguredCustomField.module.scss';
import {ConfiguredFieldCashInput as CashInput} from './ConfiguredFieldCashInput';
import {ConfiguredFieldDatePicker as DatePicker} from './ConfiguredFieldDatePicker';
import {ConfiguredFieldFileInput as FileInput} from './ConfiguredFieldFileInput';
import {ConfiguredFieldMultiSelect as MultiSelect} from './ConfiguredFieldMultiSelect';
import {ConfiguredFieldNumberInput as NumberInput} from './ConfiguredFieldNumberInput';
import {ConfiguredFieldPercentageInput as PercentageInput} from './ConfiguredFieldPercentageInput';
import {ConfiguredFieldSelect as Select} from './ConfiguredFieldSelect';
import {ConfiguredFieldTexArea} from './ConfiguredFieldTextArea';
import {FieldDisablementReason} from './field-disablement-reason.model';

interface Props {
  type?: FieldType;
  field: ConfiguredField | ConfiguredField<NxFile> | ConfiguredField<DocumentField>,
  label: React.ReactNode,
  customOptions?: NxSelectOption[];
  disablementReason?: FieldDisablementReason;
}

export const ConfiguredCustomField = ({field, label, customOptions, type, disablementReason}: Props): ReactElement => {

  const overflowingText = useRef<HTMLDivElement | null>(null);
  const [overflowActive, setOverflowActive] = useState<boolean>(false);

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [overflowActive]);

  const checkOverflow = (textContainer: HTMLDivElement | null): boolean => {
    if (textContainer) {
      return (textContainer.offsetWidth < textContainer.scrollWidth);
    }
    return false;
  };

  const fieldLabel = (): ReactElement => (
    <div className={styles.labelContainer}>
      <div className={styles.labelContent} ref={overflowingText}>{label}</div>
      {
        overflowActive && label && <Tooltip arrow title={label.toString()}>
          <div className={styles.infoToolip}><InfoIcon className={styles.infoIcon} /></div>
        </Tooltip>
      }
    </div>
  );

  const props = {name: field.name ?? '', field: field as ConfiguredField, label: fieldLabel(), disablementReason};

  switch (type) {
    case FieldType.BOOLEAN:
      return <Select {...props} options={YES_NO_SELECT_OPTIONS} />;
    case FieldType.CASH:
      return <CashInput {...props} field={{...field, value: NumberHelper.toNumberOrUndefined(field.value)}} />;
    case FieldType.DATE:
      return <DatePicker {...props} />;
    case FieldType.DICTIONARY_MULTI:
      return <MultiSelect {...props} options={customOptions} />;
    case FieldType.DICTIONARY_SINGLE:
      return <Select {...props} options={customOptions} />;
    case FieldType.DOCUMENT:
      return <DocumentInput {...props} configuredField={field as ConfiguredField<DocumentField>} />;
    case FieldType.FILE:
      return <FileInput {...props} configuredField={field as ConfiguredField<NxFile>} />;
    case FieldType.FLOAT:
      return <NumberInput {...props} step={0.01}
                          field={{...field, value: NumberHelper.toNumberOrUndefined(field.value)}} />;
    case FieldType.INTEGER:
      return <NumberInput {...props} step={1}
                          field={{...field, value: NumberHelper.toNumberOrUndefined(field.value)}} />;
    case FieldType.PERCENTAGE:
      return <PercentageInput {...props} field={{...field, value: NumberHelper.toNumberOrUndefined(field.value)}} />;
    case FieldType.STRING:
      return <ConfiguredFieldTexArea {...props} />;
    default:
      return <></>;
  }
};
