import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {NxIconButton} from '@nextbank/ui-components';
import React, {forwardRef, ReactElement, ReactNode, useImperativeHandle} from 'react';
import {ReactComponent as CloseIcon} from '../../../assets/images/icon-close.svg';
import styles from './SidePanel.module.scss';

export type CloseSidePanel = {
  close: () => void
}

interface Props {
  children: ReactElement;
  open: boolean;
  setOpen: (open: boolean) => void;
  header?: ReactNode;
}

/**
 * On click outside panel is hidden. Only on panel shown children element is created and rendered.
 *
 * @param children to be rendered inside panel.
 * @param ref an reference to close side panel button.
 */
function SidePanel({children, header, open, setOpen}: Props, ref): ReactElement {

  const hideDrawer = (): void => setOpen(false);
  const showDrawer = (): void => setOpen(true);

  useImperativeHandle(ref, () => ({
    close: (): void => hideDrawer()
  }));

  return (
    <SwipeableDrawer anchor={'right'} open={open} onClose={hideDrawer} onOpen={showDrawer}>
      <div className={styles.drawer} role='presentation'>
        <div className={styles.header}>
          {header}
          <div className={styles.close}>
            <NxIconButton onClick={hideDrawer} icon={<CloseIcon />} />
          </div>
        </div>
        {
          open && children
        }
      </div>
    </SwipeableDrawer>
  );
}

export default forwardRef<CloseSidePanel, Props>(SidePanel);
