import {NxSwitch} from '@nextbank/ui-components';
import React, {ChangeEvent} from 'react';
import {Trans} from 'react-i18next';
import {GeneralFormFields, MOBILE_ENABLED, OTC_ENABLED, WEB_ENABLED} from '../general.model';
import styles from './Channels.module.scss';

interface Props {
  values: GeneralFormFields;
  handleChange: (e: ChangeEvent) => void;
}

export default function Channels({values, handleChange}: Props): React.ReactElement {

    const channels = [
      { name: OTC_ENABLED, transLabel: 'BRANCH' },
      { name: MOBILE_ENABLED, transLabel: 'MOBILE' },
      { name: WEB_ENABLED, transLabel: 'DESKTOP' }
    ];

    return (
      <>
        {
          channels.map(({name, transLabel}) =>
            <div className={styles.row} key={name}>
              <NxSwitch name={name} checked={values[name]} onChange={handleChange} />
              <Trans>{`COMMON.CHANNELS.${transLabel}`}</Trans>
            </div>
          )
        }
      </>
    );
}
