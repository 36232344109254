import * as H from 'history';
import {LocationDescriptor} from 'history';
import isString from 'lodash/isString';
import {useContext} from 'react';
import {useHistory} from 'react-router';
import {RoutePaths} from '../../routes/routes.paths';
import {GuardedHistory} from './guarded-history.model';
import {GuardedRouterActionType} from './guarded-router-context.model';
import {GuardedRouterContext} from './GuardedRouterContext';

// Array stores parent paths under what there is no need to invoke guard
const ParentPaths = [
  RoutePaths.CUSTOMER_DATA
];

export function useGuardedHistory<LocationState = H.LocationState>(): GuardedHistory<LocationState> {

  const history = useHistory<LocationState>();
  const {routerContextState, dispatchAction} = useContext(GuardedRouterContext);

  type Location = LocationDescriptor<LocationState>;
  const push = (location: Location): void => handleRouteLock((): void => history.push(location), location);
  const replace = (location: Location): void => handleRouteLock(() => history.replace(location), location);
  const forcePush = (location: Location): void => unlockAndPerformRouteChange(() => history.push(location));

  const handleRouteLock = (onRouteChange: () => void, location: Location): void => {
    if (!checkIfGuardRequired(location)) {
      onRouteChange();
      return;
    }

    dispatchAction({
      type: GuardedRouterActionType.SHOW_CONFIRMATION_POPUP,
      onRouteChangeConfirm: () => unlockAndPerformRouteChange(onRouteChange)
    });
  };

  const checkIfGuardRequired = (direction: Location): boolean => {
    if (!routerContextState.routeChangeLock) {
      return false;
    }

    // Checks if it is rerouting under any parent path. Assumption is there is no guard needed in such a situations.
    if (ParentPaths.filter(path => history.location.pathname.includes(path)
      && isString(direction)
      && direction.includes(path)).length > 0) {
      return false;
    }

    return true;
  };

  const unlockAndPerformRouteChange = (onRouteChange: () => void): void => {
    onRouteChange();
    dispatchAction({type: GuardedRouterActionType.UNLOCK_ROUTE_CHANGE});
  };

  return {
    ...history,
    forcePush,
    push,
    replace
  };
}

