import {Tooltip} from '@material-ui/core';
import {NxIconButton, NxLoader, NxLoaderColorVariant, NxLoaderVariant} from '@nextbank/ui-components';
import clsx from 'clsx';
import React from 'react';
import styles from './IconButtonSwitch.module.scss';

interface Props {
  ariaLabel?: string;
  ariaControlsId?: string;
  className?: string;
  icon: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  value?: boolean;
  tooltipTitle?: React.ReactNode;
  bordered?: boolean;
  disabled?: boolean;
  loaded?: boolean;
}

const IconButtonSwitch = (
  {
    ariaLabel,
    ariaControlsId,
    className,
    icon,
    onClick,
    tooltipTitle,
    value,
    bordered = true,
    disabled,
    loaded = true
  }: Props
): React.ReactElement => {

  const buttonClassName = clsx(
    styles.button,
    !!className && className,
    {
      [styles.button_bordered]: bordered,
      [styles.button_disabled]: disabled
    });

  const iconWrapperClassNames = clsx(styles.iconWrapper, {[styles.iconWrapper_checked]: value});

  const WrappedIconButton =
    <div className={buttonClassName} onClick={onClick}>
      <div className={iconWrapperClassNames}>
        {
          !loaded
            ? <NxLoader variant={NxLoaderVariant.SMALL} colorVariant={NxLoaderColorVariant.PRIMARY} />
            : <NxIconButton ariaControlsId={ariaControlsId} ariaLabel={ariaLabel} icon={icon} disabled={disabled} />
        }
      </div>
    </div>;

  // TODO LOS-31 style tooltip like in designs
  return (
    tooltipTitle
      ? <Tooltip arrow title={tooltipTitle}>
        <div>{WrappedIconButton}</div>
      </Tooltip>
      : WrappedIconButton
  );
};

export default IconButtonSwitch;
