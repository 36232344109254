import {NxNumberInput} from '@nextbank/ui-components';
import React, {useContext} from 'react';
import {ApprovalRule} from '../../../../../../shared/model/phase.model';
import BinButton from '../../../../../shared/bin-button/BinButton';
import {PrefixTrans} from '../../LoanConfigurationStep';
import {ApprovalRulesContext} from '../ApprovalConfiguration';
import {cloneDeep} from 'lodash';
import styles from './ApprovalRuleHeader.module.scss';

interface Props {
  rule: ApprovalRule;
  ruleIndex: number;
}

export default function ApprovalRuleHeader({rule, ruleIndex}: Props): React.ReactElement {

  const {rules, setRules} = useContext(ApprovalRulesContext);

  const handleAmountChange = (key: 'minAmount' | 'maxAmount'): (amount: number | null) => void =>
    (amount: number | null): void => {
      const updatedRules = cloneDeep(rules);
      updatedRules[ruleIndex][key] = amount ?? undefined;
      setRules(updatedRules)
    };

  const removeRule = (): void => {
    const updatedRules = cloneDeep(rules);
    updatedRules.splice(ruleIndex, 1);
    setRules(updatedRules)
  }

  return (
    <>
      <NxNumberInput className={styles.input}
                     label={<PrefixTrans>MINIMUM_LOAN_VALUE</PrefixTrans>}
                     onChange={handleAmountChange('minAmount')}
                     value={rule.minAmount}
                     min={0} />
      <NxNumberInput className={styles.input}
                     label={<PrefixTrans>MAXIMUM_LOAN_VALUE</PrefixTrans>}
                     onChange={handleAmountChange('maxAmount')}
                     value={rule.maxAmount}
                     min={0} />
      <BinButton onClick={removeRule} />
    </>
  );
}
