export interface DictionaryRequirement {
  id: number,
  dictionaryId: number,
  entryRequirements: DictionaryEntryRequirement[],
}

export interface DictionaryEntryRequirement {
  id: number,
  dictionaryRequirementId: number,
  dictionaryEntryId: number,
  enabled: boolean,
  requirement: FieldRequirement
}

export enum FieldRequirement {
  REQUIRED = 'REQUIRED',
  OPTIONAL = 'OPTIONAL'
}