import {NxButton, NxInput, NxSelect} from '@nextbank/ui-components';
import clsx from 'clsx';
import {cloneDeep} from 'lodash';
import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {DroppableProvided} from 'react-beautiful-dnd';
import {Trans, useTranslation} from 'react-i18next';
import {Field, FieldOrigination, FieldType} from '../../../../../../shared/model/field.model';
import {getFieldTypeOptions, isDictionaryType} from '../../../../../../utils/custom-fields-utils';
import {generateKey} from '../../../../../../utils/generate-key';
import {getInputValue} from '../../../../../../utils/input-utils';
import {StringHelper} from '../../../../../../utils/string-helper';
import CustomDictionarySidePanel from '../../../../../shared/custom-dictionary/CustomDictionarySidePanel';
import DragDropItem from '../../../../../shared/drag-drop/DragDropItem';
import DragDropItems from '../../../../../shared/drag-drop/DragDropItems';
import RequiredButtonSwitch from '../../../../../shared/icon-button-switches/RequiredButtonSwitch';
import SelfCareButtonSwitch from '../../../../../shared/icon-button-switches/SelfCareButtonSwitch';
import CustomField from './CustomField';
import styles from './CustomFieldGroup.module.scss';
import CustomFieldGroupHeader from './CustomFieldGroupHeader';
import {CustomFieldsContext} from './CustomFields';
import {KeyedField, KeyedFieldGroup} from './keyed-fields.model';
import isNil from 'lodash/isNil';

interface Props {
  fieldGroup: KeyedFieldGroup;
  provided: DroppableProvided;
  isDragging: boolean;
}

const newFieldInitValue: Field = {
  name: '',
  origination: FieldOrigination.CUSTOM,
  type: undefined,
  required: false,
  enabled: true,
  selfCare: false
};

export default function CustomFieldGroup({fieldGroup, provided, isDragging}: Props): React.ReactElement {

  const {t} = useTranslation();

  const {fieldGroups, setFieldGroups, phaseId} = useContext(CustomFieldsContext);
  const [newField, setNewField] = useState<Field>(newFieldInitValue);

  useEffect((): void => {
    if (getGroupIndex() <= -1) {
      throw new Error('Custom group index out of range.');
    }
  }, [fieldGroups]);

  const getGroupIndex = (): number => fieldGroups.findIndex(group => group.key === fieldGroup.key);

  const handleNewFieldNameChange = (event: ChangeEvent): void =>
    setNewField({...newField, name: getInputValue(event)});

  const handleNewFieldTypeChange = (type: string | null): void =>
    setNewField({...newField, type: type as FieldType});

  const setFieldSelfCare = (): void => setNewField({...newField, selfCare: !newField.selfCare});
  const setFieldRequired = (): void => setNewField({...newField, required: !newField.required});

  const setFields = (fields: KeyedField[]): void => {
    const updatedFieldGroups = cloneDeep(fieldGroups);
    updatedFieldGroups[getGroupIndex()].fields = fields;
    setFieldGroups(updatedFieldGroups);
  };

  const addField = (): void => {
    const updatedFieldGroups = cloneDeep(fieldGroups);
    updatedFieldGroups[getGroupIndex()].fields.push(
      {
        ...newField,
        ...generateKey()
      }
    );

    setFieldGroups(updatedFieldGroups);
    setNewField(newFieldInitValue);
  };

  const GroupHeader = <CustomFieldGroupHeader groupIndex={getGroupIndex()} groupName={fieldGroup.name} />;

  const customFieldItemFunction = (provided, isDragging, item): React.ReactElement =>
    <CustomField provided={provided} field={item} isDragging={isDragging} groupIndex={getGroupIndex()} />;

  const handleDictionarySave = (dictionaryId: number): void => setNewField({...newField, dictionaryId});

  return (
    <DragDropItem className={styles.container} provided={provided} isDragging={isDragging} item={GroupHeader}>
      <div className={styles.groupFields}>
        <DragDropItems<KeyedField>
          droppableId={`droppableCustomFields-${fieldGroup.key}`}
          items={fieldGroup.fields}
          setItems={setFields}
          itemComponentFunction={customFieldItemFunction} />
        <div className={styles.addRow}>
          <NxInput className={styles.input}
                   label={<Trans>COMMON.FIELDS.FIELD_NAME</Trans>}
                   onChange={handleNewFieldNameChange}
                   value={newField.name} />
          <NxSelect className={clsx(styles.input, styles.input_type)}
                    options={getFieldTypeOptions(t, true)}
                    label={<Trans>COMMON.FIELDS.TYPE</Trans>}
                    onChange={handleNewFieldTypeChange}
                    value={newField.type ?? ''} />
          {
            isDictionaryType(newField.type) &&
              <CustomDictionarySidePanel handleSave={handleDictionarySave}
                                         dictionaryId={newField.dictionaryId}
                                         phaseId={phaseId} />
          }
          <SelfCareButtonSwitch onClick={setFieldSelfCare} value={newField.selfCare} />
          <RequiredButtonSwitch onClick={setFieldRequired} value={newField.required} />
          <NxButton onClick={addField}
                    className={styles.addButton}
                    disabled={StringHelper.isBlank(newField.name) || isNil(newField.type)}>
            <Trans>COMMON.ADD</Trans>
          </NxButton>
        </div>
      </div>
    </DragDropItem>
  );
}
