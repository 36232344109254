import {Table, TableBody, TableContainer, TableRow} from '@material-ui/core';
import React, {ReactElement, useContext, useMemo, useState} from 'react';
import {Category} from '../../../../shared/model/category.model';
import {addParentsToCategoryName} from '../../../../utils/category-utils';
import TableDataCell from '../../../shared/table/cells/TableDataCell';
import {CategoriesContext} from '../Categories';
import CategoriesTableHead from './categories-table-head/CategoriesTableHead';
import CategoryMenu from './category-menu/CategoryMenu';
import styles from './CategoriesTable.module.scss';
import isNil from 'lodash/isNil';
import DeleteCategoryConfirmationPopup from './delete-category-confirmation-popup/DeleteCategoryConfirmationPopup';

const CategoriesTable = (): ReactElement => {

  const {categories} = useContext(CategoriesContext);
  const [categoryToDeletion, setCategoryToDeletion] = useState<number>();
  const [deleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] = useState(false);

  const onDeleteCategory = (categoryId: number): void => {
    setCategoryToDeletion(categoryId);
    setDeleteConfirmationPopupOpen(true);
  };

  const categoriesWithParentNames = useMemo(
    () => addParentsToCategoryName(categories, false), [categories]
  );

  const getParentCategoryName = (category: Category): string => {
    if (isNil(category.parentCategoryId)) {
      return '-';
    }

    const parentCategory = categoriesWithParentNames?.find(({id}) => id === category.id);

    return parentCategory ? parentCategory.name : '-';
  };

  return (
    <>
      <TableContainer>
        <Table>
          <CategoriesTableHead />
          <TableBody>
            {
              categories?.map((category, index) =>
                <TableRow key={category.id} className={styles.tableRow}>
                  <TableDataCell data={index + 1} />
                  <TableDataCell data={category.id} />
                  <TableDataCell data={category.name} />
                  <TableDataCell data={getParentCategoryName(category)} />
                  <TableDataCell data={<CategoryMenu category={category} onDeleteCategory={onDeleteCategory} /> } />
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteCategoryConfirmationPopup categoryId={categoryToDeletion}
                                       open={deleteConfirmationPopupOpen}
                                       setOpen={setDeleteConfirmationPopupOpen} />
    </>
  );
};

export default CategoriesTable;
