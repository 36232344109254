import {
  getPhaseRoutePath
} from '../components/loan-applications/dashboard/loan-applications-table/loan-applications-table-utils';
import {ApplicationBasicData} from '../components/loan-applications/loan-application/loan-application.model';
import {buildApplicationStepPath} from './loan-steps-utils';

export const createPathToApplication = (application: ApplicationBasicData): string => {

  const routePath = getPhaseRoutePath(application.currentPhaseName, application.currentPhaseId);
  return buildApplicationStepPath(routePath, application.processId, application.id);
};
