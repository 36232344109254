import isEmpty from 'lodash/isEmpty';
import {Phase, PhaseType} from '../shared/model/phase.model';

export const getCustomPhases = (phases?: Phase[]): Phase[] =>
  phases
    ? phases
      .filter(phase => phase.type === PhaseType.CUSTOM_PHASE)
      .sort((a, b) => a.orderNo - b.orderNo)
    : [];

export const getFirstRoleId = (roleIds: number[]): string => !isEmpty(roleIds) ? roleIds[0].toString() : '';
