import isEmpty from 'lodash/isEmpty';
import React, {ReactElement, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as InfoIcon} from '../../../../assets/images/icon-info.svg';
import {
  PhaseValidationResult,
  FieldError
} from '../../../../shared/model/phase-validation-result.model';
import DetailsButton from '../../../shared/details-button/DetailsButton';
import styles from './ConfigErrorResult.module.scss';

interface Props {
  results: PhaseValidationResult[];
}

const CUSTOM_PHASES_NAME = 'Custom phases';
const TRANS_PREFIX = 'LOAN_CONFIGURATIONS';

export default function ConfigErrorResult({results}: Props): React.ReactElement {

  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);
  const {t} = useTranslation();

  const handleCustomPhaseErrors = (customPhaseErrors: FieldError[]): PhaseValidationResult[] => {
    return customPhaseErrors.map(error => {
      const fieldPathArray = error.fieldPath.split('.');
      const phaseName = fieldPathArray.shift();

      return {
        phaseName,
        errors: [{...error, fieldPath: fieldPathArray.join('.')}]
      } as PhaseValidationResult;
    });
  };

  const parsedResults = useMemo(() => {
    const customPhaseErrors = results.filter(result => result.phaseName === CUSTOM_PHASES_NAME)
      .map(result => handleCustomPhaseErrors(result.errors))
      .flatMap(customPhaseValidationResult => customPhaseValidationResult);

    const parsedResults = results.filter(result => result.phaseName !== CUSTOM_PHASES_NAME);
    parsedResults.push(...customPhaseErrors);
    return parsedResults.filter(result => !isEmpty(result.errors));
  }, [results]);

  const phaseErrors = (phaseResult: PhaseValidationResult): ReactElement => (
    <div className={styles.phaseErrors} key={phaseResult.phaseName}>
      {phaseResult.phaseName}
      <div>
        {
          phaseResult.errors.map(error => fieldError(error))
        }
      </div>
    </div>
  );

  const fieldError = (error: FieldError): ReactElement => (
    <div className={styles.error} key={error.fieldPath}>
      <InfoIcon /> <span className={styles.fieldName}>{`${error.fieldName}:`}</span> {error.messages}
    </div>
  );

  const getErrorsCount = (): number => parsedResults.flatMap(value => value.errors).length;

  return (
    <div className={styles.container}>
      {moreDetailsOpen
        ? <div className={styles.errors}> {parsedResults.map(phaseErrors)} </div>
        : <div>{t(`${TRANS_PREFIX}.N_ERRORS_TO_FIX`, {errorsCount: getErrorsCount()})}</div>
      }
      <DetailsButton onClick={(): void => setMoreDetailsOpen(!moreDetailsOpen)}
                     isOpen={moreDetailsOpen}
                     iconOnly={true} />
    </div>
  );
}
