import clsx from 'clsx';
import React from 'react';
import {Link} from 'react-router-dom';
import {useGuardedHistory} from '../GuardedHistory';
import styles from './GuardedLink.module.scss';

interface Props {
  className?: string
  children: React.ReactNode,
  to: string,
}

export function GuardedLink({children, className, to}: Props): React.ReactElement {

  const history = useGuardedHistory();

  const onClickTest = (event): void => {
    event.preventDefault();
    history.push(to);
  }

  return (
    <Link to={to} onClick={onClickTest} className={clsx(styles.link, className)}>
      {children}
    </Link>
  );
}
