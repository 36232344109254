import {isObject} from 'lodash';
import {FileBasicData, NxFile} from './nx-file.model';

export interface Field<T_DEFAULT_VALUE = string> {
  id?: number;
  phaseId?: number;
  code?: string;
  origination: FieldOrigination;
  tag?: string;
  name: string;
  type?: FieldType;
  dictionaryId?: number;
  defaultValue?: T_DEFAULT_VALUE;
  enabled: boolean;
  required: boolean;
  selfCare: boolean;
  fieldGroupId?: number;
  orderNo?: number
}

export interface ConfiguredField<T_VALUE = string> {
  // ui-components returns null for invalid model
  value?: T_VALUE | null;
  enabled?: boolean;
  required?: boolean;
  name?: string;
  code?: string;
  type?: FieldType;
}

export enum FieldOrigination {
  PREDEFINED = 'PREDEFINED',
  CUSTOM = 'CUSTOM'
}

export enum FieldType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  FLOAT = 'FLOAT',
  CASH = 'CASH',
  PERCENTAGE = 'PERCENTAGE',
  DATE = 'DATE',
  FILE = 'FILE',
  DOCUMENT = 'DOCUMENT',
  DICTIONARY_SINGLE = 'DICTIONARY_SINGLE',
  DICTIONARY_MULTI = 'DICTIONARY_MULTI'
}

export interface DocumentFieldTO<T_FILE = FileBasicData> {
  id?: number;
  name: string;
  file: T_FILE;
  type: DocumentFieldType;
  number?: string;
  remarks?: string;
}

export interface DocumentField<T_FILE = NxFile> extends DocumentFieldTO<T_FILE> {
}

export const isDocumentField = (object?: unknown): object is DocumentField => {
  const documentField = object as DocumentField;
  return isObject(documentField) && 'file' in documentField && 'type' in documentField && 'name' in documentField;
};

export const fromDocumentFieldTO = (documentField: DocumentFieldTO, file: NxFile): DocumentField =>
  ({...documentField, file});

export enum DocumentFieldType {
  APPLICATION_FORM = 'APPLICATION_FORM',
  REQUIREMENTS = 'REQUIREMENTS',
  OTHERS = 'OTHERS'
}
