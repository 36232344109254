import React, {ReactElement} from 'react';
import {TABLE_DATE_FORMAT} from '../../../../constants/format-templates';
import {TransHelper} from '../../../../utils/trans-helper';
import TableDataCell from '../../../shared/table/cells/TableDataCell';
import LosTableRow from '../../../shared/table/row/LosTableRow';
import {BatchItem} from '../../batch.model';
import IsValidChip from './is-valid-chip/BatchItemIsValidChip';

interface Props {
  batchItem: BatchItem;
  onItemClick: (batchItem: BatchItem) => void;
}

export const TRANS_PREFIX = 'BATCH.BATCH_ITEMS.STATUS';
export const StatusPrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

const BatchTableRow = ({batchItem, onItemClick}: Props): ReactElement => {
  return (
    <LosTableRow onClick={(): void => onItemClick(batchItem)}>
      <>
        <TableDataCell data={batchItem.id} />
        <TableDataCell data={batchItem.referenceNo} />
        <TableDataCell data={batchItem.description ?? '-'} />
        <TableDataCell data={<IsValidChip isValid={batchItem.valid} status={batchItem.status} />} />
        <TableDataCell data={<StatusPrefixTrans>{batchItem.status}</StatusPrefixTrans>} />
        <TableDataCell data={batchItem.processedOn?.format(TABLE_DATE_FORMAT) ?? '-'} />
      </>
    </LosTableRow>
  );
};

export default BatchTableRow;
