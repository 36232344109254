import {useField} from 'formik';
import {FormikErrors} from 'formik/dist/types';
import isNil from 'lodash/isNil';
import React, {ReactElement, useState} from 'react';
import {CustomerDataRequirementEntries} from '../../../../../../shared/hooks/use-customer-data-requirements.hook';
import {Dictionary} from '../../../../../../shared/model/dictionary.model';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {
  CustomerDataPhase
} from '../../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {ApplicationRelationshipType, CustomerDataFormFields} from '../customer-data-form.model';
import CustomerSelect from '../customer-select/CustomerSelect';
import {CustomerDataApplicationDictionaries} from '../hooks/use-customer-data-application-dictionaries.hook';
import {ApplicantDataForm} from '../shared/applicant-data-form/ApplicantDataForm';
import {ApplicantContext} from '../shared/ApplicantContext';

export const CUSTOMER_STEP_TRANS_PREFIX = 'LOAN_APPLICATIONS.CUSTOMER_DATA';
export const PrefixTrans = TransHelper.getPrefixedTrans(CUSTOMER_STEP_TRANS_PREFIX);

interface Props {
  config: CustomerDataPhase;
  requirementEntries: CustomerDataRequirementEntries;
  dictionaryEntries: CustomerDataApplicationDictionaries;
  customDictionaries?: Dictionary[];
  hideCustomerSelect?: boolean;
}

const FIELD_PATH = 'borrower';

export default function BorrowerData(
  {config, requirementEntries, dictionaryEntries, customDictionaries, hideCustomerSelect = false}: Props
): ReactElement {

  const [fields, meta] = useField<CustomerDataFormFields>(FIELD_PATH);
  const [isSelectingCustomer, setSelectingCustomer] = useState(false);

  const customerSelect = <CustomerSelect isSelecting={isSelectingCustomer}
                                         setSelecting={setSelectingCustomer}
                                         lockedCustomerType={config.customerType}
                                         relationshipType={ApplicationRelationshipType.BORROWER} />;
  return (
    <ApplicantContext.Provider value={{isCoreCustomer: fields.value?.isCoreCustomer}}>
      {!hideCustomerSelect && customerSelect}
      {!isNil(fields.value) &&
        <ApplicantDataForm config={config}
                           entries={dictionaryEntries}
                           errors={meta.error as FormikErrors<CustomerDataFormFields>}
                           values={fields.value}
                           formikFieldPath={FIELD_PATH}
                           customDictionaries={customDictionaries}
                           requirementEntries={requirementEntries}
                           showTabs={fields.value && !isSelectingCustomer} />
      }
    </ApplicantContext.Provider>
  );
}

