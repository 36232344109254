import isNil from 'lodash/isNil';
import {useEffect, useState} from 'react';
import useFileUpload from '../../../../../shared/hooks/use-file-upload.hook';
import {FileBasicData, NxFile} from '../../../../../shared/model/nx-file.model';

const initialState = {
  id: undefined,
  file: undefined,
  isChanged: false
};

export default function useLogoImage(fileBasicData?: FileBasicData): NxFile {

  const {downloadFile} = useFileUpload();
  const [logoFile, setLogoFile] = useState<NxFile>(initialState);

  useEffect(() => {
    if (!fileBasicData || isNil(fileBasicData.url)) {
      return;
    }

    downloadFile(fileBasicData)
      .then(file => setLogoFile(file));

  }, [fileBasicData]);

  return logoFile;
}
