import React, {forwardRef, ReactElement, ReactNode, useState} from 'react';
import IconButtonSwitch from '../icon-button-switches/IconButtonSwitch';
import SidePanel, {CloseSidePanel} from './SidePanel';
import styles from './SidePanelWithIconButton.module.scss';

interface Props {
  children: ReactElement;
  disabled?: boolean;
  header: ReactNode;
  icon: ReactElement;
  tooltip: string;
}

/**
 * Component is an icon button integrated with SidePanel. When icon button is clicked panel is shown. On next click
 * panel is hidden. Only on panel shown children element is created and rendered.
 *
 * @param children to be rendered inside panel.
 * @param ref an reference to close side panel button.
 */
function SidePanelWithIconButton({children, disabled = false, header, icon, tooltip}: Props, ref): ReactElement {

  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButtonSwitch icon={icon}
                        disabled={disabled}
                        ariaLabel={tooltip}
                        className={styles.iconButton}
                        onClick={(): void => setOpen(true)}
                        tooltipTitle={!disabled && tooltip} />
      <SidePanel ref={ref} open={open} setOpen={setOpen} header={header}>
        {children}
      </SidePanel>
    </>
  );
}

export default forwardRef<CloseSidePanel, Props>(SidePanelWithIconButton);
