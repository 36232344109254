import {isEmpty, isNil} from 'lodash';
import useSingleQuery from '../../../../../../shared/hooks/use-single-query.hook';
import {Dictionary} from '../../../../../../shared/model/dictionary.model';
import {FieldGroup} from '../../../../../../shared/model/field-group.model';
import {ApiHelper} from '../../../../../../utils/api-helper';

export interface CustomFieldDictionariesState {
  areCustomDictionariesLoaded: boolean;
  customDictionaries?: Dictionary[];
}

const {getDictionariesUrl} = ApiHelper;

export default function useCustomFieldDictionaries(fieldGroups?: FieldGroup[]): CustomFieldDictionariesState {

  const dictionaryIds: number[] = [];

  fieldGroups?.forEach(group => group.fields.forEach(field => {

    const {dictionaryId} = field;

    if (!isNil(dictionaryId)) {
      dictionaryIds.push(dictionaryId);
    }
  }));

  const params = new URLSearchParams(`dictionaryIds=${dictionaryIds}`);
  const emptyIdsList = isEmpty(dictionaryIds);
  const customDictionaries = useSingleQuery<Dictionary[]>(getDictionariesUrl(), null, !emptyIdsList, params);
  const areCustomDictionariesLoaded = !isNil(customDictionaries) || (emptyIdsList && !isNil(fieldGroups));

  return {customDictionaries, areCustomDictionariesLoaded};
}
