import {CancelTokenSource} from 'axios';
import HttpService from '../http-service/http.service';
import useCatchUnauthorizedError from './use-catch-unauthorized-error.hook';

/**
 * Dedicated hook for perform GET request.
 *
 * @param url - target url for API request.
 * @param params - Map of query parameters (url parameters), defaulted to empty if undefined.
 *
 * T - type of data returned by api call.
 *
 * @return function which allows execute configured GET request. It has following arguments:
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */

export default function useGet<T>(url: string):
  (params?: URLSearchParams, cancelTokenSource?: CancelTokenSource | null) => Promise<T> {

    const catchUnauthorizedError = useCatchUnauthorizedError();

    return (params: URLSearchParams = {} as URLSearchParams, cancelTokenSource?: CancelTokenSource | null): Promise<T> =>
      HttpService.get<T>(url, cancelTokenSource, {params})
        .catch(catchUnauthorizedError);
  }
