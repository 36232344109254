import React, {ReactElement, useContext, useEffect, useMemo, useState} from 'react';
import {CBSLoanDetails, VALID_STATUSES_FOR_CONSOLIDATION} from '../../../../../../../shared/model/cbs-loans';
import {CBS_LOANS} from '../../../../../../../constants/api-urls';
import useGet from '../../../../../../../shared/hooks/use-get.hook';
import {ApiHelper} from '../../../../../../../utils/api-helper';
import ConsolidatedLoansTableRow from '../table/ConsolidatedLoansTableRow';
import {Table, TableBody} from '@material-ui/core';
import TableHeader from '../../../../../../shared/table/header/TableHeaders';
import {getCustomerCbsLoansTableHeaders} from '../../../../../../../utils/table-helper';
import {ConsolidatedLoansContext} from '../consolidated-loans-context';
import {NxLoader} from '@nextbank/ui-components';

const HEADERS_TRANS_PREFIX = 'LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.CONSOLIDATED_LOANS';

interface Props {
  applicantSourceId?: number;
  loading: boolean,
  setLoading: (value: boolean) => void;
}

const CustomerCbsLoansTable = ({applicantSourceId, loading, setLoading}: Props): ReactElement => {

  const [customerCbsLoans, setCustomerCbsLoans] = useState<CBSLoanDetails[]>();
  const columnHeaders = getCustomerCbsLoansTableHeaders();
  const {consolidatedLoans} = useContext(ConsolidatedLoansContext);

  const getCbsLoans = useGet<CBSLoanDetails[]>(CBS_LOANS);

  useEffect(() => {
    if (applicantSourceId) {
      setLoading(true);
      getCbsLoans(ApiHelper.constructUrlParams({customerId: applicantSourceId}))
        .then(result => setCustomerCbsLoans(result))
        .finally(() => setLoading(false));
    }

  }, [applicantSourceId]);

  const isLoanAdded = (cbsLoan: CBSLoanDetails): boolean => consolidatedLoans.some(loan => loan.loanId === cbsLoan.loanId);

  return <>
    {loading ? (<NxLoader />) : (
      <Table>
        <TableHeader translationPath={HEADERS_TRANS_PREFIX} headers={columnHeaders} />
        <TableBody>
          {customerCbsLoans?.filter(cbsLoan => VALID_STATUSES_FOR_CONSOLIDATION.includes(cbsLoan?.status || ''))
            .map((cbsLoan, index) => <ConsolidatedLoansTableRow key={`${cbsLoan.loanId}-${index}`}
                                                                cbsLoanDetails={cbsLoan}
                                                                rowNumber={index + 1}
                                                                isCustomerLoansSelection
                                                                hasActionButton
                                                                disableActionButton={isLoanAdded(cbsLoan)} />)}
        </TableBody>
      </Table>
    )}
  </>;
};

export default CustomerCbsLoansTable;
