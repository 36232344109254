import {NxTab, NxTabs} from '@nextbank/ui-components';
import React, {ReactElement, ReactNode} from 'react';
import {TransHelper} from '../../../utils/trans-helper';
import styles from './CustomerDataTabs.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.CUSTOMER_DATA');

export interface CustomerDataTab {
  tabPanel: ReactNode;
  error?: boolean;
}

interface Props {
  className?: string;
  tabs: {
    individualDataTab?: CustomerDataTab;
    corporateDataTab?: CustomerDataTab;
    documentsTab: CustomerDataTab;
    addressesTab: CustomerDataTab;
    customFieldsTab: CustomerDataTab;
    incomeTab: CustomerDataTab;
  };
}

const CustomerDataTabs = ({tabs, className}: Props): ReactElement => {

  const getTab = (key: string, label: string, tab: CustomerDataTab): NxTab => ({
    ...tab,
    label: <span className={styles.tabTitle}><PrefixTrans>{label}</PrefixTrans></span>,
    key
  });

  let customerDataTab;
  if (tabs.individualDataTab) {
    customerDataTab = getTab('CUSTOMER_DATA', 'APPLICANT_DATA.INDIVIDUAL_DATA_LABEL', tabs.individualDataTab);
  } else if (tabs.corporateDataTab) {
    customerDataTab = getTab('CUSTOMER_DATA', 'APPLICANT_DATA.CORPORATE_DATA_LABEL', tabs.corporateDataTab);
  } else {
    throw Error('No customer data tab present');
  }

  const completeTabs = [
    customerDataTab,
    getTab('DOCUMENTS', 'DOCUMENTS', tabs.documentsTab),
    getTab('ADDRESSES', 'ADDRESSES', tabs.addressesTab),
    getTab('INCOMES', 'INCOMES', tabs.incomeTab),
    getTab('CUSTOM_FIELDS', 'CUSTOM_FIELDS.LABEL', tabs.customFieldsTab)
  ];

  return (
    <div className={className}>
      <NxTabs tabs={completeTabs} />
    </div>
  );
};

export default CustomerDataTabs;
