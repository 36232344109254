import {NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React from 'react';
import {DICTIONARIES_URL} from '../../../constants/api-urls';
import usePost from '../../../shared/hooks/use-post.hook';
import usePut from '../../../shared/hooks/use-put.hook';
import useSingleQuery from '../../../shared/hooks/use-single-query.hook';
import {ApiHelper} from '../../../utils/api-helper';
import {generateKey} from '../../../utils/generate-key';
import {sortByOrderNo} from '../../../utils/sort-by-order';
import ListFieldConfig, {ListFieldItem} from '../list-value-config/ListFieldConfig';
import {CustomDictionary, CustomDictionaryEntry} from './custom-dictionary.model';
import {Dictionary, DictionaryEntry} from '../../../shared/model/dictionary.model';

interface Props {
  phaseId: number;
  handleSave: (dictionaryId: number) => void;
  dictionaryId?: number;
}

const {getDictionaryUrl, getDictionaryEntriesUrl} = ApiHelper;

export default function CustomDictionaryConfig({handleSave, phaseId, dictionaryId}: Props): React.ReactElement {

  const createDictionary = usePost<Dictionary, CustomDictionary>(DICTIONARIES_URL);
  const updateDictionary = usePut<Dictionary, CustomDictionary>(DICTIONARIES_URL);
  const dictionary =
    useSingleQuery<Dictionary>(getDictionaryUrl(dictionaryId), null, !isNil(dictionaryId));
  const dictionaryEntries =
    useSingleQuery<DictionaryEntry[]>(getDictionaryEntriesUrl(dictionaryId), null, !isNil(dictionaryId));

  const getListFieldItems = (): ListFieldItem[] => {
    if (!dictionaryEntries) {
      return [];
    }

    return sortByOrderNo(dictionaryEntries).map(value  => ({
      id: (value as DictionaryEntry).id,
      name: (value as DictionaryEntry).name,
      ...generateKey()
    }));
  };

  const handleDictionarySave = async (fields: ListFieldItem[]): Promise<void> => {
    const newDictionaryEntries: (CustomDictionaryEntry | DictionaryEntry)[] =
      fields.map((newEntry, index) => ({
          ...(dictionaryEntries && dictionaryEntries.find(originalEntry => originalEntry.id === newEntry.id)),
          name: newEntry.name,
          orderNo: index,
          enabled: true,
        })
      );

    let customDictionary: CustomDictionary = {
      phaseId,
      shared: false,
      enabled: true,
      dictionaryEntries: newDictionaryEntries,
    };

    if (dictionaryId && dictionary) {
      customDictionary = {
        ...customDictionary,
        ...dictionary
      };
      await updateDictionary(customDictionary);
      handleSave(dictionaryId);

      return;
    }

    const createdDictionary = await createDictionary(customDictionary);

    if (createdDictionary) {
      handleSave(createdDictionary.id);

      return;
    }

    throw Error('Saved dictionary is undefined');
  };

  const ListFieldConfigWithInitialItems = !isNil(dictionary) && !isNil(dictionaryEntries)
    ? <ListFieldConfig initialItems={getListFieldItems()} handleSave={handleDictionarySave} />
    : <NxLoader />;

  return dictionaryId ? ListFieldConfigWithInitialItems : <ListFieldConfig handleSave={handleDictionarySave} />;
}
