import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {AccountInformationOptions} from '../../../../../../../shared/hooks/use-loan-application-data-options.hook';
import {isAnyEnabled} from '../../../../../../../utils/empty-checks-utils';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import EmptyState from '../../../../../../shared/empty-state/EmptyState';
import {ConfiguredFieldSelect as Select} from '../../configured-fields/ConfiguredFieldSelect';
import {LoanApplicationDataFormFields} from '../../../loan-application-data/loan-application-data-form.model';
import styles from './AccountInformation.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.LOAN_APPLICATION_DATA.ACCOUNT_INFORMATION');

interface Props {
  values: LoanApplicationDataFormFields;
  options: AccountInformationOptions;
}

export default function AccountInformation({values, options}: Props): ReactElement {
  return (
    !isAnyEnabled([
      values.loanClassId, values.loanPurposeId, values.loanSecurityId, values.loanEconomicActivityId, values.misGroupId,
      values.microfinanceClassificationId, values.borrowerTypeId, values.transactionTypeId, values.cicContractTypeId,
      values.loanPurposeToIndustryId
    ])
      ? <EmptyState instruction={<Trans>LOAN_APPLICATIONS.CONFIGURATION_EMPTY_STATE</Trans>} />
      : <>
          <Select name='loanClassId'
                  field={values.loanClassId}
                  label={<PrefixTrans>LOAN_CLASS</PrefixTrans>}
                  options={options.loanClassId} />
          <Select name='loanPurposeId'
                  field={values.loanPurposeId}
                  label={<PrefixTrans>LOAN_PURPOSE</PrefixTrans>}
                  options={options.loanPurposeId} />
          <Select name='loanSecurityId'
                  field={values.loanSecurityId}
                  label={<PrefixTrans>LOAN_SECURITY</PrefixTrans>}
                  options={options.loanSecurityId} />
          <Select name='loanEconomicActivityId'
                  field={values.loanEconomicActivityId}
                  label={<PrefixTrans>LOAN_ECONOMIC_ACTIVITY</PrefixTrans>}
                  options={options.loanEconomicActivityId} />
          <Select name='microfinanceClassificationId'
                  field={values.microfinanceClassificationId}
                  label={<PrefixTrans>MICROFINANCE_CLASSIFICATION</PrefixTrans>}
                  options={options.microfinanceClassificationId} />
          <Select name='borrowerTypeId'
                  field={values.borrowerTypeId}
                  label={<PrefixTrans>BORROWER_TYPE</PrefixTrans>}
                  options={options.borrowerTypeId} />
          <Select name='transactionTypeId'
                  field={values.transactionTypeId}
                  label={<PrefixTrans>TRANSACTION_TYPE</PrefixTrans>}
                  options={options.transactionTypeId} />
          <Select name='cicContractTypeId'
                  field={values.cicContractTypeId}
                  label={<PrefixTrans>CIC_CONTRACT_TYPE</PrefixTrans>}
                  options={options.cicContractTypeId} />
          <Select name='loanPurposeToIndustryId'
                  field={values.loanPurposeToIndustryId}
                  label={<PrefixTrans>LOAN_PURPOSE_TO_INDUSTRY</PrefixTrans>}
                  options={options.loanPurposeToIndustryId?.map(
                    option => ({
                      ...option,
                      label: <span className={styles.purposeToIndustryLabel}>{option.label}</span>
                    })
                  )} />
          <Select name='misGroupId'
                  field={values.misGroupId}
                  label={<PrefixTrans>MIS_GROUP</PrefixTrans>}
                  options={options.misGroupId} />
        </>
  );
}
