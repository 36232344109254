import {Table, TableBody, TableContainer} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {PAGE_RECORDS_LIMIT} from '../../../constants/table-defaults';
import {PagedRecords} from '../../../shared/model/paged.model';
import EmptyState from '../../shared/empty-state/EmptyState';
import TableHeader from '../../shared/table/header/TableHeaders';
import TablePaginationFooter from '../../shared/table/pagination-footer/TablePaginationFooter';
import {Batch} from '../batch.model';
import {BatchTableHeaderLabel} from './batch-table.model';
import BatchTableRow from './row/BatchTableRow';

interface Props {
  batches: PagedRecords<Batch>;
  onPageChange: (pageNo: number) => Promise<void>;
  className?: string;
}

export const TRANS_PREFIX = 'BATCH.LABELS';

const BatchTable = ({batches, onPageChange, className}: Props): ReactElement =>
  <div className={className}>
    {
      isEmpty(batches.result)
        ? <EmptyState instruction={<Trans>COMMON.NO_RESULTS</Trans>} />
        : <>
          <TableContainer>
            <Table>
              <TableHeader translationPath={TRANS_PREFIX} headers={Object.values(BatchTableHeaderLabel)} />
              <TableBody>
                {
                  batches.result.map((batch) =>
                    <BatchTableRow key={batch.id} batch={batch} />
                  )
                }
              </TableBody>
              <TablePaginationFooter page={batches.pageNo}
                                     onPageChange={onPageChange}
                                     count={batches.totalCount}
                                     rowsPerPage={PAGE_RECORDS_LIMIT} />
            </Table>
          </TableContainer>
        </>
    }
  </div>;

export default BatchTable;
