import {isNil} from 'lodash';
import {useEffect, useState} from 'react';
import useFileUpload from '../../../../../../shared/hooks/use-file-upload.hook';
import {Check, CheckType} from '../../../../../../shared/model/check.model';
import {FieldType} from '../../../../../../shared/model/field.model';
import {NxFile} from '../../../../../../shared/model/nx-file.model';
import {Phase} from '../../../../../../shared/model/phase.model';
import {NumberHelper} from '../../../../../../utils/number-helper';
import {FALSE_STRING} from '../../../../../shared/form-bolean-option-values/YesNoSelectOptions';
import {CheckValue, CheckValueTO} from './check-value.model';
import {handleNxFiles, parseSavedCheck} from './check-values-utils';

const getInitValues = (checks: Check[], valuesTO?: CheckValueTO[]): CheckValue[] =>
  checks.map(check => {

    const {automatic, id, proofType, type, required} = check;
    const savedValue = valuesTO?.find(({checkId}) => checkId === id)?.value;

    let value = isNil(savedValue) && type !== CheckType.MANUAL
      ? FALSE_STRING
      : parseSavedCheck(savedValue, proofType);
    value = handleNumericProofType(value, proofType);

    return {checkId: id, isAutomatic: automatic, required, value, fieldType: proofType};
  });

function handleNumericProofType<T>(value: T, proofType: FieldType | undefined): T | number | undefined{
  switch (proofType) {
    case FieldType.CASH:
    case FieldType.INTEGER:
    case FieldType.FLOAT:
    case FieldType.PERCENTAGE:
      return NumberHelper.toNumberOrUndefined(value);
    default:
      return value;
  }
}

export default function useCustomCheckInitValues(config?: Phase,
                                                 valuesTO?: CheckValueTO[]): (CheckValue | CheckValue<number> | CheckValue<NxFile>)[] | undefined {

  const {downloadFile} = useFileUpload();
  const [checkInitValues, setCheckInitValues] = useState<(CheckValue | CheckValue<NxFile>)[]>();

  useEffect(() => {
    if (isNil(config)) {
      return;
    }

    const checks = config.checkGroups.flatMap(checkGroup => checkGroup.checks);
    const promises = getInitValues(checks, valuesTO)
      .map(value => handleNxFiles(value, downloadFile));

    Promise.all(promises)
      .then(resolvedData => {
        setCheckInitValues(resolvedData as (CheckValue | CheckValue<NxFile>)[]);
      })
      .catch(e => console.error('Error when handling check files', e));
  }, [config, valuesTO]);

  return checkInitValues;
}
