import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React, {ReactElement, useContext} from 'react';
import {Trans} from 'react-i18next';
import usePost from '../../../../shared/hooks/use-post.hook';
import {ApiHelper} from '../../../../utils/api-helper';
import {ApplicationStatus} from '../loan-application.model';
import {LoanApplicationContext} from '../LoanApplication';
import {StatusChangePayload} from './status-change-button/status-change-payload.model';

interface Props {
  className?: string;
  applicationId: number;
}

export default function UndoDeferLoanButton({className, applicationId}: Props): ReactElement {

  const {refreshApplication} = useContext(LoanApplicationContext);
  const undoDefer = usePost<void, StatusChangePayload>(`${ApiHelper.getApplicationUrl(applicationId)}/change-status`);

  const onClick = (): Promise<void> => undoDefer({status: ApplicationStatus.PENDING}).then(() => { refreshApplication() });

  return <NxButton className={className}
                   onClick={onClick}
                   variant={NxButtonVariant.UNDO}>
    <Trans>TOP_BAR.DEFER.UNDO_DEFER</Trans>
  </NxButton>;
}
