import {NxFormikInput} from '@nextbank/ui-components';
import React from 'react';
import {DEFAULT_VALUE} from '../../../constants/static-field-values';
import {Field} from '../../../shared/model/field.model';
import {SetFieldValue} from '../../../shared/model/formik.model';
import {FieldDefaultCheckbox} from './FieldDefaultCheckbox';

interface Props {
  label: React.ReactNode;
  name: string;
  field: Field<unknown>
  setFieldValue: SetFieldValue;
}

export const FieldDefaultInput = ({label, name, field, setFieldValue}: Props): React.ReactElement => (
  <FieldDefaultCheckbox name={name} field={field} setFieldValue={setFieldValue}>
    <NxFormikInput name={`${name}.${DEFAULT_VALUE}`} label={label} />
  </FieldDefaultCheckbox>
);
