import {
  NxButton,
  NxButtonVariant,
  NxFormikSelect,
  NxFormikTextarea,
  NxPopup,
  NxSelectOption
} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ReactComponent as CheckedIcon} from '../../../../../../../../../assets/images/icon-checked.svg';
import {ApprovalExecutionInput} from '../../../../../../loan-application.model';
import {PrefixTrans} from '../../approval-utils';
import {getApprovalExecutionValidationSchema} from './approval-execution-validation-schema';
import styles from './ApprovalExecutionPopup.module.scss';

interface Props {
  onConfirm: (approvalExecution: ApprovalExecutionInput) => void;
  onCancel: () => void;
  open: boolean;
  rejectPopupVariant?: boolean;
  availableApprovalOptions: NxSelectOption<number>[];
}

interface FormFields {
  approverId?: number;
  comment?: string;
}

const TEXT_AREA_ROWS_NUMBER = 4;

export const ApprovalExecutionPopup = (
  {onConfirm, onCancel, open, rejectPopupVariant = false, availableApprovalOptions}: Props): ReactElement => {

  const {t} = useTranslation();

  const showApproverSelect = availableApprovalOptions?.length > 1;

  const header =
    <div className={styles.header}>
      <PrefixTrans>{`POPUP.${rejectPopupVariant ? 'REJECT_HEADER' : 'APPROVE_HEADER'}`}</PrefixTrans>
    </div>;

  const submit = (values: FormFields): void => {
    if (isNil(values.approverId)) {
      throw Error('Approver ID has to be filled when approval is executed');
    }

    onConfirm(values);
  };

  const initialValues = {
    approverId: availableApprovalOptions?.length === 1 ? availableApprovalOptions[0].value : undefined
  };

  const ApprovalExecutionForm = ({submitForm, isSubmitting}: FormikProps<FormFields>): ReactElement => (
    <Form>
      {
        showApproverSelect && <NxFormikSelect<number> name='approverId'
                                                      required={true}
                                                      className={styles.input}
                                                      options={availableApprovalOptions}
                                                      label={<PrefixTrans>POPUP.APPROVER_SELECT</PrefixTrans>} />
      }
      <NxFormikTextarea className={styles.input}
                        label={<PrefixTrans>POPUP.ADDITIONAL_INFORMATION</PrefixTrans>}
                        required={rejectPopupVariant}
                        rows={TEXT_AREA_ROWS_NUMBER}
                        name='comment' />
      <div className={styles.buttonRow}>
        <NxButton onClick={onCancel} variant={NxButtonVariant.TEXT}>{<Trans>COMMON.CANCEL</Trans>}</NxButton>
        {
          rejectPopupVariant
            ? <NxButton variant={NxButtonVariant.REJECT}
                        className={styles.button}
                        disabled={isSubmitting}
                        loaded={!isSubmitting}
                        onClick={submitForm}>
              <Trans>COMMON.REJECT</Trans>
            </NxButton>
            : <NxButton startIcon={<CheckedIcon />}
                        className={styles.button}
                        disabled={isSubmitting}
                        loaded={!isSubmitting}
                        onClick={submitForm}>
              <Trans>COMMON.APPROVE</Trans>
            </NxButton>
        }
      </div>
    </Form>
  );

  return (
    <NxPopup header={header} open={open}>
      <div className={styles.container}>
        <div className={styles.description}>
          <PrefixTrans>POPUP.PROVIDE_ADDITIONAL_INFORMATION</PrefixTrans>
        </div>
        <Formik<FormFields>
          onSubmit={submit}
          initialValues={initialValues}
          validationSchema={getApprovalExecutionValidationSchema(t, rejectPopupVariant)}>
          {ApprovalExecutionForm}
        </Formik>
      </div>
    </NxPopup>
  );
};
