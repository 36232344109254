import {FixedPaymentOption, PaymentType} from '../../../../../shared/model/payment.model';
import {PhaseWithStaticFields} from '../../../../../shared/model/phase.model';
import {generateKey} from '../../../../../utils/generate-key';

export interface CalculatorPhase extends PhaseWithStaticFields {
  fixedPaymentOptions?: FixedPaymentOption[];
  paymentType: PaymentType;
}

export interface KeyedFixedPaymentOption extends FixedPaymentOption {
  key: string;
}

export const toKeyedFixedPaymentOption = (option: FixedPaymentOption): KeyedFixedPaymentOption =>
  ({...option, ...generateKey()});

