import {Tab, TabClassKey, Tabs} from '@material-ui/core';
import {NxLoader, NxLoaderColorVariant, NxLoaderVariant} from '@nextbank/ui-components';
import clsx from 'clsx';
import React from 'react';
import {getStepPathPart} from '../../../utils/loan-steps-utils';
import styles from './LoanStepTabs.module.scss';

interface LoanStep {
  path: string;
  redirectTo?: string;
  label: string;
  orderNo?: string;
  isConfigStep?: boolean;
  isMarkedStep?: boolean;
  isSubStep?: boolean;
  onStepClick: () => void;
  disabled?: boolean;
}

interface Props {
  steps: LoanStep[];
  currentStepPath: string;
  loaded?: boolean;
}

export const LoanStepTabs = ({steps, currentStepPath, loaded = true}: Props): React.ReactElement => {

  const getTabClasses = (step: LoanStep): Partial<Record<TabClassKey, string>> => ({
    root: clsx(
      styles.tab,
      {
        [styles.tab_configStep]: step.isConfigStep,
        [styles.tab_markedStep]: step.isMarkedStep,
        [styles.tab_subStep]: step.isSubStep
      }
    ),
    selected: styles.tab_selected,
    wrapper: styles.tabLabel
  });

  const Loader = <NxLoader variant={NxLoaderVariant.SMALL} colorVariant={NxLoaderColorVariant.TEXT} />;

  return (
    <div className={styles.flexColumn}>
      {!loaded ? Loader : (
        <Tabs classes={{root: styles.flexColumn}}
              value={getStepPathPart(currentStepPath)}
              indicatorColor='primary'
              orientation='vertical'
              textColor='primary'>
          {
            steps.map((step, index) =>
              <Tab classes={getTabClasses(step)}
                   value={!step.redirectTo ? getStepPathPart(step.path) : undefined}
                   onClick={step.onStepClick}
                   label={`${step.orderNo ? `${step.orderNo}. ${step.label}` : step.label} `}
                   disabled={step.disabled}
                   key={index} />)
          }
        </Tabs>
      )}
    </div>
  );
};
