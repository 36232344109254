import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React, {useContext, useState} from 'react';
import {BATCH} from '../../../../constants/api-urls';
import usePost from '../../../../shared/hooks/use-post.hook';
import HttpService from '../../../../shared/http-service/http.service';
import {ReportResponse, ReportStatus} from '../../../../shared/model/report.model';
import {downloadFile} from '../../../../utils/file-download-utils';
import {TransHelper} from '../../../../utils/trans-helper';
import {AppSnackbarContext} from '../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {Batch} from '../../batch.model';

export const getBatchUrl = (batchId: string): string => `${BATCH}/${batchId}`;
export const TRANS_PREFIX = 'BATCH.DETAILS';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

interface Props {
  batch: Batch;
}

export const GenerateReportButton = ({batch}: Props): React.ReactElement => {
  const generateReportUrl = `${getBatchUrl(batch.id.toString())}/error-report`;
  const generateErrorReport = usePost<ReportResponse, void>(generateReportUrl);
  const [isGenerating, setIsGenerating] = useState(false);
  const {showErrorMessage} = useContext(AppSnackbarContext);

  const downloadErrorReport = async (): Promise<void> => {
    if (isGenerating) {
      return;
    }

    setIsGenerating(true);
    let report = await generateErrorReport();

    const intervalId = setInterval(async () => {
      report = await HttpService.post<ReportResponse>(generateReportUrl);

      if (report.status == ReportStatus.COMPLETED) {
        const reportName = batch?.name ? `${batch?.name} error report` : 'Error report';
        downloadFile(report.url, reportName);
        clearInterval(intervalId);
        setIsGenerating(false);
      }

      if (report.status == ReportStatus.FAILED) {
        showErrorMessage('Failed to generate batch report');
        clearInterval(intervalId);
        setIsGenerating(false);
      }
    }, 1000);
  };

  return <NxButton variant={NxButtonVariant.OUTLINED}
                   onClick={downloadErrorReport}
                   loaded={!isGenerating}>
    <PrefixTrans>DOWNLOAD_ERRORS_REPORT</PrefixTrans>
  </NxButton>;
};

