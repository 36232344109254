export const getFieldNameByCode = (code: string, fieldsCodes: object): string => {

  const fieldNamesArray = Object.entries(fieldsCodes)
    .filter(([, value]) => value === code)
    .map(([key]) => key);
  if (fieldNamesArray.length > 0) {
    return fieldNamesArray[0];
  }

  return code;
};
