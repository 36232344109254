import {NxButton, NxButtonVariant, NxIconButton} from '@nextbank/ui-components';
import clsx from 'clsx';
import React from 'react';
import {Trans} from 'react-i18next';
import {ReactComponent as ChevronIcon} from '../../../assets/images/icon-chevron.svg';
import styles from './DetailsButton.module.scss';

interface Props {
  iconOnly?: boolean;
  isOpen?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
  smallIcon?: boolean;
}

const DetailsButton = (
  {iconOnly = false, className, onClick, isOpen = false, smallIcon = false}: Props
): React.ReactElement => {

  const chevronIconClassName = clsx(
    styles.chevron,
    {
      [styles.chevron_up]: isOpen,
      [styles.chevron_small]: smallIcon
    }
  );

  return iconOnly
    ? <NxIconButton onClick={onClick} icon={<ChevronIcon className={chevronIconClassName} />} />
    : <NxButton onClick={onClick}
                className={className}
                variant={NxButtonVariant.TEXT}
                endIcon={<ChevronIcon className={chevronIconClassName} />}>
      <Trans>COMMON.DETAILS</Trans>
    </NxButton>;

};

export default DetailsButton;
