import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React, {ReactElement, useContext, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {TransHelper} from '../../../../../utils/trans-helper';
import {ApplicationStatus} from '../../loan-application.model';
import {LoanApplicationContext} from '../../LoanApplication';
import styles from './LoanStatusChangeButton.module.scss';
import {LoanStatusChangePopup} from './LoanStatusChangePopup';
import {StatusChangePayload} from './status-change-payload.model';

interface Props {
  className?: string;
  productName: string;
  status: ApplicationStatus;
  changeStatus: (values: StatusChangePayload) => Promise<void>,
  icon: ReactElement,
  translationPath: string
}

export default function LoanStatusChangeButton(
  {className, productName, status, changeStatus, icon, translationPath}: Props
): ReactElement {

  const {refreshApplication} = useContext(LoanApplicationContext);
  const [popupOpen, setPopupOpen] = useState(false);
  const PrefixTrans = TransHelper.getPrefixedTrans(translationPath);

  const togglePopup = (): void => setPopupOpen(!popupOpen);

  const onConfirm = (values: StatusChangePayload): Promise<void> =>
    changeStatus(values)
      .then(() => refreshApplication())
      .then(() => togglePopup());

  const description = <>
    <div className={styles.description}>
      <Trans i18nKey={`${translationPath}.DESCRIPTION`}
             values={{name: productName}}
             components={{bold: <span className={styles.productName} />}} />
      <div><PrefixTrans>DESCRIPTION_P2</PrefixTrans></div>
    </div>
    <PrefixTrans>WARNING</PrefixTrans>
  </>;

  return (
    <>
      <NxButton className={className}
                onClick={togglePopup}
                variant={NxButtonVariant.OUTLINED}
                startIcon={icon}>
        <PrefixTrans>BUTTON</PrefixTrans>
      </NxButton>
      <LoanStatusChangePopup onCancel={togglePopup}
                             onConfirm={onConfirm}
                             status={status}
                             open={popupOpen}
                             description={description}
                             header={<PrefixTrans>HEADER</PrefixTrans>}
                             confirmButtonMessage={<PrefixTrans>BUTTON</PrefixTrans>}
      />
    </>);
}
