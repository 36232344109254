import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ConfirmationPopup} from '../../shared/confirmation-popup/ConfirmationPopup';
import {TransHelper} from '../../../utils/trans-helper';
import styles from './LoanCreationTypePopup.module.scss';
import {NxSelect} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import {useGuardedHistory} from '../../router/GuardedHistory';
import {ADDITIONAL_LOAN_APPLICATION_PATH, CONSOLIDATE_LOAN_APPLICATION_PATH, NEW_LOAN_APPLICATION_PATH, RELOAN_APPLICATION_PATH} from '../../dashboard/routes/dashboard-routes';
import {LoanCreationType} from '../../../shared/model/creation-type.model';

export const TRANS_PREFIX = 'LOAN_APPLICATIONS.LOAN_CREATION_TYPE.POPUP';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

interface LoanCreationTypePopupProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const creationTypes = [
  {value: LoanCreationType.NEW_LOAN, label: 'New Loan'},
  {value: LoanCreationType.ADDITIONAL_LOAN, label: 'Additional Loan'},
  {value: LoanCreationType.RELOAN, label: 'Reloan'},
  {value: LoanCreationType.CONSOLIDATION, label: 'Consolidate'}
];

export const LoanCreationTypePopup = ({open, setOpen}: LoanCreationTypePopupProps): React.ReactElement => {
  const {t} = useTranslation();
  const history = useGuardedHistory();

  const [selectedCreationType, setSelectedCreationType] = useState();
  const [errorMessage, setErrorMessage] = useState<string>();
  const closePopup = (): void => {
    setSelectedCreationType(undefined);
    setOpen(false);
  };

  const onChange = (value): void => {
    if (isNil(value)) {
      console.warn('Selected value is null');
      return;
    }

    setSelectedCreationType(value);
  };

  const onConfirm = (): void => {
    if (isNil(selectedCreationType)) {
      setErrorMessage(t('COMMON.ERRORS.REQUIRED_FIELD'));
      return;
    }

    if (selectedCreationType === LoanCreationType.CONSOLIDATION) {
      history.push(CONSOLIDATE_LOAN_APPLICATION_PATH);
    } else if (selectedCreationType === LoanCreationType.ADDITIONAL_LOAN) {
      history.push(ADDITIONAL_LOAN_APPLICATION_PATH);
    } else if (selectedCreationType === LoanCreationType.RELOAN) {
      history.push(RELOAN_APPLICATION_PATH);
    } else {
      history.push(NEW_LOAN_APPLICATION_PATH);
    }
    setSelectedCreationType(undefined);
  };

  const messageBody = (
    <>
      <div className={styles.message}>
        <Trans>{`${TRANS_PREFIX}.MESSAGE`}</Trans>
      </div>
      <div className={styles.dropdown}>
        <NxSelect className={styles.select}
                  options={creationTypes}
                  value={selectedCreationType}
                  onChange={onChange}
                  error={errorMessage}
                  label={<Trans>{`${TRANS_PREFIX}.CREATION_TYPE_LABEL`}</Trans>} />
      </div>
    </>
  );

  return <ConfirmationPopup
    header={<PrefixTrans>HEADER</PrefixTrans>}
    description={messageBody}
    onConfirm={onConfirm}
    confirmButtonLabel={<PrefixTrans>CONFIRM_BUTTON</PrefixTrans>}
    confirmButtonClass={styles.confirmButton}
    onDeny={closePopup}
    denyButtonLabel={<Trans>COMMON.CANCEL</Trans>}
    open={open}
  />;
};

