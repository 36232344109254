import {NxSelectOption} from '@nextbank/ui-components';
import mapValues from 'lodash/mapValues';
import {
  CustomerDataPhase
} from '../../components/loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {SelectHelper} from '../../utils/select-helper';
import {movePhilippinesOptionToTop} from '../../utils/select-options-utils';
import useCorporateDataDictionaries from './use-corporate-data-dictionaries.hook';

export interface CorporateDataOptionsState {
  areCorporateDataOptionsLoaded?: boolean;
  corporateDataOptions: CorporateDataOptions;
}

export interface CorporateDataOptions {
  businessType?: NxSelectOption[];
  riskLevel?: NxSelectOption[];
  dosriType?: NxSelectOption[];
  registrationCountry?: NxSelectOption[];
  conglomerate?: NxSelectOption[];
  relatedPartyTransaction?: NxSelectOption[];
}

export default function useCorporateDataOptions(config?: CustomerDataPhase): CorporateDataOptionsState {
  const {
    corporateDataDictionaryEntries,
    areCorporateDataDictionaryEntriesLoaded
  } = useCorporateDataDictionaries(config);

  const corporateDataOptions = mapValues(
    corporateDataDictionaryEntries,
    entries => SelectHelper.mapToStringOptions(entries)
  );

  return {
    corporateDataOptions: {
      ...corporateDataOptions,
      registrationCountry: movePhilippinesOptionToTop(corporateDataOptions.registrationCountry)
    },
    areCorporateDataOptionsLoaded: areCorporateDataDictionaryEntriesLoaded
  };
}

