import {NxButton} from '@nextbank/ui-components';
import React, {ReactElement, useContext, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {RoutePaths} from '../../../../../../routes/routes.paths';
import {PostRequest} from '../../../../../../shared/hooks/use-post.hook';
import {useGuardedHistory} from '../../../../../router/GuardedHistory';
import {AppSnackbarContext} from '../../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {StepContext} from '../../shared/loan-application-step/LoanApplicationStep';
import {PrefixTrans, TRANS_PREFIX} from '../Summary';
import {ConfirmationPopup} from '../../../../../shared/confirmation-popup/ConfirmationPopup';

interface Props {
  submitApplicationRequest: PostRequest<unknown, unknown>;
  withLinkedDepositAccount: boolean;
}

export default function ReleaseButton({submitApplicationRequest, withLinkedDepositAccount}: Props): ReactElement {

  const {t} = useTranslation();
  const history = useGuardedHistory();
  const {isStepReadonly} = useContext(StepContext);
  const {showErrorMessage, showSuccessMessage} = useContext(AppSnackbarContext);
  const [isReleasing, setReleasing] = useState(false);

  // for with linked deposit account
  const [isReleasingPopup, setIsReleasingPopup] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const onRelease = async (): Promise<void> => {
    setReleasing(true);

    submit()
      .finally(() => {
        setReleasing(false);
      });
  };

  const onReleaseWithLinkedDeposit = () => {
    setIsReleasingPopup(true);

    submit()
      .finally(() => {
        setIsReleasingPopup(false);
      });
  };

  const submit = () => {
    return submitApplicationRequest()
      .then((): void => {
        const successMessage = withLinkedDepositAccount
          ? `${t(`${TRANS_PREFIX}.RELEASE_SUCCESS_MESSAGE`)} ${t(`${TRANS_PREFIX}.WITH_LINKED_DEPOSIT_ACCOUNT`)}`
          : t(`${TRANS_PREFIX}.RELEASE_SUCCESS_MESSAGE`);

        showSuccessMessage(successMessage);
        history.push(RoutePaths.LOAN_APPLICATIONS);
      })
      .catch(error => showErrorMessage(error.message));
  };

  if (withLinkedDepositAccount) {
    return (
      <>
        <NxButton onClick={() => setIsPopupOpen(true)} loaded={!isReleasing} disabled={isStepReadonly || isReleasing}>
          <PrefixTrans>RELEASE</PrefixTrans>
        </NxButton>
        <ConfirmationPopup
          isLoading={isReleasingPopup}
          header={<PrefixTrans>WITH_LINKED_DEPOSIT_ACCOUNT_CONFIRMATION_POPUP.HEADER</PrefixTrans>}
          description={<PrefixTrans>WITH_LINKED_DEPOSIT_ACCOUNT_CONFIRMATION_POPUP.DESCRIPTION</PrefixTrans>}
          onConfirm={onReleaseWithLinkedDeposit}
          confirmButtonLabel={<Trans>COMMON.PROCEED</Trans>}
          onDeny={() => {
            setIsPopupOpen(false);
            setReleasing(false);
          }}
          denyButtonLabel={<Trans>COMMON.CANCEL</Trans>}
          open={isPopupOpen}
        />
      </>
    );
  }

  return (
    <NxButton onClick={onRelease} loaded={!isReleasing} disabled={isStepReadonly || isReleasing}>
      <PrefixTrans>RELEASE</PrefixTrans>
    </NxButton>
  );
}
