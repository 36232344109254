import {NxFile} from '../../../../../shared/model/nx-file.model';

export const SHOULD_EXPIRE = 'shouldExpire';
export const DAYS_AFTER_EXPIRE = 'noOfDaysAfterExpire';
export const SUBMISSION_DATE_LIMIT_ENABLED = 'submissionDateLimitEnabled';
export const SUBMISSION_DATE_LIMIT = 'submissionDateLimit';
export const OTC_ENABLED = 'branchChannelEnabled';
export const MOBILE_ENABLED = 'mobileChannelEnabled';
export const WEB_ENABLED = 'desktopChannelEnabled';
export const FEATURES = 'features';
export const PROVIDER_EMAIL = 'providerEmail';
export const PROVIDER_NAME = 'providerName';
export const PROVIDER_PHONE_NUMBERS = 'providerPhoneNumbers';
export const PROVIDER_WEBSITE = 'providerWebsite';
export const CATEGORY_ID = 'categoryId';
export const IMAGE = 'image';

export interface GeneralFormFields {
  [SHOULD_EXPIRE]: boolean;
  [DAYS_AFTER_EXPIRE]?: number;
  [SUBMISSION_DATE_LIMIT_ENABLED]: boolean;
  [SUBMISSION_DATE_LIMIT]?: number;
  [OTC_ENABLED]: boolean;
  [MOBILE_ENABLED]: boolean;
  [WEB_ENABLED]: boolean;
  [FEATURES]: string;
  [PROVIDER_EMAIL]: string;
  [PROVIDER_NAME]: string;
  [PROVIDER_PHONE_NUMBERS]: string[];
  [PROVIDER_WEBSITE]: string;
  [CATEGORY_ID]: number;
  [IMAGE]: NxFile
}

export interface LogoFile {
  file: File,
  publiclyAccessible: boolean
}
