import {RouteProps} from 'react-router-dom'

export interface KeyedRouteProps extends RouteProps {
  key: string;
  routes?: KeyedRouteProps[];
}

export enum UrlParam {
  APPLICATION_ID = 'applicationId',
  PROCESS_ID = 'processId',
  PHASE_ID = 'phaseId',
  BATCH_ID = 'batchId',
  APPLICANT_SOURCE_ID = 'applicantSourceId',
  CUSTOMER_TYPE = 'customerType',
  CREATION_TYPE = 'creationType'
}

export interface UrlParams {
  [UrlParam.APPLICATION_ID]: string;
  [UrlParam.PROCESS_ID]: string;
  [UrlParam.PHASE_ID]: string;
  [UrlParam.APPLICANT_SOURCE_ID]: string;
  [UrlParam.CUSTOMER_TYPE]: string;
  [UrlParam.CREATION_TYPE]: string;
}

export interface BatchUrlParams {
  [UrlParam.BATCH_ID]: string;
}
