import {PAGE_RECORDS_LIMIT} from '../../../constants/table-defaults';
import {UserData} from '../../../security-context.model';
import {ApiHelper} from '../../../utils/api-helper';
import {FilterValues, TableType} from './loan-application-dashboard.model';

export const getUrlParams = (appliedFilters: FilterValues, userData?: UserData, pageNo = 0): URLSearchParams => {
  const {customerName, referenceNumber, customerNumber, applicationStatuses, approvalStatuses, tableType} = appliedFilters;

  const applicationStatusFilterApplied = applicationStatuses.length > 0;
  const approvalStatusFilterApplied = approvalStatuses.length > 0;

  return ApiHelper.constructUrlParams({
    pageNo,
    pageSize: PAGE_RECORDS_LIMIT,
    referenceNo: referenceNumber,
    customerName,
    customerNumber,
    ...approvalStatusFilterApplied ? {approvalStatuses} : {},
    ...applicationStatusFilterApplied ? {applicationStatuses} : {},
    ...tableType == TableType.MY_APPLICATIONS ? {userId: userData?.id} : {},
    ...tableType == TableType.ALL_APPLICATIONS ? {branchIds: userData?.branchIds} : {},
    ...tableType == TableType.ASSIGNED_TO_ME ? {assignedToId: userData?.id} : {},
  });
};
