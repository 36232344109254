import {TableRow} from '@material-ui/core';
import React, {ReactElement} from 'react';
import {TABLE_DATE_FORMAT} from '../../../../constants/format-templates';
import {RoutePaths} from '../../../../routes/routes.paths';
import {TransHelper} from '../../../../utils/trans-helper';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import TableDataCell from '../../../shared/table/cells/TableDataCell';
import {Batch} from '../../batch.model';
import BatchStatusChip from '../../status-chip/BatchStatusChip';
import styles from './BatchTableRow.module.scss';

interface Props {
  batch: Batch;
}

const PrefixTrans = TransHelper.getPrefixedTrans('BATCH.TYPE');

const BatchTableRow = ({batch}: Props): ReactElement => {

  const history = useGuardedHistory();

  const navigateToBatch = (batch: Batch): () => void => (): void => {
    history.push(`${RoutePaths.BATCH}/${batch.id}`);
  };

  return (
    <TableRow onClick={navigateToBatch(batch)} className={styles.tableRow}>
      <TableDataCell data={batch.id} />
      <TableDataCell data={batch.name} />
      <TableDataCell data={<PrefixTrans>{`${batch.type}`}</PrefixTrans>} />
      <TableDataCell data={batch.itemsCount ?? '-'} />
      <TableDataCell data={batch.createdOn?.format(TABLE_DATE_FORMAT) ?? '-'} />
      <TableDataCell data={<BatchStatusChip status={batch.status} />} />
    </TableRow>
  );
};

export default BatchTableRow;
