import {ConfiguredField} from '../../../../../shared/model/field.model';
import {LoanSimulation, LoanSimulationParams} from '../../../../../shared/model/loan-simulation.model';
import {
  LoanApplicationDataFormFieldsConfiguration
} from '../../../../../shared/model/step-forms/loan-application-data-form.model';
import {OptionalValidationForm} from '../../../../../shared/model/step-forms/optional-validation-form.model';
import {StepPayloadType} from '../shared/step-payload.model';

export const SIMULATION_REQUIRED_INDICATOR_FIELD = 'simulationRequired';
export const SIMULATION_PARAMS_CHANGED_INDICATOR_FIELD = 'simulationParamsChanged';

export interface LoanApplicationDataFormFields
  extends LoanApplicationDataFormFieldsConfiguration<ConfiguredField, ConfiguredField<number>>, OptionalValidationForm {
  isPaymentDynamic?: boolean;
  fixedTotalAmortizationNumber: ConfiguredField;
  simulation?: {
    result: LoanSimulation,
    input: LoanSimulationParams
  };
  [SIMULATION_REQUIRED_INDICATOR_FIELD]?: boolean;  // field only for yup validation
  [SIMULATION_PARAMS_CHANGED_INDICATOR_FIELD]?: boolean;  // field only for yup validation
  term?: number; // field only for yup validation
  remadeFromLoanIds?: number[];
}

export interface LoanApplicationDataPayload {
  type: StepPayloadType.LOAN_APPLICATION_DATA_PAYLOAD,
  loanParameters: {
    applicationId?: number,
    id?: number,
    phaseId?: number,
    result?: LoanSimulation,
    input?: LoanSimulationParams
  },
  loanClassId: number,
  loanPurposeId: number,
  loanSecurityId: number,
  loanEconomicActivityId: number,
  microfinanceClassificationId: number,
  borrowerTypeId: number,
  transactionTypeId: number,
  cicContractTypeId: number,
  loanPurposeToIndustryId: number,
  misGroupId: number
}
