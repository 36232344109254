import React, {useRef} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {CloseSidePanel} from '../side-panel/SidePanel';
import SidePanelWithIconButton from '../side-panel/SidePanelWithIconButton';
import CustomDictionaryConfig from './CustomDictionaryConfig';
import {ReactComponent as DictionaryIcon} from '../../../assets/images/icon-dictionary.svg';

interface Props {
  phaseId: number;
  dictionaryId?: number;
  handleSave: (dictionaryId: number) => void;
}

export default function CustomDictionarySidePanel({handleSave, phaseId, dictionaryId}: Props): React.ReactElement {

  const {t} = useTranslation();
  const tooltip = t('COMMON.TOOLTIPS.TOGGLE_LIST_CONFIG');
  const header = <Trans>COMMON.LIST_CONFIGURATION</Trans>;
  const panelRef = useRef<CloseSidePanel>(null);

  const handleDictionarySave = (dictId: number): void => {
    handleSave(dictId);
    panelRef.current && panelRef.current.close();
  };

  return (
    <SidePanelWithIconButton ref={panelRef} header={header} icon={<DictionaryIcon />} tooltip={tooltip}>
      <CustomDictionaryConfig phaseId={phaseId} handleSave={handleDictionarySave} dictionaryId={dictionaryId} />
    </SidePanelWithIconButton>
  );
}
