import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DOCUMENTS_URL} from '../../../../../../../../constants/api-urls';
import HttpService from '../../../../../../../../shared/http-service/http.service';
import {DocumentFieldTO} from '../../../../../../../../shared/model/field.model';
import {TransHelper} from '../../../../../../../../utils/trans-helper';
import Instruction from '../../../../../../../shared/instruction/Instruction';
import {CloseSidePanel} from '../../../../../../../shared/side-panel/SidePanel';
import {ReactComponent as SearchIcon} from '../../../../../../../../assets/images/icon-search-dark.svg';
import SidePanelWithIconButton from '../../../../../../../shared/side-panel/SidePanelWithIconButton';
import DocumentSearch from './document-search/DocumentSearch';
import styles from './DocumentSearchSidePanel.module.scss';

const TRANS_PREFIX = 'LOAN_APPLICATIONS.DOCUMENT_ASSIGN';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);
const header = <PrefixTrans>HEADER</PrefixTrans>;

interface Props {
  disabled: boolean;
  onSelect: (document: DocumentFieldTO) => void;
}

export default function DocumentSearchSidePanel({disabled, onSelect}: Props): React.ReactElement {

  const {t} = useTranslation();
  const tooltip = t(`${TRANS_PREFIX}.SEARCH_DOCUMENT`);
  const panelRef = useRef<CloseSidePanel>(null);
  const [isSelecting, setSelecting] = useState(false);

  const handleDocumentSelect = (documentId: number): void => {

    setSelecting(true);

    HttpService.get<DocumentFieldTO>(`${DOCUMENTS_URL}/${documentId}`)
      .then(document => {
        onSelect(document);
        setSelecting(false);
        panelRef.current && panelRef.current.close();
      });
  };

  return (
    <SidePanelWithIconButton ref={panelRef} header={header} icon={<SearchIcon />} tooltip={tooltip} disabled={disabled}>
      <div className={styles.panelWrapper}>
        <Instruction>
          <PrefixTrans>INSTRUCTION</PrefixTrans>
        </Instruction>
        <DocumentSearch onDocumentSelect={handleDocumentSelect} isSelecting={isSelecting} />
      </div>
    </SidePanelWithIconButton>
  );
}
