import {cloneDeep} from 'lodash';
import React, {useContext, useEffect, useMemo} from 'react';
import {DroppableProvided} from 'react-beautiful-dnd';
import {Approver} from '../../../../../../shared/model/phase.model';
import BinButton from '../../../../../shared/bin-button/BinButton';
import DragDropItem from '../../../../../shared/drag-drop/DragDropItem';
import {ApprovalRulesContext} from '../ApprovalConfiguration';
import ApproverInputs from './ApproverInputs';
import {KeyedApprover} from './keyed-approval-rules.model';

interface Props {
  approver: KeyedApprover;
  ruleIndex: number;
  provided: DroppableProvided;
  isDragging: boolean;
}

export default function ApproverItem({approver, ruleIndex, provided, isDragging}: Props): React.ReactElement {

  const {rules, setRules} = useContext(ApprovalRulesContext);

  useEffect((): void => {
    if (approverIndex <= -1) {
      throw new Error('Approver index out of range.');
    }
  }, [rules]);

  const approverIndex = useMemo((): number =>
    rules[ruleIndex].approvers.findIndex(({key}) => key === approver.key),
    [rules, ruleIndex, approver]);

  const updateApprover = (approverKey: keyof KeyedApprover, value: unknown): void => {
    const updatedRules = cloneDeep(rules);
    const approverToUpdate = updatedRules[ruleIndex].approvers[approverIndex] as Approver;
    approverToUpdate[approverKey] = value;
    setRules(updatedRules);
  };

  const removeApprover = (): void => {
    const updatedRules = cloneDeep(rules);
    updatedRules[ruleIndex].approvers.splice(approverIndex, 1);
    setRules(updatedRules);
  };

  const handleRoleChange = (roleIds: number[]): void => updateApprover('roleIds', roleIds);
  const handleBranchChange = (branchIds: number[]): void => updateApprover('branchIds', branchIds);

  const item = (
    <>
      <ApproverInputs approver={approver} handleRoleChange={handleRoleChange} handleBranchChange={handleBranchChange}/>
      <BinButton onClick={removeApprover} />
    </>
  );

  return <DragDropItem provided={provided} isDragging={isDragging} item={item} />;
}
