import dayjs, {Dayjs} from 'dayjs';
import {DocumentFieldType} from '../../../../../../../../../shared/model/field.model';
import {PagedRecords, PagedRequest} from '../../../../../../../../../shared/model/paged.model';

export interface DocumentTO<T_DATE = string, T_FIELD_TYPE = string> {
  applicationReferenceNo: string;
  createdOn: T_DATE;
  documentId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  name: string;
  number: string;
  type: T_FIELD_TYPE;
  userFullName: string;
}

export type Document = DocumentTO<Dayjs, DocumentFieldType>

export interface DocumentsByPage extends PagedRecords<Document> {
}

export interface DocumentsByPageTO extends PagedRecords<DocumentTO> {
}

export const fromDocumentsByPageTO = (documentsTO: DocumentsByPageTO): DocumentsByPage => ({
  ...documentsTO,
  result: documentsTO.result.map(fromDocumentTO)
});

export const fromDocumentTO = (documentTO: DocumentTO): Document => ({
  ...documentTO,
  createdOn: dayjs(documentTO.createdOn),
  type: DocumentFieldType[documentTO.type]
});

export interface DocumentSearchFormFields extends PagedRequest {
  applicationReferenceNo?: string;
  createdOnFrom?: string;
  createdOnTo?: string;
  customerName?: string;
  name?: string;
  number?: string;
  type?: DocumentFieldType;
}
