import {useContext, useEffect} from 'react';
import {LoanConfigurationContext} from '../../LoanConfiguration';

export function useSetConfigurationFormChanged(dirty: boolean): void {

  const {configurationChanged, setConfigurationChanged} = useContext(LoanConfigurationContext);

  useEffect(() => {
    if (dirty && !configurationChanged) {
      setConfigurationChanged(true);
    }
  }, [dirty]);
}
