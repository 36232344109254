import React, {useEffect} from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {RoutePaths} from '../../../../routes/routes.paths';
import {SingleMessagePageWrapper} from '../../single-message-page-wrapper/SingleMessagePageWrapper';

export default function PageNotFound(): React.ReactElement {

  const history = useHistory();

  useEffect(() => {
    history.push(RoutePaths.PAGE_NOT_FOUND);
  }, []);

  return (
    <SingleMessagePageWrapper>
      <Trans>PAGE_NOT_FOUND</Trans>
    </SingleMessagePageWrapper>
  );
}
