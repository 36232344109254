import clsx from 'clsx';
import React from 'react';
import styles from './Instruction.module.scss';

interface Props {
  children: React.ReactNode;
  defaultFieldsInstruction?: boolean;
}

const Instruction = ({children, defaultFieldsInstruction = false}: Props): React.ReactElement => (
  <div className={clsx(styles.instruction, {[styles.instruction_defaultFields]: defaultFieldsInstruction})}>
    {children}
  </div>
);

export default Instruction;
