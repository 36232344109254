import {Table, TableBody, TableContainer} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, {ReactElement, useContext} from 'react';
import {Trans} from 'react-i18next';
import {LoanApplicationTableHeaders, LoanApplicationWithCheckBoxTableHeaders} from '../../../../constants/loan-application-labels';
import {PAGE_RECORDS_LIMIT} from '../../../../constants/table-defaults';
import EmptyState from '../../../shared/empty-state/EmptyState';
import TableHeader from '../../../shared/table/header/TableHeaders';
import TablePaginationFooter from '../../../shared/table/pagination-footer/TablePaginationFooter';
import {KeyedApplicationBasicDataByPage} from '../../loan-application/loan-application.model';
import LoanApplicationsTableRow from './LoanApplicationsTableRow';
import {SecurityContext} from '../../../../App';
import {isSysAdminOrManager} from '../../../../utils/permissions-utils';
import {getLoanApplicationTableHeaders} from '../../../../utils/table-helper';
import {PaymentInterval} from '../../../../shared/model/payment-interval.model';

interface Props {
  applications: KeyedApplicationBasicDataByPage;
  onPageChange: (pageNo: number) => Promise<void>;
  paymentIntervals?: PaymentInterval[];
}

const HEADERS_TRANS_PREFIX = 'LOAN_APPLICATIONS.TABLE_HEADER';

const LoanApplicationsTable = ({applications, onPageChange, paymentIntervals}: Props): ReactElement => {
 
  const {userData} = useContext(SecurityContext);
  const isReassignLoanApplicationAllowed = isSysAdminOrManager(userData);
  const columnHeaders = getLoanApplicationTableHeaders(isReassignLoanApplicationAllowed);

  return <>
    {
      isEmpty(applications.result)
        ? <EmptyState instruction={<Trans>COMMON.NO_RESULTS</Trans>} />
        : <>
          <TableContainer>
            <Table>
              <TableHeader translationPath={HEADERS_TRANS_PREFIX} headers={columnHeaders} />
              <TableBody>
                {
                  applications.result.map((application) =>
                    <LoanApplicationsTableRow key={application.key} application={application} paymentIntervals={paymentIntervals} />)
                }
              </TableBody>
              <TablePaginationFooter page={applications.pageNo}
                                     onPageChange={onPageChange}
                                     count={applications.totalCount}
                                     rowsPerPage={PAGE_RECORDS_LIMIT} />
            </Table>
          </TableContainer>
        </>
    }
  </>;  
}

export default LoanApplicationsTable;
