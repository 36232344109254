import clsx from 'clsx';
import React, {ReactElement, useMemo, useState} from 'react';
import {NumberedApprover} from '../../../../../../../shared/model/phase.model';
import DetailsButton from '../../../../../../shared/details-button/DetailsButton';
import {ApprovalExecution, ApprovalExecutionStatus as Status} from '../../../../loan-application.model';
import {ApprovalStatus} from './approval-status/ApprovalStatus';
import {PrefixTrans} from './approval-utils';
import styles from './ApprovalPanel.module.scss';
import {ApproverDetail} from './ApproverDetail';

interface Props {
  approvers: NumberedApprover[];
  approvalExecutions: ApprovalExecution[];
}

export const ApprovalPanel = ({approvers, approvalExecutions}: Props): ReactElement => {

  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);

  const status = useMemo(() => {

    if (approvalExecutions.some(({status}) => status === Status.REJECTED)) {
      return Status.REJECTED;
    }

    if (
      approvers.length === approvalExecutions.length
      && approvalExecutions.every(({status}) => status === Status.APPROVED)
    ) {
      return Status.APPROVED;
    }
  }, [approvalExecutions]);

  return (
    <div className={styles.panel}>
      <div className={styles.panelHeader}>
        <PrefixTrans>STEP_APPROVAL_PROGRESS</PrefixTrans>: {approvalExecutions.length}/{approvers.length}
        <DetailsButton onClick={(): void => setMoreDetailsOpen(!moreDetailsOpen)}
                       isOpen={moreDetailsOpen}
                       className={styles.detailsButton}
                       smallIcon />
        <ApprovalStatus status={status} />
      </div>
      <div className={clsx(styles.moreDetails, {[styles.moreDetails_toggled]: moreDetailsOpen})}>
        <div className={styles.approvers}>
          {
            approvers.map(approver =>
              <ApproverDetail key={approver.ordinalNumber} approvalExecutions={approvalExecutions} approver={approver} />
            )
          }
        </div>
      </div>
    </div>
  );
};
