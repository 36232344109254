import {NxButton, NxInput} from '@nextbank/ui-components';
import clsx from 'clsx';
import {cloneDeep} from 'lodash';
import React, {ChangeEvent, useState} from 'react';
import {Trans} from 'react-i18next';
import {generateKey} from '../../../../../../utils/generate-key';
import {getInputValue} from '../../../../../../utils/input-utils';
import {StringHelper} from '../../../../../../utils/string-helper';
import DragDropItem from '../../../../../shared/drag-drop/DragDropItem';
import DragDropItems from '../../../../../shared/drag-drop/DragDropItems';
import {KeyedCustomPhase} from '../custom-phases.model';
import styles from './CustomPhasesConfig.module.scss';
import CustomPhaseInput from './CustomPhaseInput';

interface Props {
  inputLabel: React.ReactNode;
  handleSave: (phases: KeyedCustomPhase[]) => Promise<void>;
  phasesState: {
    customPhases: KeyedCustomPhase[];
    setCustomPhases: (phases: KeyedCustomPhase[]) => void;
  };
}

export default function CustomPhasesConfig({inputLabel, phasesState, handleSave}: Props): React.ReactElement {
  const [isSaving, setIsSaving] = useState(false);
  const [newPhaseName, setNewPhaseName] = useState<string>('');
  const {customPhases, setCustomPhases} = phasesState;

  const handleNewPhaseChange = (event: ChangeEvent): void => setNewPhaseName(getInputValue(event));

  const handleListFieldChange = (key: string): (e: ChangeEvent) => void => (event: ChangeEvent): void => {
    const phases = cloneDeep(customPhases);
    const index = phases.findIndex(item => item.key === key);
    phases[index].name = getInputValue(event);
    setCustomPhases(phases);
  };

  const removeListItem = (key: string): void => {
    const filteredList = customPhases.filter(item => item.key !== key);
    setCustomPhases(filteredList);
  };

  const ListItem = ({item}: {item: KeyedCustomPhase}): React.ReactElement =>
    <CustomPhaseInput className={styles.input}
                      onRemove={(): void => removeListItem(item.key)}
                      onBlur={handleListFieldChange(item.key)}
                      initValue={item.name}
                      label={inputLabel} />;

  const phaseItemFunction = (provided, isDragging, item): React.ReactElement =>
    <DragDropItem className={styles.row} provided={provided} isDragging={isDragging} item={<ListItem item={item} />} />;

  const addPhase = (): void => {
    const phases = cloneDeep(customPhases);
    phases.push(
      {
        name: newPhaseName,
        ...generateKey()
      }
    );

    setCustomPhases(phases);
    setNewPhaseName('');
  };

  const handlePhasesSave = (): void => {
    setIsSaving(true);
    handleSave(customPhases)
      .then(() => setIsSaving(false));
  };

  return (
    <>
      {
        customPhases.length > 0 &&
          <div className={styles.phases}>
            <DragDropItems<KeyedCustomPhase>
              droppableId={'customPhasesConfiguration'}
              itemComponentFunction={phaseItemFunction}
              setItems={setCustomPhases}
              items={customPhases} />
          </div>
      }
      <div className={styles.row}>
        <NxInput className={clsx(styles.input, styles.input_new)}
                 onChange={handleNewPhaseChange}
                 value={newPhaseName}
                 label={inputLabel} />
        <NxButton onClick={addPhase} disabled={StringHelper.isBlank(newPhaseName)}>
          <Trans>COMMON.ADD</Trans>
        </NxButton>
      </div>
      <div className={clsx(styles.row, styles.row_save)}>
        <NxButton onClick={handlePhasesSave} loaded={!isSaving}>
          <Trans>COMMON.SAVE</Trans>
        </NxButton>
      </div>
    </>
  );
}
