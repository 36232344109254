import {FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {LoanApplicationStepsRoutePaths} from '../../../../../routes/routes.paths';
import {PrintType} from '../../../../../shared/model/print.model';
import {validateAndSubmitForm} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';
import {TransHelper} from '../../../../../utils/trans-helper';
import {
  CustomerDataPhase
} from '../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {
  LoanApplicationDataPhase
} from '../../../../loan-configurations/loan-configuration/steps/loan-application-data/loan-application-data-phase.model';
import LoanApplicationStep from '../shared/loan-application-step/LoanApplicationStep';
import ApplicantsSummary, {ApplicantBasicData} from './applicants-summary/ApplicantsSummary';
import BorrowerData from './borrower-data/BorrowerData';
import {ApplicationRelationshipType, CustomerDataFormFields, CustomersDataFormFields} from './customer-data-form.model';
import {CustomerPhaseData} from './hooks/use-customer-data-start-data.hook';
import RelatedPeopleData from './related-people/RelatedPeopleData';
import {LoanApplicationContext} from '../../LoanApplication';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {
  CalculatorPhase
} from '../../../../loan-configurations/loan-configuration/steps/calculator/calculator-phase.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {PhaseName} from '../../../../../constants/api-urls';
import {LoanApplicationSimulation} from '../../loan-application.model';
import {LoanCreationType} from '../../../../../shared/model/creation-type.model';

export const CUSTOMER_STEP_TRANS_PREFIX = 'LOAN_APPLICATIONS.CUSTOMER_DATA';
export const PrefixTrans = TransHelper.getPrefixedTrans(CUSTOMER_STEP_TRANS_PREFIX);

interface Props {
  formikProps: FormikProps<CustomersDataFormFields>;
  config: CustomerDataPhase;
  loanApplicationDataConfig: LoanApplicationDataPhase;
  customerData: CustomerPhaseData;
}

export default function CustomerDataForm(
  {formikProps, config, loanApplicationDataConfig, customerData}: Props
): ReactElement {

  const location = useLocation();
  const {t} = useTranslation();

  const {process, application} = useContext(LoanApplicationContext);
  const calculatorPhaseConfig = useSingleQuery<CalculatorPhase>(ApiHelper.getPhaseConfigurationUrl(process.id,
    PhaseName.CALCULATOR));

  const isConsolidateLoan = useMemo(() => {
    if (application) {
      const simulation = application?.simulations.find(simulation => simulation.phaseId === calculatorPhaseConfig?.id) as LoanApplicationSimulation;
      return simulation?.input.creationType === LoanCreationType.CONSOLIDATION;
    }
    return false;
  }, [application, calculatorPhaseConfig]);

  const {values, submitForm, validateForm, setFieldValue, dirty} = formikProps;

  const handleSave = async (validate: boolean): Promise<void> =>
    validateAndSubmitForm<CustomersDataFormFields>(
      values, validate, submitForm, validateForm, setFieldValue, t
    );

  const toApplicantBasicData = (applicantData?: CustomerDataFormFields): ApplicantBasicData | undefined => {
    if (isNil(applicantData)) {
      return;
    }

    return {
      key: applicantData.key,
      businessName: applicantData?.corporateData.businessName.value ?? undefined,
      registrationDate: applicantData?.corporateData.registrationDate.value ?? undefined,
      firstName: applicantData.individualData.firstName.value ?? undefined,
      lastName: applicantData.individualData.lastName.value ?? undefined,
      middleName: applicantData.individualData.middleName.value ?? undefined,
      birthDate: applicantData?.individualData.birthDate.value ?? undefined,
      type: applicantData.type,
      relationshipType: applicantData.relationshipType
    };
  };

  const renderCorrectForm = (): ReactElement => {
    // TODO fix path recognize
    if (location.pathname.includes(LoanApplicationStepsRoutePaths.CO_BORROWERS)) {
      return <RelatedPeopleData
        formikFieldPath={'coBorrowers'}
        relationshipType={ApplicationRelationshipType.CO_BORROWER}
        config={config}
        requirementEntries={customerData.requirementEntries}
        dictionaryEntries={customerData.dictionaryEntries}
        customDictionaries={customerData.customDictionaries}
      />;
      // TODO
    } else if (location.pathname.includes(LoanApplicationStepsRoutePaths.CO_MAKERS)) {
      return <RelatedPeopleData
        formikFieldPath={'coMakers'}
        relationshipType={ApplicationRelationshipType.CO_MAKER}
        config={config}
        requirementEntries={customerData.requirementEntries}
        dictionaryEntries={customerData.dictionaryEntries}
        customDictionaries={customerData.customDictionaries}
      />;
    } else if (location.pathname.includes(LoanApplicationStepsRoutePaths.BORROWER)) {
      return <BorrowerData
        config={config}
        requirementEntries={customerData.requirementEntries}
        dictionaryEntries={customerData.dictionaryEntries}
        customDictionaries={customerData.customDictionaries}
        hideCustomerSelect={isConsolidateLoan} />;
    }

    const coBorrowers = values.coBorrowers?.map(applicant => toApplicantBasicData(applicant))
      .filter(applicant => !isNil(applicant)) as (ApplicantBasicData)[] | undefined;
    const coMakers = values.coMakers?.map(applicant => toApplicantBasicData(applicant))
      .filter(applicant => !isNil(applicant)) as (ApplicantBasicData)[] | undefined;

    return <ApplicantsSummary
      borrower={toApplicantBasicData(values.borrower)}
      coBorrowers={{
        customers: coBorrowers ?? [],
        max: loanApplicationDataConfig?.maxCoBorrowerNumber ?? 0,
        min: loanApplicationDataConfig?.minCoBorrowerNumber ?? 0
      }}
      coMakers={{
        customers: coMakers ?? [],
        max: loanApplicationDataConfig?.maxCoMakerNumber ?? 0,
        min: loanApplicationDataConfig?.minCoMakerNumber ?? 0
      }}
      isConsolidateLoan={isConsolidateLoan} />;
  };

  return <LoanApplicationStep stepInstruction={<PrefixTrans>INSTRUCTION</PrefixTrans>}
                              phaseId={config.id}
                              handleSave={handleSave}
                              dataChanged={dirty}
                              approvalRules={config.approvalRules}
                              printType={PrintType.CUSTOMER_DATA}>

    {renderCorrectForm()}
  </LoanApplicationStep>;
}

