import dayjs, {Dayjs} from 'dayjs';
import {PagedRecords} from '../../../../../../../shared/model/paged.model';

export enum EventLogGroupType {
  APPLICATION = 'APPLICATION',
  STATUS = 'STATUS',
  APPROVAL = 'APPROVAL',
  INPUT = 'INPUT'
}

export interface EventLogTO<T_DATE = string> {
  id: number,
  applicationId: number,
  userId: number,
  userFullName: string,
  registeredOn: T_DATE,
  logGroup: EventLogGroupType,
  message: string,
  phaseId: number,
  phaseName: string,
  branchName: string,
  roleNames: string[]
}

export interface EventLog extends EventLogTO<Dayjs> {
}

export interface KeyedEventLog extends EventLog {
  key: string;
}

export interface KeyedEventLogSearchResult extends PagedRecords<KeyedEventLog> {
}

export interface EventLogSearchResult extends PagedRecords<EventLogTO> {
}

export const fromEventLogTO = (eventLogTO: EventLogTO): EventLog => ({
  ...eventLogTO,
  registeredOn: dayjs(eventLogTO.registeredOn)
});
