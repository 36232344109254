import {NxFormikSelect, NxSelectOption} from '@nextbank/ui-components';
import React from 'react';
import {DEFAULT_VALUE} from '../../../constants/static-field-values';
import {Field} from '../../../shared/model/field.model';
import {SetFieldValue} from '../../../shared/model/formik.model';
import {FieldDefaultCheckbox} from './FieldDefaultCheckbox';

interface Props {
  label: React.ReactNode;
  name: string;
  field: Field<unknown>;
  options?: NxSelectOption[];
  setFieldValue: SetFieldValue;
  requiredButtonVisible?: boolean;
  emptyOption?: boolean;
  disabled?: boolean;
}

export const FieldDefaultSelect = (
  {label, name, field, setFieldValue, options, requiredButtonVisible, emptyOption, disabled}: Props): React.ReactElement => (
  <FieldDefaultCheckbox name={name}
                        field={field}
                        setFieldValue={setFieldValue}
                        requiredButtonVisible={requiredButtonVisible}>
    <NxFormikSelect name={`${name}.${DEFAULT_VALUE}`}
                    options={options ?? []}
                    label={label}
                    emptyOption={emptyOption} 
                    disabled={disabled}/>
  </FieldDefaultCheckbox>
);

