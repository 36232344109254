import React from 'react';
import {Trans} from 'react-i18next';
import {LoanApplicationTableHeaders} from '../constants/loan-application-labels';
import {LoanDetailsLabel} from '../constants/loan-details-labels';

interface Props {
  children: string;
}

export const TransHelper = {
  getPrefixedTrans: (prefix: string): ({children}: Props) => React.ReactElement =>
    function PrefixTrans({children}: Props): React.ReactElement {
      return <Trans>{`${prefix}.${children}`}</Trans>;
    },

  getLoanDetailsLabel: (label: LoanDetailsLabel): React.ReactElement => <Trans>{`LOAN_DETAILS.${label}`}</Trans>,

  getApplicationLabel: (label: LoanApplicationTableHeaders): React.ReactElement =>
    <Trans>{`LOAN_APPLICATIONS.TABLE_HEADER.${label}`}</Trans>
};
