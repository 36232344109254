import {ApiHelper} from '../../utils/api-helper';
import {DictionaryEntry} from '../model/dictionary.model';
import useSingleQuery from './use-single-query.hook';

export default function useDictionaryEntriesQuery(dictionaryId?: number | string): DictionaryEntry[] | undefined {

  const url = ApiHelper.getDictionaryEntriesUrl(dictionaryId);

  return useSingleQuery<DictionaryEntry[]>(url, null, !!dictionaryId)
}

