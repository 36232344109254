import {Drawer} from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {NxIconButton} from '@nextbank/ui-components';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../../../../../../assets/images/icon-close.svg';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import {LoanApplicationContext} from '../../../../LoanApplication';
import {EventLogs} from './EventLogs';
import styles from './EventLogsDrawer.module.scss';

export const EVENT_LOGS_TRANS_PREFIX = 'LOAN_APPLICATIONS.EVENT_LOG';
export const EventLogsPrefixTrans = TransHelper.getPrefixedTrans(EVENT_LOGS_TRANS_PREFIX);

interface Props {
  onClose: () => void,
  open: boolean,
}

export const EventLogsDrawer = ({open, onClose}: Props): ReactElement => {

  const {t} = useTranslation();
  const {application} = useContext(LoanApplicationContext);

  return (
    <Drawer onClose={onClose}
                     anchor='right'
                     open={open}>
      <div className={styles.eventLogsDrawer} role='presentation'>
        <div className={styles.header}>
          <EventLogsPrefixTrans>HEADER</EventLogsPrefixTrans>
          <NxIconButton onClick={onClose} icon={<CloseIcon />} />
        </div>
        <div className={styles.description}>
          {t(`${EVENT_LOGS_TRANS_PREFIX}.DESCRIPTION`, {refNumber: application?.referenceNo})}
        </div>
        <EventLogs />
      </div>
    </Drawer>
  );
};
