import {Table, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import {useFormikContext} from 'formik';
import capitalize from 'lodash/capitalize';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {CustomerType} from '../../../../../../shared/model/customer-type.model';
import {StringHelper} from '../../../../../../utils/string-helper';
import {TransHelper} from '../../../../../../utils/trans-helper';
import BinButton, {BinVariant} from '../../../../../shared/bin-button/BinButton';
import TableDataCell from '../../../../../shared/table/cells/TableDataCell';
import TableHeaderCell from '../../../../../shared/table/cells/TableHeaderCell';
import {LoanApplicationContext} from '../../../LoanApplication';
import {ApplicationRelationshipType, CustomersDataFormFields} from '../customer-data-form.model';
import {getRedirectPath} from './applicant-summary-utils';
import {ApplicantBasicData} from './ApplicantsSummary';
import styles from './ApplicantsSummaryTable.module.scss';

export const CUSTOMER_STEP_TRANS_PREFIX = 'LOAN_APPLICATIONS.CUSTOMER_DATA.CUSTOMER_DETAILS';
export const PrefixTrans = TransHelper.getPrefixedTrans(CUSTOMER_STEP_TRANS_PREFIX);

interface Props {
  applicants: ApplicantBasicData[];
  hideDeleteButton?: boolean;
}

export default function ApplicantsSummaryTable({applicants, hideDeleteButton = false}: Props): ReactElement {

  const {t} = useTranslation();
  const history = useHistory();
  const {values, setValues} = useFormikContext<CustomersDataFormFields>();
  const {applicationParams} = useContext(LoanApplicationContext);

  const headers = ['FULL_NAME', 'DATE', 'CUSTOMER_TYPE'];

  const translatedHeaders = headers.map(header => t(`${CUSTOMER_STEP_TRANS_PREFIX}.TABLE.${header}`));
  // Add empty header above action column
  translatedHeaders.push('');

  const getFullClientName = (applicant: ApplicantBasicData): string =>
    applicant.type === CustomerType.CORPORATE
      ? applicant.businessName ?? '-'
      : StringHelper.getClientName(
        applicant.firstName, applicant.middleName, applicant.lastName
      );

  const deleteApplicantData = (key: string, relationshipType: ApplicationRelationshipType): void => {
    switch (relationshipType) {
      case ApplicationRelationshipType.BORROWER:
        setValues({...values, borrower: undefined});
        break;
      case ApplicationRelationshipType.CO_MAKER:
        setValues({...values, coMakers: values.coMakers?.filter(coMaker => coMaker.key !== key)});
        break;
      case ApplicationRelationshipType.CO_BORROWER:
        setValues({...values, coBorrowers: values.coBorrowers?.filter(coBorrower => coBorrower.key !== key)});
        break;
      default:
        throw new Error('Applicant relationship not recognized');
    }
  };

  const generateCustomerActionButtons = (applicant: ApplicantBasicData): ReactElement => (
    <div className={styles.customerActions}>
      {!hideDeleteButton &&
        <BinButton bordered={false}
                   variant={BinVariant.PRIMARY}
                   disabled={!applicationParams.isEditable}
                   onClick={(): void => deleteApplicantData(applicant.key, applicant.relationshipType)} />
      }
      <NxButton onClick={(): void => onDetailsClick(applicant.relationshipType, applicant.key)}
                variant={NxButtonVariant.TEXT}>
        <PrefixTrans>DETAILS</PrefixTrans>
      </NxButton>
    </div>
  );

  const onDetailsClick = (relationshipType: ApplicationRelationshipType, applicantKey: string): void =>
    history.push(getRedirectPath(history.location.pathname, relationshipType), {applicantKey});

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {
              translatedHeaders.map((header, index) =>
                <TableHeaderCell key={index} header={header} />
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            applicants.map(
              (applicant, index) => (
                <TableRow key={index}>
                  {/* cell title set to appropriate table headers to provide basic tooltips */}
                  <TableDataCell className={styles.fullNameHeader}
                                 title={translatedHeaders[0]}
                                 data={getFullClientName(applicant)} />
                  <TableDataCell title={translatedHeaders[1]}
                                 data={applicant.type === CustomerType.CORPORATE
                                   ? applicant.registrationDate
                                   : applicant.birthDate} />
                  <TableDataCell title={translatedHeaders[2]} data={capitalize(applicant.type)} />
                  <TableDataCell data={generateCustomerActionButtons(applicant)} />
                </TableRow>
              )
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

