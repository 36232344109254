import {isNil} from 'lodash';
import {useEffect, useState} from 'react';
import useFileUpload from '../../../../../shared/hooks/use-file-upload.hook';
import {FileBasicData, NxFile} from '../../../../../shared/model/nx-file.model';

interface FileIconState {
  iconFile?: NxFile;
  isIconFileLoading: boolean;
}

export default function useCategoryIconFile(image?: FileBasicData): FileIconState {

  const {downloadFile} = useFileUpload();
  const [iconFile, setIconFile] = useState<NxFile>();
  const [isIconFileLoading, setIconFileLoading] = useState(true);

  useEffect(() => {
      if (isNil(image) || isNil(image.url)) {
        setIconFileLoading(false);
        return;
      }

      setIconFileLoading(true);

      downloadFile(image)
        .then(file => {
          setIconFile(file);
          setIconFileLoading(false);
        });
    },
    [image?.url]
  );

  return {iconFile, isIconFileLoading};
}
