import isNil from 'lodash/isNil';
import React from 'react';
import ButtonSwitch from '../../../../../../shared/button-switch/ButtonSwitch';
import {PrefixTrans} from '../../Calculator';
import styles from './PaymentModeSwitch.module.scss';

interface Props {
  paymentModeState: {
    isPaymentDynamic: boolean;
    setIsPaymentDynamic?: (isDynamic: boolean) => void;
  };
}

export default function PaymentModeSwitch({paymentModeState}: Props): React.ReactElement {

  const {isPaymentDynamic, setIsPaymentDynamic} = paymentModeState;

  const setIsDynamic = (isDynamic: boolean): () => void => (): void => {
    if (setIsPaymentDynamic) {
      setIsPaymentDynamic(isDynamic);
    }
  };

  return <div className={styles.switchWrapper}>
    <ButtonSwitch
      leftButtonActive={isPaymentDynamic}
      leftButton={<PrefixTrans>DYNAMIC</PrefixTrans>}
      onLeftButtonClick={setIsDynamic(true)}
      rightButton={<PrefixTrans>FIXED</PrefixTrans>}
      onRightButtonClick={setIsDynamic(false)}
      disabled={isNil(setIsPaymentDynamic)}
    />
  </div>;
}
