import React from 'react';
import {TransHelper} from '../../../../../utils/trans-helper';
import {ConfirmationPopup} from '../../../../shared/confirmation-popup/ConfirmationPopup';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.PUBLISH_FAILED.POPUP');

interface Props {
  onConfirm: () => void;
  onDeny: () => void;
  open: boolean;
}

export const LoanPublishingErrorPopup = ({onConfirm, onDeny, open}: Props): React.ReactElement => (
  <ConfirmationPopup header={<PrefixTrans>HEADER</PrefixTrans>}
                     description={<PrefixTrans>HELP_MESSAGE</PrefixTrans>}
                     confirmButtonLabel={<PrefixTrans>GO_TO_LOAN_CONFIGURATION</PrefixTrans>}
                     onConfirm={onConfirm}
                     onDeny={onDeny}
                     open={open} />
);
