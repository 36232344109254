import {NxSelectOption} from '@nextbank/ui-components';
import React from 'react';
import {Trans} from 'react-i18next';
import {LANDLINE_PHONE_NUMBER} from '../../../../../../constants/format-templates';
import {SetFieldValue} from '../../../../../../shared/model/formik.model';
import {
  IndividualDataFormFieldsConfiguration
} from '../../../../../../shared/model/step-forms/individual-data-form.model';
import {
  ThirdPartySystemDataFormFieldsConfiguration
} from '../../../../../../shared/model/step-forms/third-party-system-form.model';
import {StringHelper} from '../../../../../../utils/string-helper';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {FieldDefaultCheckbox} from '../../../../../shared/field-default/FieldDefaultCheckbox';
import {FieldDefaultDatePicker} from '../../../../../shared/field-default/FieldDefaultDatePicker';
import {FieldDefaultInput} from '../../../../../shared/field-default/FieldDefaultInput';
import {FieldDefaultPhoneInput} from '../../../../../shared/field-default/FieldDefaultPhoneInput';
import {FieldDefaultSelect} from '../../../../../shared/field-default/FieldDefaultSelect';
import {YES_NO_SELECT_OPTIONS} from '../../../../../shared/form-bolean-option-values/YesNoSelectOptions';
import FormColumn from '../../../../../shared/form-column/FormColumn';
import Instruction from '../../../../../shared/instruction/Instruction';
import styles from './IndividualData.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.CUSTOMER_DATA.APPLICANT_DATA');

interface Props {
  values: IndividualDataFormFieldsConfiguration;
  thirdPartySystemValues: ThirdPartySystemDataFormFieldsConfiguration;
  setFieldValue: SetFieldValue;
  options: {
    title?: NxSelectOption[];
    gender?: NxSelectOption[];
    residence?: NxSelectOption[];
    citizenship?: NxSelectOption[];
    riskLevel?: NxSelectOption[];
    civilStatus?: NxSelectOption[];
    dosriType?: NxSelectOption[];
    birthCountry?: NxSelectOption[];
    occupation?: NxSelectOption[];
  };
}

export default function IndividualData({values, thirdPartySystemValues, setFieldValue, options}: Props): React.ReactElement {

  return (
    <>
      <Instruction defaultFieldsInstruction>
        <Trans>COMMON.FIELDS.DEFAULT_FIELDS_INSTRUCTION</Trans>
      </Instruction>
      <div className={styles.row}>
        <FormColumn header={<PrefixTrans>PERSONAL_DATA</PrefixTrans>}>
          <FieldDefaultInput name='individualData.firstName'
                             field={values.firstName}
                             label={values.firstName.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultInput name='individualData.lastName'
                             field={values.lastName}
                             label={values.lastName.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultInput name='individualData.middleName'
                             field={values.middleName}
                             label={values.middleName.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultSelect name='individualData.titleId'
                              field={values.titleId}
                              label={values.titleId.name}
                              setFieldValue={setFieldValue}
                              options={options.title} />
          <FieldDefaultInput name='individualData.nickname'
                             field={values.nickname}
                             label={values.nickname.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultInput name='individualData.extension'
                             field={values.extension}
                             label={values.extension.name}
                             setFieldValue={setFieldValue} />
        </FormColumn>
        <FormColumn header={<PrefixTrans>CONTACT_DATA</PrefixTrans>}>
          <FieldDefaultInput name='individualData.email'
                             field={values.email}
                             label={values.email.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultPhoneInput name='individualData.mobileNumber'
                                  field={values.mobileNumber}
                                  label={values.mobileNumber.name}
                                  setFieldValue={setFieldValue} />
          <FieldDefaultPhoneInput name='individualData.workNumber'
                                  field={values.workNumber}
                                  label={values.workNumber.name}
                                  setFieldValue={setFieldValue} />
          <FieldDefaultPhoneInput name='individualData.landlineNumber'
                                  field={values.landlineNumber}
                                  label={values.landlineNumber.name}
                                  setFieldValue={setFieldValue}
                                  mask={LANDLINE_PHONE_NUMBER} />
        </FormColumn>
      </div>
      <div className={styles.row}>
        <FormColumn header={<PrefixTrans>COMPLIANCE</PrefixTrans>}>
          <FieldDefaultSelect name='individualData.vip'
                              field={values.vip}
                              label={values.vip.name}
                              setFieldValue={setFieldValue}
                              options={YES_NO_SELECT_OPTIONS}
                              emptyOption={false} />
          <FieldDefaultCheckbox name='individualData.signature' field={values.signature} setFieldValue={setFieldValue}>
            <PrefixTrans>CUSTOMER_SIGNATURE</PrefixTrans>
          </FieldDefaultCheckbox>
          <FieldDefaultSelect name='individualData.pep'
                              field={values.pep}
                              label={values.pep.name}
                              setFieldValue={setFieldValue}
                              options={YES_NO_SELECT_OPTIONS}
                              emptyOption={false} />
          <FieldDefaultSelect name='individualData.riskLevelId'
                              field={values.riskLevelId}
                              label={values.riskLevelId.name}
                              setFieldValue={setFieldValue}
                              options={options.riskLevel} />
          <FieldDefaultSelect name='individualData.dosri'
                              field={values.dosri}
                              label={values.dosri.name}
                              setFieldValue={setFieldValue}
                              options={YES_NO_SELECT_OPTIONS}
                              emptyOption={false} />
          {
            StringHelper.stringToBoolean(values.dosri.defaultValue) &&
            <FieldDefaultSelect name='individualData.dosriTypeId'
                                field={values.dosriTypeId}
                                label={values.dosriTypeId.name}
                                setFieldValue={setFieldValue}
                                options={options.dosriType} />
          }
          <FieldDefaultSelect name='individualData.bankEmployee'
                              field={values.bankEmployee}
                              label={values.bankEmployee.name}
                              setFieldValue={setFieldValue}
                              options={YES_NO_SELECT_OPTIONS}
                              emptyOption={false} />
          <FieldDefaultSelect name='individualData.occupationId'
                              field={values.occupationId}
                              label={values.occupationId.name}
                              setFieldValue={setFieldValue}
                              options={options.occupation}
                              emptyOption={false} />
          <FieldDefaultCheckbox name='individualData.picture' field={values.picture} setFieldValue={setFieldValue}>
            <PrefixTrans>CUSTOMER_PICTURE</PrefixTrans>
          </FieldDefaultCheckbox>
        </FormColumn>
        <FormColumn header={<PrefixTrans>DEMOGRAPHICS</PrefixTrans>}>
          <FieldDefaultSelect name='individualData.genderId'
                              field={values.genderId}
                              label={values.genderId.name}
                              setFieldValue={setFieldValue}
                              options={options.gender} />
          <FieldDefaultSelect name='individualData.civilStatusId'
                              field={values.civilStatusId}
                              label={values.civilStatusId.name}
                              setFieldValue={setFieldValue}
                              options={options.civilStatus} />
          <FieldDefaultDatePicker name='individualData.birthDate'
                                  field={values.birthDate}
                                  label={values.birthDate.name}
                                  setFieldValue={setFieldValue} />
          <FieldDefaultSelect name='individualData.birthCountryId'
                              field={values.birthCountryId}
                              label={values.birthCountryId.name}
                              setFieldValue={setFieldValue}
                              options={options.birthCountry} />
          <FieldDefaultInput name='individualData.birthPlace'
                             field={values.birthPlace}
                             label={values.birthPlace.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultSelect name='individualData.residenceId'
                              field={values.residenceId}
                              label={values.residenceId.name}
                              setFieldValue={setFieldValue}
                              options={options.residence} />
          <FieldDefaultSelect name='individualData.citizenshipId'
                              field={values.citizenshipId}
                              label={values.citizenshipId.name}
                              setFieldValue={setFieldValue}
                              options={options.citizenship} />
        </FormColumn>
      </div>
      <div className={styles.row}>
        <FormColumn header={<PrefixTrans>RELATIVES</PrefixTrans>}>
          <FieldDefaultInput name='individualData.mothersFirstName'
                             field={values.mothersFirstName}
                             label={values.mothersFirstName.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultInput name='individualData.mothersMaidenName'
                             field={values.mothersMaidenName}
                             label={values.mothersMaidenName.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultInput name='individualData.mothersMiddleName'
                             field={values.mothersMiddleName}
                             label={values.mothersMiddleName.name}
                             setFieldValue={setFieldValue} />
        </FormColumn>
        <FormColumn header={<PrefixTrans>THIRD_PARTY_SYSTEM_DATA</PrefixTrans>}>
          <FieldDefaultInput name='thirdPartySystemData.extraNumber'
                             field={thirdPartySystemValues.extraNumber}
                             label={thirdPartySystemValues.extraNumber.name}
                             setFieldValue={setFieldValue} />
        </FormColumn>
      </div>
    </>
  );
}
