import {NxFormikSelect, NxSelectOption} from '@nextbank/ui-components';
import {isEmpty} from 'lodash';
import React, {ReactElement} from 'react';
import {ACCEPTED_IMAGE_FILE_TYPES} from '../../../../../../constants/file-uploads';
import {SetFieldValue} from '../../../../../../shared/model/formik.model';
import {NxFile} from '../../../../../../shared/model/nx-file.model';
import NxFileUpload from '../../../../../shared/inputs/nx-file-upload/NxFileUpload';
import NxWysiwyg from '../../../../../shared/nx-wysiwyg/NxWysiwyg';
import {PrefixTrans} from '../General';
import {GeneralFormFields, FEATURES, CATEGORY_ID} from '../general.model';
import styles from './Description.module.scss';
import {MaximumFileSize} from '../../../../../shared/inputs/nx-file-upload/file-upload.model';

interface Props {
  values: GeneralFormFields;
  logoFile?: NxFile;
  categoryOptions?: NxSelectOption<number>[];
  onFileChanged: (file?: File) => Promise<void> | void;
  setFieldValue: SetFieldValue;
}

export default function Description({values, categoryOptions, logoFile, onFileChanged, setFieldValue}: Props): ReactElement {
  return (
    <>
      <div className={styles.header}>
        <PrefixTrans>CATEGORIES</PrefixTrans>
      </div>
      <NxFormikSelect<number> name={CATEGORY_ID}
                              label={<PrefixTrans>CATEGORIES</PrefixTrans>}
                              disabled={isEmpty(categoryOptions)}
                              options={categoryOptions ?? []}
                              emptyOption />
      <div className={styles.header}>
        <PrefixTrans>LOGO</PrefixTrans>
      </div>
      <NxFileUpload acceptedFileProperties={[{ types: ACCEPTED_IMAGE_FILE_TYPES, maxSize: MaximumFileSize.IMAGE }]}
                    onChange={onFileChanged}
                    value={logoFile?.file} />
      <div className={styles.header}>
        <PrefixTrans>FEATURES</PrefixTrans>
      </div>
      <NxWysiwyg name={FEATURES} value={values[FEATURES]} setFieldValue={setFieldValue} />
    </>
  );
}
