import {TFunction} from 'i18next';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import {AnySchema, array, boolean, number, object, string} from 'yup';
import {CustomerType} from '../../../../../shared/model/customer-type.model';
import {ConfiguredFieldRecords} from '../../../../../utils/step-form-utils/configured-fields-utils';
import {
  getConfiguredFieldValidationDefaultSchema
} from '../../../../../utils/step-form-utils/validation-schema/configured-field-validation-schema';
import {
  getCustomFieldValueSchema
} from '../../../../../utils/step-form-utils/validation-schema/custom-field-value-validation-schema';
import {
  dependencyKeysPairs,
  getDefaultFieldLengthExceededError,
  getDefaultRequiredError,
  getOptionalValidationSchema
} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';
import {CustomerDataFormFields, CustomersDataFormFields} from './customer-data-form.model';

export const getValidationSchema = (customersDataFormFields: CustomersDataFormFields,
                                    t: TFunction): AnySchema => {

  const REQUIRED_ERROR = getDefaultRequiredError(t);

  /**
   * Returns optional schema if partialValidation is false. In other case returns alternativeRequiredSchema if present
   * or schema + marked as required.
   */
  const getOptionallyRequiredSchema = (partialValidation: boolean,
                                       schema: AnySchema,
                                       alternativeRequiredSchema?: AnySchema): AnySchema => {

    const optionalSchema = schema.nullable();
    const requiredSchema = alternativeRequiredSchema ?? optionalSchema.required(REQUIRED_ERROR);

    return partialValidation ? optionalSchema : requiredSchema;
  };

  const getAddressesFieldsSchema = (partialValidation = false, t: TFunction): AnySchema => {
    const postalCodeSchema = string().max(8, getDefaultFieldLengthExceededError(t, 8));

    const addressFieldsArray = ['barangay', 'city', 'countryId', 'postalCode', 'provinceId', 'street'];

    const getSchema = (schema: AnySchema): AnySchema =>
      getOptionallyRequiredSchema(partialValidation, schema);

    return array().of(
      object().shape({
        barangay: getSchema(string()),
        city: getSchema(string()),
        countryId: getSchema(number()),
        provinceId: getSchema(number()),
        street: getSchema(string()),
        required: boolean(),
        applicantId: number(),
        postalCode: getSchema(postalCodeSchema),
        id: number(),
        isPrimary: boolean(),
        typeId: number()
      }, [...dependencyKeysPairs(addressFieldsArray)])
    );
  };

  const getIncomeSourcesSchema = (partialValidation = false): AnySchema => {

    const incomeFieldsArray = [
      'natureOfWorkId', 'income', 'companyName', 'description', 'employerTypeId', 'employerName'
    ];

    const getSchema = (schema: AnySchema): AnySchema =>
      getOptionallyRequiredSchema(partialValidation, schema);

    const optionalFields = {
      companyName: string().nullable(),
      natureOfWorkId: number().nullable(),
      description: string().nullable(),
      employerName: string().nullable()
    };

    return array().of(
      object().shape({
        income: getSchema(number()),
        required: boolean(),
        applicantId: number(),
        employerTypeId: getSchema(number()),
        ...optionalFields
      }, [...dependencyKeysPairs(incomeFieldsArray)])
    );
  };

  const getIdDocumentsSchema = (partialValidation = false): AnySchema => {

    const idDocumentsFieldsArray = ['countryId', 'number', 'issuedOn', 'issuingAuthority', 'expirationDate', 'scans'];

    const getSchema = (schema: AnySchema): AnySchema =>
      getOptionallyRequiredSchema(partialValidation, schema);

    const optionalFields = {
      countryId: number().nullable(),
      issuedOn: string().nullable(),
      issuingAuthority: string().nullable(),
      expirationDate: string().nullable(),
      scans: getOptionallyRequiredSchema(partialValidation, array(), array())
    };

    return array().of(
      object().shape({
        number: getSchema(string()),
        ...optionalFields,
        required: boolean(),
        applicantId: number()
      }, [...dependencyKeysPairs(idDocumentsFieldsArray)])
    );
  };

  const individualDataSchema = (formFields: CustomerDataFormFields, partialValidation = false): AnySchema => {

    const {signature, picture, ...fieldsConfig} = formFields.individualData as unknown as ConfiguredFieldRecords;

    return object({
      ...mapValues(fieldsConfig, field => getConfiguredFieldValidationDefaultSchema(partialValidation, field, t)),
      picture: getConfiguredFieldValidationDefaultSchema(partialValidation, picture, t),
      signature: getConfiguredFieldValidationDefaultSchema(partialValidation, signature, t)
    });
  };

  const corporateDataSchema = (formFields: CustomerDataFormFields, partialValidation = false): AnySchema => {

    const {signature, picture, ...fieldsConfig} = formFields.corporateData as unknown as ConfiguredFieldRecords;

    return object({
      ...mapValues(fieldsConfig, field => getConfiguredFieldValidationDefaultSchema(partialValidation, field, t)),
      picture: getConfiguredFieldValidationDefaultSchema(partialValidation, picture, t),
      signature: getConfiguredFieldValidationDefaultSchema(partialValidation, signature, t)
    });
  };

  const getCustomFieldsSchema = (partialValidation = false): AnySchema => array().of(
    object({
      required: boolean(),
      customFieldId: number(),
      fieldType: string(),
      value: getCustomFieldValueSchema(partialValidation, t)
    })
  );

  const getApplicantSchema = (customersDataFormFields: CustomerDataFormFields,
                              partialValidation = false): AnySchema => {
    return object({
      individualData: object()
        .when('type',
          {is: CustomerType.INDIVIDUAL, then: individualDataSchema(customersDataFormFields, partialValidation)}),
      corporateData: object()
        .when('type',
          {is: CustomerType.CORPORATE, then: corporateDataSchema(customersDataFormFields, partialValidation)}),
      addresses: getAddressesFieldsSchema(partialValidation, t),
      idDocuments: getIdDocumentsSchema(partialValidation),
      incomeSources: getIncomeSourcesSchema(partialValidation),
      customFieldValues: getCustomFieldsSchema(partialValidation)
    });
  };

  let customersDataSchema = {
    validate: boolean(),
    borrower: object() as AnySchema,
    coMakers: array() as AnySchema,
    coBorrowers: array() as AnySchema
  };

  if (!isNil(customersDataFormFields.borrower)) {
    const getBorrowerSchema = (partialValidation = false): AnySchema => {
      return getApplicantSchema(customersDataFormFields.borrower!, partialValidation).default(undefined);
    };
    customersDataSchema = {
      ...customersDataSchema,
      borrower: getOptionalValidationSchema(getBorrowerSchema)
    };
  }

  if (!isNil(customersDataFormFields.coBorrowers) && !isEmpty(customersDataFormFields.coBorrowers)) {
    const getCoBorrowersSchema = (partialValidation = false): AnySchema => {
      return array(getApplicantSchema(customersDataFormFields.coBorrowers![0], partialValidation));
    };
    customersDataSchema = {
      ...customersDataSchema,
      coBorrowers: getOptionalValidationSchema(getCoBorrowersSchema)
    };
  }

  if (!isNil(customersDataFormFields.coMakers) && !isEmpty(customersDataFormFields.coMakers)) {
    const getCoMakersSchema = (partialValidation = false): AnySchema => {
      return array(getApplicantSchema(customersDataFormFields.coMakers![0], partialValidation));
    };
    customersDataSchema = {
      ...customersDataSchema,
      coMakers: getOptionalValidationSchema(getCoMakersSchema)
    };
  }

  return object(customersDataSchema);
};
