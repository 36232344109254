import { entries } from "lodash";
import {DictionaryEntry} from "../shared/model/dictionary.model";

export const getGeolocationByParentIdAndName = (
  entries: DictionaryEntry[] | undefined,
  parentId: number | undefined,
  name: string
): DictionaryEntry | undefined =>
  entries?.find(
    (entry) => entry.parentId === parentId && entry.name === name
  );
