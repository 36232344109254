import {NxNumberInput} from '@nextbank/ui-components';
import React, {useMemo} from 'react';
import {NumberMask} from './NumberMask';

export interface NxNumberMaskInputProps {
  className?: string;
  currency?: string;
  disabled?: boolean;
  error?: string;
  id?: string;
  label: React.ReactNode;
  name?: string;
  onBlur?: () => void;
  onChange?: (value: number | null) => void;
  positionAbsoluteError?: boolean;
  value?: number | null;
  required?: boolean;
  step?: number;
  max?: number;
  min?: number;
}

const NxNumberMaskInput = (
  {
    className,
    disabled = false,
    error,
    id,
    label,
    name,
    onBlur,
    onChange,
    positionAbsoluteError,
    required,
    step,
    max,
    min,
    value
  }: NxNumberMaskInputProps
): React.ReactElement => {

  const MaskComponent = useMemo(() => NumberMask, []);

  return (
    <NxNumberInput className={className}
                   disabled={disabled}
                   error={error}
                   id={id}
                   onBlur={onBlur}
                   onChange={onChange}
                   label={label}
                   name={name}
                   required={required}
                   positionAbsoluteError={positionAbsoluteError}
                   step={step}
                   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                   // @ts-ignore
                   inputComponent={MaskComponent}
                   max={max}
                   min={min}
                   showArrows={false}
                   value={value} />
  );
};

export default NxNumberMaskInput;
