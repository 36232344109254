import {TableHead, TableRow} from '@material-ui/core';
import React from 'react';
import {LoanDetailsLabel} from '../../../../../constants/loan-details-labels';
import {TransHelper} from '../../../../../utils/trans-helper';
import TableHeaderCell from '../../../../shared/table/cells/TableHeaderCell';

const LoanTypeSelectionTableHead = (): React.ReactElement => {

  const loanDetails = [
    LoanDetailsLabel.NO,
    LoanDetailsLabel.CODE,
    LoanDetailsLabel.PRODUCT_NAME,
    LoanDetailsLabel.MIN_INTEREST_RATE,
    LoanDetailsLabel.MAX_INTEREST_RATE,
    LoanDetailsLabel.MIN_AMOUNT,
    LoanDetailsLabel.MAX_AMOUNT,
    LoanDetailsLabel.MIN_TERM,
    LoanDetailsLabel.MAX_TERM
  ];

  return (
    <TableHead>
      <TableRow>
        {
          loanDetails.map((header, index) => (
              <TableHeaderCell key={index} header={TransHelper.getLoanDetailsLabel(header)} />
            )
          )
        }
      </TableRow>
    </TableHead>
  )
};

export default LoanTypeSelectionTableHead;
