import {Table, TableBody, TableContainer} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {PAGE_RECORDS_LIMIT} from '../../../../../../../../constants/table-defaults';
import EmptyState from '../../../../../../../shared/empty-state/EmptyState';
import TableHeader from '../../../../../../../shared/table/header/TableHeaders';
import TablePaginationFooter from '../../../../../../../shared/table/pagination-footer/TablePaginationFooter';
import {KeyedEventLogSearchResult} from '../event-log.model';
import {EventLogTableHeaders} from './event-log-table-labels';
import EventLogsTableRow from './EventLogsTableRow';

interface Props {
  eventLogs: KeyedEventLogSearchResult;
  onPageChange: (pageNo: number) => Promise<void>;
}

export const TRANS_PREFIX = 'LOAN_APPLICATIONS.EVENT_LOG.TABLE_HEADER';

const EventLogsTable = ({eventLogs, onPageChange}: Props): ReactElement =>
  <>
    {
      isEmpty(eventLogs.result)
        ? <EmptyState instruction={<Trans>COMMON.NO_RESULTS</Trans>} />
        : <>
          <TableContainer>
            <Table>
              <TableHeader translationPath={TRANS_PREFIX} headers={Object.values(EventLogTableHeaders)} />
              <TableBody>
                {
                  eventLogs.result.map((eventLog) =>
                    <EventLogsTableRow key={eventLog.key} eventLog={eventLog} />)
                }
              </TableBody>
              <TablePaginationFooter page={eventLogs.pageNo}
                                     onPageChange={onPageChange}
                                     count={eventLogs.totalCount}
                                     rowsPerPage={PAGE_RECORDS_LIMIT} />
            </Table>
          </TableContainer>
        </>
    }
  </>;

export default EventLogsTable;
