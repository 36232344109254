export enum ExternalApplicationOrigination {
  RMB_SDE = 'RMB_SDE'
}

export interface ExternalApplicationData {
  id: number;
  applicationId: number; 
  origination: ExternalApplicationOrigination;
  createdOn: string;
  data: any;
}

export interface RmbSdeApplicationDataTO {
  principal: number;
  loanTerm: number;
  borrower: RmbSdeApplicationBorrower;
  loanPurpose: string;
  clientType: string;
  referralName: string;
  branchId: number;
}

interface RmbSdeApplicationBorrower {
  firstName: string;
  middleNam: string;
  lastName: string;
  suffix: string;
  gender: string;
  birthDate: string;
  currentAddress: RmbSdeApplicationBorrowerAddress;
  permanentAddress: RmbSdeApplicationBorrowerAddress;
  businessAddress: RmbSdeApplicationBorrowerBusinessAddress;
  primaryMobileNumber: string;
}

interface RmbSdeApplicationAddress {
  geolocationId: number;
  street: string;
  postalCode: string;
}

interface RmbSdeApplicationBorrowerAddress extends RmbSdeApplicationAddress {
  lengthOfStay: number;
}

interface RmbSdeApplicationBorrowerBusinessAddress extends RmbSdeApplicationAddress {
  yearsInOperation: number;
  phoneType: string;
  number: string;
}