import {NxFormikPhoneInput} from '@nextbank/ui-components';
import React, {useContext} from 'react';
import {ConfiguredField} from '../../../../../../shared/model/field.model';
import {StepContext} from '../loan-application-step/LoanApplicationStep';
import {ConfiguredFieldWrapper} from './ConfiguredFieldWrapper';
import {FieldDisablementReason} from './field-disablement-reason.model';

interface Props {
  field: ConfiguredField;
  label: React.ReactNode;
  name: string;
  disablementReason?: FieldDisablementReason;
  mask?: string;
}

export const ConfiguredFieldPhoneInput = ({field, label, name, disablementReason, mask}: Props): React.ReactElement => {

  const {isStepReadonly} = useContext(StepContext);

  return (
    <ConfiguredFieldWrapper enabled={field.enabled} disablementReason={disablementReason}>
      <NxFormikPhoneInput disabled={isStepReadonly || !!disablementReason}
                          required={field.required}
                          name={`${name}.value`}
                          label={label}
                          mask={mask}/>
    </ConfiguredFieldWrapper>
  );
};
