import {NxLoader, NxSelectOption} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {useContext} from 'react';
import {CATEGORIES_URL, PhaseName} from '../../../../../constants/api-urls';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {Category} from '../../../../../shared/model/category.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {SelectHelper} from '../../../../../utils/select-helper';
import {TransHelper} from '../../../../../utils/trans-helper';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {GeneralPhase} from './general-phase.model';
import GeneralFrom from './GeneralForm';

const {mapCategoriesToOptions} = SelectHelper;

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.GENERAL');

export default function General(): React.ReactElement {

  const {process} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.GENERAL);
  const config = useSingleQuery<GeneralPhase>(phaseUrl);
  const categoryOptions = useSingleQuery<NxSelectOption<number>[], Category[]>(CATEGORIES_URL, mapCategoriesToOptions);

  return isNil(config) || isNil(categoryOptions) ? <NxLoader /> : <GeneralFrom config={config}
                                                                               categoryOptions={categoryOptions} />;
}
