import React from 'react';
import styles from './FormColumn.module.scss';
import FormGroup from './FormGroup';

interface Props {
  children?: React.ReactNode;
  header?: React.ReactNode;
}

const FormColumn = ({children, header}: Props): React.ReactElement => (
  <FormGroup className={styles.column} header={header}>
    {children}
  </FormGroup>
);

export default FormColumn;
