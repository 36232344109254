import React, {ReactElement} from 'react';
import {LoanParametersOptions} from '../../../../../../../shared/hooks/use-loan-application-data-options.hook';
import {SetFieldValue} from '../../../../../../../shared/model/formik.model';
import {CalculatorFormFieldsConfiguration} from '../../../../../../../shared/model/step-forms/calculator-form.model';
import {
  LoanApplicationDataFormFieldsConfiguration
} from '../../../../../../../shared/model/step-forms/loan-application-data-form.model';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import {FieldDefaultCashInput} from '../../../../../../shared/field-default/FieldDefaultCashInput';
import {FieldDefaultDatePicker} from '../../../../../../shared/field-default/FieldDefaultDatePicker';
import {FieldDefaultNumberInput} from '../../../../../../shared/field-default/FieldDefaultNumberInput';
import {FieldDefaultPercentageInput} from '../../../../../../shared/field-default/FieldDefaultPercentageInput';
import {FieldDefaultSelect} from '../../../../../../shared/field-default/FieldDefaultSelect';
import {YES_NO_SELECT_OPTIONS} from '../../../../../../shared/form-bolean-option-values/YesNoSelectOptions';
import FormColumn from '../../../../../../shared/form-column/FormColumn';
import Instruction from '../../../../../../shared/instruction/Instruction';
import Payment, {PaymentFieldProps} from '../../../calculator/payment/Payment';
import styles from './LoanParameters.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('COMMON.FIELDS');

interface Props {
  values: CalculatorFormFieldsConfiguration | LoanApplicationDataFormFieldsConfiguration;
  setFieldValue: SetFieldValue;
  loanParametersOptions: LoanParametersOptions;
  paymentFieldProps: PaymentFieldProps;
}

export default function LoanParameters(
  {values, setFieldValue, loanParametersOptions, paymentFieldProps}: Props
): ReactElement {
  return (
    <>
      <Instruction defaultFieldsInstruction>
        <PrefixTrans>DEFAULT_FIELDS_INSTRUCTION</PrefixTrans>
      </Instruction>
      <div className={styles.container}>
        <FormColumn header={<PrefixTrans>CALCULATION_DATA</PrefixTrans>}>
          <FieldDefaultSelect name='availableCreditLine'
                              field={values.availableCreditLine}
                              label={<PrefixTrans>AVAILABLE_CREDIT_LINE</PrefixTrans>}
                              setFieldValue={setFieldValue}
                              options={YES_NO_SELECT_OPTIONS} />
          <FieldDefaultSelect name='creationType'
                              field={values.creationType}
                              label={<PrefixTrans>CREATION_TYPE</PrefixTrans>}
                              setFieldValue={setFieldValue}
                              options={loanParametersOptions.creationType}
                              requiredButtonVisible={false} />
          <FieldDefaultCashInput name='principal'
                                 field={values.principal}
                                 label={<PrefixTrans>PRINCIPAL</PrefixTrans>}
                                 setFieldValue={setFieldValue}
                                 requiredButtonVisible={false} />
          <FieldDefaultPercentageInput name='interestRate'
                                       field={values.interestRate}
                                       label={<PrefixTrans>INTEREST_RATE</PrefixTrans>}
                                       setFieldValue={setFieldValue}
                                       requiredButtonVisible={false} />
          {
            !!values.overrideAmortizationAmount &&
            <FieldDefaultNumberInput name='overrideAmortizationAmount'
                                     field={values.overrideAmortizationAmount}
                                     label={
                                       <PrefixTrans>OVERRIDE_AMORTIZATION_AMOUNT</PrefixTrans>}
                                     setFieldValue={setFieldValue}
                                     min={0} />
          }
          {
            !!values.diminishingAmortizationNumber &&
            <FieldDefaultNumberInput name='diminishingAmortizationNumber'
                                     field={values.diminishingAmortizationNumber}
                                     label={
                                       <PrefixTrans>DIMINISHING_AMORTIZATION_NUMBER</PrefixTrans>}
                                     setFieldValue={setFieldValue}
                                     min={0} />
          }
          <FieldDefaultNumberInput name='advanceInterestNo'
                                   field={values.advanceInterestNo}
                                   label={<PrefixTrans>ADVANCE_INTEREST_NO</PrefixTrans>}
                                   setFieldValue={setFieldValue}
                                   min={0} />
          <FieldDefaultSelect name='advanceInterestApplication'
                              field={values.advanceInterestApplication}
                              label={<PrefixTrans>ADVANCE_INTEREST_APPLICATION</PrefixTrans>}
                              setFieldValue={setFieldValue}
                              options={loanParametersOptions.advanceInterestApplication} />
          {
            !!values.interestCalculationParameter &&
            <FieldDefaultNumberInput name='interestCalculationParameter'
                                     field={values.interestCalculationParameter}
                                     label={<PrefixTrans>INTEREST_CALCULATION_PARAMETER</PrefixTrans>}
                                     setFieldValue={setFieldValue}
                                     requiredButtonVisible={false}
                                     min={0} />
          }
          {
            !!values.uidAmortizationNumber &&
            <FieldDefaultNumberInput name='uidAmortizationNumber'
                                     field={values.uidAmortizationNumber}
                                     label={<PrefixTrans>UID_AMORTIZATION_NO</PrefixTrans>}
                                     setFieldValue={setFieldValue}
                                     requiredButtonVisible={false}
                                     min={0} />
          }
          {
            !!values.uidApplication &&
            <FieldDefaultSelect name='uidApplication'
                                field={values.uidApplication}
                                label={<PrefixTrans>UID_APPLICATION</PrefixTrans>}
                                setFieldValue={setFieldValue}
                                options={loanParametersOptions.uidApplicationOptions}
                                requiredButtonVisible={false} />
          }
          { "withLinkedDepositAccount" in values && (
            <FieldDefaultSelect 
                name='withLinkedDepositAccount'
                field={values.withLinkedDepositAccount} 
                label={<PrefixTrans>WITH_LINKED_DEPOSIT_ACCOUNT</PrefixTrans>} 
                setFieldValue={setFieldValue} 
                options={YES_NO_SELECT_OPTIONS} 
                requiredButtonVisible={false} 
                disabled={true} 
            />
          )}
          <FieldDefaultDatePicker name='dateGranted'
                                  label={<PrefixTrans>DATE_GRANTED</PrefixTrans>}
                                  field={values.dateGranted}
                                  setFieldValue={setFieldValue} />
          <FieldDefaultDatePicker name='firstPaymentDate'
                                  label={<PrefixTrans>FIRST_PAYMENT_DATE</PrefixTrans>}
                                  field={values.firstPaymentDate}
                                  setFieldValue={setFieldValue} />
        </FormColumn>
        <Payment {...paymentFieldProps} />
      </div>
    </>
  );
}
