import React from 'react';
import {Trans} from 'react-i18next';
import {TransHelper} from '../../../../utils/trans-helper';
import {ConfirmationPopup} from '../../../shared/confirmation-popup/ConfirmationPopup';
import styles from './ProcessConfirmationPopup.module.scss';

const TRANS_PREFIX = 'BATCH.DETAILS.POPUP.CONFIRMATION';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

interface Props {
  batchName: string;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const ProcessingConfirmationPopup = ({batchName, open, setOpen}: Props): React.ReactElement => {

  const boldComponent = <span className={styles.bold} />;
  const messageBody = (
    <div className={styles.message}>
      <span>
        <Trans values={{batchName: batchName}} components={{bold: boldComponent}}>{`${TRANS_PREFIX}.MESSAGE`}</Trans>
      </span>
      <span><PrefixTrans>INFO</PrefixTrans></span>
    </div>
  );

  const closePopup = (): void => setOpen(false);

  return <ConfirmationPopup
    header={<PrefixTrans>HEADER</PrefixTrans>}
    description={messageBody}
    onConfirm={closePopup}
    confirmButtonLabel={<PrefixTrans>OK</PrefixTrans>}
    footerClassName={styles.centeredFooter}
    open={open}
  />;
};
