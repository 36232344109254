import find from 'lodash/find';
import isNil from 'lodash/isNil';
import {UrlParam} from '../routes/routes.model';
import {RoutePaths} from '../routes/routes.paths';
import {LoanStep} from '../shared/model/loan-step.model';
import {Phase} from '../shared/model/phase.model';

export const getStepLabel = (step: string): string => `LOAN_STEPS_LABELS.${step}`;

export const getStepPathPart = (path: string): string | undefined => path.split('/').pop();

export const buildConfigurationStepPath = (route = '', processId?: number): string =>
  `${RoutePaths.LOAN_CONFIGURATIONS}/${isNil(processId) ? `:${UrlParam.PROCESS_ID}` : processId}${route}`;

export const buildApplicationStepPath =
  (route = '', processId?: number | string, applicationId?: number | string): string => {

    const processIdParam = isNil(processId) ? `:${UrlParam.PROCESS_ID}` : processId;
    const applicationIdParam = isNil(applicationId) ? `:${UrlParam.APPLICATION_ID}` : applicationId;

    return `${RoutePaths.LOAN_APPLICATIONS}/${processIdParam}/application/${applicationIdParam}${route}`;
  };

export const getPredefinedConfigurationSteps = (processId: number, steps: LoanStep[]): LoanStep[] =>
  steps.map(step => {

    const buildStepPath = (path: string): string => buildConfigurationStepPath(path, processId);

    return buildStep(step, buildStepPath);
  });

export const getPredefinedApplicationSteps =
  (processId: number | string, applicationIdId: number | string, steps: LoanStep[], phases: Phase[] = []): LoanStep[] =>
    steps.map(step => {
      const buildStepPath = (path: string): string => buildApplicationStepPath(path, processId, applicationIdId);
      const phaseId = find(phases, phase => step.type === phase.type)?.id;

      return buildStep(step, buildStepPath, phaseId);
    });

export const buildStep = (step: LoanStep, buildPath: (path: string) => string, phaseId?: number): LoanStep => {
  const {path, redirectTo} = step;

  return {
    ...step,
    phaseId,
    path: buildPath(path),
    ...redirectTo ? {redirectTo: buildPath(redirectTo)} : {}
  };
};
