import {useEffect, useRef} from 'react';
import {ApprovalStatus} from '../../../loan-application.model';

/**
 * Hook checks if approval status have changed to 'PENDING' or have just changed from 'PENDING' to another status
 */

export const useHasApprovalStatusChangedToOrFromPending = (newStatus?: ApprovalStatus): boolean => {

  const previousStatusRef = useRef<ApprovalStatus>();

  useEffect(() => {
    previousStatusRef.current = newStatus;
  });

  return newStatus === ApprovalStatus.PENDING || previousStatusRef.current === ApprovalStatus.PENDING;
}

