import clsx from 'clsx';
import React from 'react';
import {AppSnackbarProvider} from '../app-snackbar-provider/AppSnackbarProvider';
import {SystemContextProvider} from '../system-context-provider/SystemContextProvider';
import styles from './MainContainer.module.scss';

interface Props {
  children: React.ReactNode;
  topBar?: React.ReactNode;
}

const MainContainer = ({children, topBar = null}: Props): React.ReactElement => {
  return (
    <>
      <SystemContextProvider>
        {topBar}
        <AppSnackbarProvider>
          <main className={clsx(styles.main, {[styles.main_withHeader]: !!topBar})}>
            {children}
          </main>
        </AppSnackbarProvider>
      </SystemContextProvider>
    </>
  );
};

export default MainContainer;
