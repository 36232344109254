import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React, {ReactElement, useState} from 'react';
import {EventLogsDrawer, EventLogsPrefixTrans} from './EventLogsDrawer';

export const EventLogsButton = (): ReactElement => {

  const [eventLogsOpen, setEventLogsOpen] = useState(false);

  const toggleEventLogsOpen = (): void => setEventLogsOpen(!eventLogsOpen);

  return (
    <>
      <NxButton variant={NxButtonVariant.OUTLINED} onClick={toggleEventLogsOpen}>
        <EventLogsPrefixTrans>HEADER</EventLogsPrefixTrans>
      </NxButton>
      <EventLogsDrawer open={eventLogsOpen} onClose={toggleEventLogsOpen} />
    </>
  );
};
