import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, {useState} from 'react';
import DetailsButton from '../details-button/DetailsButton';
import styles from './TopBar.module.scss';

interface Props {
  details: React.ReactNode;
  actionButtons: React.ReactNode;
  moreDetails?: React.ReactNode;
  moreActionButtons?: React.ReactNode;
}

export default function TopBar(
  {details, actionButtons, moreDetails, moreActionButtons}: Props
): React.ReactElement {
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);

  const showMoreButton = !!moreDetails || !isEmpty(moreActionButtons)

  return (
    <div className={styles.topBar}>
      <div className={showMoreButton ? styles.container : styles.noMoreContainer}>
        <div className={styles.detailsList}>
          {details}
        </div>
        {showMoreButton ? <DetailsButton onClick={(): void => setMoreDetailsOpen(!moreDetailsOpen)}
                                          isOpen={moreDetailsOpen}
                                          className={styles.button} /> : <></>}
        <div className={styles.buttonsColumn}>
          {actionButtons}
        </div>
      </div>
      <div className={clsx(styles.moreContainer, {[styles.moreContainer_toggled]: moreDetailsOpen})}>
        <div className={styles.detailsList}>
          {moreDetails}
        </div>
        <div>
          <div className={styles.buttonsColumn}>
            {moreActionButtons}
          </div>
        </div>
      </div>
    </div>
  );
}
