import {NxButton, NxInput, NxSelectOption} from '@nextbank/ui-components';
import React, {ChangeEvent, createContext, useMemo, useState} from 'react';
import {Trans} from 'react-i18next';
import {DictionaryEntry} from '../../../../shared/model/dictionary.model';
import {generateKey} from '../../../../utils/generate-key';
import {getInputValue} from '../../../../utils/input-utils';
import {SelectHelper} from '../../../../utils/select-helper';
import {StringHelper} from '../../../../utils/string-helper';
import DragDropItems from '../../../shared/drag-drop/DragDropItems';
import {DraggableCheck, DraggableCheckGroup} from './draggable-checks.model';
import styles from './DraggableChecks.module.scss';
import DraggableCheckGroupForm from './group/DraggableCheckGroupForm';

type DraggableChecksContextType = {
  checkGroups: DraggableCheckGroup[];
  setCheckGroups: (checkGroups: DraggableCheckGroup[]) => void;
  phaseId: number;
  provinceOptions: NxSelectOption<number>[],
  residenceOptions: NxSelectOption<number>[],
  ageCheckTypeOptions: NxSelectOption<number>[],
  nationalityOptions: NxSelectOption<number>[],
  occupationOptions: NxSelectOption<number>[],
  handleAutomaticCheck: boolean
}

const defaultEntries = {
  province: [],
  residence: [],
  ageCheckTypes: [],
  nationality: [],
  occupations: []
};

export const DraggableChecksContext = createContext<DraggableChecksContextType>({
  checkGroups: [],
  setCheckGroups: () => null,
  phaseId: 0,
  provinceOptions: [],
  residenceOptions: [],
  ageCheckTypeOptions: [],
  nationalityOptions: [],
  occupationOptions: [],
  handleAutomaticCheck: false
});

interface DraggableChecksDictionaryEntries {
  province: DictionaryEntry[];
  residence: DictionaryEntry[];
  ageCheckTypes: DictionaryEntry[];
  nationality: DictionaryEntry[];
  occupations: DictionaryEntry[];
}

interface Props {
  checkGroups: DraggableCheckGroup[],
  setCheckGroups: (checkGroups: DraggableCheckGroup[]) => void;
  phaseId: number;
  entries?: DraggableChecksDictionaryEntries,
  handleAutomaticCheck?: boolean
}

const DraggableChecks = (
  {checkGroups, setCheckGroups, phaseId, entries = defaultEntries, handleAutomaticCheck = false}: Props
): React.ReactElement => {

  const [newGroupName, setNewGroupName] = useState('');
  const provinceOptions = useMemo(() => SelectHelper.mapToNumberOptions(entries.province), [entries.province]);
  const residenceOptions = useMemo(() => SelectHelper.mapToNumberOptions(entries.residence), [entries.residence]);
  const ageCheckTypeOptions = useMemo(() => SelectHelper.mapToNumberOptions(entries.ageCheckTypes), [entries.ageCheckTypes]);
  const nationalityOptions = useMemo(() => SelectHelper.mapToNumberOptions(entries.nationality), [entries.nationality]);
  const occupationOptions = useMemo(() => SelectHelper.mapToNumberOptions(entries.occupations), [entries.occupations]);

  const handleNewGroupNameChange = (event: ChangeEvent): void =>
    setNewGroupName(getInputValue(event));

  const addGroupItem = (): void => {
    setCheckGroups([
      ...checkGroups,
      {
        name: newGroupName,
        checks: [] as DraggableCheck[],
        // Order no is set according to position in array when submitting
        orderNo: checkGroups.length,
        ...generateKey()
      } as DraggableCheckGroup
    ]);
    setNewGroupName('');
  };

  const checkGroupItemFunction = (provided: unknown,
                                  isDragging: boolean,
                                  item: DraggableCheckGroup): React.ReactElement =>
    <DraggableCheckGroupForm provided={provided} isDragging={isDragging} checkGroup={item} />;

  return <>
    <DraggableChecksContext.Provider value={
      {checkGroups, setCheckGroups, phaseId, provinceOptions, residenceOptions, ageCheckTypeOptions, nationalityOptions, occupationOptions, handleAutomaticCheck}
    }>
      {
        checkGroups.length > 0 &&
        <div className={styles.checkGroups}>
          <DragDropItems<DraggableCheckGroup>
            itemComponentFunction={checkGroupItemFunction}
            droppableId={'droppableCheckGroups'}
            items={checkGroups}
            setItems={setCheckGroups} />
        </div>
      }
      <div className={styles.row}>
        <NxInput className={styles.newGroupInput}
                 label={<Trans>LOAN_CONFIGURATIONS.CHECKS.NEW_GROUP_NAME</Trans>}
                 onChange={handleNewGroupNameChange}
                 value={newGroupName} />
        <NxButton onClick={addGroupItem} disabled={StringHelper.isBlank(newGroupName)}>
          <Trans>LOAN_CONFIGURATIONS.CHECKS.ADD_GROUP</Trans>
        </NxButton>
      </div>
    </DraggableChecksContext.Provider>
  </>;
};

export default DraggableChecks;
