import React, {ReactElement} from 'react';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import styles from './SimulationParametersError.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.SIMULATED_LOAN_PARAMETERS');

export const SimulationRequiredError = (): ReactElement =>
  <div className={styles.error}>
    <PrefixTrans>SIMULATION_REQUIRED_ERROR</PrefixTrans>
  </div>;

export const SimulationParametersChangedError = (): ReactElement =>
  <div className={styles.error}>
    <PrefixTrans>SIMULATION_PARAMS_CHANGED</PrefixTrans>
  </div>;

