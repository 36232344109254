import React, {useState, useEffect, useContext} from 'react';
import {Trans} from 'react-i18next';
import {NxSelect, NxSelectOption} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import {useTranslation} from 'react-i18next';
import usePost from '../../../../../../shared/hooks/use-post.hook';
import useGet from '../../../../../../shared/hooks/use-get.hook';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {ConfirmationPopup} from '../../../../../shared/confirmation-popup/ConfirmationPopup';
import styles from './ReassignLoanApplicationPopup.module.scss';
import {MANAGEMENT_USERS_URL, REASSIGN_APPLICATIONS_URL} from '../../../../../../constants/api-urls';
import {User} from '../../../../../../shared/model/management.model';
import {SelectedApplicationsContext} from '../../../LoanApplicationDasboard';
import {StringHelper} from '../../../../../../utils/string-helper';
import {ReassignApplicationPayload} from '../reassign-loan-application-payload.model';
import {SecurityContext} from '../../../../../../App';
import {ApiHelper} from '../../../../../../utils/api-helper';

export const TRANS_PREFIX = 'LOAN_APPLICATIONS.REASSIGN.POPUP';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const ReassignLoanApplicationPopup = ({open, setOpen}: Props): React.ReactElement => {
  const {t} = useTranslation()
  const fetchAssignees = useGet<User[]>(MANAGEMENT_USERS_URL);
  const reassignLoanApplications = usePost<void, ReassignApplicationPayload>(REASSIGN_APPLICATIONS_URL);
  
  const {userData} = useContext(SecurityContext);
  const {selectedApplications, setSelectedApplication, refreshTableData} = useContext(SelectedApplicationsContext);
  const [assignees, setAssignees] = useState<User[]>([]);
  const [options, setOptions] = useState<NxSelectOption<number>[]>([]);
  const [selectedAssigneeId, setSelectedAssigneeId] = useState<number>();
  const [selectErrorMessage, setSelectErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectedAssignee = options.find(option => option.value === selectedAssigneeId)?.value;
  const referenceNumbers = selectedApplications.map(application => application.referenceNo).join(', ');

  const onChange = (value): void => {
    if (isNil(value)) {
      console.warn('Selected value is null');
      return;
    }

    const assignee = assignees.find(user => user.id == value);
    if (isNil(assignee)) {
      console.warn('Selected assignee is null');
      return;
    }

    setSelectedAssigneeId(assignee.id);
  };

  const onConfirm = () => {
    if (isNil(selectedAssigneeId)) {
      setSelectErrorMessage(t('COMMON.ERRORS.REQUIRED_FIELD'));
    } else {
      setIsLoading(true);
      const applicationIds = selectedApplications.map(application => application.id);
      reassignLoanApplications({assignedToId: selectedAssigneeId, applicationIds}).then(() => {
        closePopup();
        setSelectedApplication([]);
        setSelectedAssigneeId(undefined);
        refreshTableData();
      }).finally(() => {
        setIsLoading(false);
      })
    }
  };

  const closePopup = (): void => setOpen(false);

  const boldComponent = <span className={styles.bold} />;
  const messagePart = <span className={styles.messagePart} />;
  const usernameText = <span className={styles.usernameText} />;
  const messageBody = (
    <>
      <div className={styles.message}>
        <Trans values={{selectedRowsNo: selectedApplications.length, referenceNumbers}} 
               components={{bold: boldComponent, component: messagePart}} >
               {`${TRANS_PREFIX}.MESSAGE`}
        </Trans>
      </div>
      <div className={styles.dropdown}>
        <NxSelect className={styles.select}
                  options={options}
                  value={selectedAssignee}
                  onChange={onChange}
                  error={selectErrorMessage}
                  label={<Trans>{`${TRANS_PREFIX}.ASSIGNEE_LABEL`}</Trans>}/>
      </div>
    </>
  );

  useEffect(() => {
    const params = ApiHelper.constructUrlParams({
      branchId: userData?.branchId
    });
    fetchAssignees(params).then(assigneesTO => {
      setAssignees(assigneesTO);
      const assigneesOptions = assigneesTO.map(user => ({
        value: user.id,
        label: <Trans values={{fullname: StringHelper.getRearrangedFullname(user.fullName), username: user.username}} 
                      components={{light: usernameText}} >
                      {`${TRANS_PREFIX}.ASSIGNEE`}
               </Trans>
      }) as NxSelectOption<number>);

      setOptions(assigneesOptions);
    });
  }, []);

  return <ConfirmationPopup
    header={<PrefixTrans>HEADER</PrefixTrans>}
    description={messageBody}
    onConfirm={onConfirm}
    confirmButtonLabel={<PrefixTrans>YES_BUTTON</PrefixTrans>}
    confirmButtonClass={styles.confirmButton}
    onDeny={closePopup}
    denyButtonLabel={<Trans>COMMON.CANCEL</Trans>}
    isLoading={isLoading}
    open={open}
  />;
};
