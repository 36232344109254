import {NxInput} from '@nextbank/ui-components';
import React, {ChangeEvent, useState} from 'react';
import {getInputValue} from '../../../../../../utils/input-utils';
import BinButton from '../../../../../shared/bin-button/BinButton';

interface Props {
  className: string;
  label: React.ReactNode;
  onBlur: (event: ChangeEvent) => void;
  onRemove: () => void;
  initValue: string;
}

/**
 * Wrapping of NxInput to prevent loosing field focus on value change. Caused by rerendering whole input, not value.
 * Component keeps value in local state and every change is propagated outsite when onBlur invoked.
 *
 * @param className
 * @param label
 * @param onBlur on blur method. The only way to get input value.
 * @param initValue
 * @param onRemove
 *
 */
export default function CustomPhaseInput({className, label, initValue,  onBlur, onRemove}: Props): React.ReactElement {
  const [value, setValue] = useState(initValue);

  const handleChange = (e: ChangeEvent): void => setValue(getInputValue(e));

  return (
    <>
      <NxInput className={className} label={label} onChange={handleChange} onBlur={onBlur} value={value} />
      <BinButton onClick={onRemove} />
    </>
  );
}
