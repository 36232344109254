import {Check, CheckTO, fromCheckTO} from './check.model';

interface CheckGroupCommonProps {
  id?: number;
  name: string;
  orderNo: number;
  phaseId?: number;
}

export interface CheckGroupTO extends CheckGroupCommonProps {
  checks: CheckTO[];
}

export interface CheckGroup<T = Check> extends CheckGroupCommonProps {
  checks: T[];
}

export const fromCheckGroupTO = (checkGroupTO: CheckGroupTO): CheckGroup => ({
  ...checkGroupTO,
  checks: checkGroupTO.checks.map(checkTO => fromCheckTO(checkTO))
});
