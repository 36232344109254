import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React, {useContext} from 'react';
import {BATCH} from '../../../../constants/api-urls';
import useGet from '../../../../shared/hooks/use-get.hook';
import {FileBasicData} from '../../../../shared/model/nx-file.model';
import {downloadFile} from '../../../../utils/file-download-utils';
import {TransHelper} from '../../../../utils/trans-helper';
import {AppSnackbarContext} from '../../../shared/app-snackbar-provider/AppSnackbarProvider';

export const getBatchUrl = (batchId: string): string => `${BATCH}/${batchId}`;
export const TRANS_PREFIX = 'BATCH.DETAILS';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

interface Props {
  batchId: string;
}

export const DownloadSourceFileButton = ({batchId}: Props): React.ReactElement => {
  const fetchSourceFileData = useGet<FileBasicData>(`${getBatchUrl(batchId)}/source-file-data`);
  const {showErrorMessage} = useContext(AppSnackbarContext);

  const downloadErrorReport = async (): Promise<void> => {
    fetchSourceFileData().then(fileBasicData => {
      if (!fileBasicData.url || !fileBasicData.name) {
        console.error(fileBasicData);
        showErrorMessage('File URL or file name not found');
        return;
      }

      downloadFile(fileBasicData.url, fileBasicData.name);
    });
  };

  return <NxButton variant={NxButtonVariant.OUTLINED}
                   onClick={downloadErrorReport}>
    <PrefixTrans>DOWNLOAD_SOURCE_FILE</PrefixTrans>
  </NxButton>;
};

