import React from 'react';
import styles from './EmptyState.module.scss';

interface Props {
  actionButton?: React.ReactNode;
  instruction: React.ReactNode;
  header?: React.ReactNode;
}

const EmptyState = ({actionButton, instruction, header}: Props): React.ReactElement =>
  <div className={styles.emptyState}>
    {
      header && <div className={styles.header}>{header}</div>
    }
    <div className={styles.instruction}>{instruction}</div>
    {
      actionButton && <div className={styles.actionButtonWrapper}>{actionButton}</div>
    }
  </div>;

export default EmptyState;
