import {NxLoader, NxSelect} from '@nextbank/ui-components';
import {FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {useContext, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {SEARCH_APPLICANT_URL} from '../../../../../../constants/api-urls';
import {UrlParams} from '../../../../../../routes/routes.model';
import useGet from '../../../../../../shared/hooks/use-get.hook';
import usePost from '../../../../../../shared/hooks/use-post.hook';
import {CustomerType} from '../../../../../../shared/model/customer-type.model';
import {ApiHelper} from '../../../../../../utils/api-helper';
import {AppSnackbarContext} from '../../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {LoanApplicationContext} from '../../../LoanApplication';
import {StepContext} from '../../shared/loan-application-step/LoanApplicationStep';
import {ApplicationRelationshipType} from '../customer-data-form.model';
import {ApplicantAssignment} from './ApplicantAssignment';
import CorporateCustomerSearchForm from './corporate-customer-search/CorporateCustomerSearchForm';
import {ApplicantSearchPayload, CustomersByPage} from './customer-select.model';
import CustomersTable from './customers-table/CustomersTable';
import styles from './CustomerSelect.module.scss';
import IndividualCustomerSearchForm from './individual-customer-search/IndividualCustomerSearchForm';
import {LoanApplicationNavigationContext} from '../../../navigation/loan-navigation.model';
import {LoanCreationType} from '../../../../../../shared/model/creation-type.model';

export const CUSTOMER_SEARCH_PAGE_RECORDS_LIMIT = 10;

interface Props {
  isSelecting: boolean;
  setSelecting: (isSelecting: boolean) => void;
  relationshipType: ApplicationRelationshipType;
  lockedCustomerType?: CustomerType;
  loanCreationType?: LoanCreationType,
  onSelectCustomer?: (applicantSourceId?: number, customerType?: CustomerType) => void;
}

const TRANS_PATH = 'COMMON.CUSTOMER_TYPES';

export default function CustomerSelect({
                                         isSelecting,
                                         setSelecting,
                                         relationshipType,
                                         lockedCustomerType,
                                         loanCreationType,
                                         onSelectCustomer
                                       }: Props): React.ReactElement {
  const {t} = useTranslation();
  const applicantSearchForm = useRef<FormikProps<unknown>>();
  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {refreshApplication} = useContext(LoanApplicationContext);
  const {saveStep, applicationChanged} = useContext(LoanApplicationNavigationContext);
  const {isStepReadonly} = useContext(StepContext);
  const {applicationId} = useParams<UrlParams>();
  const assignApplicant = usePost<void, ApplicantAssignment>(ApiHelper.getAssignApplicantUrl(applicationId));
  const handleCustomerSearch = useGet<CustomersByPage>(SEARCH_APPLICANT_URL);
  const [customers, setCustomers] = useState<CustomersByPage>();
  const [searchedData, setSearchedData] = useState<ApplicantSearchPayload>();
  const [isSearching, setSearching] = useState(false);
  const [customerType, setCustomerType] = useState(lockedCustomerType ?? CustomerType.INDIVIDUAL);
  const isConsolidation = loanCreationType !== LoanCreationType.CONSOLIDATION;

  const setSearchResults = (customers?: CustomersByPage, searchData?: ApplicantSearchPayload): void => {
    setCustomers(customers ?? undefined);
    setSearchedData(searchData ?? undefined);
  };

  const getSearchCustomersPage = ({pageNo}: {pageNo: number}) => (fields: ApplicantSearchPayload): Promise<void> => {

    setSearching(true);

    const searchData = {
      ...fields,
      pageNo,
      pageSize: CUSTOMER_SEARCH_PAGE_RECORDS_LIMIT
    };

    return handleCustomerSearch(ApiHelper.constructUrlParams(searchData))
      .then((customers) => setSearchResults(customers, searchData))
      .finally(() => setSearching(false));
  };

  const searchCustomersPage = (pageNo): Promise<void> => getSearchCustomersPage({pageNo})(
    searchedData as ApplicantSearchPayload);

  const selectCustomer = (id?: number): void => {
    setSelecting(true);
    setSearchResults();

    const applicantAssignment = {
      applicantSourceId: id,
      relationshipType,
      customerType
    };

    if (applicationChanged) {
      saveStep(false)
        .then(() => assignApplicantAndRefreshApplication(applicantAssignment));
    } else {
      assignApplicantAndRefreshApplication(applicantAssignment);
    }
  };

  const handleSelectCustomer = (id?: number): void => {
    if (onSelectCustomer) {
      onSelectCustomer(id, customerType);
    } else {
      selectCustomer(id);
    }
  };

  const assignApplicantAndRefreshApplication = (applicantAssignment): void => {
    assignApplicant(applicantAssignment, null)
      .then(() => refreshApplication())
      .catch(error => showErrorMessage(error.message))
      .finally(() => {
        setSelecting(false);
        applicantSearchForm.current?.resetForm();
      });
  };

  const customerTypeOptions = [
    {value: CustomerType.INDIVIDUAL, label: t(`${TRANS_PATH}.INDIVIDUAL`)},
    {value: CustomerType.CORPORATE, label: t(`${TRANS_PATH}.CORPORATE`)}
  ];

  const handleCustomerType = (value): void => {
    if (isNil(value)) {
      throw new Error('Customer type cannot be null');
    }
    setCustomerType(value);
  };

  return (
    <>
      {
        !isStepReadonly && (
          <>
            <div className={styles.search}>
              <NxSelect className={styles.customerType}
                        value={customerType}
                        options={customerTypeOptions}
                        label={t(`${TRANS_PATH}.LABEL`)}
                        onChange={handleCustomerType}
                        disabled={!isNil(lockedCustomerType)} />
              <div className={styles.form}>
                {customerType === CustomerType.CORPORATE
                  ? <CorporateCustomerSearchForm searchCustomersPage={getSearchCustomersPage({pageNo: 0})}
                                                 formikRef={applicantSearchForm} />
                  : <IndividualCustomerSearchForm searchCustomersPage={getSearchCustomersPage({pageNo: 0})}
                                                  formikRef={applicantSearchForm} />
                }
              </div>
            </div>
            {
              isSelecting
                ? <NxLoader />
                : <CustomersTable customerType={customerType}
                                  customers={customers}
                                  searchCustomer={searchCustomersPage}
                                  selectCustomer={handleSelectCustomer}
                                  loading={isSearching}
                                  showCreateNewCustomer={isConsolidation} />
            }
          </>
        )
      }
    </>
  );
}
