import React, {ReactElement, useContext} from 'react';
import ConsolidatedLoansTableRow from './ConsolidatedLoansTableRow';
import {ConsolidatedLoansContext} from '../consolidated-loans-context';
import isEmpty from 'lodash/isEmpty';
import BigNumber from 'bignumber.js';
import {CBSLoanDetails} from '../../../../../../../shared/model/cbs-loans';

const ConsolidatedLoansTableFooter = (): ReactElement => {

  const {consolidatedLoans} = useContext(ConsolidatedLoansContext);

  const getTotalLoanValue = (): CBSLoanDetails => {
    const initialValue = {
      customerName: 'Total:',
      principalBalance: 0.00,
      interestBalance: 0.00,
      cbuAmount: 0.00,
      pfAmount: 0.00,
      tpAmount: 0.00,
      pastDueInterestAmount: 0.00,
      penaltiesAmount: 0.00,
      customFeesAmount: 0.00
    };

    if (isEmpty(consolidatedLoans)) {
      return initialValue;
    }

    return consolidatedLoans.reduce((item1, item2) => {
      item1.principalBalance = BigNumber(item1.principalBalance).plus(item2?.principalBalance || 0).toNumber();
      item1.interestBalance = BigNumber(item1.interestBalance).plus(item2?.interestBalance || 0).toNumber();
      item1.cbuAmount = BigNumber(item1.cbuAmount).plus(item2.cbuAmount).toNumber();
      item1.pfAmount = BigNumber(item1.pfAmount).plus(item2.pfAmount).toNumber();
      item1.tpAmount = BigNumber(item1.tpAmount).plus(item2.tpAmount).toNumber();
      item1.pastDueInterestAmount = BigNumber(item1.pastDueInterestAmount).plus(item2.pastDueInterestAmount).toNumber();
      item1.penaltiesAmount = BigNumber(item1.penaltiesAmount).plus(item2.penaltiesAmount).toNumber();
      item1.customFeesAmount = BigNumber(item1.customFeesAmount).plus(item2.customFeesAmount).toNumber();
      return item1;
    }, initialValue);
  };

  const getRowNumber = (): number => isEmpty(consolidatedLoans) ? 1 : consolidatedLoans.length + 1;

  return <ConsolidatedLoansTableRow cbsLoanDetails={getTotalLoanValue()} rowNumber={getRowNumber()} />;

};

export default ConsolidatedLoansTableFooter;
