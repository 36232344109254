import dayjs, {Dayjs} from 'dayjs';
import {LoanConfiguration} from './loan-configuration.model';
import {FixedPaymentOption, PaymentType} from './payment.model';
import {fromPhaseTO, Phase, PhaseTO} from './phase.model';

export interface ProcessTO<T_STATUS = string, T_LOAN = LoanConfiguration, T_CHANNEL = string, T_DATE = string,
  T_RELEASE_METHOD = string, T_PHASE = PhaseTO> {
  id: number;
  status: T_STATUS;
  loanProduct: T_LOAN;
  channels: T_CHANNEL[];
  expireAfter: number;
  modifiedBy?: string;
  modifiedOn?: T_DATE;
  releaseMethod: T_RELEASE_METHOD;
  phases: T_PHASE[];
  paymentType: PaymentType,
  fixedPaymentOptions?: FixedPaymentOption[];
}

export type Process = ProcessTO<ProductStatus, LoanConfiguration, Channel, Dayjs, ReleaseMethod, Phase>

export const fromProcessTO = (processTO: ProcessTO): Process => ({
  ...processTO,
  status: ProductStatus[processTO.status],
  channels: processTO.channels?.map(value => Channel[value]),
  modifiedOn: processTO.modifiedOn ? dayjs(processTO.modifiedOn) : undefined,
  releaseMethod: ReleaseMethod[processTO.releaseMethod],
  phases: processTO.phases ? processTO.phases.map(phase => fromPhaseTO(phase)) : []
});

export enum Channel {
  WEB = 'WEB',
  MOBILE = 'MOBILE',
  OTC = 'OTC'
}

export enum ProductStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

enum ReleaseMethod {
  CBS = 'CBS',
  PARTNER = 'PARTNER'
}
