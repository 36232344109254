import {NxFormikProps} from '@nextbank/ui-components/dist/model/SharedTypes';
import {useField, useFormikContext} from 'formik';
import React, {ReactElement} from 'react';
import NxNumberMaskInput, {NxNumberMaskInputProps} from './NxNumberMaskInput';

export type NxFormikCashInputProps = Omit<NxNumberMaskInputProps, NxFormikProps> & {
  name: string;
  validate?: (value: number | null) => string | void | Promise<string | void>;
};

/**
 * For invalid numbers, component provides null to the model
 */
export const NxFormikNumberMaskInput = (props: NxFormikCashInputProps): ReactElement => {

  const context = useFormikContext();
  const [field, meta, helpers] = useField(props);
  const {onChange, onBlur, ...rest} = field;

  return <NxNumberMaskInput {...rest}
                            {...props}
                            error={meta.error}
                            disabled={props.disabled || context.isSubmitting}
                            required={props.required}
                            onBlur={(): void => helpers.setTouched(true, context.validateOnBlur)}
                            onChange={(value: number | null): void => helpers.setValue(value,
                              context.validateOnChange)} />;
};
