import {Tooltip} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import styles from './LoanDetail.module.scss';

interface Props {
  title: React.ReactNode;
  content: React.ReactNode;
  horizontal?: boolean;
  desktopOnly?: boolean;
  longDetailValue?: boolean;
}

export const LoanDetail = (
  {title, content, desktopOnly = false, horizontal = false, longDetailValue = false}: Props): React.ReactElement => {

  const detailClassName = clsx(
    styles.detail,
    {
      [styles.detail_desktopOnly]: desktopOnly,
      [styles.detail_row]: horizontal
    }
  );

  const valueClassName = clsx(
    styles.value,
    {
      [styles.value_long]: longDetailValue
    }
  );

  return (<Tooltip arrow title={<>{content}</>}>
      <div className={detailClassName}>
        <div className={styles.title}>{title}</div>
        <div className={valueClassName}>{content}</div>
      </div>
    </Tooltip>
  );
};
