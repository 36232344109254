import {NxButton} from '@nextbank/ui-components';
import React, {useContext, useState} from 'react';
import {SecurityContext} from '../../App';
import {hasExecutionReadonlyAccess} from '../../utils/permissions-utils';
import {TransHelper} from '../../utils/trans-helper';
import Instruction from '../shared/instruction/Instruction';
import LoanApplicationDashboardTable from './dashboard/LoanApplicationDasboard';
import {LoanCreationTypePopup} from './new-loan-application/LoanCreationTypePopup';
import styles from './LoanApplications.module.scss';

export const TRANS_PREFIX = 'LOAN_APPLICATIONS';
export const PrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);

const LoanApplications = (): React.ReactElement => {

  const {userData} = useContext(SecurityContext);
  const [loanCreationTypeOpen, setLoanCreationTypeOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          <PrefixTrans>HEADER</PrefixTrans>
        </h1>
        <NxButton className={styles.button}
                  onClick={(): void => setLoanCreationTypeOpen(true)}
                  disabled={hasExecutionReadonlyAccess(userData)}>
          <PrefixTrans>NEW_LOAN</PrefixTrans>
        </NxButton>
      </div>
      <Instruction>
        <PrefixTrans>SELECT_EXISTING_LOAN_APPLICATION</PrefixTrans>
      </Instruction>
      <LoanApplicationDashboardTable />
      <LoanCreationTypePopup open={loanCreationTypeOpen} setOpen={setLoanCreationTypeOpen} />
    </div>
  );
};

export default LoanApplications;
