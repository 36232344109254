import {NxLoader} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {useContext} from 'react';
import {PhaseName} from '../../../../../constants/api-urls';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {ApiHelper} from '../../../../../utils/api-helper';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {AgreementsPhase} from './agreements-phase.model';
import AgreementsForm from './AgreementsForm';

export default function Agreements(): React.ReactElement {

  const {process} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.AGREEMENT);
  const config = useSingleQuery<AgreementsPhase>(phaseUrl);

  return isNil(config) ? <NxLoader /> : <AgreementsForm config={config} />;
}
