import {NxButton, NxInput} from '@nextbank/ui-components';
import React, {createContext, ChangeEvent, useState} from 'react';
import {Trans} from 'react-i18next';
import {generateKey} from '../../../../../../utils/generate-key';
import {getInputValue} from '../../../../../../utils/input-utils';
import {StringHelper} from '../../../../../../utils/string-helper';
import DragDropItems from '../../../../../shared/drag-drop/DragDropItems';
import CustomFieldGroup from './CustomFieldGroup';
import styles from './CustomFields.module.scss';
import {KeyedField, KeyedFieldGroup} from './keyed-fields.model';

type CustomFieldsContextType = {
  fieldGroups: KeyedFieldGroup[];
  setFieldGroups: (fieldGroups: KeyedFieldGroup[]) => void;
  phaseId: number
}

export const CustomFieldsContext = createContext<CustomFieldsContextType>({
  fieldGroups: [],
  setFieldGroups: () => null,
  phaseId: 0
});

interface Props {
  fieldGroups: KeyedFieldGroup[],
  setFieldGroups: (fieldGroups: KeyedFieldGroup[]) => void;
  phaseId: number;
}

export default function CustomFields({fieldGroups, setFieldGroups, phaseId}: Props): React.ReactElement {

  const [newGroupName, setNewGroupName] = useState('');

  const handleNewGroupNameChange = (event: ChangeEvent): void =>
    setNewGroupName(getInputValue(event));

  const addGroupItem = (): void => {
    setFieldGroups([
      ...fieldGroups,
      {
        name: newGroupName,
        fields: [] as KeyedField[],
        ...generateKey()
      }
    ]);
    setNewGroupName('');
  };

  const customFieldGroupItemFunction = (provided, isDragging, item): React.ReactElement =>
    <CustomFieldGroup provided={provided} isDragging={isDragging} fieldGroup={item} />;

  return (
    <CustomFieldsContext.Provider value={{fieldGroups, setFieldGroups, phaseId}}>
      {
        fieldGroups.length > 0 &&
        <div className={styles.fieldGroups}>
          <DragDropItems<KeyedFieldGroup>
            itemComponentFunction={customFieldGroupItemFunction}
            droppableId={'droppableCustomFieldGroups'}
            items={fieldGroups}
            setItems={setFieldGroups} />
        </div>
      }
      <div className={styles.row}>
        <NxInput className={styles.newGroupInput}
                 label={<Trans>LOAN_CONFIGURATIONS.CUSTOMER_DATA.CUSTOM_FIELDS.TYPE_NEW_GROUP_NAME</Trans>}
                 onChange={handleNewGroupNameChange}
                 value={newGroupName} />
        <NxButton onClick={addGroupItem} disabled={StringHelper.isBlank(newGroupName)}>
          <Trans>LOAN_CONFIGURATIONS.CUSTOMER_DATA.CUSTOM_FIELDS.ADD_GROUP</Trans>
        </NxButton>
      </div>
    </CustomFieldsContext.Provider>
  );
}
