export enum FieldDisablementReason {
  NO_PERMISSIONS_TO_EDIT = 'NO_PERMISSIONS_TO_EDIT',
  PAYMENT_INTERVAL_NOT_CHOSEN = 'PAYMENT_INTERVAL_NOT_CHOSEN',
  SINGLE_PAYMENT_INTERVAL_CHOSEN = 'SINGLE_PAYMENT_INTERVAL_CHOSEN',
  VALUE_CALCULATED_AUTOMATICALLY = 'VALUE_CALCULATED_AUTOMATICALLY',
  VALUE_MANAGED_BY_CBS = 'VALUE_MANAGED_BY_CBS',
  PROVINCE_FIELD_MUST_BE_FILLED_FIRST = 'PROVINCE_FIELD_MUST_BE_FILLED_FIRST',
  CITY_FIELD_MUST_BE_FILLED_FIRST = 'CITY_FIELD_MUST_BE_FILLED_FIRST',
  CONSOLIDATE_LOAN_CREATION_TYPE = 'CONSOLIDATE_LOAN_CREATION_TYPE'
}
