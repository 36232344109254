import {NxTabs} from '@nextbank/ui-components';
import React, {ReactElement} from 'react';
import {TransHelper} from '../../utils/trans-helper';
import {Access} from './accesses/Access';
import styles from './Admin.module.scss';
import {Categories} from './categories/Categories';

export const PrefixTrans = TransHelper.getPrefixedTrans('ADMIN');

const Admin = (): ReactElement => {

  const categoriesTab = {
    key: 'CATEGORIES',
    label: <PrefixTrans>CATEGORIES.LABEL</PrefixTrans>,
    tabPanel: <Categories />
  };

  const accessTab = {
    key: 'ACCESS',
    label: <PrefixTrans>ACCESS.LABEL</PrefixTrans>,
    tabPanel: <Access />
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          <PrefixTrans>HEADER</PrefixTrans>
        </h1>
      </div>
      <div className={styles.tabsWrapper}>
        <NxTabs tabs={[categoriesTab, accessTab]} />
      </div>
    </div>
  );
};

export default Admin;
