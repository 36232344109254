import {KeyedRouteProps, UrlParam} from '../../../routes/routes.model';
import {LoanCreationRouteIds, RoutePaths} from '../../../routes/routes.paths';
import {buildApplicationStepPath, buildConfigurationStepPath} from '../../../utils/loan-steps-utils';
import Admin from '../../admin/Admin';
import ApplicationBatch from '../../batch/ApplicationBatch';
import {BatchDetails} from '../../batch/details/BatchDetails';
import LoanApplication from '../../loan-applications/loan-application/LoanApplication';
import LoanApplications from '../../loan-applications/LoanApplications';
import NewLoanApplication from '../../loan-applications/new-loan-application/NewLoanApplication';
import LoanConfiguration from '../../loan-configurations/loan-configuration/LoanConfiguration';
import LoanConfigurations from '../../loan-configurations/LoanConfigurations';
import NoPermissions from '../../no-permissions/NoPermissions';
import ConsolidateLoanApplication
  from '../../loan-applications/consolidate-loan-application/ConsolidateLoanApplication';

export const NEW_LOAN_APPLICATION_PATH = `${RoutePaths.LOAN_APPLICATIONS}/${LoanCreationRouteIds.NEW}`;
export const CONSOLIDATE_LOAN_APPLICATION_PATH = `${RoutePaths.LOAN_APPLICATIONS}/${LoanCreationRouteIds.CONSOLIDATE}`;
export const ADDITIONAL_LOAN_APPLICATION_PATH = `${RoutePaths.LOAN_APPLICATIONS}/${LoanCreationRouteIds.ADDITIONAL_LOAN}`;
export const RELOAN_APPLICATION_PATH = `${RoutePaths.LOAN_APPLICATIONS}/${LoanCreationRouteIds.RELOAN}`;

export const DASHBOARD_ROUTES: KeyedRouteProps[] = [
  {
    path: RoutePaths.BATCH,
    key: 'BATCH',
    exact: true,
    component: ApplicationBatch
  },
  {
    path: `${RoutePaths.BATCH}/:${UrlParam.BATCH_ID}`,
    key: 'BATCH_DETAILS',
    exact: true,
    component: BatchDetails
  },
  {
    path: RoutePaths.LOAN_APPLICATIONS,
    key: 'LOAN_APPLICATIONS',
    exact: true,
    component: LoanApplications
  },
  {
    path: CONSOLIDATE_LOAN_APPLICATION_PATH,
    key: 'CONSOLIDATE_LOAN_APPLICATION',
    exact: true,
    component: ConsolidateLoanApplication
  },
  {
    path: `${RoutePaths.LOAN_APPLICATIONS}/:${UrlParam.CREATION_TYPE}`,
    key: 'NEW_LOAN_APPLICATION',
    exact: true,
    component: NewLoanApplication
  },
  {
    path: buildApplicationStepPath(),
    key: 'LOAN_APPLICATION',
    component: LoanApplication
  },
  {
    path: RoutePaths.LOAN_CONFIGURATIONS,
    key: 'LOAN_CONFIGURATIONS',
    exact: true,
    component: LoanConfigurations
  },
  {
    path: buildConfigurationStepPath(),
    key: 'LOAN_CONFIGURATION',
    component: LoanConfiguration
  },
  {
    path: RoutePaths.NO_PERMISSIONS,
    key: 'NO_PERMISSIONS',
    exact: true,
    component: NoPermissions
  },
  {
    path: RoutePaths.ADMIN,
    key: 'ADMIN',
    exact: true,
    component: Admin
  }
];
