import React, {useContext, useEffect} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import {UrlParams} from '../../../routes/routes.model';
import {buildApplicationStepPath} from '../../../utils/loan-steps-utils';
import {RoutePaths} from '../../../routes/routes.paths';
import {ApplicantAssignment} from '../loan-application/steps/customer-data/customer-select/ApplicantAssignment';
import {ApiHelper} from '../../../utils/api-helper';
import {ApplicationRelationshipType} from '../loan-application/steps/customer-data/customer-data-form.model';
import {CustomerType} from '../../../shared/model/customer-type.model';
import {AppSnackbarContext} from '../../shared/app-snackbar-provider/AppSnackbarProvider';
import usePost from '../../../shared/hooks/use-post.hook';
import {LoanApplicationContext} from '../loan-application/LoanApplication';

const AssignConsolidateCustomerData = (): React.ReactElement => {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {refreshApplication} = useContext(LoanApplicationContext);
  const {applicationId, applicantSourceId, processId, customerType} = useParams<UrlParams>();
  const assignApplicant = usePost<void, ApplicantAssignment>(ApiHelper.getAssignApplicantUrl(applicationId));

  useEffect(() => {
    const applicantAssignment = {
      applicantSourceId: Number(applicantSourceId),
      relationshipType: ApplicationRelationshipType.BORROWER,
      customerType: CustomerType[customerType.toUpperCase() as keyof typeof CustomerType]
    };

    assignApplicant(applicantAssignment, null)
      .then(() => refreshApplication())
      .catch(error => showErrorMessage(error.message));
  }, []);

  return (
    <Redirect to={buildApplicationStepPath(RoutePaths.CUSTOMER_DATA, processId, applicationId)} />
  );
};

export default AssignConsolidateCustomerData;
