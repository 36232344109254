import {NxFormikInput, NxFormikSelect} from '@nextbank/ui-components';
import {isEmpty} from 'lodash';
import React, {ReactElement} from 'react';
import {DictionaryEntry} from '../../../../../../../shared/model/dictionary.model';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import {ConfiguredFieldWrapper} from '../../../shared/configured-fields/ConfiguredFieldWrapper';
import {FieldDisablementReason} from '../../../shared/configured-fields/field-disablement-reason.model';
import {IncomeSource} from '../income-source.model';
import {useIncomeSourceTypeInputsOptions} from './use-incone-source-type-inputs-options';

export interface IncomeSourceTypeInputsEntries {
  natureOfWork?: DictionaryEntry[];
  natureType?: DictionaryEntry[];
  businessType?: DictionaryEntry[];
}

interface Props {
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.incomeSources[1].number.
   */
  formPath: string;
  entries: IncomeSourceTypeInputsEntries;
  disablementReason?: FieldDisablementReason;
  disabled?: boolean;
  required?: boolean;
}

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CUSTOMER_DATA.INCOME_SOURCES');

export default function IncomeSourceTypeInputs(
  {entries, formPath, disablementReason, disabled, required}: Props
): ReactElement {

  const getName = (key: keyof IncomeSource): string => `${formPath}.${key}`;

  const natureOfWorkFieldName = getName('natureOfWorkId');
  const natureTypeFieldName = getName('natureTypeId');

  const options = useIncomeSourceTypeInputsOptions(natureOfWorkFieldName, natureTypeFieldName, entries);

  return (
    <>
      <ConfiguredFieldWrapper disablementReason={disablementReason}>
        <NxFormikSelect<number> label={<PrefixTrans>NATURE_OF_WORK_OR_BUSINESS</PrefixTrans>}
                                name={getName('natureOfWorkId')}
                                options={options.natureOfWork}
                                disabled={disabled}
                                required={required}
                                emptyOption />
      </ConfiguredFieldWrapper>
      {
        !isEmpty(options.natureType) &&
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikSelect<number> label={<PrefixTrans>NATURE_TYPE</PrefixTrans>}
                                  name={getName('natureTypeId')}
                                  options={options.natureType ?? []}
                                  disabled={disabled}
                                  required={required}
                                  emptyOption />
        </ConfiguredFieldWrapper>
      }
      {
        !isEmpty(options.businessType) &&
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikSelect<number> label={<PrefixTrans>BUSINESS_TYPE</PrefixTrans>}
                                  name={getName('businessTypeId')}
                                  options={options.businessType ?? []}
                                  disabled={disabled}
                                  required={required}
                                  emptyOption />
        </ConfiguredFieldWrapper>
      }
      <ConfiguredFieldWrapper disablementReason={disablementReason}>
        <NxFormikInput label={<PrefixTrans>DESCRIPTION</PrefixTrans>}
                       name={getName('description')}
                       disabled={disabled}
                       required={required} />
      </ConfiguredFieldWrapper>
    </>
  );
}
