import {useEffect} from 'react';
import {CalculatorFormFields} from '../../../calculator/calculator.model';
import {CALCULATE_FIRST_PAYMENT_DATE_URL} from '../../../../../../../constants/api-urls';
import useGet from '../../../../../../../shared/hooks/use-get.hook';

export function useCalculateFirstPaymentDate(values: CalculatorFormFields,
                                             onFirstPaymentDateCalculated: (value) => void): void {
  const getCalculateFirstPaymentDate = useGet<string>(CALCULATE_FIRST_PAYMENT_DATE_URL);

  useEffect(() => {
    const dateGranted = values.dateGranted.value;
    const paymentIntervalId = values.paymentIntervalId.value;
    if (dateGranted && paymentIntervalId) {
      const urlSearchParams = getUrlParams(dateGranted, paymentIntervalId.toString());
      getCalculateFirstPaymentDate(urlSearchParams).then(firstPaymentDate => {
        const firstPaymentDateCopy = {...values.firstPaymentDate, value: firstPaymentDate};
        onFirstPaymentDateCalculated(firstPaymentDateCopy);
      });
    }
  }, [values.dateGranted.value, values.paymentIntervalId.value]);
}

function getUrlParams(dateGranted: string, paymentIntervalId: string): URLSearchParams {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('grantDate', dateGranted);
  urlSearchParams.set('paymentIntervalId', paymentIntervalId);
  return urlSearchParams;
}
