import {NxFormikInput, NxFormikMultiSelect, NxSelectOption} from '@nextbank/ui-components';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {TransHelper} from '../../../utils/trans-helper';
import {NxFormikNumberMaskInput} from '../../shared/inputs/number-input/NxFormikNumberMaskInput';
import {BatchStatus} from '../batch.model';
import styles from './SearchBatchForm.module.scss';

export const TRANS_PREFIX = 'BATCH';
export const STATUS_TRANS_PREFIX = `${TRANS_PREFIX}.STATUS`;
export const PrefixTrans = TransHelper.getPrefixedTrans(`${TRANS_PREFIX}.SEARCH`);

const SearchBatchForm = (): React.ReactElement => {

  const {t} = useTranslation();

  const statusSelectOptions = Object.values(BatchStatus)
    .map(status => ({
        label: t(`${STATUS_TRANS_PREFIX}.${status}`),
        value: status
      } as NxSelectOption)
    );

  return (
    <>
      <div className={styles.description}>
        <PrefixTrans>TITLE</PrefixTrans>
      </div>
      <div className={styles.inputs}>
        <NxFormikNumberMaskInput name={'id'}
                                 label={<PrefixTrans>ID</PrefixTrans>}
                                 className={styles.textInput}
                                 step={1}
                                 min={0} />
        <NxFormikInput name={'name'}
                       label={<PrefixTrans>NAME</PrefixTrans>}
                       className={styles.textInput} />
        <NxFormikMultiSelect name={'statuses'}
                             label={<PrefixTrans>STATUS</PrefixTrans>}
                             options={statusSelectOptions}
                             className={styles.selectInput} />
      </div>
    </>
  );
};

export default SearchBatchForm;
