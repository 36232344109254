import React from 'react';
import {Trans} from 'react-i18next';
import {useParams} from 'react-router';
import {BatchUrlParams} from '../../../../routes/routes.model';
import usePost from '../../../../shared/hooks/use-post.hook';
import {TransHelper} from '../../../../utils/trans-helper';
import {ConfirmationPopup} from '../../../shared/confirmation-popup/ConfirmationPopup';
import {getBatchUrl} from '../BatchDetails';
import styles from './RevokePopup.module.scss';

export const TRANS_PREFIX = 'BATCH.DETAILS.POPUP';
export const CommonPrefixTrans = TransHelper.getPrefixedTrans(TRANS_PREFIX);
export const PrefixTrans = TransHelper.getPrefixedTrans(`${TRANS_PREFIX}.REVOKE`);

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  refreshBatch: () => Promise<void>;
}

export const RevokePopup = ({open, setOpen, refreshBatch}: Props): React.ReactElement => {

  const {batchId} = useParams<BatchUrlParams>();
  const revokeBatch = usePost<void, void>(`${getBatchUrl(batchId)}/revoke`);

  const messageBody = (
    <div className={styles.message}>
      <span><PrefixTrans>MESSAGE_ROW_1</PrefixTrans></span>
      <span><PrefixTrans>MESSAGE_ROW_2</PrefixTrans></span>
      <span><CommonPrefixTrans>CONFIRMATION_MSG</CommonPrefixTrans></span>
    </div>
  );

  const closePopup = (): void => setOpen(false);

  return <ConfirmationPopup
    header={<PrefixTrans>HEADER</PrefixTrans>}
    description={messageBody}
    onConfirm={(): Promise<void> => revokeBatch().then(() => {
      refreshBatch();
      closePopup();
    })}
    confirmButtonLabel={<PrefixTrans>YES_BUTTON</PrefixTrans>}
    confirmButtonClass={styles.confirmButton}
    onDeny={closePopup}
    denyButtonLabel={<Trans>COMMON.CANCEL</Trans>}
    open={open}
  />;
};
