import {UrlParam} from '../../../../routes/routes.model';
import {RoutePaths} from '../../../../routes/routes.paths';
import {buildConfigurationStepPath} from '../../../../utils/loan-steps-utils';
import Agreements from '../steps/agreements/Agreements';
import Calculator from '../steps/calculator/Calculator';
import CustomPhase from '../steps/custom-phase/CustomPhase';
import CustomerData from '../steps/customer-data/CustomerData';
import EligibilityCriteria from '../steps/eligibility-criteria/EligibilityCriteria';
import General from '../steps/general/General';
import LoanApplicationData from '../steps/loan-application-data/LoanApplicationData';
import PreRelease from '../steps/pre-release/PreRelease';
import EditCustomPhases from '../steps/edit-custom-phases/EditCustomPhases';

export const LOAN_CONFIGURATION_ROUTES = [
  {
    path: RoutePaths.GENERAL,
    key: 'CONFIGURATION_GENERAL',
    component: General
  },
  {
    path: RoutePaths.CALCULATOR,
    key: 'CONFIGURATION_CALCULATOR',
    component: Calculator
  },
  {
    path: RoutePaths.CUSTOMER_DATA,
    key: 'CONFIGURATION_CUSTOMER_DATA',
    component: CustomerData
  },
  {
    path: RoutePaths.ELIGIBILITY_CRITERIA,
    key: 'CONFIGURATION_ELIGIBILITY_CRITERIA',
    component: EligibilityCriteria
  },
  {
    path: RoutePaths.AGREEMENTS,
    key: 'CONFIGURATION_AGREEMENTS',
    component: Agreements
  },
  {
    path: RoutePaths.LOAN_APPLICATION_DATA,
    key: 'CONFIGURATION_LOAN_APPLICATION_DATA',
    component: LoanApplicationData
  },
  {
    path: RoutePaths.PRE_RELEASE,
    key: 'CONFIGURATION_PRE_RELEASE',
    component: PreRelease
  },
  {
    path: `${RoutePaths.CUSTOM}/:${UrlParam.PHASE_ID}`,
    key: 'CONFIGURATION_CUSTOM',
    component: CustomPhase
  },
  {
    path: RoutePaths.EDIT_CUSTOM_PHASES,
    key: 'CONFIGURATION_EDIT_CUSTOM_PHASES',
    component: EditCustomPhases
  }]
  .map(route => ({
    ...route,
    path: buildConfigurationStepPath(route.path)
  }));