import {isObject} from 'lodash';

export interface FileBasicData {
  id: number,
  name?: string,
  url?: string
}

export interface NxFile {
  id?: number,
  file?: File,
  isChanged: boolean
}

export const isNxFile = (object?: unknown): object is NxFile => {
  const nxFile = object as NxFile;
  return isObject(nxFile) && 'file' in nxFile && 'isChanged' in nxFile;
};
