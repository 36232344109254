import {NxCashInput, NxPercentageInput} from '@nextbank/ui-components';
import React from 'react';
import styles from './ReadonlyInput.module.scss';

interface Props {
  label: React.ReactNode;
  value?: number | null;
  isPercentage?: boolean;
}

export default function ReadonlyInput({label, value, isPercentage = false}: Props): React.ReactElement {

  const props = {
    onChange: (): void => {/* Empty function to silence warning `NumberInput undefined changed, but no onChange provided. */},
    className: styles.fieldRow,
    disabled: true,
    label,
    value
  };

  return isPercentage ? <NxPercentageInput {...props} /> : <NxCashInput  {...props} />
}

