import {NxSelectOption} from '@nextbank/ui-components';
import {useField} from 'formik';
import {useMemo} from 'react';
import {SelectHelper} from '../../../../../../../utils/select-helper';
import {businessTypeConfig, natureTypeConfig} from './income-source-type-input-dependencies.config';
import {IncomeSourceTypeInputsEntries} from './IncomeSourceTypeInputs';

interface IncomeSourceTypeInputsOptions {
  natureOfWork: NxSelectOption<number>[];
  natureType: NxSelectOption<number>[];
  businessType: NxSelectOption<number>[];
}

export const useIncomeSourceTypeInputsOptions = (natureOfWorkFieldName: string,
                                                 natureTypeFieldName: string,
                                                 entries: IncomeSourceTypeInputsEntries): IncomeSourceTypeInputsOptions => {

  const [natureOfWorkField] = useField<number>(natureOfWorkFieldName);
  const [natureTypeField] = useField<number>(natureTypeFieldName);

  const natureOfWorkOptions = SelectHelper.mapToNumberOptions(entries.natureOfWork);

  const natureTypeOptions = useMemo(() => {
    const selectedNatureOfWorkCode = entries.natureOfWork?.find(entry => entry.id === natureOfWorkField.value)?.code;
    if (!selectedNatureOfWorkCode) {
      return [];
    }

    const natureTypeOptionCodes: string[] = natureTypeConfig[selectedNatureOfWorkCode];
    if (!natureTypeOptionCodes) {
      return [];
    }

    const filteredEntries = entries.natureType?.filter(entry => natureTypeOptionCodes.includes(entry.code));
    return filteredEntries ? SelectHelper.mapToNumberOptions(filteredEntries) : [];
  }, [natureOfWorkField.value]);

  const businessTypeOptions = useMemo(() => {
    const selectedNatureOfWorkCode = natureOfWorkField.value
      ? entries.natureOfWork?.find(entry => entry.id === natureOfWorkField.value)?.code
      : undefined;
    const selectedNatureTypeCode = natureTypeField.value
      ? entries.natureType?.find(entry => entry.id === natureTypeField.value)?.code
      : undefined;

    if (!selectedNatureOfWorkCode && !selectedNatureTypeCode) {
      return [];
    }

    // non null checked above. At least one has to be filled
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const businessTypeOptions: string[] = businessTypeConfig[(selectedNatureTypeCode ?? selectedNatureOfWorkCode)!];
    if (!businessTypeOptions) {
      return [];
    }

    const filteredEntries = entries.businessType?.filter(entry => businessTypeOptions.includes(entry.code));
    return filteredEntries ? SelectHelper.mapToNumberOptions(filteredEntries) : [];
  }, [natureOfWorkField.value, natureTypeField.value]);

  return {natureOfWork: natureOfWorkOptions, natureType: natureTypeOptions, businessType: businessTypeOptions};
};
