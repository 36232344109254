import {NxButton, NxInput} from '@nextbank/ui-components';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import React, {ReactElement, useContext} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {SecurityContext} from '../../../App';
import {MFA_VERIFY_URL} from '../../../constants/api-urls';
import {UserData} from '../../../security-context.model';
import usePost from '../../../shared/hooks/use-post.hook';
import {HttpError} from '../../../shared/http-service/http-error';
import {SimpleErrorBody} from '../../../shared/http-service/http-error.model';
import styles from './MfaForm.module.scss';

interface Props {
  logInUser: (user: UserData) => void;
}

interface MfaFields {
  code: string;
}

export default function MfaForm({logInUser}: Props): React.ReactElement {

  const GENERAL_ERROR_KEY = 'authentication';

  const {logoutReason, setLogoutReason} = useContext(SecurityContext);
  const {t} = useTranslation();
  const mfaVerify = usePost<UserData, FormData>(MFA_VERIFY_URL);

  const getErrorMessage = (httpError: HttpError<SimpleErrorBody>): string => {
    return httpError.response.data.errorMessage ?? t('LOGIN.ERROR');
  };

  const submitMfa = (values: MfaFields, actions: FormikHelpers<MfaFields>): void => {

    setLogoutReason(undefined);

    const formData = new FormData();
    formData.append('mfaCode', values.code);

    mfaVerify(formData)
      .then(userData => logInUser(userData))
      .catch(error => {
        const httpError = (error as HttpError<SimpleErrorBody>);
        actions.setFieldError(GENERAL_ERROR_KEY, getErrorMessage(httpError));
      })
      .finally(() => actions.setSubmitting(false));
  };

  const MfaForm = ({
                     values, handleChange, handleBlur, handleSubmit, isSubmitting, errors
                   }: FormikProps<MfaFields>): ReactElement => (
    <form onSubmit={handleSubmit}>
      <div className={styles.hint}><Trans>LOGIN.MFA.HINT</Trans></div>
      <NxInput
        className={styles.input}
        label={<Trans>LOGIN.MFA.CODE</Trans>}
        name='code'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.code}
      />
      <NxButton type='submit' className={styles.button} disabled={isSubmitting} loaded={!isSubmitting}>
        <Trans>LOGIN.MFA.VERIFY</Trans>
      </NxButton>
      <div className={styles.error}>
        {logoutReason ?? errors[GENERAL_ERROR_KEY]}
      </div>
    </form>
  );

  return (
    <Formik<MfaFields> initialValues={{code: ''}} onSubmit={submitMfa}>
      {MfaForm}
    </Formik>
  );
}
