import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {GuardedRouterActionType} from './guarded-router-context.model';
import {GuardedRouterContext, useGuardedRouterContextState} from './GuardedRouterContext';
import {RouteChangeConfirmationPopup} from './route-change-confirmation-popup/RouteChangeConfirmationPopup';

export const GuardedRouter = ({children}: {children: React.ReactNode}): React.ReactElement => {
  const [routerContextState, dispatchAction] = useGuardedRouterContextState();

  return (
    <GuardedRouterContext.Provider value={{routerContextState, dispatchAction}}>
      <BrowserRouter>
        {children}
        <RouteChangeConfirmationPopup
          onConfirm={routerContextState.onRouteChangeConfirm}
          onDeny={(): void => dispatchAction({type: GuardedRouterActionType.HIDE_CONFIRMATION_POPUP})}
          open={routerContextState.showRouteChangeConfirmationPopup} />
      </BrowserRouter>
    </GuardedRouterContext.Provider>
  );
};
