import isNil from 'lodash/isNil';
import React, {createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {useLocation} from 'react-router-dom';
import {SecurityContext} from '../../../../../../App';
import {UrlParams} from '../../../../../../routes/routes.model';
import {ApprovalRule} from '../../../../../../shared/model/phase.model';
import {PrintType} from '../../../../../../shared/model/print.model';
import {hasExecutionReadonlyAccess} from '../../../../../../utils/permissions-utils';
import {useGuardedHistory} from '../../../../../router/GuardedHistory';
import Instruction from '../../../../../shared/instruction/Instruction';
import {LoanStepNavigationButtons} from '../../../../../shared/loan-step-navigation-buttons/LoanStepNavigationButtons';
import {ApprovalStatus} from '../../../loan-application.model';
import {LoanApplicationContext} from '../../../LoanApplication';
import {LoanApplicationNavigationContext} from '../../../navigation/loan-navigation.model';
import {getAvailableApprovers} from './approval/approval-utils';
import {ApprovalPanel} from './approval/ApprovalPanel';
import {ApprovalExecutionBar} from './approval/execution-bar/ApprovalExecutionBar';
import {EditButton} from './edit-button/EditButton';
import styles from './LoanApplicationStep.module.scss';
import {PrintButton} from './print-button/PrintButton';
import {useApplicationLock} from './use-loan-application-save.hook';
import {isSysAdminOrManager} from '../../../../../../utils/permissions-utils';

export interface StepContextType {
  isStepReadonly: boolean;
}

export const StepContext = createContext<StepContextType>({} as StepContextType);

interface Props {
  children?: ReactNode;
  handleSave?: (validate: boolean) => Promise<void>;
  phaseId?: number,
  dataChanged?: boolean;
  stepInstruction?: ReactNode;
  stepNavigation?: ReactNode;
  approvalRules?: ApprovalRule[];
  printType?: PrintType
  isStepEnabled?: boolean;
}

const LoanApplicationStep = (
  {
    children,
    handleSave,
    phaseId,
    dataChanged,
    stepInstruction,
    stepNavigation,
    approvalRules = [],
    printType
  }: Props): ReactElement => {
  const history = useGuardedHistory();

  const {t} = useTranslation();
  const path = useLocation().pathname;
  const {applicationId} = useParams<UrlParams>();
  const {
    application,
    applicationParams,
    steps,
    processChanged
  } = useContext(LoanApplicationContext);
  const {isSubmitting, setSaveStepFunc, revertStep, moveStep} = useContext(LoanApplicationNavigationContext);
  const {userData} = useContext(SecurityContext);
  const stepPaths = steps.getPreviousAndNextStepPaths(path);
  const {isStepDone, isEditable, isDeferred, isOpenedPhaseCurrent} = applicationParams;
  const isUserAssignee = application?.assignedTo === userData?.id;

  useEffect(() => {
    if (handleSave) {
      setSaveStepFunc(handleSave);
    }
  }, [handleSave]);

  useApplicationLock(dataChanged && isOpenedPhaseCurrent);

  const {approvalStatus, currentPhaseId} = application ?? {};
  // Navi
  const isSubStep = steps.getStepByPath(path)?.isSubStep;
  const isCurrentPhase = phaseId === currentPhaseId;

  // App state
  const isApprovalPending = isCurrentPhase && approvalStatus === ApprovalStatus.PENDING;
  const isApprovalRejected = isCurrentPhase && approvalStatus === ApprovalStatus.REJECTED;

  const approvalExecutions = useMemo(() =>
      (application?.approvalExecutions ?? []).filter(value => value.phaseId === phaseId),
    [application?.approvalExecutions, phaseId]);

  const approvers = useMemo(() =>
      isNil(application) ? [] : getAvailableApprovers(approvalRules, application.principalAmount),
    [approvalRules, application]);

  const requiresApproval = useMemo(() =>
      (approvalRules).some(rule => rule.phaseId === phaseId),
    [approvalRules, phaseId]);

  const isPhaseReadonly = !processChanged && (!isNil(application) &&
    (!isCurrentPhase || isSubmitting || isApprovalPending || !isEditable));
  const hasReadOnlyAccess = hasExecutionReadonlyAccess(userData);

  const isStepReadonly = hasReadOnlyAccess || isPhaseReadonly;

  const navigateTo = useCallback((path: string): void | Promise<void> =>
      path === stepPaths.nextStepPath && !hasReadOnlyAccess && !isDeferred && (processChanged || isCurrentPhase)
        ? moveStep(path)
        : history.push(path)
    , [stepPaths, hasReadOnlyAccess]);

  const StepNavigationButtons = stepNavigation ??
    <LoanStepNavigationButtons nextButtonLoaded={!isSubmitting}
                               disableButtons={isSubmitting}
                               navigateTo={navigateTo}
                               stepPaths={stepPaths} />;

  const getPrintButton = (pId: number, appId: number, printType: PrintType): ReactElement =>
    <PrintButton applicationId={appId}
                 phaseId={pId}
                 disabled={!isStepDone || (!isSysAdminOrManager(userData) && !isUserAssignee)}
                 printType={printType} />;

  return (
    <StepContext.Provider value={{isStepReadonly}}>
      <div className={styles.stepHeading}>
        <h1 className={styles.stepName}>
          {steps.getTranslatedStepLabel(path, t)}
        </h1>
        <div className={styles.stepNavigation}>
          {
            isStepDone && isEditable && !!phaseId &&
            <EditButton loaded={!isSubmitting} revertStep={(): Promise<void> => revertStep(phaseId)} />
          }
          {
            !!printType && !!phaseId && getPrintButton(phaseId, parseInt(applicationId), printType)
          }
          {
            isApprovalPending
              ? <ApprovalExecutionBar applicationId={applicationId}
                                      approvalExecutions={approvalExecutions}
                                      approvers={approvers} 
                                      phaseId={phaseId} />
              : !isSubStep && StepNavigationButtons
          }
        </div>
      </div>
      <div>
        <Instruction>
          {
            stepInstruction ?? <Trans>LOAN_CONFIGURATIONS.STEPS_INSTRUCTION</Trans>
          }
        </Instruction>
        {
          (isApprovalRejected || isApprovalPending || (requiresApproval && isStepDone))
          && <ApprovalPanel approvalExecutions={approvalExecutions} approvers={approvers} />
        }
        {children}
      </div>
    </StepContext.Provider>
  );
};

export default LoanApplicationStep;
