import {useContext, useEffect, useState} from 'react';
import {AppSnackbarContext} from '../../components/shared/app-snackbar-provider/AppSnackbarProvider';
import {ApiHelper} from '../../utils/api-helper';
import {fromProcessTO, Process, ProcessTO} from '../model/process.model';
import useGet from './use-get.hook';

interface LoanProcess {
  process: Process;
  updateProcess: () => void;
  isProcessUpdating: boolean;
  setIsProcessUpdating: (isUpdating: boolean) => void;
  arePhasesUpdating: boolean;
}

export function useLoanProcess(processId: string | number): LoanProcess {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const [isProcessUpdating, setIsProcessUpdating] = useState(false);
  const [arePhasesUpdating, setArePhasesUpdating] = useState(false);
  const [process, setProcess] = useState<Process>({} as Process);

  const fetchProcessUrl = (fetchPhases: boolean): string => ApiHelper.getProcessUrl(processId, {fetchPhases});
  const fetchProcess = useGet<ProcessTO>(fetchProcessUrl(true));
  const fetchProcessWithoutPhases = useGet<ProcessTO>(fetchProcessUrl(false));

  const setUpdatingMarkers = (isUpdating: boolean, fetchPhases: boolean): void => {
    setIsProcessUpdating(isUpdating);

    if (fetchPhases) {
      setArePhasesUpdating(isUpdating);
    }
  }

  const updateProcess = (fetchPhases: boolean): void => {

    setUpdatingMarkers(true, fetchPhases);
    const fetchFunction = fetchPhases ? fetchProcess : fetchProcessWithoutPhases;

    fetchFunction()
      .then(updatedProcess => setProcess(
        {
          ...fromProcessTO(updatedProcess),
          ...fetchPhases ? {} : {phases: process.phases}
        }))
      .then(() => setUpdatingMarkers(false, fetchPhases))
      .catch(error => showErrorMessage(error.message));
  };

  useEffect(() => {
    updateProcess(true);
  }, [processId]);

  return {
    process,
    updateProcess: (fetchPhases = false): void => updateProcess(fetchPhases),
    isProcessUpdating,
    setIsProcessUpdating,
    arePhasesUpdating
  };
}
