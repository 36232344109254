import dayjs, {Dayjs} from 'dayjs';
import React, {createContext, useEffect, useState} from 'react';
import {SYSTEM_CONFIG_URL, SYSTEM_STATUS_URL} from '../../../constants/api-urls';
import useGet from '../../../shared/hooks/use-get.hook';
import {SystemConfiguration} from '../../../shared/model/system-configuration.model';
import {fromSystemStatusTO, SystemStatusTO} from '../../../shared/model/system-status.model';

interface SystemContext {
  webServerTime: Dayjs;
  printsEnabled: boolean;
}

const defaultSystemContext = {webServerTime: dayjs(), printsEnabled: false};
export const SystemContext = createContext<SystemContext>(defaultSystemContext);

interface Props {
  children: React.ReactNode;
}

export function SystemContextProvider({children}: Props): React.ReactElement {

  const getSystemStatus = useGet<SystemStatusTO>(SYSTEM_STATUS_URL);
  const getSystemConfiguration = useGet<SystemConfiguration>(SYSTEM_CONFIG_URL);

  const [systemContext, setSystemContext] = useState<SystemContext>(defaultSystemContext);

  useEffect(() => {
    const systemStatus = getSystemStatus()
      .then(systemStatusTO => fromSystemStatusTO(systemStatusTO));
    const systemConfig = getSystemConfiguration();

    Promise.all([systemConfig, systemStatus])
      .then(([systemConfig, systemStatus]) => {
        setSystemContext({
          ...systemContext,
          webServerTime: systemStatus.webServerTime,
          printsEnabled: systemConfig.printsEnabled
        });
      });
  }, []);

  return (
    <SystemContext.Provider value={systemContext}>
      {children}
    </SystemContext.Provider>
  );
}

