import {TableCell} from '@material-ui/core';
import clsx from 'clsx';
import React, {ReactNode} from 'react';
import styles from './TableHeaderCell.module.scss';

interface Props {
  className?: string;
  header?: ReactNode;
  small?: boolean;
}

const TableHeaderCell = ({className, header, small}: Props): React.ReactElement =>
  <TableCell className={clsx(styles.cell, className, {[styles.cell_small]: small})}>{header}</TableCell>;

export default TableHeaderCell;
