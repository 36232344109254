import {Menu} from '@material-ui/core';
import {NxButton, NxButtonVariant} from '@nextbank/ui-components';

import React, {ReactElement, ReactNode, useState} from 'react';
import {ReactComponent as FilterActiveIcon} from '../../../../assets/images/icon-filter-active.svg';
import {ReactComponent as FilterIcon} from '../../../../assets/images/icon-filter.svg';
import {TransHelper} from '../../../../utils/trans-helper';
import IconButtonSwitch from '../../icon-button-switches/IconButtonSwitch';
import styles from './AdvancedSearchMenu.module.scss';

interface Props {
  children: ReactNode;
  menuId: string;
  isActive: boolean;
  onReset: () => void;
}

export const PrefixTrans = TransHelper.getPrefixedTrans('COMMON.ADVANCED_SEARCH');

const AdvancedSearchMenu = React.forwardRef(
  ({children, menuId, isActive, onReset}: Props, ref
  ): ReactElement => {

    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorElement);
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => setAnchorElement(event.currentTarget);

    const getFilterIcon = (): ReactElement => {
      if (isActive) {
        return <FilterActiveIcon className={styles.filterIcon} />;
      }
      return <FilterIcon className={styles.filterIcon} />;
    };

    return (
      <>
        <IconButtonSwitch ariaLabel='more'
                          className={styles.advancedFilterButton}
                          ariaControlsId={menuId}
                          icon={getFilterIcon()}
                          onClick={handleClick} />
        <Menu ref={ref}
          // When using this more than once in rendered document use different id as it has to be unique
              id={menuId}
              open={open}
              keepMounted
              elevation={6}
              anchorEl={anchorElement}
              getContentAnchorEl={null}
              onClose={(): void => setAnchorElement(null)}
              anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
          <div className={styles.container}>
            <div className={styles.header}>
              <PrefixTrans>FILTER</PrefixTrans>
              <NxButton variant={NxButtonVariant.TEXT} onClick={onReset}>
                <PrefixTrans>RESET</PrefixTrans>
              </NxButton>
            </div>
            {children}
          </div>
        </Menu>
      </>
    );
  });

AdvancedSearchMenu.displayName = 'AdvancedSearchMenu';

export default AdvancedSearchMenu;
