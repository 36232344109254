import {
  LoanApplicationWithCheckBoxTableHeaders,
  LoanApplicationTableHeaders,
  ConsolidatedLoansTableHeaders,
  CustomerCbsLoansTableHeaders
} from '../constants/loan-application-labels';

export const getLoanApplicationTableHeaders = (isReassignLoanApplicationAllowed: boolean) =>
  isReassignLoanApplicationAllowed
    ? Object.values(LoanApplicationWithCheckBoxTableHeaders)
    : Object.values(LoanApplicationTableHeaders);

export const getConsolidatedLoansTableHeaders = (): ConsolidatedLoansTableHeaders[] => Object.values(
  ConsolidatedLoansTableHeaders);

export const getCustomerCbsLoansTableHeaders = (): CustomerCbsLoansTableHeaders[] => Object.values(
  CustomerCbsLoansTableHeaders);
  