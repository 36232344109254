import clsx from 'clsx';
import isNil from 'lodash/isNil';
import React, {ReactElement} from 'react';
import {
  DictionaryEntryRequirement,
  FieldRequirement
} from '../../../../../../shared/model/dictionary-requirement.model';
import {DictionaryEntry} from '../../../../../../shared/model/dictionary.model';
import {DictionaryHelper} from '../../../../../../utils/dictionary-helper';
import {TransHelper} from '../../../../../../utils/trans-helper';
import BinButton from '../../../../../shared/bin-button/BinButton';
import styles from './RequirementFormHeader.module.scss';

interface Props {
  disabled?: boolean;
  entry: DictionaryEntryRequirement;
  entries?: DictionaryEntry[];
  deleteFunction?: (typeId: number) => void;
}

const {getEntryRequirementLabel} = DictionaryHelper;

export default function RequirementFormHeader({disabled, entry, entries, deleteFunction}: Props): ReactElement {
  return (
    <div className={clsx(styles.header, {[styles.header_fixedHeight]: deleteFunction})}>
      {getEntryRequirementLabel(entries, entry)}
      {
        !isNil(deleteFunction) && entry.requirement === FieldRequirement.OPTIONAL &&
        <BinButton disabled={disabled}
                   className={styles.bin}
                   onClick={(): void => deleteFunction(entry.dictionaryEntryId)} />
      }
    </div>
  );
}
