import {NxFormikPhoneInput} from '@nextbank/ui-components';
import React from 'react';
import {DEFAULT_VALUE} from '../../../constants/static-field-values';
import {Field} from '../../../shared/model/field.model';
import {SetFieldValue} from '../../../shared/model/formik.model';
import {FieldDefaultCheckbox} from './FieldDefaultCheckbox';

interface Props {
  label: React.ReactNode;
  name: string;
  field: Field;
  setFieldValue: SetFieldValue;
  mask?: string
}

export const FieldDefaultPhoneInput = ({label, name, field, setFieldValue, mask}: Props): React.ReactElement => (
  <FieldDefaultCheckbox name={name} field={field} setFieldValue={setFieldValue}>
    <NxFormikPhoneInput name={`${name}.${DEFAULT_VALUE}`} label={label} mask={mask} />
  </FieldDefaultCheckbox>
);
