import {NxLoader} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {boolean, object} from 'yup';
import {PhaseName} from '../../../../../constants/api-urls';
import {UrlParams} from '../../../../../routes/routes.model';
import useFileUpload from '../../../../../shared/hooks/use-file-upload.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {Phase} from '../../../../../shared/model/phase.model';
import {PrintType} from '../../../../../shared/model/print.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {optionalValidationParams} from '../../../../../utils/optional-validation-form-utils';
import {validateAndSubmitForm} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';
import FormGroup from '../../../../shared/form-column/FormGroup';
import {LoanApplicationContext} from '../../LoanApplication';
import styles from '../customer-data/custom-fields/CustomFields.module.scss';
import {getCheckValuesValidationSchema} from '../shared/custom-checks/check-values-schema-utils';
import {handleCustomFieldValues} from '../shared/custom-checks/check-values-utils';
import CheckFields from '../shared/custom-checks/CheckFields';
import useCustomCheckInitValues from '../shared/custom-checks/use-custom-check-init-values';
import useCustomCheckDictionaries from '../shared/custom-checks/use-custom-checks-dictionaries-query.hook';
import LoanApplicationStep from '../shared/loan-application-step/LoanApplicationStep';
import {StepPayloadType} from '../shared/step-payload.model';
import {EligibilityCriteriaFormFields, EligibilityCriteriaPayload} from './eligiblity-criteria-form.model';

const {getPhaseConfigurationUrl, getPhaseSaveUrl} = ApiHelper;

export default function EligibilityCriteria(): React.ReactElement {

  const {t} = useTranslation();
  const {uploadFile} = useFileUpload();
  const {applicationId} = useParams<UrlParams>();
  const {processId, application, setOpenedPhaseId} = useContext(LoanApplicationContext);
  const phaseUrl = getPhaseConfigurationUrl(processId, PhaseName.ELIGIBILITY);
  const config = useSingleQuery<Phase>(phaseUrl);
  const saveStep = usePost<void, EligibilityCriteriaPayload>(getPhaseSaveUrl(applicationId));
  const {dictionaries, areDictionariesLoaded} = useCustomCheckDictionaries(config?.checkGroups);
  const checkInitValues = useCustomCheckInitValues(config, application?.checkValues);

  const submit = async (values: EligibilityCriteriaFormFields): Promise<void> => {

    const manualCheckValues = values.checkValues.filter(({isAutomatic}) => !isAutomatic);
    const checkValues = await Promise.all(handleCustomFieldValues(manualCheckValues, uploadFile));

    const stepPayload: EligibilityCriteriaPayload = {
      type: StepPayloadType.ELIGIBILITY_CRITERIA_PAYLOAD,
      checkValues
    };

    return saveStep(stepPayload, null, optionalValidationParams(values.validate));
  };

  useEffect(() => {
    if (config) {
      setOpenedPhaseId(config.id);
    }
  }, [config]);

  const EligibilityForm = (
    {values, submitForm, validateForm, setFieldValue, dirty}: FormikProps<EligibilityCriteriaFormFields>
  ): ReactElement => {

    const handleSave = async (validate: boolean): Promise<void> =>
      validateAndSubmitForm<EligibilityCriteriaFormFields>(values,
        validate,
        submitForm,
        validateForm,
        setFieldValue,
        t);

    return (
      <LoanApplicationStep handleSave={handleSave}
                           phaseId={config?.id}
                           dataChanged={dirty}
                           approvalRules={config?.approvalRules}
                           printType={PrintType.ELIGIBILITY_CRITERIA}>
        <Form>
          {
            config?.checkGroups.map((checkGroup, groupIndex) =>
              <FormGroup className={styles.inputs} header={checkGroup.name} key={groupIndex}>
                <CheckFields checkValues={values.checkValues}
                             checks={checkGroup.checks}
                             dictionaries={dictionaries} />
              </FormGroup>
            )
          }
        </Form>
      </LoanApplicationStep>
    );
  };

  const validationSchema = object({
    validate: boolean(),
    checkValues: getCheckValuesValidationSchema(t)
  });

  return isNil(config) || !areDictionariesLoaded || isNil(checkInitValues)
    ? <NxLoader />
    : <Formik<EligibilityCriteriaFormFields>
      onSubmit={submit}
      validateOnChange={false}
      validationSchema={validationSchema}
      initialValues={{checkValues: checkInitValues}}>
      {EligibilityForm}
    </Formik>;
}
