import {NxMultiSelect, NxSelectOption} from '@nextbank/ui-components';
import clsx from 'clsx';
import React, {useContext, useMemo} from 'react';
import {Approver} from '../../../../../../shared/model/phase.model';
import {LoanConfigurationContext} from '../../../LoanConfiguration';
import {PrefixTrans} from '../../LoanConfigurationStep';
import styles from './ApproverInputs.module.scss';

interface Props {
  approver: Approver;
  handleRoleChange: (roleIds: number[]) => void;
  handleBranchChange: (roleIds: number[]) => void;
  isAddRow?: boolean;
}

const mapToNumberOptions = (options?: NxSelectOption[]): NxSelectOption<number>[] =>
  options ? options.map(option => ({...option, value: Number(option.value) })) : [];

export default function ApproverInputs({approver, handleRoleChange, handleBranchChange, isAddRow = false}: Props): React.ReactElement {

  const {branches, roles} = useContext(LoanConfigurationContext);
  const branchIdsOptions = useMemo(() => mapToNumberOptions(branches), [branches])
  const roleIdsOptions = useMemo(() => mapToNumberOptions(roles), [roles])

  return (
    <>
      <NxMultiSelect className={clsx(styles.input, {[styles.input_long]: isAddRow})}
                     label={<PrefixTrans>USER_ROLE</PrefixTrans>}
                     options={roleIdsOptions}
                     onChange={handleRoleChange}
                     value={approver.roleIds} />
      <NxMultiSelect className={clsx(styles.input, styles.input_long)}
                     label={<PrefixTrans>BRANCH</PrefixTrans>}
                     options={branchIdsOptions}
                     onChange={handleBranchChange}
                     value={approver.branchIds} />
    </>
  );
}
