import {NxSelect} from '@nextbank/ui-components';
import {useField} from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, {ReactElement, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {ReactComponent as ValidateIcon} from '../../../../assets/images/check-document.svg';
import {PhaseValidationResult} from '../../../../shared/model/phase-validation-result.model';
import {TransHelper} from '../../../../utils/trans-helper';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import {AppSnackbarContext} from '../../../shared/app-snackbar-provider/AppSnackbarProvider';
import IconButtonSwitch from '../../../shared/icon-button-switches/IconButtonSwitch';
import {LoanStepNavigationButtons} from '../../../shared/loan-step-navigation-buttons/LoanStepNavigationButtons';
import ConfigErrorResult from '../../shared/config-error-result/ConfigErrorResult';
import {LoanConfigurationContext} from '../LoanConfiguration';
import ApprovalConfiguration from './approval-configuration/ApprovalConfiguration';
import ApprovalConfigurationButtonSwitch from './approval-configuration/ApprovalConfigurationButtonSwitch';
import styles from './LoanConfigurationStep.module.scss';
import LoanConfigurationStepWrapper from './LoanConfigurationStepWrapper';

export const FINAL_APPROVAL_TRANS_PREFIX = 'LOAN_CONFIGURATIONS.FINAL_APPROVAL';
export const PrefixTrans = TransHelper.getPrefixedTrans(FINAL_APPROVAL_TRANS_PREFIX);

const finalApprovalOptions = [
  {value: false, label: <PrefixTrans>OPTIONAL</PrefixTrans>},
  {value: true, label: <PrefixTrans>REQUIRED</PrefixTrans>}
];

interface Props {
  children: React.ReactNode;
  handleSave: () => Promise<void>;
  validationFunction?: () => Promise<PhaseValidationResult>;
  handleValidation?: (validationResult: PhaseValidationResult) => void;
}

const LoanConfigurationStep = ({children, handleSave, validationFunction, handleValidation}: Props): ReactElement => {

  const {showErrorMessage, showSuccessMessage} = useContext(AppSnackbarContext);
  const {t} = useTranslation();
  const path = useLocation().pathname;
  const history = useGuardedHistory();
  const {steps, configurationChanged, setConfigurationChanged} = useContext(LoanConfigurationContext);
  const label = steps.getTranslatedStepLabel(path, t);
  const showFinalApprovalConfiguration = steps.showFinalApprovalConfiguration(path);
  const [showApprovalConfiguration, setShowApprovalConfiguration] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isApprovalRequiredField, , isApprovalRequiredHelpers] = useField('isApprovalRequired');

  const handleFinalApprovalRequiredChange = (isApprovalRequired: boolean | null): void => {
    if (!configurationChanged) {
      setConfigurationChanged(true);
    }

    if (!isApprovalRequired && showApprovalConfiguration) {
      toggleApprovalConfiguration();
    }

    isApprovalRequiredHelpers.setValue(isApprovalRequired);
  };

  const toggleApprovalConfiguration = (): void => setShowApprovalConfiguration(!showApprovalConfiguration);

  const validate = (): void => {
    setIsValidating(true);
    if (validationFunction) {
      validationFunction()
        .then(result => {
          if (!isEmpty(result.errors)) {
            !!handleValidation && handleValidation(result)
            showErrorMessage(<ConfigErrorResult results={[result]} />);
            return;
          }
          showSuccessMessage(t('LOAN_CONFIGURATIONS.PHASE_CONFIGURATION_VALID'));
        })
        .catch(error => showErrorMessage(error.message))
        .finally(() => setIsValidating(false));
    }
  };

  const StepNavigation = (
    <div className={styles.stepNavigation}>
      {
        showFinalApprovalConfiguration &&
        <>
          <NxSelect<boolean>
            label={<PrefixTrans>STEP_FINAL_APPROVAL</PrefixTrans>}
            onChange={handleFinalApprovalRequiredChange}
            className={styles.approvalRequiredSelect}
            value={isApprovalRequiredField.value}
            options={finalApprovalOptions} />
          <ApprovalConfigurationButtonSwitch enabled={isApprovalRequiredField.value}
                                             onClick={toggleApprovalConfiguration}
                                             value={showApprovalConfiguration} />
        </>
      }
      {
        validationFunction && <IconButtonSwitch
          icon={<ValidateIcon />}
          disabled={isValidating}
          onClick={validate} />
      }
      <LoanStepNavigationButtons stepPaths={steps.getPreviousAndNextStepPaths(path)} navigateTo={history.push} />
    </div>
  );

  return (
    <LoanConfigurationStepWrapper hideStepInstruction={showApprovalConfiguration}
                                  stepNavigation={StepNavigation}
                                  handleSave={handleSave}
                                  label={label}>
      {
        showApprovalConfiguration ? <ApprovalConfiguration hideConfiguration={toggleApprovalConfiguration} /> : children
      }
    </LoanConfigurationStepWrapper>
  );
};

export default LoanConfigurationStep;
