import {NxFormikCheckbox, NxFormikRadio} from '@nextbank/ui-components';
import clsx from 'clsx';
import React from 'react';
import styles from './DictionaryEntryRow.module.scss';

interface Props {
  checkboxName: string;
  enabled: boolean;
  label: string;
  radioName: string;
}

export enum RequirementRadioValue {
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED'
}

export default function DictionaryEntryRow(
    {checkboxName, enabled, label, radioName}: Props
  ): React.ReactElement {

  const radioClassName = clsx(styles.radio, { [styles.radio_hidden]: !enabled });

  const options = [
    {
      label: 'Required',
      value: RequirementRadioValue.REQUIRED,
      key: 'REQUIRED'
    },
    {
      label: 'Optional',
      value: RequirementRadioValue.OPTIONAL,
      key: 'OPTIONAL'
    }
  ];

  return (
    <NxFormikCheckbox name={checkboxName} className={styles.checkboxRow} translucentUncheckedRow>
      {label}
      <NxFormikRadio name={radioName} className={radioClassName} options={options} />
    </NxFormikCheckbox>
  );
}

