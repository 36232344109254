import {useReducer} from 'react';
import {Severity, SnackBarMessage} from './AlertSnackbar';

export enum AlertSnackbarActionType {
  SHOW = 'SHOW',
  HIDE = 'HIDE'
}

export interface AlertSnackbarAction {
  type: AlertSnackbarActionType;
  message?: SnackBarMessage;
  severity?: Severity;
}

export interface AlertSnackbarState {
  isOpen: boolean;
  message?: SnackBarMessage;
  severity?: Severity;
}

const initialState: AlertSnackbarState = {
  isOpen: false
};

const reducer = (state: AlertSnackbarState, action: AlertSnackbarAction): AlertSnackbarState => {
  switch (action.type) {
    case AlertSnackbarActionType.HIDE:
      return {
        ...state,
        isOpen: false
      };
    case AlertSnackbarActionType.SHOW:
      return {
        isOpen: true,
        message: action.message,
        severity: action.severity ?? Severity.SUCCESS
      };
    default:
      throw new Error();
  }
};

export function useAlertSnackbar(): [AlertSnackbarState, (action: AlertSnackbarAction) => void] {
  const [productsState, dispatch] = useReducer(reducer, initialState);

  const changeState = (action: AlertSnackbarAction): void => {
    dispatch(action);
  };

  return [productsState, changeState];
}
