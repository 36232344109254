import {isArray, isEmpty, isNil, some} from 'lodash';

type recordWithEnabled = {
  enabled?: boolean;
}

export const areNotNil = (values: unknown[]): boolean => !some(values, value => isNil(value));
export const areNotEmpty = (values: unknown[]): boolean => some(values, value => !isEmpty(value));
export const isAnyEnabled = (values: recordWithEnabled[]): boolean => some(values, value => !!value.enabled);
export const isAnyFilled = (values: unknown[]): boolean =>
  some(values, value => !isNil(value) && !(isArray(value) && isEmpty(value)));