import {isArray} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, {ReactElement, useContext, useState} from 'react';
import {useParams} from 'react-router';
import {UrlParams} from '../../../../routes/routes.model';
import usePost from '../../../../shared/hooks/use-post.hook';
import {FieldError} from '../../../../shared/model/phase-validation-result.model';
import {ApiHelper} from '../../../../utils/api-helper';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import {AppSnackbarContext} from '../../../shared/app-snackbar-provider/AppSnackbarProvider';
import {LoanApplicationContext} from '../LoanApplication';
import {StepTransitionResult} from '../steps/shared/step-transition-result.model';
import {LoanApplicationNavigationContext} from './loan-navigation.model';

interface Props {
  children: React.ReactNode;
}

const defaultHandleSaveFunc = (message: string) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  () => (validate: boolean): Promise<void> => {
    console.warn(`Function ${message} not present2. It's required to navigation work properly`);
    return Promise.resolve();
  };

export const LoanNavigation = ({children}: Props): ReactElement => {
  const {applicationId} = useParams<UrlParams>();

  const history = useGuardedHistory();

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const {refreshApplication} = useContext(LoanApplicationContext);

  const handleMove = usePost<StepTransitionResult, void>(ApiHelper.getPhaseMoveUrl(applicationId));
  const postRevertStep = usePost<void, void>(ApiHelper.getPhaseRevertUrl(applicationId));

  const [applicationChanged, setApplicationChanged] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [handleSave, setHandleSave] = useState(defaultHandleSaveFunc('handleSave'));

  const handleSaveStepError = (error: unknown | FieldError[]): Promise<void> => {
    if (isArray((error)) && !isEmpty(error)) {
      showErrorMessage((error as FieldError[]).map((error) => fieldError(error)));
      const hasApprovalError = (error as FieldError[]).some(fieldError => fieldError.fieldPath === 'approval');
      if (hasApprovalError) {
        // Refresh application needed when move was stop because of approval is required
        refreshApplication();
      }
      return Promise.reject(error);
    }

    showErrorMessage(`${error}`);
    return Promise.reject(error);
  };

  const fieldError = (error: FieldError): ReactElement => (
    <div key={error.fieldPath}>
      <span>{`${error.fieldName}:`}</span> {error.messages}
    </div>
  );

  // useState executes function passed into setter so if we want to keep function in useState then we need to wrap it
  const setHandleSaveWrappedInFunction = (newHandleSave: (validate: boolean) => Promise<void>): void => {
    const saveStep = async (validate: boolean): Promise<void> => {
      return newHandleSave(validate)
        .then(() => setApplicationChanged(false))
        .catch(handleSaveStepError);
    };

    setHandleSave(() => saveStep);
  };

  const revertStep = async (phaseId: number): Promise<void> => {

    setSubmitting(true);

    const targetPhaseIdParams = new URLSearchParams(`targetPhaseId=${phaseId}`);
    return postRevertStep(null, null, targetPhaseIdParams)
      .then(() => { 
        refreshApplication(); 
      })
      .catch(error => showErrorMessage(error.message))
      .finally(() => setSubmitting(false));
  };

  const moveStep = (path: string): Promise<void> => {
    setSubmitting(true);

    if (!handleSave) {
      throw new Error('Handle save method not provided');
    }

    const move = (fieldErrors: void): Promise<StepTransitionResult | void> => {
      if (isEmpty(fieldErrors)) {
        return handleMove().then(result => {
          return isEmpty(result.error)
            ? result
            : Promise.reject(result.error);
        });
      }

      return Promise.reject(fieldErrors);
    };

    return handleSave(true)
      .then(move)
      .then(() => refreshApplication())
      .then(() => history.forcePush(path))
      .catch(handleSaveStepError)
      .finally(() => setSubmitting(false));
  };

  const saveStep = async (validate: boolean): Promise<void> => {
    setSubmitting(true);

    return handleSave(validate)
      .finally(() => setSubmitting(false));
  };

  const navigationContext = {
    isSubmitting,
    applicationChanged,
    setApplicationChanged,
    moveStep,
    revertStep,
    saveStep,
    setSaveStepFunc: setHandleSaveWrappedInFunction
  };

  return (
    <LoanApplicationNavigationContext.Provider value={navigationContext}>
      {children}
    </LoanApplicationNavigationContext.Provider>
  );
};
