import React from 'react';
import {Trans} from 'react-i18next';
import {ReactComponent as NegativeIcon} from '../../../assets/images/icon-status-negative.svg';
import {ReactComponent as PositiveIcon} from '../../../assets/images/icon-status-positive.svg';
import styles from './YesNoSelectOptions.module.scss';

export const FALSE_STRING = 'false';
export const TRUE_STRING = 'true';

export const YES_NO_SELECT_OPTIONS = [
  {
    value: FALSE_STRING,
    label: (
      <div className={styles.option}>
        <NegativeIcon className={styles.icon} /><Trans>COMMON.NO</Trans>
      </div>
    )
  },
  {
    value: TRUE_STRING,
    label: (
      <div className={styles.option}>
        <PositiveIcon className={styles.icon} /><Trans>COMMON.YES</Trans>
      </div>
    )
  }
];
