import {NxButton, NxIconButton, NxNumberInput} from '@nextbank/ui-components';
import {useField} from 'formik';
import isNil from 'lodash/isNil';
import React, {createContext, ReactElement, useContext, useState} from 'react';
import {Trans} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../../../../assets/images/icon-close.svg';
import {APPROVAL_RULES} from '../../../../../shared/model/phase.model';
import {generateKey} from '../../../../../utils/generate-key';
import DragDropItems from '../../../../shared/drag-drop/DragDropItems';
import Instruction from '../../../../shared/instruction/Instruction';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {PrefixTrans} from '../LoanConfigurationStep';
import ApprovalRuleItem from './approval-rule-items/ApprovalRuleItem';
import {KeyedApprovalRule} from './approval-rule-items/keyed-approval-rules.model';
import styles from './ApprovalConfiguration.module.scss';

type ApprovalRulesContextType = {
  rules: KeyedApprovalRule[];
  setRules: (rules: KeyedApprovalRule[]) => void;
}

export const ApprovalRulesContext = createContext<ApprovalRulesContextType>({
  rules: [],
  setRules: () => null
});

interface Props {
  hideConfiguration: () => void;
}

const ApprovalConfiguration = ({hideConfiguration}: Props): ReactElement => {

  const {setConfigurationChanged} = useContext(LoanConfigurationContext);
  const [minAmount, setMinAmount] = useState<number | null>();
  const [maxAmount, setMaxAmount] = useState<number | null>();
  const [field, , helpers] = useField(APPROVAL_RULES);
  const rules = field.value;

  const setRules = (value: KeyedApprovalRule[]): void => {
    setConfigurationChanged(true);
    helpers.setValue(value);
  };

  const addRule = (): void => {
    setRules([
      ...rules ?? [],
      {
        minAmount,
        maxAmount,
        approvers: [],
        ...generateKey()
      }
    ]);

    setMaxAmount(undefined);
    setMinAmount(undefined);
  };

  const approvalRuleItemFunction = (provided, isDragging, item): ReactElement =>
    <ApprovalRuleItem provided={provided} isDragging={isDragging} rule={item} />;

  return (
    <div className={styles.approvalConfigurationPanel}>
      <Instruction>
        <div className={styles.instructionRow}>
          <PrefixTrans>STEP_FINAL_APPROVAL</PrefixTrans>
          <NxIconButton onClick={hideConfiguration} icon={<CloseIcon />} />
        </div>
      </Instruction>
      <ApprovalRulesContext.Provider value={{rules, setRules}}>
        {
          rules?.length > 0 &&
            <div className={styles.rules}>
              <DragDropItems<KeyedApprovalRule>
                itemComponentFunction={approvalRuleItemFunction}
                droppableId={'droppableApprovalRules'}
                setItems={setRules}
                items={rules} />
            </div>
        }
        <div className={styles.row}>
          <NxNumberInput className={styles.input}
                         label={<PrefixTrans>MINIMUM_LOAN_VALUE</PrefixTrans>}
                         onChange={setMinAmount}
                         value={minAmount}
                         min={0} />
          <NxNumberInput className={styles.input}
                         label={<PrefixTrans>MAXIMUM_LOAN_VALUE</PrefixTrans>}
                         onChange={setMaxAmount}
                         value={maxAmount}
                         min={0} />
          <NxButton className={styles.addButton} onClick={addRule} disabled={isNil(minAmount)}>
            <Trans>COMMON.ADD</Trans>
          </NxButton>
        </div>
      </ApprovalRulesContext.Provider>
    </div>
  );
};

export default ApprovalConfiguration;
