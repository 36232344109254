import {NxLoader} from '@nextbank/ui-components';
import {Form, Formik, FormikProps} from 'formik';
import isNil from 'lodash/isNil';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {boolean, object, string} from 'yup';
import {PhaseName} from '../../../../../constants/api-urls';
import {UrlParams} from '../../../../../routes/routes.model';
import useFileUpload from '../../../../../shared/hooks/use-file-upload.hook';
import usePost from '../../../../../shared/hooks/use-post.hook';
import useSingleQuery from '../../../../../shared/hooks/use-single-query.hook';
import {Phase} from '../../../../../shared/model/phase.model';
import {PrintType} from '../../../../../shared/model/print.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {optionalValidationParams} from '../../../../../utils/optional-validation-form-utils';
import {validateAndSubmitForm} from '../../../../../utils/step-form-utils/validation-schema/validation-schema-utils';
import {TransHelper} from '../../../../../utils/trans-helper';
import FormGroup from '../../../../shared/form-column/FormGroup';
import {LoanApplicationContext} from '../../LoanApplication';
import styles from '../customer-data/custom-fields/CustomFields.module.scss';
import {getCheckValuesValidationSchema} from '../shared/custom-checks/check-values-schema-utils';
import {handleCustomFieldValues} from '../shared/custom-checks/check-values-utils';
import CheckFields from '../shared/custom-checks/CheckFields';
import useCustomCheckInitValues from '../shared/custom-checks/use-custom-check-init-values';
import useCustomCheckDictionaries from '../shared/custom-checks/use-custom-checks-dictionaries-query.hook';
import LoanApplicationStep, {StepContext} from '../shared/loan-application-step/LoanApplicationStep';
import {StepPayloadType} from '../shared/step-payload.model';
import {PreReleaseFormFields, PreReleasePayload} from './pre-release-form.model';
import PreReleaseCommentsField from './PreReleaseCommentsField';

const {getPhaseConfigurationUrl, getPhaseSaveUrl} = ApiHelper;

export default function PreRelease(): React.ReactElement {

  const {t} = useTranslation();
  const {uploadFile} = useFileUpload();
  const {applicationId} = useParams<UrlParams>();
  const {processId, application, setOpenedPhaseId} = useContext(LoanApplicationContext);
  const phaseUrl = getPhaseConfigurationUrl(processId, PhaseName.PRE_RELEASE);
  const config = useSingleQuery<Phase>(phaseUrl);
  const saveStep = usePost<void, PreReleasePayload>(getPhaseSaveUrl(applicationId));
  const {dictionaries, areDictionariesLoaded} = useCustomCheckDictionaries(config?.checkGroups);
  const checkInitValues = useCustomCheckInitValues(config, application?.checkValues);

  const submit = async (values: PreReleaseFormFields): Promise<void> => {

    const checkValues = await Promise.all(handleCustomFieldValues(values.checkValues, uploadFile));

    const stepPayload: PreReleasePayload = {
      type: StepPayloadType.PRE_RELEASE,
      comments: values.comments,
      checkValues
    };

    return saveStep(stepPayload, null, optionalValidationParams(values.validate));
  };

  useEffect(() => {
    if (config) {
      setOpenedPhaseId(config.id);
    }
  }, [config]);

  const PreReleaseForm =
    ({values, submitForm, validateForm, setFieldValue, dirty}: FormikProps<PreReleaseFormFields>): ReactElement => {

      const handleSave = async (validate: boolean): Promise<void> =>
        validateAndSubmitForm<PreReleaseFormFields>(values, validate, submitForm, validateForm, setFieldValue, t);

      return (
        <LoanApplicationStep handleSave={handleSave}
                             phaseId={config?.id}
                             dataChanged={dirty}
                             approvalRules={config?.approvalRules}
                             printType={PrintType.PRE_RELEASE}>
          <Form>
            {
              config?.checkGroups.map((checkGroup, groupIndex) =>
                <FormGroup className={styles.inputs} header={checkGroup.name} key={groupIndex}>
                  <CheckFields checkValues={values.checkValues}
                               checks={checkGroup.checks}
                               dictionaries={dictionaries} />
                </FormGroup>
              )
            }
            <PreReleaseCommentsField />
          </Form>
        </LoanApplicationStep>
      );
    };

  const validationSchema = object({
    validate: boolean(),
    comments: string().nullable(),
    checkValues: getCheckValuesValidationSchema(t)
  });

  return isNil(config) || !areDictionariesLoaded || isNil(checkInitValues)
    ? <NxLoader />
    : <Formik<PreReleaseFormFields>
      onSubmit={submit}
      validateOnChange={false}
      validationSchema={validationSchema}
      initialValues={
        {
          checkValues: checkInitValues,
          comments: application?.preRelease ? application?.preRelease.comments : undefined
        }
      }>
      {PreReleaseForm}
    </Formik>;
}
