import clsx from 'clsx';
import React, {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {ReactComponent as BanIcon} from '../../../assets/images/icon-ban.svg';
import {ReactComponent as NegativeIcon} from '../../../assets/images/icon-status-negative.svg';
import {ReactComponent as PositiveIcon} from '../../../assets/images/icon-status-positive.svg';
import {ReactComponent as WaitingIcon} from '../../../assets/images/icon-status-waiting.svg';
import {ApplicationStatus, ApprovalStatus} from '../../loan-applications/loan-application/loan-application.model';
import styles from './ApplicationStatusChip.module.scss';

interface Props {
  status: ApplicationStatus;
  approvalStatus: ApprovalStatus;
  phaseName: string;
}

const ApplicationStatusChip = ({status, approvalStatus, phaseName}: Props): ReactElement => {

  const isSubmitted = status === ApplicationStatus.SUBMITTED;
  const isPendingApproval = approvalStatus === ApprovalStatus.PENDING;
  const isDeferred = status === ApplicationStatus.DEFERRED;
  const isDisabled = status === ApplicationStatus.CANCELED || isDeferred;
  const isError = status === ApplicationStatus.ERROR || approvalStatus === ApprovalStatus.REJECTED;

  const statusClassName = clsx(
    styles.status,
    {
      [styles.status_error]: isError,
      [styles.status_disabled]: isDisabled,
      [styles.status_submitted]: isSubmitted,
      [styles.status_pendingApproval]: isPendingApproval
    });

  return (
    <div className={statusClassName}>
      {
        (isSubmitted || isPendingApproval || isDeferred || isError) &&
        <div className={styles.icon}>
          {
            // Ifleton needs to be fixed when different status groups will be extracted to variables
            isSubmitted
              ? <PositiveIcon />
              : isDeferred
                ? <BanIcon />
                : isError
                  ? <NegativeIcon />
                  : <WaitingIcon />
          }
        </div>
      }
      <div className={styles.label}>
        {
          status === ApplicationStatus.PENDING
            ? phaseName
            : <Trans>{`LOAN_APPLICATIONS.APPLICATION_STATUS.${status}`}</Trans>
        }
      </div>
    </div>
  );
};

export default ApplicationStatusChip;
