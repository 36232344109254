import {isEmpty, uniq, values} from 'lodash';
import {useEffect, useState} from 'react';
import {ApiHelper} from '../../utils/api-helper';
import {Dictionary} from '../model/dictionary.model';
import useGet from './use-get.hook';

export default function useDictionariesQuery<T>(dictionaryIds?: T): {
  dictionaries: Dictionary[],
  loaded: boolean
} {

  const [loaded, setLoaded] = useState(false);

  const getDictionaries = useGet<Dictionary[]>(ApiHelper.getDictionariesUrl());
  const [dictionaries, setDictionaries] = useState<Dictionary[]>([]);

  useEffect(() => {

    if (!isEmpty(dictionaryIds)) {
      setLoaded(false);
      getDictionaries(queryParams(dictionaryIds))
        .then(result => setDictionaries(result))
        .finally(() => setLoaded(true));
    }
  }, [dictionaryIds]);

  return {dictionaries, loaded: loaded};
}

const queryParams = <T>(dictionaryIds?: T): URLSearchParams => {
  const queryParams = new URLSearchParams();

  if (dictionaryIds) {
    queryParams.set('dictionaryIds', uniq(values(dictionaryIds).filter(value => !!value)).toString());
  }

  return queryParams;
};
