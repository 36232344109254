import {NxButton, NxButtonVariant, NxLoader} from '@nextbank/ui-components';
import {isNil} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {PROCESSES_URL, SYSTEM_SYNCHRONIZE_URL} from '../../constants/api-urls';
import useGet from '../../shared/hooks/use-get.hook';
import usePost from '../../shared/hooks/use-post.hook';
import {fromProcessTO, Process, ProcessTO} from '../../shared/model/process.model';
import {TransHelper} from '../../utils/trans-helper';
import {AppSnackbarContext} from '../shared/app-snackbar-provider/AppSnackbarProvider';
import EmptyState from '../shared/empty-state/EmptyState';
import styles from './LoanConfigurations.module.scss';
import LoanConfigurationsTable from './loans-configurations-table/LoanConfigurationsTable';
import {RefreshConfigPopup} from './refresh-opoup/RefreshConfigPopup';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS');

const LoanConfigurations = (): React.ReactElement => {

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const getProcesses = useGet<ProcessTO[]>(PROCESSES_URL);
  const synchronizeSystem = usePost<void, void>(SYSTEM_SYNCHRONIZE_URL);

  const [processes, setProcesses] = useState<Process[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [confirmPopupOpen, setConfirmPopupOpen] = useState<boolean>(false);

  const updateProcesses = (): void => {
    getProcesses()
      .then(result => setProcesses(result.map(fromProcessTO)))
      .catch(error => showErrorMessage(error.message));
  };

  useEffect(() => {
    setIsLoading(true);
    updateProcesses();
    setIsLoading(false);
  }, []);

  const LoanProcesses = processes && processes.length > 0
    ? <LoanConfigurationsTable processes={processes} setProcesses={setProcesses} />
    : <EmptyState instruction={<PrefixTrans>EMPTY_STATE</PrefixTrans>} />;

  const synchronizeConfig = (): Promise<void> => {
    setIsLoading(true);
    setConfirmPopupOpen(false);
    return synchronizeSystem()
      .then(() => updateProcesses())
      .then(() => setIsLoading(false));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          <PrefixTrans>HEADER</PrefixTrans>
        </h1>
        <NxButton onClick={(): void => setConfirmPopupOpen(true)}
                  variant={NxButtonVariant.OUTLINED}
                  disabled={isLoading}>
          <Trans>LOAN_CONFIGURATIONS.REFRESH_BUTTON</Trans>
        </NxButton>
      </div>
      {
        isLoading || isNil(processes) ? <NxLoader /> : LoanProcesses
      }
      <RefreshConfigPopup open={confirmPopupOpen} setOpen={setConfirmPopupOpen} onConfirm={synchronizeConfig} />
    </div>
  );
};

export default LoanConfigurations;
