import {SimulatedFee} from '../shared/model/loan-simulation.model'
import {FeesOverrideType} from '../components/loan-applications/loan-application/steps/shared/simulation/fees-override/fees-override-type.model';

export const sortSimulatedFees = (simulatedFees: SimulatedFee[]): SimulatedFee[] => simulatedFees.sort(compare);

const compare = (simulatedFee1: SimulatedFee, simulatedFee2: SimulatedFee): number => {
  const simulatedFeesValues = Object.values(FeesOverrideType) as string[];
  const index1 = simulatedFeesValues.indexOf(simulatedFee1.feeSourceCode);
  const index2 = simulatedFeesValues.indexOf(simulatedFee2.feeSourceCode);

  if (shouldMoveToEnd(FeesOverrideType[simulatedFee1.feeSourceCode]) &&
    !shouldMoveToEnd(FeesOverrideType[simulatedFee2.feeSourceCode])
  ) {
    return 1;
  } else if (!shouldMoveToEnd(FeesOverrideType[simulatedFee1.feeSourceCode]) &&
    shouldMoveToEnd(FeesOverrideType[simulatedFee2.feeSourceCode])
  ) {
    return -1;
  }

  if (index1 === -1 && index2 === -1) {
    return 0;
  } else if (index1 === -1) {
    return 1;
  } else if (index2 === -1) {
    return -1;
  }

  return index1 - index2;
};

const shouldMoveToEnd = (feeSourceCode: FeesOverrideType): boolean => {
  // List the feeSourceCodes that should be moved to the end
  // If there are custom fees, the fees below will be sorted after the custom fees
  const feesToMoveToEnd = [
    FeesOverrideType.PAST_DUE_INTEREST,
    FeesOverrideType.PAST_DUE_INTEREST_MATURITY,
    FeesOverrideType.PENALTY,
    FeesOverrideType.PENALTY_MATURITY
  ];

  return feesToMoveToEnd.includes(feeSourceCode);
};