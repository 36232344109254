import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import {isNil} from 'lodash';
import React, {ReactElement, useContext} from 'react';
import {Trans} from 'react-i18next';
import {SecurityContext} from '../../../../App';
import {LoanDetailsLabel} from '../../../../constants/loan-details-labels';
import {RoutePaths} from '../../../../routes/routes.paths';
import {CustomerType} from '../../../../shared/model/customer-type.model';
import {hasExecutionReadonlyAccess, isSysAdminOrManager} from '../../../../utils/permissions-utils';
import {StringHelper} from '../../../../utils/string-helper';
import {TransHelper} from '../../../../utils/trans-helper';
import {useGuardedHistory} from '../../../router/GuardedHistory';
import DiscardChangesButton from '../../../shared/discard-changes-button/DiscardChangesButton';
import {LoanDetail} from '../../../shared/loan-detail/LoanDetail';
import SaveAndExitButton from '../../../shared/save-and-exit-button/SaveAndExitButton';
import TopBar from '../../../shared/top-bar/TopBar';
import {LoanApplicationContext} from '../LoanApplication';
import {LoanApplicationNavigationContext} from '../navigation/loan-navigation.model';
import {Applicant} from '../steps/customer-data/customer-data-form.model';
import {EventLogsButton} from '../steps/shared/loan-application-step/event-logs/EventLogsButton';
import CancelLoanButton from './CancelLoanButton';
import DeferLoanButton from './DeferLoanButton';
import UndoDeferLoanButton from './UndoDeferLoanButton';

const {getLoanDetailsLabel} = TransHelper;

interface Props {
  productName: string;
}

const getBorrowerName = (borrower: Applicant): string => {

  const {individualData, corporateData, type} = borrower;

  return type === CustomerType.CORPORATE
    ? corporateData?.businessName ?? '-'
    : StringHelper.getClientName(individualData?.firstName, individualData?.middleName, individualData?.lastName);
};

export default function LoanApplicationTopBar({productName}: Props): React.ReactElement {

  const history = useGuardedHistory();
  const {isSubmitting, applicationChanged, saveStep} = useContext(LoanApplicationNavigationContext);
  const {application, process, applicationParams} = useContext(LoanApplicationContext);
  const {userData} = useContext(SecurityContext);

  const isUserAssignee = application?.assignedTo === userData?.id;
  const isUserCreator = application?.createdBy === userData?.id;

  const hasReadonlyAccess = hasExecutionReadonlyAccess(userData);

  const saveDraft = (): Promise<void> => {
    if (!saveStep) {
      throw new Error('Step draft save requested, but no handleSave function provided for this step');
    }

    return saveStep(false);
  };

  const borrower = application?.borrower;

  /**
   * TODO LOS-562 Hack alert! Defer button needs to be hidden for ['Summary', 'Customer data', 'Calculator'] statuses.
   * Currently there is no setting to turn on/off buttons from phase configuration.
   */
  const showDefferButton = process.phases
    .filter(phase => application?.currentPhaseId === phase.id)
    .some(phase => !['Summary', 'Customer data', 'Calculator'].includes(phase.name));

  const getCancelAndDeferButtons = (applicationId: number): ReactElement[] => {
    const actionsArray: ReactElement[] = [];
    if (!applicationParams.isEditable || hasReadonlyAccess) {
      actionsArray.push(
        <NxButton variant={NxButtonVariant.CLOSE}
                  onClick={(): void => history.push(RoutePaths.LOAN_APPLICATIONS)}>
          <Trans>TOP_BAR.EXIT</Trans>
        </NxButton>);
      if (applicationParams.isDeferred) {
        actionsArray.push(<UndoDeferLoanButton applicationId={applicationId} />);
      }
      if (applicationParams.isSubmitted && isSysAdminOrManager(userData)) {
        actionsArray.push(<CancelLoanButton productName={productName} applicationId={applicationId} />);
      }
    } else if (applicationParams.isPending && showDefferButton) {
      actionsArray.push(<DeferLoanButton productName={productName} applicationId={applicationId} />);
    }

    return actionsArray;
  };

  const getActionButtons = (): {actionButtons: ReactElement[], moreActionButtons: ReactElement[]} => {
    const actionsArray: ReactElement[] = [];

    if (applicationParams.isEditable) {
      if (application?.id) {
        actionsArray.push(<DiscardChangesButton path={RoutePaths.LOAN_APPLICATIONS}
                                                disabled={hasReadonlyAccess} />);
      }
      actionsArray.push(<SaveAndExitButton configurationChanged={applicationChanged}
                                           showConfirmationPopup={applicationChanged}
                                           savingInProgress={isSubmitting}
                                           onSave={saveDraft}
                                           redirectPath={RoutePaths.LOAN_APPLICATIONS} />);
    }

    if (application?.id) {
      actionsArray.push(...getCancelAndDeferButtons(application?.id));

      if (isSysAdminOrManager(userData) || isUserAssignee || isUserCreator) {
        actionsArray.push(<EventLogsButton />);
      }
    }

    return {
      actionButtons: actionsArray.slice(0, 2),
      moreActionButtons: actionsArray.slice(2)
    };
  };

  return <TopBar
    details={<>
      <LoanDetail longDetailValue title={getLoanDetailsLabel(LoanDetailsLabel.PRODUCT_NAME)} content={productName} />
      <LoanDetail title={getLoanDetailsLabel(LoanDetailsLabel.REFERENCE_NO)}
                  content={application?.referenceNo ?? '-'} />
      {
        !isNil(application?.repeated) &&
        <LoanDetail title={getLoanDetailsLabel(LoanDetailsLabel.APPLICATION_TYPE)}
                    content={
                      <Trans>{`LOAN_DETAILS.REPEATED.${application?.repeated.toString().toUpperCase()}`}</Trans>
                    } />
      }
      {
        !isNil(borrower) && (
          <>
            <LoanDetail title={getLoanDetailsLabel(LoanDetailsLabel.CIF)} content={borrower.sourceNumber ?? '-'} />
            <LoanDetail title={getLoanDetailsLabel(LoanDetailsLabel.CLIENT)} content={getBorrowerName(borrower)} />
          </>
        )
      }
    </>
    }
    {...getActionButtons()}
  />;
}
