import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as UserIcon} from '../../../assets/images/icon-user.svg';
import IconButtonSwitch from './IconButtonSwitch';
import styles from './SelfCareButtonSwitch.module.scss';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  value?: boolean;
}

const SelfCareButtonSwitch = ({ onClick, value}: Props): React.ReactElement => {

  const {t} = useTranslation();
  const tooltip = t('COMMON.TOOLTIPS.MARK_AS_SELF_CARE');

  return (
    <IconButtonSwitch
      className={styles.button}
      ariaLabel={tooltip}
      icon={<UserIcon />}
      onClick={onClick}
      tooltipTitle={tooltip}
      value={value}
    />
  );
}

export default SelfCareButtonSwitch;
