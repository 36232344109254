import {NxTabs} from '@nextbank/ui-components';
import {Formik, FormikProps} from 'formik';
import React, {ReactElement, useContext} from 'react';
import {PhaseName} from '../../../../../constants/api-urls';
import usePost from '../../../../../shared/hooks/use-post.hook';
import usePut from '../../../../../shared/hooks/use-put.hook';
import {PhaseValidationResult} from '../../../../../shared/model/phase-validation-result.model';
import {ApiHelper} from '../../../../../utils/api-helper';
import {TransHelper} from '../../../../../utils/trans-helper';
import {AppSnackbarContext} from '../../../../shared/app-snackbar-provider/AppSnackbarProvider';
import NxWysiwyg from '../../../../shared/nx-wysiwyg/NxWysiwyg';
import {
  KeyedApprovalRule,
  toApprovalRule
} from '../../loan-configuration-step/approval-configuration/approval-rule-items/keyed-approval-rules.model';
import LoanConfigurationStep from '../../loan-configuration-step/LoanConfigurationStep';
import {LoanConfigurationContext} from '../../LoanConfiguration';
import {getInitialApprovalRulesValues} from '../hooks/use-initial-approval-rules-values.hook';
import {useSetConfigurationFormChanged} from '../hooks/use-set-configuration-form-changed.hook';
import {AgreementsPhase} from './agreements-phase.model';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.AGREEMENTS');

interface FormFields {
  approvalRules?: KeyedApprovalRule[];
  isApprovalRequired?: boolean;
  termsAndConditions: string;
}

interface Props {
  config: AgreementsPhase,
}

export default function AgreementsForm({config}: Props): React.ReactElement {

  const {process} = useContext(LoanConfigurationContext);
  const phaseUrl = ApiHelper.getPhaseConfigurationUrl(process.id, PhaseName.AGREEMENT);

  const {showErrorMessage} = useContext(AppSnackbarContext);
  const updatePhase = usePut<void, AgreementsPhase>(phaseUrl);
  const validatePhase = usePost<PhaseValidationResult, AgreementsPhase>(`${phaseUrl}/validate`);

  const buildPhaseConfig = (formFields: FormFields): AgreementsPhase => ({
    ...config,
    approvalRules: formFields.isApprovalRequired ? formFields.approvalRules?.map(toApprovalRule) : [],
    termsAndConditions: formFields.termsAndConditions
  });

  const submit = async (formFields: FormFields): Promise<void> =>
    await updatePhase(buildPhaseConfig(formFields)).catch(error => showErrorMessage(error.message));

  const AgreementDataForm = (
    {values, handleSubmit, submitForm, setFieldValue, dirty}: FormikProps<FormFields>
  ): ReactElement => {

    useSetConfigurationFormChanged(dirty);

    const validate = (): Promise<PhaseValidationResult> => validatePhase(buildPhaseConfig(values));

    const termsAndConditionsTab = {
      key: 'TERMS_AND_CONDITIONS',
      label: <PrefixTrans>TERMS_AND_CONDITIONS</PrefixTrans>,
      tabPanel: <NxWysiwyg name='termsAndConditions' value={values.termsAndConditions} setFieldValue={setFieldValue} />
    };

    return (
      <LoanConfigurationStep handleSave={submitForm} validationFunction={validate}>
        <form onSubmit={handleSubmit}>
          <NxTabs tabs={[termsAndConditionsTab]} />
        </form>
      </LoanConfigurationStep>
    );
  };

  return (
    <Formik<FormFields>
      onSubmit={submit}
      initialValues={{
        ...getInitialApprovalRulesValues(config),
        termsAndConditions: config.termsAndConditions ?? ''
      }}>
      {AgreementDataForm}
    </Formik>
  );
}
