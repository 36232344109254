import {useField} from 'formik';
import React, {ReactElement, useContext, useMemo, useState} from 'react';
import {DictionaryRequirement} from '../../../../../../shared/model/dictionary-requirement.model';
import {DictionaryHelper} from '../../../../../../utils/dictionary-helper';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {FieldDisablementReason} from '../../shared/configured-fields/field-disablement-reason.model';
import {StepContext} from '../../shared/loan-application-step/LoanApplicationStep';
import {ApplicantContext} from '../shared/ApplicantContext';
import {EntryRequirementHelper} from '../shared/entry-requirement-helper';
import OptionalRequirementsSelect from '../shared/OptionalRequirementsSelect';
import {IncomeSource} from './income-source.model';
import styles from './Income.module.scss';
import IncomeSourceForm, {IncomeSourceFromEntries} from './IncomeSourceFrom';

interface Props {
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.individualData.firstName.
   */
  formikFieldPath: string;
  requirement?: DictionaryRequirement;
  incomeSources: IncomeSource[];
  entries: IncomeSourceFromEntries;
}

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CUSTOMER_DATA.INCOME_SOURCES');
const {getEnabledEntryRequirements} = DictionaryHelper;
const {getOptionalRequirementsOptions, getInitialOptionalRequirements} = EntryRequirementHelper;

export default function Income({formikFieldPath, entries, requirement, incomeSources}: Props): ReactElement {

  const {isCoreCustomer} = useContext(ApplicantContext);
  const {isStepReadonly} = useContext(StepContext);
  const disablementReason = isCoreCustomer ? FieldDisablementReason.VALUE_MANAGED_BY_CBS : undefined;
  const disabled = isStepReadonly || !!disablementReason;
  const enabledRequirements = getEnabledEntryRequirements(requirement);
  const [selectedOptionalRequirements, setSelectedOptionalRequirements] =
    useState<number[]>(getInitialOptionalRequirements(incomeSources, enabledRequirements));
  const fieldPath = `${formikFieldPath}.incomeSources`;

  const optionalRequirementsOptions = useMemo(() =>
    getOptionalRequirementsOptions(enabledRequirements, entries.incomeRequirementEntries), [enabledRequirements]);

  const [, , helpers] = useField(fieldPath);

  const updateRequirementsSelectValue = (wasRequirementAdded: boolean, typeId: number): void =>
    helpers.setValue(wasRequirementAdded ? [...incomeSources, {typeId}] : filterSources(typeId));

  const removeIncomeSource = (typeId: number): void => {

    const filteredRequirements = selectedOptionalRequirements.filter(requirement => requirement !== typeId);

    setSelectedOptionalRequirements(filteredRequirements);
    helpers.setValue(filterSources(typeId));
  };

  const filterSources = (typeId: number): IncomeSource[] => incomeSources.filter(source => source.typeId !== typeId);

  return (
    <>
      <OptionalRequirementsSelect disabled={disabled}
                                  disablementReason={disablementReason}
                                  options={optionalRequirementsOptions}
                                  updateSelectValue={updateRequirementsSelectValue}
                                  selectedRequirements={selectedOptionalRequirements}
                                  setSelectedRequirements={setSelectedOptionalRequirements}
                                  label={<PrefixTrans>OPTIONAL_REQUIREMENTS</PrefixTrans>} />
      <div className={styles.row}>
        {
          incomeSources.map((incomeSource, index) => {

            const entry = enabledRequirements.find(entry => entry.dictionaryEntryId === incomeSource.typeId);

            if (!entry) {
              throw Error('Requirement type for existing income source not found');
            }

            return <IncomeSourceForm key={index}
                                     entry={entry}
                                     disabled={disabled}
                                     formPath={`${fieldPath}[${index}]`}
                                     deleteFunction={removeIncomeSource}
                                     disablementReason={disablementReason}
                                     entries={entries} />;
          })
        }
      </div>
    </>
  );
}
